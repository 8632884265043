import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import swal from "sweetalert2";
import { Company } from 'src/app/models/company';
import { environment } from 'src/environments/environment';
import { CompanyService } from 'src/app/services/company.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { InventoryService } from '../../../services/inventory.service';
import { StoreService } from 'src/app/services/store.service';
import { PrintService } from 'src/app/services/print.service';
import { UtilsService } from 'src/app/services/utils.service';
import * as xlsx from 'xlsx';

interface transferHeader {
  transferCode,
  transferBusSerie,
  transferOriginStoreCode,
  transferDestinationStoreCode,
  transferWarehouseCode,
  transferDescription,
  transferType,

  //auxiliares
  transferName
  transferPhone,
  transferAddress,
  transferAdviser,
  transferTaxDocument,
}

@Component({
  selector: 'app-transfer-process',
  templateUrl: './transfer-process.component.html',
  styleUrls: ['./transfer-process.component.scss'],
  providers: [CompanyService, WarehouseService, InventoryService, StoreService, PrintService, UtilsService]
})
export class TransferProcessComponent implements OnInit {
  // Variables auxiliares
  company = {} as Company;
  public transferHeader = {} as transferHeader;
  public transferDetail: any[] = [];
  public detail: any[] = [];
  public json = {};
  public date: string;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public userName: string;
  public isProcess: boolean;
  public isFinish: boolean;
  public isDisabled: boolean;
  public getAll: number = 0;
  public transferWarehouseCode: number;
  public url: string;
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public transferList = new Array();

  // DATA SELECT
  public warehouseList: any[] = new Array();
  public selectedItemsWarehouse = [];
  public dropdownSettingsWarehouse = {};

  // VARIABLE FOR SELECT 
  public storeList: any[] = new Array();
  public selectedItemsStore = [];
  public dropdownSettingsStore = {};

  public settings = {
    columns: {
      salio: {
        title: 'Salio'
      },

      entro: {
        title: 'Entro'
      },
      fecha: {
        title: 'Fecha',
      },
      serie: {
        title: 'Serie',
      },
      usuario: {
        title: 'Usuario',
      }
    }
  };

  constructor(
    private modalService: NgbModal,
    private cdref: ChangeDetectorRef,
    private _printService: PrintService,
    private _storeService: StoreService,
    private _utilsService: UtilsService,
    private _companyService: CompanyService,
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService
  ) { this.url = environment.url; }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    const _user = JSON.parse(localStorage.getItem('user'));
    this.userName = _user.userName ? _user.userName.toString() : '';
        
    this.isProcess = true;
    this.isFinish = false;
    this.isDisabled = false;
    this.date = this._utilsService.dateTime('dd-MM-yyy');
    
    await this.getCompany(this.companyCode);
    this.getStoreByCompany();
    this.getSelectSettingStore();
    this.getAllWarehouse();
    this.getSelectSettingWarehouse();
  }
  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.transferList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getSelectSettingWarehouse() {
    // Object.keys(selected).length > 0 ? this.selectedItemsWarehouse = [selected] : '';

    this.dropdownSettingsWarehouse = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      lazyLoading: false,
    };
  };

  getSelectSettingStore() {
    this.dropdownSettingsStore = {
      singleSelection: true,
      text: "Seleccione Sucursal...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };

  getAllWarehouse() {
    return this._warehouseService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result == true) {
          this.warehouseList = response.records[1].map(function(e) {        
            const result = {
              id: e.warehouseCode,
              itemName: e.warehouseName,
              phone: e.warehousePhone,
              address: e.warehouseClientAddress,
              client: e.warehouseClientCode,
              nit: e.nit == null ? '' : e.nit
            }
            return result; 
          }); 
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getStoreByCompany() {
    return this._storeService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result) {
          this.storeList = response.records.map(function(e) {        
            const result = {
              id: e.storeCode,
              itemName: e.storeName
            }
            return result; 
          }); 
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelectWarehouse(item: any) {
    this.transferWarehouseCode = item.id;
  }

  onItemSelectStore(item: any) {
    this.transferHeader.transferDestinationStoreCode = item.id;
  }

  // GET ALL TRANSFER BY WAREHOSUE
  getAllByWarehouse() {
    this.loading = true;
    this.isDisabled = true;

    return this._warehouseService.getTransferByWarehouse(this.transferWarehouseCode, 1).then(
      async response => {
        if (response.result) {
          this.transferList = [];
          this.transferList = response.records;
          this.temp = this.transferList;
          this.getAll = 1;
          this.isProcess = true;
          this.isFinish = false;
          this._utilsService.showNotification(1, response.message);
        } else {
          console.log(response.errorMessage);
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  // GET ALL TRANSFER PENDING BY COMPANY
  getAllByCompany() {
    this.loading = true;
    this.isDisabled = true;
    this.selectedItemsWarehouse = [];

    return this._warehouseService.getTransferByCompany(this.companyCode, 1).then(
      response => {
        if (response.result) {
          this.transferList = [];
          this.transferList = response.records;
          this.temp = this.transferList;
          this.getAll = 2;
          this.viewProcess();
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  // GET ALL TRANSFER COME IN STORES
  getAllComeInStore() {
    this.loading = true;
    this.isDisabled = true;
    this.selectedItemsWarehouse = [];

    return this._warehouseService.getTransferByCompany(this.companyCode, 0).then(
      response => {
        if (response.result) {          
          this.transferList = response.records;
          this.temp = this.transferList;
          this.getAll = 3;
          console.log(this.transferList);
          this.viewFinish();
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.transferList;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-transferencias.xlsx');
  }

  // GET ONE CLIENT
  viewFunction($event) {
    this.loading = true;
    $event.preventDefault();

    this._warehouseService.getOneTransfer(this.activeRow.transferCode).then(
      async (response) => {
        if (response.result == true) {
          this.transferHeader = this.activeRow;
          this.transferDetail = response.records[0].detail;
          await this.inventoryLine();      
          
          const logo = await this._companyService.getOne(this.companyCode);
          logo.records[0].companyLogoUrl != 'undefined' && (this.company.companyLogoUrl = logo.records[0].companyLogoUrl);

          console.log(this.companyCode);
          if(this.companyCode == 22){
            this._printService.noteTransferNail(this.activeRow, this.detail, this.company);
          }
          else{
            this._printService.noteTransfer(this.activeRow, this.detail, this.company);
          }
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(async () => {
      this.loading = false;
    });
  }

  // GET INVENTORY DATA
  async inventoryLine() {
    this.detail = [];

    for (const e of this.transferDetail) {
      await this._inventoryService.getOne(e.transferInventoryDetailInventoryCode).then(
        async (response) => {
          let item = response.records[0];
          let subtotal = e.transferInventoryDetailQuantity * item.inventoryPrice;

          if (e.transferDetailType == 1) {
            this.detail.push({
              transferInventoryDetailQuantity: e.transferInventoryDetailQuantity,
              inventoryBusinessCode: item.inventoryBusinessCode,
              transferDetailType: 1,
              quantity: e.transferInventoryDetailQuantity,
              description: e.transferInventoryDetailDescription,
              code: item.inventoryBusinessCode,
              unitPrice: item.inventoryPrice,
              subtotal: subtotal
            })
          } else {
            await this._warehouseService.getOneSerial(this.transferHeader.transferCode, e.transferInventoryDetailInventoryCode).then(
              (response) => {
                let detailCancel = response.records[0].detail;
                let seriales: any[] = [];

                detailCancel.forEach(i => {
                  seriales.push({
                    serialCode: i.trasferSerialDetailSerialCode,
                    description: i.trasferSerialDetailDescription
                  })
                });

                this.detail.push({
                  inventoryBusinessCode: item.inventoryBusinessCode,
                  transferInventoryDetailQuantity: e.transferInventoryDetailQuantity,
                  transferDetailType: 0,
                  quantity: e.transferInventoryDetailQuantity,
                  description: e.transferInventoryDetailDescription,
                  code: item.inventoryBusinessCode,
                  unitPrice: item.inventoryPrice,
                  subtotal: subtotal,
                  serial: seriales
                })                
              }
            )
          }
        });
    }
  }

  //BILLING CLIENT
  alertBilling($event) {
    $event.preventDefault();
    const clientTaxDocument = this.activeRow.clientTaxDocument;
    const transferCode = this.activeRow.transferCode;

    swal.fire({
      title: 'Esta seguro',
      text: "El  facturar es un proceso que retira la trasnferencia definitivamte!",
      icon: "warning",
      width: 750,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: 'Factura Normal',
      denyButtonText: 'Fatura Cambiaria',
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-success",
        denyButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
    }).then((result) => {
      if (result.isDismissed) {
        console.log('candelado')
      } else {
        if (result.isConfirmed) {
          this.billing(clientTaxDocument, transferCode);
        } else if (result.isDenied) {
          this.billing_exchange(clientTaxDocument, transferCode);
        }
        swal.fire({
          title: "Procesando!",
          text: "Espera un momento por favor.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    })
  }

  billing(clientTaxDocument, transferCode) {
    this.loading = true;

    let billingType;
    this.company.companyInvoiceType == 1 ? billingType = 'inventory' : billingType = 'description';
    setTimeout(async () => {
      window.open(this.url + billingType + '-billing?nit=' + clientTaxDocument + '&transfer=' + transferCode);
      this.cancelFunction(transferCode, undefined);
      this.loading = false;
    }, 1500);
  }

  billing_exchange(clientTaxDocument, transferCode) {
    this.loading = true;

    let billingType;
    this.company.companyInvoiceType == 1 ? billingType = 'inventory' : billingType = 'description';
    setTimeout(async () => {
      window.open(this.url + billingType + '-exchange-note?nit=' + clientTaxDocument + '&transfer=' + transferCode);
      this.cancelFunction(transferCode, undefined);
      this.loading = false;
    }, 1500);
  }

  //CANCEL
  alertCancel($event) {
    $event.preventDefault();

    swal.fire({
      title: "Esta seguro",
      text: "Se cancelara la trasnferencia!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, cancelar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.cancelFunction(undefined, undefined);
        swal.fire({
          title: "Procesando!",
          text: "Espera un momento por favor.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  //BILLING CLIENT
  async cancelFunction(billing?, destination?) {    
    this.loading = true;
    const activeRow = this.activeRow;

    // Cancelar Transferencia
    await this._warehouseService.getOneTransfer(this.activeRow.transferCode).then(
      async (response) => {
        if (response.result) {
          this.transferHeader = this.activeRow;
          this.transferDetail = response.records[0].detail;
          await this.inventoryLine();

          this.json = {
            "header": {
              "transferDestinationStoreCode": destination != undefined ? destination : this.activeRow.transferOriginStoreCode,
              "transferCode": this.activeRow.transferCode,
              "transferType": this.activeRow.transferType
            },
            "detail": this.detail
          }
        }
      });

    //RETRASAR EL CONSUMO 
    setTimeout(async () => {
      // UPDATE DESTINATION
      await this._warehouseService.insertDestination(this.json).then(
        (response) => {
          if (response.result == true) {
            let message = 'ms';
            if (billing != undefined || destination != undefined) {
              billing != undefined ? message = 'Los articulos fueron reintegrados nuevamente al inventario.' : '';
              destination != undefined ? message = 'Los articulos fueron trasladados de sucursal.' : '';
              this.cleanModel();
            } else {
              setTimeout(async () => {
                this._printService.cancelTransfer(this.date, this.userName, this.company, activeRow, this.detail);                
                this.cleanModel();
              }, 2000);
            }
            this._utilsService.showNotification(1, response.message + '<br>' + message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    }, 5000);
  }

  // MODAL
  closeResult = '';
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //AL ACEPTAR
      this.cancelFunction(undefined, this.transferHeader.transferDestinationStoreCode)
      this.cleanModal();
    }, (reason) => {
      //AL CERRAR
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  cleanModal() {
    this.selectedItemsStore = null;
  }

  // Limpiar Campos
  cleanModel() {
    this.detail = [];
    this.transferHeader = {} as transferHeader;
    this.transferDetail = [];

    switch (this.getAll) {
      case 1: this.getAllByWarehouse();
        break;
      case 2: this.getAllByCompany();
        break;
      case 3: this.getAllComeInStore();
        break;
    }
  }
  
  viewProcess() {
    this.isProcess = true;
    this.isFinish = false;
  }

  viewFinish() {
    this.isProcess = false;
    this.isFinish = true;
  } 
}
