<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">    
    <div class="row link-header ml-5">        
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="a-return" routerLink="/cancellation">Anulación y Reimpresion/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/cancellation-invoice">Factura</a>
        </div>
    </div>
</div>
<br />
<div class="main-content">
    <!-- LISTAR FACTURAS -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">                        
                        <div class="col-sm-12">
                            <b style="font-size: 1rem;">{{storeName}}</b>
                        </div>
                        <div class="col-md-4">
                            <label>Del:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="begDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Para:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="endDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <br>
                            <div class="form-group">
                                <button class="btn btn-info btn-sale" style="width: 100%;" [disabled]="isDisabled"
                                    (click)="getAll()">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN LISTAR FACTURAS -->

    <!-- TABLA -->
    <div class="row" *ngIf="isReprint">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Facturas</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Correlativo"></ngx-datatable-column>
                            <ngx-datatable-column name="Letra"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Factura"></ngx-datatable-column>
                            <ngx-datatable-column name="Serie"></ngx-datatable-column>
                            <ngx-datatable-column name="Total"></ngx-datatable-column>
                            <ngx-datatable-column name="Nit"></ngx-datatable-column>
                            <ngx-datatable-column name="Cliente"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="true"
                                [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="searchInvoice($event)" ngbTooltip="Reimprimir" placement="top">
                                        <i class="now-ui-icons files_paper"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        *ngIf="row.invoiceStatus == 1" (click)="getAnnular($event)" ngbTooltip="Anular"
                                        placement="left">
                                        <i class="fas fa-times"></i>
                                    </a>
                                    <p href="#" class="btn btn-round btn-gray btn-icon btn-sm remove"
                                        *ngIf="row.invoiceStatus == 0" ngbTooltip="Anulada" placement="left">
                                        <i class="fas fa-times"></i>
                                    </p>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN TABLA -->

    <!-- Imprimir Factura Ticket -->
    <div class="row justify-content-center" *ngIf="isPrintT">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-info btn-round" (click)="printTest()" style="width: 100%;">
                                <i class="now-ui-icons files_paper"></i> Imprimir
                            </button>
                        </div>
                        <div class="col-md-6">
                            <a class="btn btn-success btn-round" (click)="viewReprint()" style="width: 100%;">
                                <i class="now-ui-icons ui-1_check"></i> Continuar
                            </a>
                        </div>
                    </div>
                    <hr>
                    <div id="printSection" #printSection style="width: 226px; margin: 0px auto;">
                        <table style="width: 90%;">
                            <tr>
                                <td style="width: 100%; text-align: center;">
                                    <span *ngIf="felComplement.invoiceStatus === 0"><b>ANULADA</b></span>
                                    <br>FACTURA
                                    <br>--DATOS DEL EMISOR--
                                    <br>{{company.companyName | uppercase}}
                                    <br>Nit: {{company.companyTaxDocument}}
                                    <br>{{fel.storeName | uppercase}}
                                    <br>{{fel.storeAddress}}, {{fel.storeMunicipality}}, {{fel.storeDepartment}}.
                                    <br>Serie:
                                    <br>{{fel.invoiceSeries}}
                                    <br>No. DTE:
                                    <br>{{fel.invoiceNumber}}
                                    <br>-------------------------------------
                                    <br>DATOS DE COMPRADOR
                                    <br>{{fel.invoiceDate | date:'dd-MM-yyyy'}}
                                    <br>Nit: {{felComplement.nit}}
                                    <br>Nombre: {{felComplement.cliente}}
                                </td>
                            </tr>
                            <tr>
                                <table style="width: 100%;">
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <br>DETALLE DE VENTA
                                            <br>------------------------------------
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cant.</td>
                                        <td>Desc.</td>
                                        <td style="text-align: right;">Precio</td>
                                    </tr>
                                    <tr *ngFor="let e of felDetail">
                                        <td>{{e.cantidadCompra | number:'1.2-2'}}</td>
                                        <td>{{e.descripcionCompra}}</td>
                                        <td style="text-align: right;">
                                            {{e.totalCompra | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <br>IVA: {{fel.invoiceTax | number:'1.2-2'}}
                                            <br><b>TOTAL: Q {{fel.invoiceTotal | number:'1.2-2'}}</b>
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                            <tr>
                                <td style="text-align: center;">
                                    -------------------------------------
                                    <br>NUMERO DE AUTORIZACIÓN
                                    <br><span style="font-weight: normal;">
                                        {{fel.invoiceAuthorization}}
                                    </span>
                                    <br>Fecha Certificación
                                    <br>{{fel.invoiceDate | date:'dd-MM-yyyy'}}
                                    <br>Sujeto a pagos trimestrales ISR
                                    <br>-------------------------------------
                                    <br>CERTIFICADOR
                                    <br>{{fel.provider | uppercase}}
                                    <br>Nit: {{fel.providerTaxDocument}}
                                    <br>¡Gracias por tu compra!
                                    <br>---------------------
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin imprimir factura Ticket-->

    <!-- Imprimir Factura Carta-->
    <div class="row justify-content-center" *ngIf="isPrintL">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-round" [useExistingCss]="true" printSectionId="printLetter"
                                ngxPrint style="width: 50%;"><i class="now-ui-icons files_paper"></i> Imprimir
                            </button>
                        </div>
                        <div class="col-md-6 text-center">
                            <a class="btn btn-success btn-round" (click)="continue()" style="width: 50%;">
                                <i class="now-ui-icons ui-1_check"></i> Continuar
                            </a>
                        </div>
                    </div>
                    <hr>
                    <div id="printLetter" #printLetter class="row justify-content-center">
                        <div style="border: 1px solid #2CA8FF; border-radius: 10px; width: 816px;">
                            <table style="width: 100%;">
                                <thead>
                                    <tr>
                                        <td rowspan="5" style="width: 18%;">
                                            <img style="width: 110px; margin:10px auto; display:block;"
                                                src="{{companyLogoUrl}}">
                                        </td>
                                        <th style="width: 64%; font-size: 19px; text-align: center;">
                                            {{fel.storeName}} <!-- {{fel.transmitter.transmitterName}}  -->
                                        </th>
                                        <td style="width: 18%; font-size: 10px;">Factura Electronica Fel</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 10px; text-align: center;">
                                            {{company.companyName}} <!-- {{fel.transmitter.transmitterComercialName}} -->
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 10px; text-align: center;">
                                            NIT: {{company.companyTaxDocument}}
                                            <!-- {{fel.transmitter.transmitterTaxDocument}} -->
                                        </td>
                                        <td>Serie: {{fel.invoiceSeries}}
                                            <!-- {{dte['dte:NumeroAutorizacion'][0]['Serie'][0]}} -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 10px; text-align: center;">
                                            {{fel.storeAddress}}.
                                            <!-- {{fel.transmitter.transmitterAddress}}.
                                            {{fel.transmitter.transmitterMunicipality}},
                                            {{fel.transmitter.transmitterDepartment}} -->
                                        </td>
                                        <td>No. DTE: {{fel.invoiceNumber}} 
                                            <!-- {{dte['dte:NumeroAutorizacion'][0]['Numero'][0]}} -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="font-size: 10px; font-size: 19px; text-align: center;">
                                            FACTURA <span *ngIf="felComplement.invoiceStatus === 0">ANULADA</span>
                                        </th>
                                        <td>Fecha: {{fel.invoiceDate | date:'dd-MM-yyyy'}}
                                            <!-- {{fel.invoice.invoiceDate | date:'dd-MM-yyyy'}} -->
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <br>
                        <div style="border: 1px solid #2CA8FF; border-radius: 10px 10px 0px 0px; width: 816px; min-height: 800px">
                            <table style="width: 100%;">
                                <tbody>
                                    <tr style="max-height: 15px;">
                                        <td colspan="4">Nombre: {{felComplement.cliente}}
                                            <!-- {{fel.receiver.receiverName}} -->
                                        </td>
                                        <td colspan="3">Nit: {{felComplement.nit}}
                                            <!-- {{fel.receiver.receiverTaxDocument}} -->
                                        </td>
                                    </tr>
                                    <!-- <tr style="max-height: 15px; border-bottom: 1px solid #2CA8FF;">
                                        <td colspan="7">Dirección: {{client.clientAddress}}</td>
                                    </tr> -->
                                    <tr
                                        style="font-size: 10px; text-align: center; border-top: 1px solid #2CA8FF; border-bottom: 1px solid #2CA8FF;">
                                        <th style="border-right: 1px solid  #2CA8FF;">CANTIDAD</th>
                                        <th style="border-right: 1px solid  #2CA8FF;">TIPO</th>
                                        <th style="border-right: 1px solid  #2CA8FF; width: 350px;">DESCRIPCIÓN</th>
                                        <th style="border-right: 1px solid  #2CA8FF;">PRECIO UNI.</th>
                                        <th style="border-right: 1px solid  #2CA8FF;">IMPUESTO</th>
                                        <th style="border-right: 1px solid  #2CA8FF;">DESCUENTO</th>
                                        <th style="width: 120px;">TOTAL</th>
                                    </tr>
                                    <tr *ngFor="let e of felDetail">
                                        <td style="text-align: center;">{{e.cantidadCompra | number:'1.2-2'}}</td>
                                        <td style="text-align: center;">{{e.tipo}}</td>
                                        <td>{{e.descripcionCompra}}</td>
                                        <td style="text-align: right;">{{e.precioUnitarioCompra | number:'1.2-2'}}</td>
                                        <td style="text-align: right;">{{e.impuesto | number:'1.2-2'}}</td>
                                        <td style="text-align: right;">{{e.descuento | number:'1.2-2'}}</td>
                                        <td style="text-align: right;">{{e.totalCompra | number:'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="border: 1px solid #2CA8FF; border-radius: 0px 0px 10px 10px; width: 816px;">
                            <table style="width: 100%;">
                                <!-- <tr style="border-bottom: 1px solid #2CA8FF;">
                                    <td colspan="7" style="text-align: center; font-size: 10px;">Sujeto a pagos
                                        trimestrales ISR</td>
                                </tr> -->
                                <tr style="min-height: 30px; max-height: 30px;">
                                    <td style="font-size: 10px">Total en Letras:</td>
                                    <!-- <td colspan="4" style="font-size: 10px"> , QUETZALES CON 00/100</td> -->
                                    <td colspan="4" style="font-size: 10px">{{fel.invoiceTotal | letras}}</td>
                                    <th style="font-size: 15px; text-align: right; border-left: 1px solid #2CA8FF; border-right: 1px solid #2CA8FF;">
                                        TOTAL: Q {{fel.invoiceTotal | number:'1.2-2'}} 
                                        <!-- {{fel.invoiceTax | number:'1.2-2'}} -->
                                        <!-- {{fel.invoice.invoiceTotal | number:'1.2-2'}} -->
                                        <!-- IDP: {{fel.invoice.invoiceOilTaxValue | number:'1.2-2'}} -->
                                        <!-- IVA: {{fel.invoice.invoiceTaxValue | number:'1.2-2'}} -->
                                    </th>
                                    <!-- <th colspan="2" style="text-align: right;">{{moneda}}{{total}}</th> -->
                                </tr>
                            </table>
                        </div>
                        <br>
                        <div style="border: 1px solid #2CA8FF; border-radius: 10px; width: 816px; min-height: 40px;">
                            <p style="padding-left: 10px; padding-top: 5px;">
                                No. de Autorización: {{fel.invoiceAuthorization}}
                                <!-- {{dte['dte:NumeroAutorizacion'][0]['_']}} -->
                            </p>
                            <p style="padding-left: 10px;">
                                Fecha de Certificación: {{fel.invoiceDate | date:'dd-MM-yyyy'}}
                                <!-- {{dte['dte:FechaHoraCertificacion'][0]}} -->
                            </p>
                            <p style="text-align: center; font-size: 10px;">
                                Certificador: {{fel.provider}}. Nit: {{fel.providerTaxDocument}}
                                <!-- {{dte['dte:NombreCertificador'][0]}}. Nit: {{dte['dte:NITCertificador'][0]}} -->
                            </p>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin imprimir factura carta -->
</div>