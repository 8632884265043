import { Component, OnInit } from '@angular/core';
import * as printJS from 'print-js';
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { AccessService } from 'src/app/services/access.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-cancellation-exchange-bill',
  templateUrl: './cancellation-exchange-bill.component.html',
  styleUrls: ['./cancellation-exchange-bill.component.scss'],
  providers: [InvoiceService, CompanyService, AccessService, UtilsService]
})
export class CancellationExchangeBillComponent implements OnInit {
  loading;
  // Variables auxiliares
  company = {} as Company;
  public isBeginning: boolean;
  public isReprint: boolean;
  public isPrint: boolean;
  public isDisabled: boolean;
  public userCommerceCode: number;
  public begDate: string;
  public endDate: string;
  public reasonForCancellation;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public storeName: string;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      invoiceAuthorization: {
        title: 'No. de Autorización'
      },
      serie: {
        title: 'Serie Fel',
      },
      factura: {
        title: 'No. Factura Fel'
      },
      invoiceInternSerial: {
        title: 'Serie Interna'
      },
      invoiceInternNumber: {
        title: 'Correlativo Interna',
      },
      invoiceSubtotal: {
        title: 'Sub Total',
        type: 'number'
      },
      invoiceTax: {
        title: 'IVA',
        type: 'number'
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      nit: {
        title: 'Nit',
      },
      cliente: {
        title: 'Cliente',
      },
      estado: {
        title: 'Estado',
      },
      usuario: {
        title: 'Usuario',
      }
    }
  };

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp = new Array();
  public invoiceList: any[] = new Array();

  //Reprint
  public dte: any;
  public fel: any;
  public felDetail: any;
  public felComplement: any;

  constructor(    
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeName = _data.storeName ? _data.storeName.toString() : null;

    this.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');
    
    this.viewBeginning();
    await this.getCompany(this.companyCode);
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isReprint = false;
    this.isPrint = false;
  }

  viewReprint() {
    this.isBeginning = false;
    this.isReprint = true;
    this.isPrint = false;
  }

  viewPrint() {
    this.isBeginning = false;
    this.isReprint = false;
    this.isPrint = true;
  }

  printTest() {
    printJS({
      printable: "printSection",
      type: "html",
      style: "#printSection{width: 224px; text-align: center}",
    });
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.invoiceList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////CRUD///////////////////////
  //Ver todas las factura
  getAll() {
    this.isDisabled = true;
    if (this.begDate && this.endDate) {
      this.loading = true;

      let json = {
        "storeCode": this.storeCode,
        "begDate": this.begDate,
        "endDate": this.endDate
      }

      this._invoiceService.getAllExchangeByCompany(json).then(
        async (response) => {
          if (response.result == true) {
            this.invoiceList = response.records;
            this.temp = this.invoiceList;
            this.isDisabled = false;
            this.isReprint = true;
            this._utilsService.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this.isDisabled = false;
      // Mostrar notificación
      this._utilsService.showNotification(2, "Ingresar Datos");
    }
  }

  // Obtener una Factura
  searchInvoice($event) {
    $event.preventDefault();
    this.loading = true;

    //INFILE
    if (this.company.companyCertifier == 0) {
      let uuid = this.activeRow.invoiceExchangeAuthorization;
      window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');
      this.loading = false;
    }
    //MEGAPRINT
    else if (this.company.companyCertifier == 1) {
      this._invoiceService.getOneMegaprintExchange(this.activeRow.id).then(
        async (response) => {
          if (response.result == true) {
            if (this.company.companyInvoiceFuel == 1 || this.company.companyPDFType == 1) {
              this.fel = response.records;
              this.felDetail = response.records.detail;
              this.felComplement = this.activeRow;
              this.viewPrint();
            } else {
              // var pdfResult = response.invoiceExchangePdf;
              const pdfResult = response.records.pdfBase64;
              const pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>");
            }
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });
    }

  }

  // Anular Factura
  getAnnular($event) {
    $event.preventDefault();
    console.log(this.activeRow);
    swal.fire({
      title: "¿Cúal es el motivo de la anulación?",
      html:
        '<div class="form-group">' +
        '<input id="input-field" type="text" class="form-control" />' +
        "</div>",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Solicitar",
      buttonsStyling: false
    })
      .then(result => {
        this.reasonForCancellation = (document.getElementById("input-field") as HTMLInputElement).value;
        if (result.value) {
          this.annular(this.activeRow.id, this.activeRow.nit, this.activeRow.invoiceExchangeAuthorization);
          swal.fire({
            title: "Éxito!",
            text: "Solicitud enviada.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false
          });
        }
      });
  }

  async annular(invoiceCode, receiverTaxDocument, invoiceAuthorization) {
    this.isDisabled = true;

    const json = {
      "invoiceExchange": {
        "invoiceExchangeCode": invoiceCode,
        "invoiceExchangeAuthorization": invoiceAuthorization,
        "invoiceExchangeDate": null,  
        "invoiceExchangeComment": this.reasonForCancellation

      },
      "receiver": {
        "receiverTaxDocument": receiverTaxDocument
      },
      "transmitter": {
        "transmitterTaxDocument": this.company.companyTaxDocument,
        "transmitterUser": this.company.companyPrefix,
        "transmitterPrefix": this.company.companyPrefix, 
        "transmitterToken": this.company.companyToken,
        "transmitterKey": this.company.companyKey
      },
      "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint"
    };

    console.log(json);
    this.loading = true;
    this._invoiceService.annularExchange(json).then(
      async (response) => {
        if (response.result == true) {

          this.getAll();          
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
          this.isDisabled = false;
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.isDisabled = false;
      this.loading = false;
    });
  }


  ///////////////////////DESCARGAR EXCEL///////////////////////
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-impresion-anulacion-factura-cambiaria.xlsx');
  }
}
