import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import swal from "sweetalert2";
import { AccessService } from 'src/app/services/access.service';
import { CompanyService } from 'src/app/services/company.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { ClientService } from 'src/app/services/client.service';
import { Client } from 'src/app/models/client';
import { UtilsService } from 'src/app/services/utils.service';

interface warehouse {
  warehouseCode;
  warehouseCompanyCode;
  warehouseClientCode;
  warehouseTaxDocument;
  warehouseName;
  warehouseClientAddress;
  warehousePhone;
  warehouseStatus;
  warehouseDeleted;
}

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss'],
  providers: [WarehouseService, AccessService, CompanyService, ClientService, UtilsService]
})

export class WarehouseComponent implements OnInit {
  // MODELS VARIABLE 
  public warehouse = {} as warehouse;
  public client = {} as Client;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isEdit: boolean;
  public withClient: boolean;
  public isDisabled: boolean;
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public warehouseList = new Array();

  // VARIABLE FOR SELECT 
  public companyList: any[] = new Array();
  public selectedItems = [];
  public dropdownSettings = {};

  public clientList: any[] = new Array();
  public selectedItemsClient = [];
  public dropdownSettingsClient = {};

  constructor(
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _clientService: ClientService,
    private _companyService: CompanyService,
    private _warehouseService: WarehouseService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    // LOAD VARIABLE
    this.isEdit = false;
    this.withClient = false;
    this.isDisabled = false;
    this.warehouse.warehouseName = '';
    this.warehouse.warehousePhone = '';
    this.warehouse.warehouseClientCode = null;

    // LOAD INFORMATION
    await this.getAllCompany();
    this.getSelectSettingClient(this.selectedItemsClient);
    if (this.companyCode > 1) {
      this.warehouse.warehouseCompanyCode = this.companyCode;
      const _selectedItemCompany = this.companyList.find(x => x.id == this.companyCode);
      this.getSelectSetting(_selectedItemCompany);
      this.getAllByCompany();
      this.getClientByCompany();
    } else {
      this.getSelectSetting(this.selectedItems);
      this.getAll();
      this.getAllClient();
    }
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.warehouseList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {          
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSetting(selected) {
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
      disabled: this.companyCode > 1 ? true : false
    };
  };

  getSelectSettingClient(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsClient = [selected] : '';

    this.dropdownSettingsClient = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
      disabled: this.withClient == true ? false : true
    };
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getAllCompany() {
    return this._companyService.getAll().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.companyList.push({
              id: e.companyCode,
              itemName: e.companyName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getAllClient() {
    return this._clientService.getAll().then(
      response => {
        if (response.result == true) {
          // console.log(response.records);
          response.records.forEach(e => {
            this.clientList.push({
              id: e.clientCode,
              itemName: e.clientName,
              phone: e.clientPhone,
              address: e.clientAddress,
              taxDocument: e.clientTaxDocument
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getClientByCompany() {
    return this._clientService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result == true) {
          // console.log(response.records);
          response.records.forEach(e => {
            this.clientList.push({
              id: e.clientCode,
              itemName: e.clientName,
              phone: e.clientPhone,
              address: e.clientAddress,
              taxDocument: e.clientTaxDocument
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelect(item: any) {
    this.warehouse.warehouseCompanyCode = item.id;
  }

  onItemSelectClient(item: any) {
    this.warehouse.warehousePhone = item.phone;
    this.warehouse.warehouseClientCode = item.id;
    this.warehouse.warehouseName = item.itemName;
    this.warehouse.warehouseClientAddress = item.address;
    this.warehouse.warehouseTaxDocument = item.taxDocument;
  }

  // SWITCH ENABLE CLIENT
  onClient() {
    if (this.withClient == true) {
      this.getSelectSettingClient(this.selectedItemsClient);
    }
    else {
      this.warehouse.warehousePhone = '';
      this.warehouse.warehouseClientCode = null;
      this.warehouse.warehouseName = '';
      this.warehouse.warehouseClientAddress = '';
      this.warehouse.warehouseTaxDocument = '';
      this.selectedItemsClient = [];
      this.getSelectSettingClient(this.selectedItemsClient);
    }
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL
  getAll() {
    this.loading = true;
    return this._warehouseService.getAll().then(
      response => {
        if (response.result) {
          this.warehouseList = response.records;
          this.temp = this.warehouseList
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ALL BY COMPANY
  getAllByCompany() {
    this.loading = true;
    return this._warehouseService.getAllByCompanyStatus(this.companyCode).then(
      response => {
        if (response.result) {
          this.warehouseList = response.records[1];
          this.temp = this.warehouseList;
          console.log('bodegas', this.temp);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // INSERT
  save() {
    if (this.warehouse.warehouseCompanyCode > 0 && this.warehouse.warehouseName != '') {
      this.isDisabled = true;
      this.loading = true;

      this.warehouse.warehouseStatus = 1;
      this.warehouse.warehouseDeleted = 0;
      //this.warehouse.warehouseClientCode == null ? 0 : 1;
      
      if(this.warehouse.warehouseClientCode == null){
        this.warehouse.warehouseClientCode = 0;
      }
      else{
        this.warehouse.warehouseClientCode = this.warehouse.warehouseClientCode;
      }

      console.log(this.warehouse.warehouseClientCode);

      console.log(this.warehouse);
      this._warehouseService.insert(this.warehouse).then(
        async (response) => {
          if (response.result == true) {
            this.companyCode == 1 ? this.getAll() : this.getAllByCompany();
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this._utilsService.showNotification(2, 'Falto llenar campos');
    }
  }

  // UPDATE
  update() {
    this.isDisabled = true;
    // console.log(this.warehouse)
    this.warehouse.warehouseTaxDocument = undefined;
    this.warehouse.warehouseClientCode = 1;
    this._warehouseService.update(this.warehouse).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          this.companyCode == 1 ? this.getAll() : this.getAllByCompany();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.warehouse.warehouseCode = this.activeRow.warehouseCode;
    this.warehouse.warehouseStatus = 0;
    this.warehouse.warehouseDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "El tipo de producto se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(async result => {
      if (result.value) {
        await this.update();
        this.cleanModel();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  updateActive($event) {
    $event.preventDefault();
    console.log('Active Row', this.activeRow);    

    let jsonUpdate = {
      "warehouseCode": this.activeRow.warehouseCode,
      "warehouseStatus": 1,
      "warehouseDeleted": 0
    };

    console.log('inventory -> ', jsonUpdate);

    this._warehouseService.update(jsonUpdate).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          this.getAllByCompany();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.isDisabled = false;
      this.loading = false;
    });
  }

  updateInactive($event) {
    $event.preventDefault();
    console.log('Inactive Row', this.activeRow);


    let jsonUpdate = {
      "warehouseCode": this.activeRow.warehouseCode,
      "warehouseStatus": 2,
      "warehouseDeleted": 0
    };

    console.log('inventory -> ', jsonUpdate);

    this._warehouseService.update(jsonUpdate).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          this.getAllByCompany();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.isDisabled = false;
      this.loading = false;
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.warehouse.warehouseCode = this.activeRow.warehouseCode;
    this.warehouse.warehouseCompanyCode = this.activeRow.warehouseCompanyCode;
    this.warehouse.warehouseClientCode = this.activeRow.warehouseClientCode;
    this.warehouse.warehouseClientAddress = this.activeRow.warehouseClientAddress;
    this.warehouse.warehouseName = this.activeRow.warehouseName;
    this.warehouse.warehousePhone = this.activeRow.warehousePhone;

    const _selectedItem = this.companyList.find(x => x.id == this.activeRow.warehouseCompanyCode);
    this.getSelectSetting(_selectedItem);

    if (this.activeRow.warehouseClientCode > 0) {
      this.withClient = true;
      const _selectedItemClient = this.clientList.find(x => x.id == this.activeRow.warehouseClientCode);
      this.warehouse.warehouseTaxDocument = _selectedItemClient.taxDocument;
      this.getSelectSettingClient(_selectedItemClient);
    } else {
      this.withClient = false;
      this.selectedItemsClient = [];
      this.getSelectSettingClient(this.selectedItemsClient);      
    }
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //CANCEL EDIT
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.isDisabled = false;
    this.withClient = false;
    this.warehouse = {} as warehouse;
    this.warehouse.warehouseName = '';
    this.warehouse.warehousePhone = '';
    this.warehouse.warehouseClientCode = null;
    this.selectedItemsClient = [];
    this.getSelectSettingClient(this.selectedItemsClient);

    if (this.companyCode > 1) {
      this.warehouse.warehouseCompanyCode = this.companyCode;
    } else {
      this.selectedItems = [];
      this.getSelectSetting(this.selectedItems);
    }
  }
}
