<footer class="footer" >
    <div class="container-fluid">
        <nav>
            <ul>
                &copy; 2021, ARAUKO ERP. Todos los derechos reservados. {{version}}
            </ul>
        </nav>
        <!-- <div class="copyright" id="copyright">
            a
            <a class="text-info" href="#" target="_blank"></a>.
        </div> -->
    </div>
</footer>