import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import swal from "sweetalert2";

import { Data } from 'src/app/models/data';
import { Plan } from 'src/app/models/plan';
import { UtilsService } from 'src/app/services/utils.service';
import { AccessService } from '../../../services/access.service';
import { CompanyType } from 'src/app/models/companyType';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-company-type',
  templateUrl: './company-type.component.html',
  styleUrls: ['../../../app.component.scss'],
  providers: [UtilsService, CompanyService, AccessService]
})

export class CompanyTypeComponent implements OnInit {
  @ViewChild('formulario') formulario!: NgForm;

  // MODELS VARIABLE 
  public plan = {} as Plan;
  public data = {} as Data;
  public isEdit: boolean = false;
  public loading: boolean = false;
  public isDisabled: boolean = false;
  public companyType = {} as CompanyType;
  public companyCode: number;
  public userCode: number;

  // VARIABLE FOR DATA TABLE
  public temp = [];
  public activeRow: any;
  public entries: number = 10;
  public selected: any[] = [];
  public companytypeList = new Array();
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;

  constructor(
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _companyService: CompanyService,
  ) { }

  ngOnInit() {
    // GET ID USER & ID COMPANY    
    const _user = JSON.parse(localStorage.getItem('user'));
    this.data.companyCode = _user.userCompany ? parseInt(_user.userCompany.toString()) : 0;
    this.data.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;

    // LOAD INFORMATION
    this.plan.planDiscount = 0;
    this.getAll();
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.companytypeList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL COMPANY PLAN
  getAll() {
    this.loading = true;

    return this._companyService.getAllCompanyType().then(
      response => {
        if (response.result) {
          this.companytypeList = response.records;
          this.temp = this.companytypeList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  validate(type: number, name: string): boolean {
    switch (type) {
      case 1: return this.formulario?.controls[name]?.errors
        && this.formulario?.controls[name]?.touched;
        break;
        case 2: return this.formulario?.controls[name]?.value < 0;
        break;
    }
  }

  validate_number(type: number, name: string, name_two?: string): void {
    switch (type) {
      case 1: (this.plan[name] < 0) && (this.plan[name] = 0);
        break;
      case 2: (this.plan[name] < 0 || this.plan[name] > this.plan[name_two]) && (this.plan[name] = 0);;
        break;
    }
  }

  // INSERT
  save() {
    if (this.formulario.valid && this.plan.planPrice > this.plan.planDiscount) {
      this.isDisabled = true;
      this.loading = true;

      this.companyType.companyTypeStatus = 1;
      this.companyType.companyDeleted = 0;

      this._companyService.insertCompanyType(this.companyType).then(
        async (response) => {
          if (response.result == true) {
            this.getAll();
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this.formulario.control.markAllAsTouched();
      this._utilsService.showNotification(2, 'Falto llenar campos');
    }
  }

  // UPDATE
  update() {
    if (this.formulario.valid && this.plan.planPrice > this.plan.planDiscount) {
      this.loading = true;
      this.isDisabled = true;

    this._companyService.updateCompanyType(this.companyType).then(
      async (response) => {
        if (response.result == true) {
          this.getAll();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  } else {
    this.formulario.control.markAllAsTouched();
    this._utilsService.showNotification(2, 'Falto llenar campos');
  }
}

  // DELETED
  async deleteFunction($event) {
    $event.preventDefault();

    this.companyType.companyTypeStatus = 0;
    this.companyType.companyDeleted = 1;
    this.companyType.companyTypeCode = this.activeRow.companyTypeCode;
    this.companyType.companyTypeName = this.activeRow.companyTypeName;

    swal.fire({
      title: "Esta seguro",
      text: "El tipo de empresa se eliminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.companyType.companyDeleted = 0;
    this.companyType.companyTypeStatus = 1;
    this.companyType.companyTypeCode = this.activeRow.companyTypeCode;
    this.companyType.companyTypeName = this.activeRow.companyTypeName;
  }

  ///////////////////////COMPLEMENT///////////////////////  
  // CLEAR MODAL
  cleanModel() {
    
    this.isEdit = false;
    this.plan = {} as Plan;
    this.isDisabled = false;
    this.companyType = {} as CompanyType;
    this.formulario.resetForm();
  }
}
