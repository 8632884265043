import { HttpClient, HttpHeaders } from '@angular/common/http'; //eliminar
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '../models/store';
import { Client } from '../models/client';
import { Company } from '../models/company';
import { environment } from 'src/environments/environment';
import { InvoiceHeader } from '../models/invoiceHeader';
import { InvoiceDetail } from '../models/invoiceDetail';
import { UtilsService } from 'src/app/services/utils.service';
import { element } from 'protractor';

@Injectable({
    providedIn: 'root'
})
export class MassiveService {
    pipeDate = new DatePipe('en-US');
    public url: string;
    public urlIMFEL: string;
    public client: Client = {} as Client;
    public company: Company = {} as Company;
    public store: Store = {} as Store;
    invoiceHeader = {} as InvoiceHeader;
    invoiceDetail = {} as InvoiceDetail;
    public PaymentLine: any = new Array;
    public invoiceLine: any = new Array;
    private _utilsService: UtilsService;

    public idp: number;
    public subIdp: number;
    public subsidio: number;
    public subsidioTemp: number;
    public petroleo: string;
    public taxOilCode: number;
    public taxOilAmount: number;
    public nowDay: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
        this.urlIMFEL = environment.apiIMFEL;
    }

    getClient(nit: string, company: number): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-client/taxDocument/company/' + nit + '/' + company, { headers: headers }).toPromise();
    }

    searchSAT(nit: string, type: string): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        // return this._http.get(this.urlIMFEL + nit + '/' + type, { headers: headers }).toPromise();
        return this._http.get('https://53jp2f6475.execute-api.us-east-1.amazonaws.com/pro/imfeltax-taxDocument/' + nit + '/' + type, { headers: headers }).toPromise();
    }

    insertClient(client: Client): Promise<any> {
        const params = JSON.stringify(client);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-client', params, { headers: headers }).toPromise();
    }

    searchInvoice(serie: string, invoice: string): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `imerbackend-invoiceExchange/complement/${serie}/${invoice}`, { headers: headers }).toPromise();
    }

    searchDocument(document): Promise<any> {
        const params = JSON.stringify(document);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + `imerbackend-document`, params, { headers: headers }).toPromise();
    }

    public header: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    xmlInvoiceExchange(invoiceCode: string): Promise<any> {
        return this._http.get(this.url + `imerbackend-invoiceExchange/xml/one/${invoiceCode}`, { headers: this.header }).toPromise();
    }
    
    xmlCreditNote(creditNoteCode: string): Promise<any> {
        return this._http.get(this.url + `imerbackend-creditNote/xml/one/${creditNoteCode}`, { headers: this.header }).toPromise();
    }


    getAllSale(begDate: string, endDate: string, company: number): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `imerbackend-invoiceExchange/report/${begDate}/${endDate}/${company}`, { headers: headers }).toPromise();
    }

    getAllBook(begDate: string, endDate: string, company: number): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `imerbackend-invoiceExchange/report/attendBooks/${begDate}/${endDate}/${company}`, { headers: headers }).toPromise();
    }

    async replaceCharter(name: string) {
        name = await name.replace(/&/g, 'Y');
        name = await name.replace(/'/g, '');
        name = await name.replace(/"/g, '');
        return name;
    }

    dateES_US(date, split) {
        const date_split = date.split(split);
        const convertedDate = `${date_split[2]}-${date_split[1]}-${date_split[0]}`;
        return convertedDate;
    }

    async clientValidation(taxDocument: string, companyCode: number, address: string, type: string, name: string, mail: string) {
        const client = await this.getClient(taxDocument, companyCode);

        // console.log('cliente DB 1',client.records[0]);

        if(type === "2"){
            // console.log('pasaporte');
            if (!client.records[0]) {
                this.client.clientName = name;
                this.client.clientAddress = address;
                this.client.clientEmail = mail;
                this.client.clientCompanyCode = companyCode;
                this.client.clientTaxDocument = taxDocument;
                this.client.clientPhone = "0000-0000";
                this.client.clientCreditAmount = 0;
                this.client.clientCreditTime = 0;
                this.client.clientCategory = 2;
                this.client.clientInterest = 0;
                this.client.clientDiscount = 0;
                this.client.clientDeleted = 0;
                this.client.clientBlocked = 0;
                this.client.clientCredit = 0;
                this.client.clientStatus = 1;
                this.client.clientType = 2;
                this.client.clientNameFrequent = '-';
                this.client.clientAddressFrequent = '-';

                const insertClient = await this.insertClient(this.client);
    
                    if (insertClient.records[0].insertId) {
                        this.client.clientCode = insertClient.records[0].insertId;
                        return this.client;
                        // {
                        //     clientEmail: this.client.clientEmail,
                        //     clientCode: insertClient.records[0].insertId,
                        //     clientAddress: this.client.clientAddress,
                        //     clientName: this.client.clientName,
                        // };
                    } else {
                        return false; 
                    }


            } else {
                return client.records[0];
            }

        }
        else{
            // console.log('NIT/CUI');
            if (!client.records[0]) {
                const newclient = await this.searchSAT(taxDocument, type);

                // console.log('newclient',newclient);
    
                if (newclient.tipo_respuesta[0] === '0') {
                    this.client.clientName = await this.replaceCharter(newclient.nombre[0]);
                    this.client.clientAddress = address
                    this.client.clientEmail = mail;
                    this.client.clientCompanyCode = companyCode;
                    this.client.clientTaxDocument = taxDocument;
                    this.client.clientPhone = "0000-0000";
                    this.client.clientCreditAmount = 0;
                    this.client.clientCreditTime = 0;
                    this.client.clientCategory = 2;
                    this.client.clientInterest = 0;
                    this.client.clientDiscount = 0;
                    this.client.clientDeleted = 0;
                    this.client.clientBlocked = 0;
                    this.client.clientCredit = 0;
                    this.client.clientStatus = 1;
                    this.client.clientType = 0;
                    this.client.clientNameFrequent = '-';
                    this.client.clientAddressFrequent = '-';
    
                    const insertClient = await this.insertClient(this.client);
    
                    if (insertClient.records[0].insertId) {
                        this.client.clientCode = insertClient.records[0].insertId;
                        return this.client; 
                        // {
                        //     clientEmail: this.client.clientEmail,
                        //     clientCode: insertClient.records[0].insertId,
                        // };
                    } else {
                        return false; 
                    }
                } else {
                    let SATerror = {
                        error: true,
                        DetalleError: newclient.listado_errores[0].error[0].cod_error + ' -> ' + newclient.listado_errores[0].error[0].desc_error + ' --> CUI no válido',
                    }
                    return SATerror; 
                }
            } else {
                // console.log('cliente DB',client.records[0]);
                this.client = client.records[0];
                return this.client;
                // return client.records[0];
            }
        }        
    }

    async convertXLStoJson(rowXLS: any, userCode: number, companyx: Company, storex: Store){

        this.invoiceDetail = {} as InvoiceDetail;
        this.invoiceHeader = {} as InvoiceHeader;
        this.invoiceLine = [];
        this.PaymentLine = [];
        this.client = {} as Client;
        this.company = {} as Company;
        this.store = {} as Store;

        console.log('rowXLS', rowXLS);
        // console.log('rowXLS NombreCliente', rowXLS.NombreCliente);
        // console.log('userCode', userCode);
        // console.log('company', companyx);
        // console.log('store', storex);

        let typeClient = "";
        if(rowXLS.TipoIdentificacion == "NIT"){
            typeClient = "0";
        }
        else if(rowXLS.TipoIdentificacion == "DPI"){
            typeClient = "1";
        }
        else{
            typeClient = "2";
        }
        //console.log('typeClient', typeClient);
        const client = await this.clientValidation(rowXLS.Identificacion, companyx.companyCode, rowXLS.DireccionCliente, typeClient, rowXLS.NombreCliente, rowXLS.CorreoCliente);
        //console.log('client',this.client);

        await this.saveLineInvoice(rowXLS.TipoPetroleo, rowXLS.CantidadProducto, 0, rowXLS.TipoProducto, rowXLS.DescripcionProducto, rowXLS.PrecioUnitario);

        /* Armar Json */
        this.company = companyx;
        this.store = storex;
        // const hour = this._utilsService.dateTime('HH:mm:ss');        

        console.log('fecha XLS', rowXLS.FechaFactura);

        let fechaFact = new Date(rowXLS.FechaFactura + ' 06:11:11');
        console.log('fecha date', fechaFact);

        const today = new Date();

        let diffDays = (today.getTime() - fechaFact.getTime()) / (1000*60*60*24);

        console.log('fecha diff dias', diffDays);

        if(diffDays >= 6){
            console.log('Fecha Mayor a 5');
            let jsonerror = {
                errorDate: true,
                errorDetail: "La Fecha de la factura no puede ser mayor a 5 días atras"
            }
            return jsonerror;
        }



        this.nowDay = this.pipeDate.transform(fechaFact, 'yyy-MM-dd');
        this.invoiceHeader.invoiceDate = this.nowDay;
        const hour = this.pipeDate.transform(fechaFact, 'HH:mm:ss');

        let transmitterType = "FACT";
        let transmitterMembership = "GEN";
        if (this.company.companyTaxToPay == 5) {
            transmitterType = "FPEQ";
            transmitterMembership = "PEQ";
        }

        this.invoiceHeader.invoiceTax = 0;
        this.invoiceLine.forEach(e => {
            this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax + e.taxAmount).toFixed(6))
        });

        this.PaymentLine = [{
            typePayment: 0,
            NamePaymentType: 'Efectivo',
            MontoPayment: parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4))
        }];

        const json = {
            "store": {
                "storeCode": this.store.storeCode
            },
            "user": {
                "userCode": userCode
            },
            "transmitter": {
                "transmitterMembership": transmitterMembership,
                "transmitterType": transmitterType,
                "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
                "transmitterEmail": this.company.companyEmail,
                "transmitterTaxDocument": this.company.companyTaxDocument,
                "transmitterComercialName": this.store.storeName,
                "transmitterName": this.company.companyName,
                "transmitterAddress": this.store.storeAddress,
                "transmitterZipCode": this.store.storeZipCode,
                "transmitterMunicipality": this.store.storeMunicipality,
                "transmitterDepartment": this.store.storeDepartment,
                "transmitterCountry": 'GT',
                "transmitterPrefix": this.company.companyPrefix,
                "transmitterUser": this.company.companyPrefix,
                "transmitterKey": this.company.companyKey,
                "transmitterToken": this.company.companyToken,
                "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
                "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
            },
            "receiver": {
                "receiverAddress": this.client.clientAddress,
                "receiverCode": this.client.clientCode,
                "receiverDeleted": 0,
                "receiverEmail": this.client.clientEmail,
                "receiverName": this.client.clientName,
                "receiverStatus": 1,
                "receiverTaxDocument": this.client.clientTaxDocument,
                "receiverTypeTaxDocument": typeClient,
                "receiverZipCode": 0,
                "receiverCountry": "GT"
            },
            "invoice": {
                "invoiceCode": 1,
                "invoiceCurrency": "GTQ",
                "invoiceChangeRate": this.invoiceHeader.invoiceExchangeRate == undefined ? 0 : this.invoiceHeader.invoiceExchangeRate,
                "invoiceTaxShortName": "IVA",
                // "invoiceDate": this.invoiceHeader.invoiceDate,
                "invoiceDate": this.invoiceHeader.invoiceDate + ' ' + hour,
                "invoiceInternSerial": "A",
                "invoiceTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(4)),
                "invoiceTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4)),
                "invoiceOilTaxShortName": "PETROLEO",
                "invoiceOilTaxValue": parseFloat((this.invoiceHeader.invoiceIDP).toFixed(4)),
                "invoiceDuesQuantity": 0,
                "invoicepaymentType": 0,
            },
            "phrase": this.company.phrase,
            "detail": this.invoiceLine,
            "paymentType": this.PaymentLine,
            "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
            "serial": [],
            "addendum": [],
            "salesPerson": 0
        };

        //console.log(json);

        return json;

    }

    // GUARDAR UNA LINEA DE COMPRA
    async saveLineInvoice(typeOil, cantidad, itemCode, detailType, descriptionProd, unitPrice) {

        //Nuevo Item
        this.idp = 0;
        this.subIdp = 0;
        this.subsidioTemp = 0;
        this.petroleo = undefined;
        this.invoiceHeader.invoiceIDP = 0;
        this.invoiceHeader.invoiceTotal = 0;
        this.invoiceLine = [];

        if (typeOil == 3) { // SUPER
            this.idp = 4.7;
            this.taxOilCode = 1;
            this.petroleo = "PETROLEO";
        } else if (typeOil == 4) { //REGULAR
            this.idp = 4.6;
            this.taxOilCode = 2;
            // this.subsidioTemp = 2.5;
            this.petroleo = "PETROLEO";
        } else if (typeOil == 5) { //DIESEL
            this.idp = 1.3;
            this.taxOilCode = 4;
            this.subsidioTemp = 5;
            this.petroleo = "PETROLEO";
        }

        /***CALCULOS***/
        this.subIdp = parseFloat((cantidad * this.idp).toFixed(6));
        this.invoiceDetail.invoiceDetailSubtotal = parseFloat((cantidad * unitPrice).toFixed(6));
        this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / 1.12)).toFixed(6));
        this.invoiceDetail.invoiceDetailQuantity = cantidad;
        /***CALCULOS***/

        /***AGREGAR LINEA***/
        this.invoiceLine.push({
            //Area de combustible
            subsidio: this.subsidioTemp,
            isOil: 1,
            taxOilUP: this.idp,
            taxOilAmount: this.subIdp,
            taxOilName: this.petroleo,
            taxOilCode: this.taxOilCode,
            taxOilQuantity: this.invoiceDetail.invoiceDetailQuantity,
            itemCode: itemCode,
            measure: "UNI",
            taxCode: 1,
            taxName: "IVA",
            isService: detailType,
            quantity: this.invoiceDetail.invoiceDetailQuantity,
            description: descriptionProd,
            discount: 0,
            unitPrice: parseFloat(unitPrice.toFixed(6)),
            price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6)),
            taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(6)),
            taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6)),
            total: parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.subIdp).toFixed(6)),
        });

            this.subsidio += (this.invoiceDetail.invoiceDetailQuantity * this.subsidioTemp);
            this.invoiceHeader.invoiceIDP = parseFloat((this.invoiceHeader.invoiceIDP + this.subIdp).toFixed(6));
            //this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax + this.invoiceDetail.invoiceDetailTax).toFixed(6));
            this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + this.invoiceDetail.invoiceDetailSubtotal + this.subIdp).toFixed(6));
            this.cleanLineInvoice();
        /***AGREGAR LINEA***/

    }

    async cleanLineInvoice() {
        this.invoiceDetail = {} as InvoiceDetail;
        this.invoiceDetail.invoiceDetailQuantity = 1;
    }    
}