<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/company-type">Frase y Escenario</a>
        </div>
    </div>
</div>

<div class="main-content">

    <!-- INPUT COMPANY -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <form #formulario="ngForm">
                <div class="card">
                    <div class="card-header ">
                        <div class="row">
                            <div class="col-11">
                                <b class="card-title" *ngIf="!isEdit">Nuevo registro</b>
                                <b class="card-title text-warning" *ngIf="isEdit">Modificar registro</b>
                            </div>
                            <div class="col-1" *ngIf="isEdit">
                                <a class="btn btn-danger btn-icon btn-round " (click)="cleanModel(true)"><i
                                        class="fas fa-times"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Empresa</label>
                                    <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItemsCompany"
                                        [settings]="dropdownSettingsCompany" (onSelect)="onItemSelectCompany($event)"
                                        (onDeSelect)="onDeItemSelectCompany()" name="selectCompany" required>
                                    </angular2-multiselect>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label>                                    
                                    Frase <span class="text-danger" *ngIf="validate_msg(2, 'phrase')"> *valor no aceptado!</span>
                                </label>
                                <div class="form-group">
                                    <input type="number" class="form-control" name="phrase" [(ngModel)]="phraseStage.phrase" (change)="validate_number(1, 'phrase')" min=0 required ngbTooltip="{{tooltip_msg}}" placement="bottom" tooltipClass="tooltip-gray"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label>
                                    Escenario <span class="text-danger" *ngIf="validate_msg(2, 'phraseStage')"> *valor no aceptado!</span>
                                </label>
                                <div class="form-group">
                                    <input type="number" class="form-control" name="phraseStage" [(ngModel)]="phraseStage.phraseStage" (change)="validate_number(1, 'phraseStage')" min=0 required ngbTooltip="{{tooltip_msg}}" placement="bottom" tooltipClass="tooltip-gray"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label></label>
                                <button class="btn btn-info btn-block btn-erp" (click)="save()" *ngIf="!isEdit"
                                    [disabled]="isDisabled || formulario.invalid">Guardar</button>
                                <button class="btn btn-primary btn-block btn-erp" (click)="update()" *ngIf="isEdit"
                                    [disabled]="isDisabled || formulario.invalid">Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- END INPUT COMPANY -->

    <!-- TABLE -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado frase - escenario de empresa</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Empresa</label>
                                <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItemsCompany"
                                    [settings]="dropdownSettingsCompany" (onSelect)="onItemSelectRegister($event)">
                                </angular2-multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Empresa" prop="companyName"></ngx-datatable-column>
                            <ngx-datatable-column name="Frase" prop="phrase"></ngx-datatable-column>
                            <ngx-datatable-column name="Escenario" prop="phraseStage"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="editFunction($event)" ngbTooltip="Editar"><i
                                            class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="deleteFunction($event)" ngbTooltip="Eliminar"><i
                                            class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>