import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment, cognitoCodes } from 'src/environments/environment';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Password } from '../models/password';
import { ToastrService } from "ngx-toastr";
import { UserStore } from '../models/userStore';
import { UtilsService } from './utils.service';

const POOL_DATA = {
    UserPoolId: environment.userPoolId,
    ClientId: environment.clientId
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable()
export class UserService {
    private succedded = false;
    public authStatusChanged = new Subject<boolean>();
    public loggedIn: boolean;

    public url: string;
    public userLogged = new Subject<boolean>();
    public isDisabled: boolean;

    constructor(
        private router: Router,
        private _http: HttpClient,
        private toastr: ToastrService,
        private utilsService: UtilsService
    ) {
        this.url = environment.apiUrl;
    }

    // Actualiza password
    async updatePassword(userCode, previousPassword, proposedPassword): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        //const accessToken: any = await this.getAccessToken(username, previousPassword);

        const params = {
            //accessToken: accessToken.accessToken.jwtToken,
            userCode: userCode,
            previousPassword: previousPassword,
            proposedPassword: proposedPassword
        };

        return this._http.post(this.url + 'imerbackend-user/changePassword/', params, { headers: headers }).toPromise();
    }

    getAccessToken(username, password) {
        const POOL_DATA = {
            UserPoolId: environment.userPoolId,
            ClientId: environment.clientId
        };
        const userPool = new CognitoUserPool(POOL_DATA);
        return new Promise((resolve, reject) => {
            userPool.getCurrentUser().getSession(
                (err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }

                }
            );
        });
    }

    async initUserSecurity() {
        const isUserAuthenticated = await this.isUserAuthenticated();
        isUserAuthenticated.subscribe((isLogged) => {
            this.userLogged.next(isLogged);
        });
    }

    async isUserAuthenticated(): Promise<Observable<boolean>> {
        const isUserLogged = await this.isUserLogged()
        return await new Observable<boolean>((observable) => {
            // console.log('Obs. #1');
            observable.next(isUserLogged);
            observable.complete();
        });
    }

    // user authentication
    async isUserLogged(): Promise<boolean> {
        console.log('valida login');

        if (localStorage.getItem('user') !== null) {
            console.log('si esta login');

            //const userCurrentLogged = userPool.getCurrentUser();
            // let userCurrentLoggedSession: any;
            // return userCurrentLoggedSession.isValid();
            return true;
        }
        else{
            console.log('no esta login');
            return false;
        }

        // const userCurrentLogged = userPool.getCurrentUser();
        // if (userCurrentLogged == null) {
        //     return false
        // }
        // const userCurrentLoggedSession = await new Promise<CognitoUserSession>(async (resolve, reject) => {
        //     await userCurrentLogged.getSession(async (err, session) => {
        //         if (err) {
        //             // this.notificationService.showNotification(3, err);
        //             reject(err);
        //         } else {
        //             resolve(session);
        //         }
        //     });
        // });
        // return userCurrentLoggedSession.isValid();
    }

     // user logout
     async userLogout(): Promise<void> {
        await Auth.signOut()
            .then(() => {
                this.userLogged.next(false)
            }).catch((err) => {
                // this.notificationService.showNotification(3, err);
            });
        await this.userLogged.next(false);
        await localStorage.clear();
    }

    // Obtiene el listado de usuarios completo
    getAllUser(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-user/', { headers: headers }).toPromise();
    }

    // GET ALL USER STORE BY ID
    getOneUserStore(userCode): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-userStore/one/' + userCode, { headers: headers }).toPromise();
    }

    // GET ALL USER COMPANY AND STORE BY ID
    getOneUserCompanyStore(userCode): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-userCompany/user/' + userCode, { headers: headers }).toPromise();
    }

    getAllByCompany(company): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-company/user/' + company, { headers: headers }).toPromise();
    }

    // INSERT MULTI COMPANY USER
    getAllMultiCompanyUser(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-userCompany/', { headers: headers }).toPromise();
    }

    // Inserta un usuario
    insert(user: User): Promise<any> {
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-user/cognito/', params, { headers: headers }).toPromise();
    }
    
    // INSERT USER STORE
    insertUserStore(userStore: UserStore): Promise<any> {
        let params = JSON.stringify(userStore);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-userStore', params, { headers: headers }).toPromise();
    }

    // INSERT MULTI COMPANY USER
    insertMultiCompanyUser(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-userCompany', params, { headers: headers }).toPromise();
    }

    // Editar
    update(user: User): Promise<any> {
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-user/cognito/', params, { headers: headers }).toPromise();
    }

    // UPDATE USER STORE
    updateUserStore(userStore: UserStore): Promise<any> {
        let params = JSON.stringify(userStore);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-userStore', params, { headers: headers }).toPromise();
    }

    // UPDATE MULTI COMPANY USER = DELETED
    updateMultiCompanyUser(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-userCompany', params, { headers: headers }).toPromise();
    }

    signIn(username: string, password: string): any {
        this.isDisabled = true;
        console.log(username, password);
        const authData = {
            Username: username,
            Password: password
        };
        const authDetails = new AuthenticationDetails(authData);
        const userData = {
            Username: username,
            Pool: userPool
        };
        const cognitoUser = new CognitoUser(userData);
        const that = this;
        this.succedded = false;
        cognitoUser.authenticateUser(authDetails, {
            onSuccess(result: any) {
                that.succedded = true;
                that.authStatusChanged.next(true);
                that.router.navigate(['']);
                this.isDisabled = false;
                that.showNotification(1, 'Bienvenido ' + result.idToken.payload.name);
                const userLogged = {
                    userEmail: result.idToken.payload.email,
                    userCognitoCode: result.idToken.payload['cognito:username'],
                    userName: result.idToken.payload.name,
                    userPassword: undefined,
                    userStatus: undefined,
                };
                localStorage.setItem('user', JSON.stringify(userLogged));
            },
            onFailure(err: any) {
                that.authStatusChanged.next(false);
                this.isDisabled = false;
                that.showNotification(3, "Usuario o Contraseña Inválidos!");
            }
        });

        this.authStatusChanged.next(this.succedded);
    }

     // user login
    async userSignin(userEmail: string, userPassword: string): Promise<boolean> {
        console.log(userEmail, userPassword);

        const that = this;
        let cognitoCode = '';
        
        await this.userLogged.next(false);

        // for (let email in cognitoCodes) {
        //     if (email.toUpperCase() === userEmail.toUpperCase()) {
        //         cognitoCode = cognitoCodes[email];
        //         console.log("Se ha encontrado cognito code", email, cognitoCode);
        //     }
        // }

        await this.oneUserEmailBackend(userEmail.toUpperCase()).then(async (result) => {    
            console.log(result);
            const userDBMail = result.records[0];
            if (userDBMail !== undefined) { 
                cognitoCode = userDBMail.userCognitoCode
                //console.log('encontrado... ', cognitoCode)
            }
            else {
                that.showNotification(3, "Usuario o Contraseña Inválidos!");      
            }
        })
        .catch((err) => {
            console.log(err.errorMessage);
            that.showNotification(3, "Usuario o Contraseña Inválidos!");
        });

        let IP = "192.168.1.5";

        await this.oneUserCognitoBackend(cognitoCode, userPassword, IP)
            .then(async (result) => {    
                console.log(result);
              const userDB = result.records[0];
                if (userDB !== undefined) { 
                    const userLoggedData = {
                      userEmail: userDB.userEmail,
                      userCognitoCode: userDB.userCognitoCode,
                      userName: userDB.userName,
                    };

                    console.log(userLoggedData);
                    await that.utilsService.showNotification(
                      1,
                      "Bienvenido " + userLoggedData.userName
                    );
                    await localStorage.setItem(
                      "user",
                      JSON.stringify(userLoggedData)
                    );

                    const data = {
                        "userCode": userDB.userCode,
                        "storeName": userDB.storeName,
                        "storeCode": userDB.storeCode,
                        "companyCode": userDB.companyCode,
                        "companyName": userDB.companyName,
                        "identify": userDB.userPassword,
                        "ip": IP,
                        "isStoreOpen": false,
                    };
                    localStorage.setItem('data', JSON.stringify(data));                    

                    await that.userLogged.next(true);
                    await that.router.navigate(["/home"]);
                }
                else {
                    that.showNotification(3, "Usuario o Contraseña Inválidos!");      
                }
          })
          .catch((err) => {
              console.log(err.errorMessage);
              that.showNotification(3, "Usuario o Contraseña Inválidos!");
          });

        return true;
    }


    async logout() {
        await this.signOut();
        this.authStatusChanged.next(false);
        localStorage.clear();
    }

    signOut(): Promise<any> {
        return Auth.signOut()
            .then(() => this.loggedIn = false);
    }

    oneUserCognitoBackend(userCognitoCode, passCognito, ip): Promise<any> {
        const filter = userCognitoCode;
        return this._http.get(environment.apiUrl + 'imerbackend-user/cognito/' + filter + '/' + passCognito + '/' + ip).toPromise();
    }

    isAuthenticated(): Observable<boolean> {
        console.log('Obs. #2');
        const user = this.getAuthenticatedUser();
        const obs = new Observable<boolean>(observer => {
            if (!user) {
                console.log('Obs. #3');
                observer.next(false);
            } else {
                user.getSession((err, session) => {
                    if (err) {
                        console.log('Obs. #4', err);
                        observer.next(false);
                    }
                    if (session.isValid()) {
                        console.log('Obs. #5', session);
                        observer.next(true);
                    } else {
                        console.log('Obs. #6');
                        observer.next(false);
                    }
                });
            }
            observer.complete();
        });
        return obs;
    }

    getAuthenticatedUser() {
        console.log('Obs. #7');
        return userPool.getCurrentUser();
    }

    getAuthenticatedUserSession() {
        let sessionReturn: any;
        userPool.getCurrentUser().getSession(
            (err, session) => {
                if (err) {
                    console.log(err);
                }
                sessionReturn = session;
            }
        );
        //console.log(sessionReturn);
        return sessionReturn;

    }

    initAuth() {        
        this.isAuthenticated().subscribe(auth => this.authStatusChanged.next(auth));
    }

    confirm(user): Promise<any> {
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        // return this._http.post(this.url + 'imerbackend-user/confirm', params, { headers: headers }).toPromise();
        return this._http.post(this.url + 'imerbackend-user/confirm/', params, { headers: headers }).toPromise();
    }

    oneUserEmailBackend(userEmail): Promise<any> {
        const filter = userEmail;
        return this._http.get(environment.apiUrl + 'imerbackend-user/email/' + filter).toPromise();
    }

    // Notificaciónes
    showNotification(type, message) {
        switch (type) {
            case 1:
                this.toastr.success(
                    '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
                    "",
                    {
                        timeOut: 5000,
                        closeButton: true,
                        enableHtml: true,
                        toastClass: "alert alert-success alert-with-icon",
                        positionClass: "toast-top-right"
                    }
                );
                break;
            case 2:
                this.toastr.warning(
                    '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
                    "",
                    {
                        timeOut: 5000,
                        closeButton: true,
                        enableHtml: true,
                        toastClass: "alert alert-warning alert-with-icon",
                        positionClass: "toast-top-right"
                    }
                );
                break;
            case 3:
                this.toastr.error(
                    '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
                    "",
                    {
                        timeOut: 5000,
                        closeButton: true,
                        enableHtml: true,
                        toastClass: "alert alert-danger alert-with-icon",
                        positionClass: "toast-top-right"
                    }
                );
                break;
        }
    }

    // Apertura y Cierre de caja
    async insertCashierLog(log){
        let params = JSON.stringify(log);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-log/insert/', params, { headers: headers }).toPromise();
    }

    // GET LATEST USER OPEN/CLOSE LOG
    async getLatestLog(userCode){
        return this._http.get(this.url + 'imerbackend-log/getone/' + userCode).toPromise();
    }
}