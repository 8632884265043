import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { DebitNote } from 'src/app/models/debit-note';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { InvoiceHeader } from 'src/app/models/invoiceHeader';
import { Store } from 'src/app/models/store';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PrintService } from 'src/app/services/print.service';
import { StoreService } from 'src/app/services/store.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-description-debit-note',
  templateUrl: './description-debit-note.component.html',
  styleUrls: ['./description-debit-note.component.scss'],
  providers: [ClientService, InvoiceService, StoreService, CompanyService, PrintService]
})
export class DescriptionDebitNoteComponent implements OnInit {
  // Variables auxiliares  
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  debitNote = {} as DebitNote;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  public begDate: string;
  public endDate: string;
  public nowDay: string;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  loading;

  public isBeginning: boolean;
  public isDebitNote: boolean;
  public isDisabled: boolean;
  // public recurrentClient: boolean;
  // public insertNewClient: boolean = false;

  // Variables de Linea de Compra
  public itemCode: string;
  public itemNumberCode: number;
  public moneda: string;
  public invoiceLine: any = new Array;
  public addAmount: boolean;

  // variables para data table
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp: any[] = new Array();
  public invoiceList: any[] = new Array();

  constructor(
    private toastr: ToastrService,
    private _printService: PrintService,
    private _storeService: StoreService,
    private _utilsService: UtilsService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
  ) { }


  async ngOnInit() {
    this.addAmount = true;
    this.isDisabled = false;
    this.moneda = "Q";
    this.itemCode = "I";
    this.itemNumberCode = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.debitNote.debitNoteConcept = 3;
    this.invoiceHeader.invoiceCurrency = 'GTQ';
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceInternSerial = 'A';
    // this.invoiceHeader.invoiceInternSerial = 'A';


    this.nowDay = this._utilsService.dateTime('yyy-MM-dd');
    this.invoiceHeader.invoiceDate = this.nowDay;
    this.begDate = this.nowDay;
    this.endDate = this.nowDay;

    // GET ID USER & ID COMPANY
    const  _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.viewBeginning();
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
  }

  public taxToPay: number = 0;
  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
          this.company.companyTaxToPay == 12 ? this.taxToPay = 1.12 : this.taxToPay = 1.05;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      response => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.invoiceList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  getAll() {
    this.isDisabled = true;
    if (this.begDate && this.endDate) {
      this.loading = true;

      let json = {
        "storeCode": this.storeCode,
        "begDate": this.begDate,
        "endDate": this.endDate
      }

      this._invoiceService.getAllByCompany(json).then(
        async (response) => {
          if (response.result == true) {
            this.invoiceList = response.records;
            this.temp = this.invoiceList;
            this.isDisabled = false;
            this.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this.isDisabled = false;
      this.showNotification(2, "Ingresar Datos");
    }
  }

  // GET ONE
  async searchInvoice($event, authorization?) {
    $event.preventDefault();
    this.loading = true;

    //INFILE
    if (this.company.companyCertifier == 0) {      
      await this._printService.printInfile(authorization);      
      this.loading = false;
    }
    //MEGAPRINT
    else if (this.company.companyCertifier == 1) {
      
      this._invoiceService.getOneMegaprint(this.activeRow.id).then(
        async (response) => {
          if (response.result == true) {
            var pdfResult = response.records.pdfBase64;
            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>");
          } else {
            this.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });
    }
  }

  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  onChange(event) {
    this.invoiceHeader.invoiceCoin = event;

    if (this.invoiceHeader.invoiceCoin == 0) {
      this.moneda = "Q";
      this.invoiceHeader.invoiceCurrency = 'GTQ';
      this.invoiceHeader.invoiceExchangeRate = null;
    } else if (this.invoiceHeader.invoiceCoin == 1) {
      this.moneda = "$"
      this.invoiceHeader.invoiceCurrency = 'USD';
    }
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isDebitNote = false;
  }

  viewCreditNote() {
    this.isBeginning = false;
    this.isDebitNote = true;
  }

  // CALCULAR IMPUESTO DE LINEA
  calculateTax() {
    let subTotalLine;

    subTotalLine = (this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice);

    if (this.invoiceDetail.invoiceDetailDiscount > 0) {
      if (this.invoiceDetail.invoiceDetailDiscount <= subTotalLine) {
        subTotalLine = subTotalLine - this.invoiceDetail.invoiceDetailDiscount;
      } else {
        this.showNotification(2, "Cantidad No permitida");
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }
    }

    let tax;

    tax = (subTotalLine - (subTotalLine / this.taxToPay)).toFixed(4);
    this.invoiceDetail.invoiceDetailTax = parseFloat(tax);
  }

  // GUARDAR UNA LINEA DE COMPRA
  saveLineInvoice() {
    if (this.invoiceDetail.invoiceDetailDescription != "" && this.invoiceDetail.invoiceDetailQuantity > 0 && this.invoiceDetail.invoiceDetailUnitPrice > 0) {
      //Aumentar Codigo si no viene uno
      if (this.itemCode == "I") {
        this.itemNumberCode++;
        this.itemCode += this.itemNumberCode;
      }

      //Calcular subtotal de linea
      this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice;

      if (this.invoiceDetail.invoiceDetailDiscount != undefined && this.invoiceDetail.invoiceDetailDiscount != null && this.invoiceDetail.invoiceDetailDiscount > 0) {
        this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailDiscount;
        this.invoiceDetail.invoiceDetailTax = this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay);
      } else {
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }

      //Actualizar
      this.invoiceLine.forEach((value) => {
        if (value.itemCode == this.itemCode) {
          this.invoiceHeader.invoiceTotal -= value.total;

          value.itemCode = this.itemCode;
          value.measure = "UNI";
          value.taxCode = 1;
          value.taxName = "IVA";
          value.isService = this.invoiceDetail.invoiceDetailType;
          value.quantity = parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(4));
          value.description = this.invoiceDetail.invoiceDetailDescription;
          value.unitPrice = parseFloat((this.invoiceDetail.invoiceDetailUnitPrice).toFixed(4));
          value.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(4));
          value.discount = parseFloat((this.invoiceDetail.invoiceDetailDiscount).toFixed(4));
          value.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(4));
          value.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4));
          value.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(4));

          this.addAmount = false;
        }
      })

      //Insertar
      if (this.addAmount == true) {
        this.invoiceLine.push({
          itemCode: this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: this.invoiceDetail.invoiceDetailType,
          quantity: parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(4)),
          description: this.invoiceDetail.invoiceDetailDescription,
          unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)),
          price: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4)),
          discount: parseFloat(this.invoiceDetail.invoiceDetailDiscount.toFixed(4)),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4)),
          taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4)),
          total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4))
        });
      }
      // Sumar Total
      this.invoiceHeader.invoiceTotal += this.invoiceDetail.invoiceDetailSubtotal;
      this.invoiceHeader.invoiceTax += this.invoiceDetail.invoiceDetailTax;
      this.cleanLineInvoice();
    }
    this.isDisabled = false;
  }

  // APLICAR DESCUENTO EN TIEMPO REAL
  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (parseFloat(descuento) <= temporal) {
          this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.total);

          e.price = temporal - parseFloat(descuento);
          e.total = temporal - parseFloat(descuento);
          e.taxAmount = parseFloat((parseFloat(e.price) - (parseFloat(e.price) / this.taxToPay)).toFixed(4));
          e.taxableAmount = parseFloat((parseFloat(e.price) - parseFloat(e.taxAmount)).toFixed(4));

          this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal + parseFloat(e.total);
        } else {
          this.showNotification(2, "Cantidad No permitida");
        }
      }
    })
  }

  // CARGAR DATOS A EDITAR
  editLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        this.itemCode = e.itemCode;
        this.invoiceDetail.invoiceDetailType = e.isService;
        this.invoiceDetail.invoiceDetailDescription = e.description;
        this.invoiceDetail.invoiceDetailQuantity = e.quantity;
        this.invoiceDetail.invoiceDetailUnitPrice = e.unitPrice;
        this.invoiceDetail.invoiceDetailSubtotal = e.price;
        this.invoiceDetail.invoiceDetailDiscount = e.discount;
        this.invoiceDetail.invoiceDetailTax = e.taxAmount;
      }
    })
    this.isDisabled = false;
  }

  // ELIMINAR UNA LINEA
  deleteLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e, index) => {
      if (e.itemCode == item) {
        this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.price);
        this.invoiceLine.splice(index, 1);
      }
    })
    this.isDisabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.addAmount = true;

    this.itemCode = "I";
    this.invoiceDetail.invoiceDetailDescription = null;
    this.invoiceDetail.invoiceDetailDiscount = null;
    this.invoiceDetail.invoiceDetailQuantity = null;
    this.invoiceDetail.invoiceDetailUnitPrice = null;
    this.invoiceDetail.invoiceDetailTax = null;
  }


  createDebitNote($event) {
    $event.preventDefault();
    this.loading = true;

    //SIMULACION DE TIEMPO DE CARGA DE NOTA CREDITO
    setTimeout(() => {
      this.loading = false;
      this.viewCreditNote();
    }, 1500);

    this.invoiceHeader.invoiceCode = this.activeRow.id;
    this.invoiceHeader.invoiceAuthorization = this.activeRow.invoiceAuthorization;
    this.client.clientCode = this.activeRow.clientCode;
    this.client.clientName = this.activeRow.cliente;
    this.client.clientTaxDocument = this.activeRow.nit;
    this.client.clientAddress = this.activeRow.clientAddress;
    this.client.clientPhone = this.activeRow.clientPhone;
    this.client.clientEmail = this.activeRow.clientEmail;
    this.debitNote.debitNoteConcept = 3;
    this.debitNote.debitNoteDate = this._utilsService.dateES_US(this.activeRow.fecha, '-');
    this.debitNote.debitNoteDocument = this.activeRow.invoiceAuthorization;
  }

  async insertDebitNote() {
    if (this.debitNote.debitNoteComment != undefined && this.invoiceLine.length >= 1) {
      this.loading = true;
      this.isDisabled = true;

      // Pequeño Contribuyente
      let transmitterType = "NDEB";
      let transmitterMembership = "GEN";
      this.company.companyTaxToPay === 5 ? transmitterMembership = "PEQ" : '';

      this.invoiceHeader.invoiceTax = ((this.invoiceHeader.invoiceTotal / this.taxToPay) * (this.taxToPay - 1));

      const hour = await this._utilsService.dateTime('HH:mm:ss');

      const json = {
        "store": {
          "storeCode": this.storeCode
        },
        "user": {
          "userCode": this.userCode
        },
        "transmitter": {
          "transmitterMembership": transmitterMembership, 
          "transmitterType": transmitterType, 
          "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
          "transmitterEmail": this.company.companyEmail,
          "transmitterTaxDocument": this.company.companyTaxDocument,
          "transmitterComercialName": this.store.storeName,
          "transmitterName": this.company.companyName,
          "transmitterAddress": this.store.storeAddress,
          "transmitterZipCode": this.store.storeZipCode,
          "transmitterMunicipality": this.store.storeMunicipality,
          "transmitterDepartment": this.store.storeDepartment,
          "transmitterCountry": 'GT',
          "transmitterPrefix": this.company.companyPrefix,
          "transmitterKey": this.company.companyKey,
          "transmitterToken": this.company.companyToken,
          "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
          "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
        },
        "receiver": {
          "receiverAddress": this.client.clientAddress,
          "receiverCode": this.client.clientCode,
          "receiverDeleted": 0,
          "receiverEmail": this.client.clientEmail,
          "receiverName": this.client.clientName,
          "receiverStatus": 1,
          "receiverTaxDocument": this.client.clientTaxDocument,
          "receiverZipCode": 0,
          "receiverCountry": "GT"
        },
        "invoice": {
          "invoiceCode": this.invoiceHeader.invoiceCode
        },
        "debitNote": {
          "debitNoteCode": 0,
          "debitNoteCurrency": this.invoiceHeader.invoiceCurrency,
          "debitNoteTaxShortName": "IVA",
          "debitNoteTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(2)),
          "debitNoteTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(2)),
          "debitNoteComplementId": "Notas",
          "debitNoteComplementName": "Notas",
          "debitNoteSourceDocumentIssuanceDate": this.debitNote.debitNoteDate.substring(0, 10),
          "debitNoteFitMotif": this.debitNote.debitNoteComment,
          "debitNoteDate": this.invoiceHeader.invoiceDate + ' ' + hour,
          "debitNoteOrigialDocumentAuthorizationNumber": this.debitNote.debitNoteDocument
        },
        "detail": this.invoiceLine,
        "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
      };

      // console.log(json);
      // this.loading = false;
      // this.isDisabled = false;
      // Insertar Venta
      if (this.company.companyCertifier == 0) {
        this._invoiceService.insertDebitNote(json).then(
          async (response) => {
            if (response.result) {
              let uuid = response.records.infileServiceResponse.registerResponse.uuid;
              window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid);
              // window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');

              await this.getAll();
              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
      else if (this.company.companyCertifier == 1) {
        this._invoiceService.insertDebitNote(json).then(
          async (response) => {
            if (response.result) {
              console.log(response.records);
              var pdfResult = response.invoicePdf;

              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
    } else {
      this.showNotification(2, 'Falto llenar datos!');
    }
  }


  // Limpiar Campos
  cleanModel() {
    this.itemCode = "I";
    this.itemNumberCode = 0;

    this.isDisabled = false;
    // this.insertNewClient = false;
    // this.recurrentClient = false;
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceLine = [];
    this.moneda = "Q";
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceCurrency = 'GTQ';
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.invoiceDate = this.nowDay;

    this.addAmount = true;
  }

  //Mostrar Notificacion
  showNotification(type, message) {
    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 4:
        this.toastr.info(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Info</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-info alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }
}
