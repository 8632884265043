import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { Manager } from 'src/app/models/manager';
import { StoreManager } from 'src/app/models/storeManager';
import { ManagerService } from 'src/app/services/manager.service';
import { StoreService } from 'src/app/services/store.service';
import { AccessService } from 'src/app/services/access.service';
import { CompanyService } from 'src/app/services/company.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss'],
  providers: [UtilsService, ManagerService, CompanyService, StoreService, AccessService]
})
export class ManagerComponent implements OnInit {
  // MODELS VARIABLE 
  public manager = {} as Manager;
  public storeManager = {} as StoreManager;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isEdit: boolean;
  public isEditStoreManager: boolean;
  public isDisabled: boolean;
  public settings = {
    columns: {
      sucursal: {
        title: 'Sucursal'
      },
      encargado: {
        title: 'Encargado'
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public tempStoreManager = [];
  public activeRow: any;
  public managerList = new Array();
  public storeManagerList = new Array();

  // VARIABLE FOR SELECT 
  public managerListSelect: any[] = new Array();
  public selectedItemsManager = [];
  public storeList: any[] = new Array();
  public selectedItemsStore = [];
  public dropdownSettings = {};
  public dropdownSettingsStore = {};
  public companyList: any[] = new Array();
  public selectedItemsCompany = [];
  public dropdownSettingsCompany = {};

  constructor(
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _managerService: ManagerService,
    private _companyService: CompanyService,
    private _storeService: StoreService,
    private _accessService: AccessService
  ) { }

  async ngOnInit() {
    // GET ID USER & ID STORE
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    // LOAD VARIABLE
    this.isEdit = false;
    this.isEditStoreManager = false;
    this.isDisabled = false;

    // LOAD INFORMATION 
    await this.getAllCompany();
    // if (this.companyCode > 1) {
      this.getAllManagerByCompany();
      this.getStoreByCompanyList();
      this.getAllStoreManagerByCompany();
      const _selectedItemCompany = this.companyList.find(x => x.id == this.companyCode);      
      this.getSelectSettingCompany(_selectedItemCompany);
    // } else {
    //   this.getAllManager();
    //   this.getStoreList();
    //   this.getAllStoreManager();
    //   this.getSelectSettingCompany(this.selectedItemsCompany);
    // }

    // SELECT TYPE STORE
    this.getSelectSettingManager(this.selectedItemsManager);
    this.getSelectSettingStore(this.selectedItemsStore);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.managerList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSettingCompany(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsCompany = [selected] : '';

    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
      // disabled: this.companyCode > 1 ? true : false
    };
  };

  getSelectSettingManager(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsManager = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };

  getSelectSettingStore(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsStore = [selected] : '';

    this.dropdownSettingsStore = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
      // disabled: this.isEditStoreManager,
    };
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getAllCompany() {
    return this._companyService.getAll().then(
      response => {
        if (response.result == true) {
          this.companyList = [];
          response.records.forEach(e => {
            this.companyList.push({
              id: e.companyCode,
              itemName: e.companyName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getStoreList() {
    return this._storeService.getAll().then(
      response => {
        if (response.result == true) {
          this.storeList = [];
          response.records.forEach(e => {
            this.storeList.push({
              id: e.storeCode,
              itemName: e.storeName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getStoreByCompanyList() {
    return this._storeService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result == true) {
          this.storeList = [];
          response.records.forEach(e => {
            this.storeList.push({
              id: e.storeCode,
              itemName: e.storeName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelectCompany(item: any) {
    this.manager.managerCompanyCode = item.id;
  }

  onItemSelectManager(item: any) {
    this.storeManager.storeManagerManagerCode = item.id;
  }

  onItemSelectStore(item: any) {
    this.storeManager.storeManagerStoreCode = item.id;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL MANAGER
  getAllManager() {
    this.loading = true;

    return this._managerService.getAll().then(
      response => {
        if (response.result) {
          this.managerList = response.records;
          this.temp = this.managerList;

          // FUNCION SELECT
          this.managerListSelect = [];
          response.records.forEach(e => {
            this.managerListSelect.push({
              id: e.managerCode,
              itemName: e.managerName
            });
          });

        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET MANAGER BY COMPANY
  getAllManagerByCompany() {
    this.loading = true;

    return this._managerService.getAllManagerByCompany(this.companyCode).then(
      response => {
        if (response.result) {
          this.managerList = response.records;
          this.temp = this.managerList;

          // FUNCION SELECT
          this.managerListSelect = [];
          response.records.forEach(e => {
            this.managerListSelect.push({
              id: e.managerCode,
              itemName: e.managerName
            });
          });

        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ALL STORE-MANAGER
  getAllStoreManager() {
    this.loading = true;

    return this._managerService.getAllStoreManager().then(
      response => {
        if (response.result) {
          this.storeManagerList = response.records;
          this.tempStoreManager = this.storeManagerList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ALL STORE-MANAGER BY COMPANY
  getAllStoreManagerByCompany() {
    this.loading = true;

    return this._managerService.getAllStoreManagerByCompany(this.companyCode).then(
      response => {
        if (response.result) {
          this.storeManagerList = response.records;
          this.tempStoreManager = this.storeManagerList;

          //Select Encargado
          this.managerListSelect = [];
          response.records.forEach(e => {
            this.managerListSelect.push({
              id: e.managerCode,
              itemName: e.managerName
            });
          });
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE MANAGER
  viewFunction($event) {
    $event.preventDefault();

    swal.fire({
      title: "Propietario:",
      html:
        '<div class="row">' +
        '<div class="col-12 text-left">' +
        '<b>Nombre: </b>' + this.activeRow.managerName + '<br>' +
        '<b>Correo: </b>' + this.activeRow.managerEmail + '<br>' +
        '<b>Teléfono: </b>' + this.activeRow.managerPhone + '<br>' +
        "</div></div>",
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 450,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    })
  }

  // INSERT
  save() {
    this.isDisabled = true;
    this.loading = true;

    this.manager.managerStatus = 1;
    this.manager.managerDeleted = 0;

    this._managerService.insert(this.manager).then(
      async (response) => {
        if (response.result == true) {
          if (this.companyCode > 1) {
            this.getAllManagerByCompany
            this.getStoreByCompanyList
          } else {
            this.getAllManager()
            this.getStoreList()
          }

          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // INSERT STORE MANAGER
  saveStoreManager() {
    this.isDisabled = true;
    this.loading = true;

    this._managerService.insertStoreManager(this.storeManager).then(
      async (response) => {
        if (response.result == true) {
          if (this.companyCode > 1) {
            this.getAllStoreManagerByCompany
          } else {
            this.getAllStoreManager()
          }

          this.cancelUpdateStoreManager(event);
          this.cleanModelStoreManager();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // UPDATE
  update() {
    this.isDisabled = true;
    this.loading = true;

    this._managerService.update(this.manager).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          if (this.companyCode > 1) {
            this.getAllManagerByCompany
            this.getStoreByCompanyList
          } else {
            this.getAllManager()
            this.getStoreList()
          }

          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // UPDATE STORE MANAGER
  updateStoreManager() {
    this.isDisabled = true;
    this.loading = true;

    this._managerService.updateStoreManager(this.storeManager).then(
      async (response) => {
        if (response.result == true) {
          this.isEditStoreManager = false;
          if (this.companyCode > 1) {
            this.getAllStoreManagerByCompany();
          } else {
            this.getAllStoreManager()
          }
          this.cancelUpdateStoreManager(event);
          this.cleanModelStoreManager();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    );
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.manager.managerCode = this.activeRow.managerCode;
    this.manager.managerName = this.activeRow.managerName;
    this.manager.managerEmail = this.activeRow.managerEmail;
    this.manager.managerPhone = this.activeRow.managerPhone;
    this.manager.managerStatus = 0;
    this.manager.managerDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "El tipo de empresa se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.manager.managerCode = this.activeRow.managerCode;
    this.manager.managerCompanyCode = this.activeRow.managerCompanyCode;
    this.manager.managerName = this.activeRow.managerName;
    this.manager.managerEmail = this.activeRow.managerEmail;
    this.manager.managerPhone = this.activeRow.managerPhone;
    this.manager.managerStatus = this.activeRow.managerStatus;
    this.manager.managerDeleted = this.activeRow.managerDeleted;

    const _selectedItemCompany = this.companyList.find(x => x.id == this.activeRow.managerCompanyCode);
    this.getSelectSettingCompany(_selectedItemCompany);
  }

  // LOAD DATA TO EDIT STORE MANAGER
  editFunctionStoreManager($event) {
    $event.preventDefault();
    this.isEditStoreManager = true;

    //this.storeManager.storeManagerCode = this.activeRow.storeManagerCode;
    this.storeManager.storeManagerManagerCode = this.activeRow.storeManagerManagerCode;
    this.storeManager.storeManagerStoreCode = this.activeRow.storeManagerStoreCode;

    const _selectedItemManager = this.managerListSelect.find(x => x.id == this.activeRow.storeManagerManagerCode);
    const _selectedItemStore = this.storeList.find(x => x.id == this.activeRow.storeManagerStoreCode);
    this.getSelectSettingManager(_selectedItemManager);
    this.getSelectSettingStore(_selectedItemStore);
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //CANCEL EDIT
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
  }

  //CANCEL EDIT STORE MANAGER
  cancelUpdateStoreManager($event) {
    this.isEditStoreManager = false;    
    this.selectedItemsManager = null;
    this.selectedItemsStore = null;
    this.selectedItemsStore = [];
    this.getSelectSettingStore(this.selectedItemsStore);
    this.cleanModelStoreManager();
  }

  // CLEAR MODAL
  cleanModel() {
    this.isDisabled = false;
    this.manager = {} as Manager;
    this.companyCode > 1 ? '' : this.selectedItemsCompany = null;
    this.companyCode > 1 ? this.manager.managerCompanyCode = this.companyCode : '';
  }

  // CLEAR MODAL
  cleanModelStoreManager() {
    this.isDisabled = false;
    this.storeManager = {} as StoreManager;
    // this.selectedItemsManager = null;
    // this.selectedItemsStore = null;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.tempStoreManager;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        json[this.settings.columns[item].title] = element[item];
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-encargado.xlsx');
  }
}
