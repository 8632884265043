import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Client } from '../models/client';
import { environment } from 'src/environments/environment';
import { ClientCategory } from '../models/clientCategory';
import { UserService } from './user.service';

@Injectable()
export class ClientService {
  public url: string;
  public urlIMFEL: string;
  public urlCuiIMFEL: string;

  constructor(private _http: HttpClient,  private userService: UserService) {
    this.url = environment.apiUrl;
    this.urlIMFEL = environment.apiIMFEL;
  }

  // GET ALL CLIENT
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .get(this.url + 'imerbackend-client', { headers: headers })
      .toPromise();
  }

  // GET ALL CLIENT BY COMPANY
  getAllByCompany(company): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + 'imerbackend-client/company/' + company, { headers: headers }).toPromise(); }

  // GET ONE BY NIT
  getOneByNit(nit): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + 'imerbackend-client/taxDocument/' + nit, { headers: headers }).toPromise();
  }

  getOneByCompanyNit(company: number, nit: string): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + 'imerbackend-client/taxDocument/company/' + nit + '/' + company , { headers: headers }).toPromise();
  }

  // GET ONE BY NIT IN SAT
  searchNitSAT(nit,type): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    // return this._http.get(this.urlIMFEL + nit + '/' + type, { headers: headers }).toPromise();
    return this._http.get('https://53jp2f6475.execute-api.us-east-1.amazonaws.com/pro/imfeltax-taxDocument/' + nit + '/' + type, { headers: headers }).toPromise();
  }

  searchNitSATNew(nit,type): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get('https://53jp2f6475.execute-api.us-east-1.amazonaws.com/pro/imfeltax-taxDocument/' + nit + '/' + type, { headers: headers }).toPromise();
  }

  // GET ONE BY NIT IN SAT
  // searchNitSATNew(client): Promise<any> {
  //   let params = JSON.stringify(client);
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this._http.post('https://jt8latp5uk.execute-api.us-east-1.amazonaws.com/pro/imfel-client/megaprint', params, { headers: headers }).toPromise();
  // }

  // INSERT CLIENT
  insert(client: Client): Promise<any> {
    let params = JSON.stringify(client);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + 'imerbackend-client', params, { headers: headers }).toPromise();
  }

  // UPDATE CLIENT
  update(client: Client): Promise<any> {
    let params = JSON.stringify(client);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + 'imerbackend-client', params, { headers: headers }).toPromise();
  }

  // Consulta de todas las categorías
  getAllClientCategory(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .get(this.url + 'imerbackend-clientCategory', { headers: headers })
      .toPromise();
  }

  // Insertar una categoría
  insertClientCategory(clientCategory: ClientCategory): Promise<any> {
    let params = JSON.stringify(clientCategory);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .post(this.url + 'imerbackend-clientCategory', params, { headers: headers })
      .toPromise();
  }

  // Actualizar una categoria
  updateClientCategory(clientCategory: ClientCategory): Promise<any> {
    let params = JSON.stringify(clientCategory);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .put(this.url + '/imerbackend-clientCategory', params, { headers: headers })
      .toPromise();
  }
}
