import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseService } from 'src/app/services/purchase.service';
import { Purchase } from 'src/app/models/purchase';
import { AccessService } from '../../../services/access.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-purchase-pending',
  templateUrl: './purchase-pending.component.html',
  styleUrls: ['./purchase-pending.component.scss'],
  providers: [PurchaseService, AccessService, UtilsService]
})
export class PurchasePendingComponent implements OnInit {

  // MODELS VARIABLE 
  public purchaseModel = {} as Purchase;
  public purchase: any[];
  public purchaseDetail: any[];
  public isViewBeginning: boolean;
  public isViewPurchase: boolean;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public status: number = 1;
  public isDisabled: boolean;
  public settings = {
    columns: {
      ingresado: {
        title: 'Ingresado'
      },
      proveedor: {
        title: 'Proveedor'
      },
      monto: {
        title: 'Monto',
        type: 'number'
      },
      estado: {
        title: 'Estado'
      }
    }
  };
  loading;

  // MODAL VARIABLE
  public comment: string;
  closeResult = '';

  // VARIABLE FOR DATA TABLE GET PENDING 
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public pendingList = new Array();

  constructor(
    private modalService: NgbModal,
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _purchaseService: PurchaseService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.comment = null;
    this.isDisabled = false;

    // LOAD INFORMATION
    this.getAllPending(this.companyCode, this.status);

    // VIEWS
    this.viewBeginning();
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTablePending($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.pendingList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.pendingList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////  
  //CHANGE PENDING
  changeQuery($event, status){
    $event.preventDefault();
    this.status = status;
    this.getAllPending(this.companyCode, status);
  }

  // GET ALL PENDING
  getAllPending(companyCode, status) {
    this.loading = true;

    return this._purchaseService.getAllByCompany_Status(companyCode, status).then(
      response => {
        if (response.result) {
          console.log('datos',response);
          this.pendingList = response.records[2];
          this.temp = this.pendingList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE PENDING
  checkFunction($event) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    this._purchaseService.getOne(this.activeRow.purchaseCode).then(
      async (response) => {
        console.log(response);
        if (response.result == true) {
          console.log(response.records);
          this.purchase = response.records[0];
          this.purchaseDetail = response.records[0].detail;
          this.viewPurchase();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.isDisabled = false;
      this.loading = false;
    });
  }

  // AUTHORIZED
  authorizedPurchase() {
    this.comment == null ? this.comment = '' : '';

    let json = {
      'purchaseCode': this.purchaseModel.purchaseCode,
      'providerEmail': this.activeRow.providerContactEmail, //providerContactEmail
      'purchaseComment': this.comment,
      'purchaseAuthorizedUser': this.userCode,
      'purchaseStatus': 2
    };

    console.log(json);
    
    this.isDisabled = true;
    this.loading = true;

    this._purchaseService.authorized(json).then(
      async (response) => {
        if (response.result == true) {
          console.log('llego');
          this.getAllPending(this.companyCode, this.status);
          this.cleanModel();
          console.log(response.records);
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.isDisabled = false;
      this.loading = false;
    });
    //*/
  }

  // CANCEL
  cancelPurchase() {
    if (this.comment != '' && this.comment != null && this.comment != undefined) {
     
      let json = {
        'purchaseCode': this.purchaseModel.purchaseCode,
        //'providerContactEmail': this.activeRow.providerContactEmail,
        'purchaseComment': this.comment,
        'purchaseCancelUser': this.userCode,
        'purchaseStatus': 3
      };

      console.log(json);
      //*
      this.isDisabled = true;
      this.loading = true;

      this._purchaseService.cancel(json).then(
        async (response) => {
          if (response.result == true) {
            this.getAllPending(this.companyCode, this.status);
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });
      //*/
    } else {
      this._utilsService.showNotification(2, 'No ingreso un comentario');
    }
  }

  ///////////////////////COMPLEMENT///////////////////////  
  // MODAL
  open(content, opcion, $event) {
    $event.preventDefault();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      this.purchaseModel.purchaseCode == undefined ? this.purchaseModel.purchaseCode = this.activeRow.purchaseCode : '';
      this.purchaseModel.providerContactEmail == undefined ? this.purchaseModel.providerContactEmail = this.activeRow.providerContactEmail : '';
      opcion == 1 ? this.authorizedPurchase() : this.cancelPurchase();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //  VIEWS
  viewBeginning() {
    this.isViewBeginning = true;
    this.isViewPurchase = false;
  }

  viewPurchase() {
    this.isViewBeginning = false;
    this.isViewPurchase = true;
  }

  // CLEAR MODAL
  cleanModel() {
    this.viewBeginning();
    this.isDisabled = false;
    this.comment = null;
    this.purchase = [];
    this.purchaseDetail = [];
    // this.purchase = {} as Purchase;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-compras-autorizar-cancelar.xlsx');
  }
}
