<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home&nbsp;/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/balance-sheet">&nbsp;Libro de Diario</a>
        </div>
    </div>
</div>

<div class="main-content">

    <div class="row">        
        <div class="col-md-12 text-right" style="margin-bottom: 15px;">                   
            <a class="btn btn-gray btn-sale" routerLink="/retentions-report">
                Reporte de Retenciones
            </a>
            &nbsp; &nbsp;
            <a class="btn btn-gray btn-sale" routerLink="/retentions-invoice-report">
                Reporte de Facturas & Retenciones
            </a>
        </div>
    </div>

    <!-- NEW REGISTER -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            Registrar
                        </div>
                        <div class="col-md-2">
                            <label>Establecimiento</label>
                            <select class="form-control form-control-sm"
                                [(ngModel)]="dairyRegister.dairyRegisterTypeEstablishment">
                                <option value=1>Sucursal</option>
                                <option value=2>Empresa</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Cuenta</label>
                            <select class="form-control form-control-sm"
                                [(ngModel)]="dairyRegister.dairyRegisterAccount">
                                <option *ngFor="let i of accountTypeList" [value]=i.accountTypeCode>
                                    {{i.accountTypeName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label>Tipo</label>
                            <select class="form-control form-control-sm" [(ngModel)]="dairyRegister.dairyRegisterType">
                                <option value=1>Debe</option>
                                <option value=2>Haber</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Fecha</label>
                            <input type="date" class="form-control form-control-sm"
                                [(ngModel)]="dairyRegister.dairyRegisterDate" />
                        </div>
                        <div class="col-md-2">
                            <label>Monto</label>
                            <input type="number" class="form-control form-control-sm"
                                [(ngModel)]="dairyRegister.dairyRegisterAmount" />
                        </div>
                        <div class="col-md-3">
                            <label>Documento</label>
                            <input type="text" class="form-control form-control-sm"
                                [(ngModel)]="dairyRegister.dairyRegisterDocument" />
                        </div>
                        <div class="col-md-7">
                            <label>Descripción</label>
                            <input type="text" class="form-control form-control-sm"
                                [(ngModel)]="dairyRegister.dairyRegisterDescription" />
                        </div>
                        <div class="col-md-2 text-center">
                            <button type="submit" class="btn btn-info btn-sm" (click)="saveRegister()"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;">
                                ✔ </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TABLE -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-6">
                            <h4 class="card-title">Libro de Diario por: </h4>
                            <h6 class="category">
                                Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-2">
                            <label>Establecimiento</label>
                            <select class="form-control form-control-sm"
                                [(ngModel)]="dairyRegisterList.dairyRegisterListTypeEstablishment">
                                <option value=1>Sucursal</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label>Fecha</label>
                            <input type="date" class="form-control form-control-sm"
                                [(ngModel)]="dairyRegisterList.dairyRegisterListDate" />
                        </div>
                        <div class="col-md-2 text-center">
                            <button type="submit" class="btn btn-info btn-sale" (click)="getDairyRegister()"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;">
                                Aceptar </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <button class="btn btn-success btn-sale" style="width: 100%;"
                                    (click)="downloadToExcel()">
                                    Descargar Excel
                                </button>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            (activate)="onActivate($event)" [rows]="temp">
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Cuenta"></ngx-datatable-column>
                            <ngx-datatable-column name="Documento"></ngx-datatable-column>
                            <ngx-datatable-column name="Monto"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo"></ngx-datatable-column>
                            <ngx-datatable-column name="Descripcion"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- TABLE  -->
</div>