import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Company } from '../models/company';
import { CompanyType } from '../models/companyType';
import { Plan } from '../models/plan';
import { environment } from 'src/environments/environment';


@Injectable()
export class CompanyService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL COMPANY
    getAll(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-company', { headers: headers }).toPromise();
    }

    // GET ONE COMPANY
    getOne(code): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-company/one/' + code, { headers: headers }).toPromise();
    }

    // GET ALL COMPANY TYPE
    getAllCompanyType(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-companyType', { headers: headers }).toPromise();
    }

    // GET ALL COMPANY PLAN
    getAllCompanyPlan(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-plan', { headers: headers }).toPromise();
    }

    // INSERT COMPANY
    insert(company: Company): Promise<any> {
        let params = JSON.stringify(company);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-company', params, { headers: headers }).toPromise();
    }

    // INSERT COMPANY TYPE
    insertCompanyType(companyType: CompanyType): Promise<any> {
        let params = JSON.stringify(companyType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-companyType', params, { headers: headers }).toPromise();
    }

    // INSERT COMPANY PLAN
    insertCompanyPlan(plan: Plan): Promise<any> {
        let params = JSON.stringify(plan);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-plan', params, { headers: headers }).toPromise();
    }

    // UPDATE COMPANY
    update(company: Company): Promise<any> {
        let params = JSON.stringify(company);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-company', params, { headers: headers }).toPromise();
    }

    // UPDATE COMPANY TYPE
    updateCompanyType(companyType: CompanyType): Promise<any> {
        let params = JSON.stringify(companyType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-companyType', params, { headers: headers }).toPromise();
    }

    // UPDATE COMPANY PLAN
    updateCompanyPlan(plan: Plan): Promise<any> {
        let params = JSON.stringify(plan);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-plan', params, { headers: headers }).toPromise();
    }
}