<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/debit-note">Nota de Débito</a>
        </div>
    </div>
</div>
<div class="main-content">

    <!-- BUSCAR FACTURA -->
    <div class="row" *ngIf="isBeginning">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Fecha:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="begDate"
                                    (change)="changeDate($event)" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <br>
                            <div class="form-group">
                                <button class="btn btn-info btn-sale" style="width: 100%;" [disabled]="isDisabled"
                                    (click)="getAll()">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="isBeginning">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Facturas</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Serie"></ngx-datatable-column>
                            <ngx-datatable-column name="Factura"></ngx-datatable-column>
                            <ngx-datatable-column name="Total"></ngx-datatable-column>
                            <ngx-datatable-column name="Nit"></ngx-datatable-column>
                            <ngx-datatable-column name="Cliente"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="true"
                                [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon edit"
                                        (click)="searchInvoice($event)" ngbTooltip="Ver" placement="top">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-success btn-icon remove"
                                        (click)="createDebitNote($event)" *ngIf="row.creditNoteAuthorization == null"
                                        ngbTooltip="Crear Nota..." placement="left">
                                        <i class="now-ui-icons files_paper"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-gray btn-icon remove"
                                        (click)="searchInvoice($event, row.creditNoteAuthorization)"
                                        ngbTooltip="Reimprimir Nota" placement="left"
                                        *ngIf="row.creditNoteAuthorization != null">
                                        <i class="now-ui-icons files_paper"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>

                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN BUSCAR FACTURA -->

    <!-- MODULO COMPLEMENTO -->
    <div class="row justify-content-center" *ngIf="isDebitNote">
        <div class="col-md-12 text-right">
            <a class="btn btn-icon btn-back" (click)="viewBeginning()" ngbTooltip="Cancelar">
                <i class="now-ui-icons arrows-1_minimal-left"></i>
            </a>
            &nbsp;
            <a class="btn btn-icon btn-back" (click)="searchInvoice($event, invoiceHeader.invoiceCode)"
                ngbTooltip="Ver Factura">
                <i class="fas fa-eye"></i>
            </a>
            &nbsp;
            <button class="btn btn-back" (click)="insertDebitNote()" [disabled]="isDisabled">
                ✔ Realizar Nota de Débito
            </button>
        </div>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>NIT:</label>
                                    <p>{{client.clientTaxDocument}}</p>
                                </div>
                                <div class="col-md-9">
                                    <label>Nombre:</label>
                                    <p>{{client.clientName}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-9">
                                    <label>Correo:</label>
                                    <p>{{client.clientEmail}}</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Télefono:</label>
                                    <p>{{client.clientPhone}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>Dirección:</label>
                            <p>{{client.clientAddress}}</p>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-9">
                                    <label>Número de Autorización:</label>
                                    <!-- <p>{{fel.invoiceAuthorization}}</p> -->
                                    <p>{{debitNote.debitNoteDocument}}</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Fecha Origen:</label>
                                    <!-- <p>{{fel.invoiceDate | date:'dd-MM-yyyy'}}</p> -->
                                    <p>{{debitNote.debitNoteDate | date:'dd-MM-yyyy'}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <label>Concepto</label>
                            <select class="form-control form-control-sm" [(ngModel)]="debitNote.debitNoteConcept">
                                <option disabled>Seleccione...</option>
                                <option value=1>Anulación</option>
                                <option value=2>Devolución</option>
                                <option value=3>Descuento</option>
                            </select>
                            <br>
                        </div>
                        <div class="col-md-12">
                            <label>Motivo del Ajuste</label>
                            <textarea class="form-control" rows="12" style="background: #f0f0f0;"
                                [(ngModel)]="debitNote.debitNoteComment"></textarea>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN COMPLEMENTO -->

    <!-- PRODUCTOS -->
    <div class="row justify-content-center" *ngIf="isDebitNote">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-10">
                            <div class="row">
                                <div class="col-sm-12 col-md-8">
                                    <ng-multiselect-dropdown [placeholder]="'Seleccione...'"
                                        [settings]="dropdownSettings" [data]="inventoryList" [(ngModel)]="selectedItems"
                                        (onSelect)="onItemSelect($event)">
                                    </ng-multiselect-dropdown>
                                    <!-- [disabled]='isEdit'-->
                                </div>
                                <div class="col-sm-12 col-md-2 form-group">
                                    <select class="form-control" [ngModel]="priceType"
                                        (ngModelChange)="onChangePrice($event)">
                                        <option value=1>Normal</option>
                                        <option value=2>Descuento</option>
                                        <option value=3>Oferta</option>
                                        <option value=4>Minimo</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <input type="number" class="form-control"
                                            [(ngModel)]='invoiceDetail.invoiceDetailQuantity' placeholder="Cantidad" />
                                    </div>
                                </div>
                                <div class="col-sm-12" *ngIf="serial">
                                    <ng-multiselect-dropdown [placeholder]="'Seleccione...'"
                                        [settings]="dropdownSettingsSerial" [data]="serialList"
                                        [(ngModel)]="selectedItemsSerial" (onSelect)="onItemSelectSerial($event)"
                                        (onDeSelect)="onItemDeSelectSerial($event)">
                                    </ng-multiselect-dropdown>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <button type="submit" class="btn btn-info btn-sale" (click)="saveLineInvoiceSerial()"
                                [disabled]="isDisabled" *ngIf="serial">Agregar</button>
                            <button type="submit" class="btn btn-info btn-sale" (click)="saveLineInvoice()"
                                [disabled]="isDisabled" *ngIf="!serial">Agregar</button>
                        </div>
                    </div>
                    <!-- Detalle de Venta -->
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr style="font-weight: bold;">
                                        <td>No.</td>
                                        <td>B/S</td>
                                        <td>Cantidad</td>
                                        <td>Descripción</td>
                                        <td class="text-center">P/U con IVA {{moneda}}</td>
                                        <td class="text-center">Descuento</td>
                                        <td class="text-center">Total {{moneda}}</td>
                                        <td class="text-center">Impuesto {{moneda}}</td>
                                        <td class="text-center">Opción</td>
                                    </tr>
                                </thead>
                                <!-- <tr *ngFor="let e of felDetail, let i=index"> -->
                                <tbody>
                                    <tr *ngFor="let e of invoiceLine, let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td class="text-center">{{e.isService}}</td>
                                        <td class="text-center">{{e.quantity | number:'1.2-2'}}</td>
                                        <td>{{e.description}}</td>
                                        <td class="text-right">{{e.unitPrice | number:'1.2-2'}}</td>
                                        <td style="text-align: right;">
                                            <input type="number" class="form-control" [(ngModel)]="e.discount"
                                                (blur)="ApplyDiscount($event, e.itemCode, e.discount)"
                                                style="max-width: 90px; margin: 0px auto;" />
                                        </td>
                                        <td class="text-right">{{e.total | number:'1.2-2'}}</td>
                                        <td class="text-right" style="color: #b7b7b7;">{{e.taxAmount |
                                            number:'1.2-2'}}
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger btn-sm" (click)="deleteLine(e.itemCode)"
                                                [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                                                <i class="now-ui-icons ui-1_simple-remove"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12"
                            style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                            Total <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                            {{invoiceHeader.invoiceTotal | number:'1.2-2'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin Productos -->
</div>