import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Manager } from '../models/manager';
import { StoreManager } from '../models/storeManager';
import { environment } from 'src/environments/environment';


@Injectable()
export class ManagerService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL MANAGER
    getAll(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-manager', { headers: headers }).toPromise();
    }  

    // GET ALL MANAGER BY COMPANY
    getAllManagerByCompany(company): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-manager/company/' + company, { headers: headers }).toPromise();
    } 

    // GET ALL STORE MANAGER
    getAllStoreManager(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-storeManager', { headers: headers }).toPromise();
    } 
    
    // GET ALL STORE MANAGER
    getAllStoreManagerByCompany(company): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-storeManager/company/' + company, { headers: headers }).toPromise();
    } 

    // INSERT MANAGER
    insert(manager: Manager): Promise<any> {
        let params = JSON.stringify(manager);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-manager', params, { headers: headers }).toPromise();
    }

    // INSERT MANAGER
    insertStoreManager(storeManager: StoreManager): Promise<any> {
        let params = JSON.stringify(storeManager);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-storeManager', params, { headers: headers }).toPromise();
    }

    // UPDATE MANAGER
    update(manager: Manager): Promise<any> {
        let params = JSON.stringify(manager);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-manager', params, { headers: headers }).toPromise();
    }

    // // UPDATE STORE MANAGER
    updateStoreManager(storeManager: StoreManager): Promise<any> {
        let params = JSON.stringify(storeManager);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-storeManager', params, { headers: headers }).toPromise();
    }
}