<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
  <div class="row link-header ml-5">
    <div>
      <a class="a-home" routerLink="/home">Home/</a>
    </div>
    <div>
      <a class="text-info" routerLink="/billing">Factura de Exportación Descripciones</a>
    </div>
  </div>
</div>
<!-- <div class="panel-header panel-header-sm">
    <div class="link-header ml-3">
        <a class="a-yes" routerLink="/home">Home</a>
        <a class="a-no"> / Ventas</a>
    </div>
</div>
<br /> -->
<div class="main-content">
  <!-- BUSCAR NIT -->
  <div class="row justify-content-center" *ngIf="isBeginning">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
                <label>Tipo de Identificación</label>
            </div>
          </div>
          <div class="row">
              <div class="col-sm-4 col-md-4 col-lg-9">
                  <select class="form-control form-control-sm" [ngModel]="client.clientType"
                      (ngModelChange)="onChangeTypeClient($event)">
                      <option [value]="0">NIT</option>
                      <option [value]="1">CUI</option>
                      <option [value]="2">Pasaporte</option>
                  </select>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12">
                  <label>No. Identificación</label>
              </div>
          </div>
          <div class="row">                        
              <div class="col-sm-4 col-md-4 col-lg-9">
                  <div class="form-group">
                      <input type="text" class="form-control" [(ngModel)]="client.clientTaxDocument"
                          (keyup.enter)="buscarNITLocal($event, client.clientTaxDocument)"
                          placeholder="*Escribe un NIT/CUI/Pasaporte sin espacios o guiones" minlength="8" />
                  </div>
              </div>
              <div class="col-sm-4 col-md-4 col-lg-9">
                  <div class="form-group">
                      <input type="text" class="form-control" [(ngModel)]="client.clientName" [toggleable]="true" [disabled]="disabledEXT"
                          placeholder="*Escribe el nombre completo del receptor" minlength="8" />
                  </div>
              </div>
              <div class="col-sm-4 col-md-4 col-lg-3">                        
                  <button class="btn btn-info btn-sale"
                      (click)="buscarNITLocal($event, client.clientTaxDocument)" [disabled]="isDisabled"
                      style="width: 100%;">Buscar</button>
              </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-info btn-sale" style="width: 100%" (click)="buscarNITLocal($event, 'CF')"
                [disabled]="isDisabled">
                Consumidor Final
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN BUSCAR NIT -->

  <!-- MODULO CLIENTE -->
  <div class="row justify-content-center" *ngIf="isBilling">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!-- <div class="col-md-1 ma-g">
                            <div class="row">
                                <div class="col-md-12">
                                    <a class="btn btn-round btn-info btn-icon btn-back remove"
                                        (click)="viewBeginning()">
                                        <i class="now-ui-icons arrows-1_minimal-left"></i>
                                    </a>
                                </div>
                            </div>
                        </div> -->
            <div class="col-md-12 ma-g">
              <div class="row">
                <div class="col-md-2 ma-g">
                  <label>Nit</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="client.clientTaxDocument"
                      [disabled]="recurrentClient" disabled />
                  </div>
                </div>
                <div class="col-md-3 ma-g">
                  <label>Nombre</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="client.clientName"/>
                  </div>
                </div>
                <div class="col-md-3 ma-g">
                  <label>E-mail</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="client.clientEmail" />
                  </div>
                </div>
                <div class="col-md-4 ma-g">
                  <label>Dirección</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="client.clientAddress" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 ma-g">
                  <label>Código consignatario</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.consigneeCode" />
                  </div>
                </div>
                <div class="col-md-3 ma-g">
                  <label>Nombre consignatario *</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.consigneeName" />
                  </div>
                </div>
                <div class="col-md-4 ma-g">
                  <label>Dirección consignatario *</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.consigneeAddress" />
                  </div>
                </div>
                <div class="col-md-3">
                  <label>INCOTERM *</label>
                  <select class="form-control form-control-sm" [(ngModel)]="exportInfo.incotermCode">
                    <option value="CRF">CRF - Costo y flete</option>
                    <option value="CIF">CIF - Costo, seguro y flete</option>
                    <option value="CIP">CIP - Flete y seguro pagado hasta</option>
                    <option value="CPT">CPT - Flete pagado hasta</option>
                    <option value="DAP">DAP - Entrega en lugar</option>
                    <option value="DAT">DAT - Entrega en terminal</option>
                    <option value="DDP">DDP - Entregado en destino con derechos pagados</option>
                    <option value="EXW">EXW - En fábrica</option>
                    <option value="FAS">FAS - Libre al costado del buque</option>
                    <option value="FCA">FCA - Libre transportista</option>
                    <option value="FOB">FOB - Libre a bordo</option>
                    <option value="ZZZ">ZZZ - Otros</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 ma-g">
                  <label>Código comprador</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.buyerCode" />
                  </div>
                </div>
                <div class="col-md-3 ma-g">
                  <label>Nombre comprador</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.buyerName" />
                  </div>
                </div>
                <div class="col-md-4 ma-g">
                  <label>Dirección comprador</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.buyerAddress" />
                  </div>
                </div>
                <div class="col-md-3 ma-g">
                  <label>Otras referencias</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.otherReferences" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 ma-g">
                  <label>Código exportador</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.exporterCode" />
                  </div>
                </div>
                <div class="col-md-3 ma-g">
                  <label>Nombre exportador</label>
                  <div class="form-group">
                    <input type="text" class="form-control pa-g" [(ngModel)]="exportInfo.exporterName" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN MODULO CLIENTE -->

  <!-- PRODUCTOS -->
  <div class="row justify-content-center" *ngIf="isBilling">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-2">
              <label>Fecha</label>
              <input type="date" class="form-control form-control-sm" [(ngModel)]="invoiceHeader.invoiceDate"
                (change)="validateDate($event)" />
            </div>
            <div class="col-md-2">
              <label>Serie</label>
              <select class="form-control form-control-sm" [ngModel]="invoiceHeader.invoiceInternSerial"
                (ngModelChange)="onChangeInternSerial($event)">
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
              </select>
            </div>
            <div class="col-md-2">
              <label>Moneda</label>
              <select class="form-control form-control-sm" [ngModel]="invoiceHeader.invoiceCoin"
                (ngModelChange)="onChange($event)">
                <!-- style="background: #2CA8FF; color: #FFFFFF; font-size: 0.8rem;" -->
                <option [value]="0">Q - Quetzal</option>
                <option [value]="1">$ - Dolar</option>
              </select>
            </div>
            <div class="col-md-2">
              <label>Tasa de Cambio</label>
              <input type="number" class="form-control form-control-sm" style="background: #ffffff"
                [(ngModel)]="invoiceHeader.invoiceExchangeRate" [disabled]="moneda == 'Q'" />
            </div>
            <div class="col-md-4" style="text-align: end">
              <button class="btn btn-success btn-sale" (click)="insertInvoice()" [disabled]="isDisabled">
                ✔ Realizar Ventas
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div>
                  <input type="hidden" [(ngModel)]="itemCode" />
                </div>
                <div class="col-md-4 pa-star">
                  <div class="form-group">
                    <select class="form-control pa" [(ngModel)]="invoiceDetail.invoiceDetailType">
                      <option value="B">Bien</option>
                      <option value="S">Servicio</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-8 pa-mar">
                  <div class="form-group">
                    <input type="text" class="form-control pa" [(ngModel)]="invoiceDetail.invoiceDetailDescription"
                      placeholder="Descripción" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="row">
                <div class="col-md-3 pa-mar">
                  <div class="form-group">
                    <input type="number" class="form-control pa" [(ngModel)]="invoiceDetail.invoiceDetailQuantity"
                      (blur)="calculateTax()" placeholder="Cantidad" />
                  </div>
                </div>
                <div class="col-md-3 pa-mar">
                  <div class="form-group">
                    <input type="number" class="form-control pa" [(ngModel)]="invoiceDetail.invoiceDetailUnitPrice"
                      (blur)="calculateTax()" placeholder="Precio" />
                  </div>
                </div>
                <div class="col-md-3 pa-mar">
                  <div class="form-group">
                    <input type="number" class="form-control pa" [(ngModel)]="invoiceDetail.invoiceDetailDiscount"
                      placeholder="Descuento" (blur)="calculateTax()" />
                  </div>
                </div>
                <div class="col-md-3 pa-mar">
                  <div class="form-group">
                    <input type="number" class="form-control pa" [(ngModel)]="invoiceDetail.invoiceDetailTax"
                      placeholder="Impuesto" disabled />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1">
              <div class="row">
                <div class="col-md-12 pa-end">
                  <button type="submit" class="btn-min btn btn-info btn-sale"
                    style="padding: 0px 0px 0px 0px !important" (click)="saveLineInvoice()">
                    ✔
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <!-- Detalle de Venta -->
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr style="font-weight: bold">
                    <td>No.</td>
                    <td>B/S</td>
                    <td>Cantidad</td>
                    <td>Descripción</td>
                    <td class="text-center">P/U con IVA {{ moneda }}</td>
                    <td class="text-center">Descuento</td>
                    <td class="text-center">Total {{ moneda }}</td>
                    <td class="text-center">Impuesto {{ moneda }}</td>
                    <td class="text-center">Opción</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let e of invoiceLine; let i = index">
                    <td class="text-center">{{ i + 1 }}</td>
                    <td class="text-center">{{ e.isService }}</td>
                    <td class="text-center">{{ e.quantity | number: "1.2-2" }}</td>
                    <td>{{ e.description }}</td>
                    <td class="text-right">{{ e.unitPrice | number: "1.2-2" }}</td>
                    <td style="text-align: right">
                      <input type="number" class="form-control" [(ngModel)]="e.discount"
                      (blur)="ApplyDiscount($event, e.itemCode, e.discount)"
                      style="max-width: 90px; margin: 0px auto" />
                    </td>
                    <td class="text-right">
                      {{ e.total | number: "1.2-2" }}
                    </td>
                    <td class="text-right" style="color: #b7b7b7">
                      {{ e.taxAmount | number: "1.2-2" }}
                    </td>
                    <td class="text-center">
                      <button class="btn btn-warning btn-sm" (click)="editLine(e.itemCode)"
                        [disabled]="isDisabled" ngbTooltip="Editar" placement="left">
                        <i class="now-ui-icons arrows-1_share-66"></i>
                      </button>
                      &nbsp;
                      <button class="btn btn-danger btn-sm" (click)="deleteLine(e.itemCode)"
                        [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="
                text-align: end;
                font-weight: bold;
                font-size: 25px;
                padding-right: 35px;
              ">
              Total
              <small style="font-weight: bold; font-size: 20px">{{
                moneda
                }}</small>
              {{ invoiceHeader.invoiceTotal | number: "1.2-2" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin Productos -->
</div>