import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { PurchaseService } from 'src/app/services/purchase.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-purchase-item-report',
  templateUrl: './purchase-item-report.component.html',
  styleUrls: ['./purchase-item-report.component.scss'],
  providers: [UtilsService, PurchaseService, InventoryService]
})
export class PurchaseItemReportComponent implements OnInit {
  // MODELS VARIABLE 
  public isDisabled: boolean;
  public begDate: string;
  public endDate: string;
  public storeCode: number;
  public storeName: string;
  public inventoryCode: number;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      sucursal: {
        title: 'Sucursal'
      },
      proveedor: {
        title: 'Proveedor'
      },
      cantidad: {
        title: 'Cantidad',
        type: 'number'
      },
      codigo: {
        title: 'Codigo'
      },
      descripcion: {
        title: 'Descripcion'
      },
      precio: {
        title: 'Precio',
        type: 'number'
      }
    }
  };
  loading;

  // DATA SELECT
  public selectedItems = [];
  public inventoryList: any[] = new Array();
  public dropdownSettings: IDropdownSettings = {};
  closeDropdownSelection = false;

  // VARIABLE FOR DATA TABLE //GET ALL //GET PENDING 
  public temp = [];
  public activeRow: any;
  public selected: any[] = [];
  public entries: number = 10;
  public allList = new Array();
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;

  constructor(
    private _utilsService: UtilsService,
    private _purchaseService: PurchaseService,
    private _inventoryService: InventoryService
  ) { }

  ngOnInit(): void {
    this.isDisabled = false;
    this.inventoryCode = null;

    // DATE
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');

    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.getSelectSetting();
    this.getInventoryList(this.storeCode);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.allList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////SELECT///////////////////////
  getSelectSetting() {
    this.dropdownSettings = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'inventoryCode',
      textField: 'inventoryName'
    };
  }

  onItemSelect(item: any) {
    this.inventoryCode = item.inventoryCode;
  }

  onDeItemSelect() {
    this.inventoryCode = null;
  }

  getInventoryList(code) {
    return this._inventoryService.getAllByStore(code).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getReport() {
    console.log(this.inventoryCode, this.begDate, this.endDate);
    if (this.inventoryCode != null && this.begDate != null) {
      const json = {
        begDate: this.begDate,
        endDate: this.endDate,
        inventoryCode: this.inventoryCode
      }

      console.log(json);
      return this._purchaseService.reportItem(json).then(
        response => {
          if (response.result) {
            console.log(response.records);
            this.allList = response.records;
            this.temp = this.allList;
            this._utilsService.showNotification(1, response.message);
          } else {
            console.log(response.message);
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this._utilsService.showNotification(2, error);
        }
      ).finally(() => {
        this.loading = false;
      });
    }
  }


  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-item-de-compra.xlsx');
  }
}
