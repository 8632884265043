<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/cancellation">Anulación y Reimpresion</a>
        </div>
    </div>
</div>
<br />
<div class="main-content">
    <!-- LISTAR FACTURAS -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg" routerLink="/cancellation-invoice">Factura</button>
                        </div>
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg" routerLink="/cancellation-exchange-bill">Factura Cambiaria</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN LISTAR FACTURAS -->

</div>