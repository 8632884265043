<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/warehouse">Bodega</a>
        </div>
    </div>
</div>

<div class="main-content">

    <!-- INPUT WAREHOUSE -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-11">
                            <b class="card-title" *ngIf="!isEdit">Nueva Bodega </b>
                            <b class="card-title text-warning" *ngIf="isEdit">Modificar Bodega </b>
                        </div>
                        <div class="col-1" *ngIf="isEdit">
                            <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                (click)="cancelUpdate($event)"><i class="fas fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>*Empresas</label>
                                <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItems"
                                    [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                                </angular2-multiselect>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>Enlazar Cliente</label>
                            <div class="form-group">
                                <bSwitch [switch-on-color]="'success'" (changeState)="onClient()"
                                    [(ngModel)]="withClient"
                                    switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                    switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                </bSwitch>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Cliente</label>
                                <angular2-multiselect [data]="clientList" [(ngModel)]="selectedItemsClient"
                                    [settings]="dropdownSettingsClient" (onSelect)="onItemSelectClient($event)">
                                </angular2-multiselect>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>NIT</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="warehouse.warehouseTaxDocument" disabled/>
                            </div>
                        </div>                        
                        <div class="col-md-4">
                            <label>*Nombre</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="warehouse.warehouseName" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>Télefono</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="warehouse.warehousePhone" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>*Dirección</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="warehouse.warehouseClientAddress" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label></label>
                            <button class="btn btn-info btn-block btn-erp" (click)="save()" *ngIf="!isEdit"
                                [disabled]="isDisabled">Guardar</button>
                            <button class="btn btn-primary btn-block btn-erp" (click)="update()" *ngIf="isEdit"
                                [disabled]="isDisabled">Actualizar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END INPUT COMPANY -->

    <!-- TABLE -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de productos</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                        records"</h6>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">                            
                            <ngx-datatable-column name="Empresa"></ngx-datatable-column>
                            <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                            <ngx-datatable-column name="Telefono"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado" prop="estadoTexto"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn" let-row="row">
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        *ngIf="row.warehouseStatus == 1"
                                        (click)="editFunction($event)" ngbTooltip="Editar"><i
                                            class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm like" 
                                        *ngIf="row.warehouseStatus == 2"
                                        (click)="updateActive($event)" ngbTooltip="Activar Bodega" placement="left"><i
                                            class="fas fa-check-circle"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm like" 
                                        *ngIf="row.warehouseStatus == 1"
                                        (click)="updateInactive($event)" ngbTooltip="Inactivar Bodega" placement="left"><i
                                            class="fas fa-minus-circle"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="deleteFunction($event)" ngbTooltip="Eliminar"><i
                                            class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>