<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="a-return" routerLink="/transfer-process">Procesar Transferencia/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/transfer-report">Reporte</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- LISTAR FACTURAS -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-7">
                            <label><b>Pendiente por Bodega</b></label>
                            <angular2-multiselect [data]="warehouseList" [(ngModel)]="selectedItemsWarehouse"
                                [settings]="dropdownSettingsWarehouse" (onSelect)="onItemSelectWarehouse($event)" (onDeSelect)="onDeSelectWarehouse()">
                            </angular2-multiselect>
                            <br>
                            <button class="btn btn-info  btn-pnd" style="width: 100%;" (click)="getReport()"
                                [disabled]="isDisabled">Aceptar</button>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-12">
                                    <label><b>Todas las pendiente</b></label>
                                    <br>
                                    <button class="btn btn-info btn-pnd" (click)="getAllReport()"
                                        [disabled]="isDisabled">Aceptar</button>
                                </div>
                                <div class="col-md-12">
                                    <label><b>Procesadas entre Sucursal</b></label>
                                    <br>
                                    <button class="btn btn-info btn-pnd btn-sale" (click)="getAllReportInStore()"
                                        [disabled]="isDisabled">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN LISTAR FACTURAS -->

    <!-- TABLE GET PENDIG -->
    <div class="row justify-content-center" *ngIf="isReport">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Transferencias Pendientes</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">                            
                            <ngx-datatable-column name="Traslado"></ngx-datatable-column>
                            <ngx-datatable-column name="Sucursal"></ngx-datatable-column>
                            <ngx-datatable-column name="Bodega"></ngx-datatable-column>
                            <ngx-datatable-column name="Cantidad"></ngx-datatable-column>
                            <ngx-datatable-column name="Producto"></ngx-datatable-column>
                            <ngx-datatable-column name="Serie"></ngx-datatable-column>                            
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE PENDING-->

     <!-- TABLE PROCESS -->
     <div class="row justify-content-center" *ngIf="isStoreReport">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de Transferencias finalizadas</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"</h6>
                </div>
                <div class="col-md-4">
                    <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcelProcesadas()"
                        [disabled]="isDisabled">
                        Descargar Excel
                    </button>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Salio"></ngx-datatable-column>
                            <ngx-datatable-column name="Entro"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Recibo"></ngx-datatable-column>
                            <ngx-datatable-column name="Descripcion"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>