import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { Purchase } from 'src/app/models/purchase';
import { PurchaseService } from 'src/app/services/purchase.service';
import { Inventory } from '../../../models/inventory';
import { InventoryService } from 'src/app/services/inventory.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PrintService } from 'src/app/services/print.service';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { ConsoleLogger } from '@aws-amplify/core';

interface State {
  files;
  fileNames;
}

@Component({
  selector: 'app-purchase-process',
  templateUrl: './purchase-process.component.html',
  styleUrls: ['./purchase-process.component.scss'],
  providers: [PurchaseService, InventoryService, UtilsService, PrintService, CompanyService]
})

export class PurchaseProcessComponent implements OnInit {
  // MODELS VARIABLE 
  company = {} as Company;
  public purchaseModel = {} as Purchase;
  public purchase: any[];
  public inventory = {} as Inventory;
  public isViewBeginning: boolean;
  public isViewPurchase: boolean;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isDisabled: boolean;
  //public productCode: number;
  public noInvoice: string;
  public serieInvoice: string;
  public authInvoice: string;
  public settings = {
    columns: {
      ingresado: {
        title: 'Ingresado'
      },
      proveedor: {
        title: 'Proveedor'
      },
      monto: {
        title: 'Monto',
        type: 'number'
      },
      estado: {
        title: 'Estado'
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE //GET ALL //GET PENDING 
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public purchaseList = new Array();

  // VARIABLE FOR SELECT 
  public inventoryList: any[] = new Array();
  public selectedItemsInventory = [];
  public dropdownSettingsInventory = {};
  public productList: any[] = new Array();
  public selectedItemsProduct = [];
  //public selectedItemsProduct: any[] = new Array();
  public dropdownSettingsProduct = {};
  public isService: number | boolean

  constructor(
    private modalService: NgbModal,
    private _utilsService: UtilsService,
    private _purchaseService: PurchaseService,
    private _inventoryService: InventoryService,
    private _printService: PrintService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.isDisabled = false;
    this.isNewItem = false;
    this.noInvoice = null;
    this.serieInvoice = null;
    this.authInvoice = null;

    // LOAD INFORMATION
    this.getAll(this.companyCode);
    await this.getInventoryList(this.storeCode);
    this.getProductList();
    this.getSelectSettingInventory(this.selectedItemsInventory);
    this.getSelectSettingProduct(this.selectedItemsProduct);
    await this.getCompany(this.companyCode);

    // VIEWS
    this.viewBeginning();
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.purchaseList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSettingInventory(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsInventory = [selected] : '';

    this.dropdownSettingsInventory = {
      singleSelection: true,
      text: "Seleccione Item...",
      searchPlaceholderText: 'Buscar',
      enableCheckAll: false,
      enableSearchFilter: true,
      lazyLoading: true,
      noDataLabel: 'Resultados:',
    };
  };

  getSelectSettingProduct(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsProduct = [selected] : '';

    this.dropdownSettingsProduct = {
      singleSelection: true,
      text: "Seleccione Item...",
      enableSearchFilter: true,
      lazyLoading: true,
    };
  };

  getInventoryList(storeCode) {
    this.loading = true;

    return this._inventoryService.getAllByStore(storeCode).then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.inventoryList.push({
              id: e.inventoryCode,
              itemName: e.inventoryBusinessCode + ' - ' + e.inventoryName,
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getProductList() {
    this.loading = true;

    return this._inventoryService.getAllProduct().then(
      response => {
        if (response.result) {
          this.productList = [];
          response.records.forEach(e => {
            this.productList.push({
              id: e.productCode,
              itemName: e.productName
            });
          })
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onItemSelectInventory(item: any) {
    this.inventory.inventoryCode = item.id;
    this.inventory.inventoryName = item.itemName;
    console.log(item);
  }

  onItemSelectProduct(item: any) {
    this.inventory.inventoryProduct = item.id;
    this.inventory.inventoryName = item.itemName;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL AUTHORIZADE
  getAll(companyCode) {
    this.loading = true;

    return this._purchaseService.getAllByCompany_Status(companyCode, 2).then(
      response => {
        if (response.result) {
          this.purchaseList = response.records[2];
          this.temp = this.purchaseList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  public purchaseDetail: any[] = new Array;
  public purchaseDetailTemp: any[] = [];
  public isNewItem: boolean;
  public companyBusinessCode: string;
  public providerBusinessCode: string;

  checkFunction($event) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    this._purchaseService.getOne(this.activeRow.purchaseCode).then(
      async (response) => {
        if (response.result == true) {
          console.log(response);
          this.purchase = response.records[0];
          this.purchaseModel = response.records[0];
          for (const e of response.records[0].detail) {
            this.purchaseDetail.push({
              purchaseFlag: false,
              purchaseDetailCode: e.purchaseDetailCode,
              purchaseDetailDeleted: e.purchaseDetailDeleted,
              purchaseDetailDescription: e.purchaseDetailDescription,
              purchaseDetailDiscount: e.purchaseDetailDiscount,
              purchaseDetailDueDate: e.purchaseDetailDueDate,
              purchaseDetailLot: e.purchaseDetailLot,
              purchaseDetailPrice: e.purchaseDetailPrice,
              purchaseDetailProductCode: e.purchaseDetailProductCode,
              purchaseDetailPurchaseCode: e.purchaseDetailPurchaseCode,
              purchaseDetailRecieveQuantity: null, //e.purchaseDetailRecieveQuantity,
              purchaseDetailRequestQuantity: e.purchaseDetailRequestQuantity,
              purchaseDetailStatus: e.purchaseDetailStatus,
              purchaseDetailTax: e.purchaseDetailTax,
              purchaseDetailUnitPrice: e.purchaseDetailUnitPrice,
              inventory: {}
            });
          };
          this.companyBusinessCode = this.activeRow.companyBusinessCode;
          this.providerBusinessCode = this.activeRow.providerBusinessCode;
          this.viewPurchase();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.isDisabled = false;
      this.loading = false;
    });
  }

  checkFunctionPDF($event) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    // console.log(this.activeRow.purchaseCode);
    console.log('active',this.activeRow);
    this._purchaseService.getOne(this.activeRow.purchaseCode).then(
      async (response) => {
        if (response.result == true) {
          console.log(response);          
          this.purchase = response.records[0];
          // if(this.companyCode == 22){
          //   this._printService.purchaseNailHistory(this.purchase, this.company);
          // }
          this._printService.purchaseNailHistory(this.purchase, this.company);
          //this.viewPurchase();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  // CANCEL LINE
  cancelLine(detailCode) {
    this.purchaseDetail.forEach(e => {
      //e.purchaseDetailCode == detailCode ? e.purchaseDetailStatus = 2 : '';
      e.purchaseDetailCode == detailCode ? e.purchaseDetailStatus = 0 : '';
    });
  }

  // ACTIVE LINE
  activeLine(detailCode) {
    this.purchaseDetail.forEach(e => {
      e.purchaseDetailCode == detailCode ? e.purchaseDetailStatus = 1 : '';
    });
  }

  // MODAL
  closeResult = '';
  open(content, detailCode) {
    this.isNewItem = false;

    //Validar si esta editando
    const index = this.purchaseDetail.findIndex(x => x.purchaseDetailCode === detailCode);
    if (Object.keys(this.purchaseDetail[index].inventory).length != 0) {
      this.inventory = this.purchaseDetail[index].inventory;

      if (this.purchaseDetail[index].inventory.inventoryCode) {
        const _selectedInventory = this.inventoryList.find(x => x.id === this.purchaseDetail[index].inventory.inventoryCode);
        _selectedInventory == undefined ? this.selectedItemsInventory = null : this.getSelectSettingInventory(_selectedInventory);
      } else {
        const _selectedProduct = this.productList.find(x => x.id === this.purchaseDetail[index].inventory.inventoryProduct);
        _selectedProduct == undefined ? this.selectedItemsInventory = null : this.getSelectSettingProduct(_selectedProduct);
        this.aceptBottom = true;
        this.isNewItem = true;
      }
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;

      //Guardar Datos de Inventario
      if (this.isNewItem) {
        delete this.inventory.inventoryCode;
      } else {
        const inventoryCode = this.inventory.inventoryCode;
        const inventoryName = this.inventory.inventoryName;
        this.inventory = {} as Inventory;
        this.inventory.inventoryCode = inventoryCode;
        this.inventory.inventoryName = inventoryName;
      }
      this.purchaseDetail[index].purchaseFlag = true;
      //this.inventory.inventoryName = this.purchaseDetail[index].purchaseDetailDescription;      
      this.inventory.inventoryQuantity = this.purchaseDetail[index].purchaseDetailRecieveQuantity;
      this.purchaseDetail[index].inventory = this.inventory;
      this.cleanModal();
    }, (reason) => {
      this.cleanModal();
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cleanModal() {
    this.selectedItemsInventory = null;
    this.selectedItemsProduct = null;
    this.inventory = {} as Inventory;
    this.aceptBottom = false;
    this.state = {} as State;
  }

  newItem() {
    this.isNewItem = true;
    if (!this.inventory.inventoryBusinessCode) {
      this.getSelectSettingProduct({ id: 1, itemName: 'SIN INVENTARIO' });
      this.inventory.inventoryProduct = 1;
    }
  }

  inventoryItem() {
    this.isNewItem = false;
  }

  async regularPhrase($event) {
    $event.preventDefault();
    this.aceptBottom = false;

    // var validacion = /\s/;
    //regexStr = '^[a-zA-Z0-9_]*$';
    // Validators.pattern('/^[1-9]\d{6,10}$/')
    // pattern="[1-9]\d{6,10}"
    //storeCode 9 & 20 company 16
    //return new RegExp(this.regexStr).test(event.key);

    //if (validacion.test(this.inventory.inventoryBusinessCode)) {
    this.inventory.inventoryBusinessCode = this.inventory.inventoryBusinessCode.replace(/\s+/g, '');
    this.inventory.inventoryBusinessCode = this.inventory.inventoryBusinessCode.replace(/\&+/g, 'y');
    this.inventory.inventoryBusinessCode = this.inventory.inventoryBusinessCode.replace(/\'+/g, '-');
    this.inventory.inventoryBusinessCode = this.inventory.inventoryBusinessCode.replace(/\"+/g, 'plg');

    //Validar si existe el code
    const validation = await this._inventoryService.validateBusinessCode(this.inventory.inventoryBusinessCode, this.storeCode);
    if (validation.records.length > 0) {
      this._utilsService.showNotification(3, 'Código existente');
    } else {
      this.aceptBottom = true;
    }
  }

  public aceptBottom = false;
  changeQuantity($event, index, quantity) {
    $event.preventDefault();
    this.purchaseDetail[index].inventory.inventoryQuantity ? this.purchaseDetail[index].inventory.inventoryQuantity = quantity : '';
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ///////////////////////FUNCTION FOR IMAGENS///////////////////////
  //public state: any = {};
  public state = {} as State;
  async addFile(e) {
    let fileNames = e.target.files[0].name;
    let files = e.target.files;

    this.state.fileNames = fileNames;
    this.state.files = files;
    this.purchaseModel.purchaseFileName = fileNames;

    const file = this.state.files.item(0);
    await this.convertBase64(file).then(
      (data: string) => {
        this.purchaseModel.purchaseFileObject = data;
      }
    );
    // console.log('archivo 64', this.purchaseModel.purchaseFileObject);
    // console.log('archivo name', this.purchaseModel.purchaseFileName);
    // console.log('files', this.state.fileNames, this.state.files);
  }

  convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  //verificar si esta vacio el state
  isObjectEmpty = function (state) {
    return Object.keys(state).length === 0;
  }

  async saveProcess() {
    // Verificar que todas la lineas sean asignadas
    let notCode = false;
    if(this.isService){
      console.log('servicio');
      for (const e of this.purchaseDetail) {
        console.log('e',e);
        e.inventory.inventoryCode = 0;
      }
      notCode = false;
    }
    else{
      console.log('no servicio');
      for (const e of this.purchaseDetail) {
        //delete e.purchaseFlag; //moverlo hasta validar el save
        Object.keys(e.inventory).length > 0 ? '' : notCode = true;
      }
    }    

    console.log('notCode',notCode, this.noInvoice);

    if (this.noInvoice != '' && this.noInvoice != null && notCode == false) { //notCode != false
      //   this.isDisabled = true;
      //   this.loading = true;

      console.log('files save', this.state.fileNames, this.state.files);
      console.log('files 64 save', this.purchaseModel.purchaseFileObject);
      console.log('archivo name', this.purchaseModel.purchaseFileName);

      //Convertir Archivo a base 64
      // if (this.isObjectEmpty(this.state) || this.state.fileNames === '') {
      //   this.purchaseModel.purchaseFileObject = '';
      //   this.state.fileNames = '';
      //   console.log('sin archivo');
      // } else {
      //   const file = this.state.files.item(0);
      //   await this.convertBase64(file).then(
      //     (data: string) => {
      //       this.purchaseModel.purchaseFileObject = data;
      //     });
      //     console.log('con archivo', this.purchaseModel.purchaseFileObject);
      // }

      let json = {
        "header": {
          "purchaseBiokal": 0,
          "purchaseStatus": 4,
          "purchaseInvoice": this.noInvoice,
          "purchaseSerieInvoice": this.serieInvoice,
          "purchaseAuthInvoice": this.authInvoice,
          "purchaseComment": this.purchaseModel.purchaseComment,
          "purchaseProcessUser": this.userCode,
          "purchaseCode": this.purchaseModel.purchaseCode,
          "purchaseCompany": this.companyCode,
          "purchaseStore": this.storeCode,
          "purchaseFileName": this.purchaseModel.purchaseFileName,
          "purchaseFile": this.purchaseModel.purchaseFileObject,
          "companyBusinessCode": this.companyBusinessCode,
          "providerBusinessCode": this.providerBusinessCode,
          "isService": this.isService
        },
        "detail": this.purchaseDetail //this.purchaseDetail
      }      

      console.log(json);
      console.log(this.purchase);
      console.log('service',this.isService);

      this._purchaseService.process(json).then(
        async (response) => {
          if (response.result == true) {

            this._printService.purchaseNail(this.purchase, this.purchaseDetail, this.company, json);

            this.viewBeginning();
            this.getAll(this.companyCode);
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });
    } 
    else {
      this._utilsService.showNotification(2, 'Falto llenar campos');
    }
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //  VIEWS
  viewBeginning() {
    this.state = {} as State;
    this.noInvoice = null;
    this.serieInvoice = null;
    this.authInvoice = null;
    this.isViewBeginning = true;
    this.isViewPurchase = false;
    this.purchaseDetail = [];
    this.cleanModal();
  }

  viewPurchase() {
    this.noInvoice = null;
    this.serieInvoice = null;
    this.authInvoice = null;
    this.isViewBeginning = false;
    this.isViewPurchase = true;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-compras-a-procesar.xlsx');
  }
}

