<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/invoice-report">Reporte de Ventas de Empresa</a>
        </div>
    </div>
</div>
<br />
<div class="main-content">
    <!-- LISTAR FACTURAS -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Del:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="begDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Para:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="endDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Forma Reporte:</label>
                            <div class="form-group">
                                <select class="form-control" [ngModel]="tipoR" (ngModelChange)="onChangeType($event)">
                                    <option value=1>Detalle Productos</option>
                                    <option value=2>Metodos de Pago</option>
                                </select>
                            </div>                            
                        </div>
                        <div class="col-md-4">
                            <br>
                            <div class="form-group">
                                <button class="btn btn-info btn-sale" style="width: 100%;" [disabled]="isDisabled"
                                    (click)="getAll()">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN LISTAR FACTURAS -->

    <!-- MODULO BUSQUEDAS FECHAS -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <!-- BUSQUEDAS FECHAS -->
                    <div class="col-sm-12"><b>Rangos a Buscar</b></div>
                    <div class="row">                        
                        <div class="col-md-5">
                            <label>Del:</label>
                            <div class="form-group">
                                <div class="form-group">
                                    <input type="date" class="form-control" [(ngModel)]="begDate" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <label>Para:</label>
                            <div class="form-group">
                                <div class="form-group">
                                    <input type="date" class="form-control" [(ngModel)]="endDate" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button type="submit" class="btn btn-info btn-sale" (click)="addDateSearch()" >Agregar</button>
                        </div>                       
                    </div>
                    <br>
                    <!-- Detalle de Venta -->
                    <div class="col-md-10">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr style="font-weight: bold;">
                                        <td class="text-center">No.</td>
                                        <td class="text-left">Del (yyyy-mm-dd)</td>
                                        <td class="text-center">Para (yyyy-mm-dd)</td>
                                        <td class="text-center">Opción</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of dateSearch, let i=index">
                                        <td class="text-center">{{i+1}}</td>                                        
                                        <td>{{e.begDate}}</td>
                                        <td class="text-center">{{e.endDate}}</td>
                                        <td class="text-center">
                                            <button class="btn btn-danger btn-sm" (click)="deleteDateSearch(i)"
                                                ngbTooltip="Eliminar" placement="left">
                                                <i class="now-ui-icons ui-1_simple-remove"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                            <div class="form-group">
                                <button class="btn btn-info btn-sale" style="width: 100%;" 
                                    (click)="getAllDet()">Buscar Datos</button>
                            </div>
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                            <div class="form-group">
                                <button class="btn btn-warning btn-sale" style="width: 100%;" 
                                    (click)="clearSearch()">Limpiar Resultados</button>
                            </div>
                        </div>                        
                    </div>
                    <!-- Fin BUSQUEDAS FECHAS -->                    
                </div>
            </div>
        </div>
    </div>
    <!-- FIN MODULO BUSQUEDAS FECHAS -->

    <!-- TABLA -->
    <div class="row" *ngIf="isReprint">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Facturas</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [summaryRow]="true" [summaryPosition]="bottom"
                            [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [colWidth]="'auto'"
                            [limit]="entries != -1 ? entries:undefined" [summaryHeight]="55" [rows]="temp"
                            [scrollbarH]="true" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Correlativo"></ngx-datatable-column>
                            <ngx-datatable-column name="Letra"></ngx-datatable-column>
                            <ngx-datatable-column name="Sucursal"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Factura"></ngx-datatable-column>
                            <ngx-datatable-column name="Nit"></ngx-datatable-column>
                            <ngx-datatable-column name="Cliente"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="tipoR == 1" name="Cantidad"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="tipoR == 1" name="Descripcion"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="tipoR == 1" name="Total"  [summaryFunc]="summaryForTotal"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="tipoR == 1" name="IVA" prop="invoiceTax"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="tipoR == 1" name="Valor Neto" prop="invoiceSubtotal"></ngx-datatable-column>
                            <ngx-datatable-column name="Pagado" prop="pagado"></ngx-datatable-column>
                            <ngx-datatable-column name="Forma Pago" prop="formapago"></ngx-datatable-column>
                            <ngx-datatable-column name="Autorizacion" prop="autorizacion"></ngx-datatable-column>
                            <ngx-datatable-column *ngIf="tipoR == 1"name="Costo"></ngx-datatable-column>
                            <ngx-datatable-column name="Usuario"></ngx-datatable-column>
                            <ngx-datatable-column name="vendedor"></ngx-datatable-column>
                            <ngx-datatable-column name="sucursal"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado"></ngx-datatable-column>
                        </ngx-datatable>                        
                    </div>
                </div>
                <div class="card-footer">
                    TOTAL: Q {{totalTotal | number:'1.2-2'}}
            </div>
        </div>
    </div>
    <!-- FIN TABLA -->
</div>