<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/invoice-recurrent">Factura recurrente</a>
        </div>
    </div>
</div>
<br/>
<div class="main-content">
    
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-center">                        
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg" routerLink="/invoice-recurrent-maintenance">
                                Lista
                            </button>
                        </div>
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg" routerLink="/invoice-recurrent-insert">
                                Nueva
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>