<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/company-type">Inventario Log</a>
        </div>
    </div>
</div>

<div class="main-content">

    <!-- INPUT COMPANY -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <form #formulario="ngForm">
                <div class="card">
                    <div class="card-header ">
                        <div class="row">
                            <div class="col-10">
                                <b class="card-title">Buscar Producto</b>
                            </div>                            
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <label>
                                    Producto
                                </label>
                                <div class="form-group">
                                    <ng-multiselect-dropdown [placeholder]="'Seleccione...'" id="ddlprod" name="ddlprod" 
                                        [settings]="dropdownSettings" [data]="inventoryList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label></label>
                                <div class="form-group">
                                    <button class="btn btn-info btn-round btn-erp" (click)="searchLog()">Buscar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- END INPUT COMPANY -->

    <!-- TABLE -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Modificaciones realizadas al producto</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"</h6>
                </div>
                <div class="col-md-4">
                    <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                      [disabled]="isDisabled">
                      Descargar Excel
                    </button>
                  </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" [scrollbarH]="true" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Codigo" prop="inventoryBusinessCode"></ngx-datatable-column>
                            <ngx-datatable-column name="Descripción" prop="inventoryDescription"></ngx-datatable-column>
                            <ngx-datatable-column name="Nombre" prop="inventoryName"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha Cambio" prop="inventoryLogDate"></ngx-datatable-column>
                            <ngx-datatable-column name="Usuario" prop="userName"></ngx-datatable-column>
                            <ngx-datatable-column name="Precio" prop="inventoryPrice"></ngx-datatable-column>
                            <ngx-datatable-column name="Costo" prop="inventoryPriceCost"></ngx-datatable-column>
                            <ngx-datatable-column name="Precio Desc" prop="inventoryPriceDiscount"></ngx-datatable-column>
                            <ngx-datatable-column name="Precio Min" prop="inventoryMinPrice"></ngx-datatable-column>
                            <ngx-datatable-column name="Precio Venta" prop="inventorySalePrice"></ngx-datatable-column>
                            <ngx-datatable-column name="Stock" prop="inventoryQuantity"></ngx-datatable-column>                         
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>