<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/user">Usuarios</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- FORMULARIO -->
    <div class="row">
        <div class="col-md-12">
            <div class="card ">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-11">
                            <h4 class="card-title" *ngIf="!isEdit">
                                Nuevo Usuario</h4>
                            <h4 class="card-title text-warning" *ngIf="isEdit">Modificar Usuario</h4>
                        </div>
                        <div class="col-1" *ngIf="isEdit">
                            <a class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="cancelUpdate($event)"><i
                                    class="fas fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="card-body ">
                    <div>
                        <div class="row">
                            <div class="col-sm-12">
                                <label>*Empresa</label>
                                <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItemsCompany"
                                    [settings]="dropdownSettingsCompany" (onSelect)="onItemSelectCompany($event)">
                                </angular2-multiselect>
                            </div> 
                            <div class="col-sm-6 col-md-4">
                                <label>*Nombre</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="user.userName"/>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label>Documento DPI</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="user.userTaxDocument"/>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label>Teléfono</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="user.userPhone"/>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label>*Email</label>
                                <div class="form-group">
                                    <input type="email" class="form-control" [(ngModel)]="user.userEmail"
                                        autocomplete="off"/>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label>*Rol</label>
                                <angular2-multiselect [data]="roleList" [(ngModel)]="selectedItemsRole"
                                    [settings]="dropdownSettingsRole" (onSelect)="onItemSelectRole($event)"
                                    (onDeSelect)="onItemDeSelectRole()">
                                </angular2-multiselect>
                            </div>                            
                            <div class="col-sm-6 col-md-4">
                                <label>*Sucursal</label>
                                <angular2-multiselect [data]="storeList" [(ngModel)]="selectedItemsStore"
                                    [settings]="dropdownSettingsStore" (onSelect)="onItemSelectStore($event)"
                                    (onDeSelect)="onItemDeSelectStore($event)" (onDeSelectAll)="onDeSelectAllStore()">
                                </angular2-multiselect>
                            </div>        
                            <div class="col-sm-6 col-md-4">
                                <div class="statistics">
                                    <button class="btn btn-primary btn-block btn-lg mt-10 btn-info" (click)="save()"
                                        *ngIf="!isEdit" [disabled]="isDisabled">Guardar</button>
                                    <button class="btn btn-primary btn-block btn-lg mt-10" (click)="update()"
                                        *ngIf="isEdit" [disabled]="isDisabled">Actualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN FORMULARIO -->

    <!-- TABLA -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Usuarios</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                            <ngx-datatable-column name="Correo"></ngx-datatable-column>
                            <ngx-datatable-column name="Telefono"></ngx-datatable-column>
                            <ngx-datatable-column name="Rol"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="editFunction($event)" ngbTooltip="Editar Usuario"><i
                                            class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="deleteFunction($event)" ngbTooltip="Eliminar Usuario"><i
                                            class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN TABLA -->
</div>
