import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Purchase } from 'src/app/models/purchase';
import { PurchaseDetail } from 'src/app/models/purchaseDetail';
import { ProviderService } from 'src/app/services/provider.service';
import { PurchaseService } from 'src/app/services/purchase.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { AccessService } from '../../../services/access.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-purchase-insert',
  templateUrl: './purchase-insert.component.html',
  styleUrls: ['./purchase-insert.component.scss'],
  providers: [PurchaseService, ProviderService, InventoryService, AccessService, UtilsService, CompanyService, PrintService]
})
export class PurchaseInsertComponent implements OnInit {
  // MODELS VARIABLE
  public purchase = {} as Purchase;
  public purchaseDetail = {} as PurchaseDetail;
  company = {} as Company;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isDisabled: boolean;
  // public isImport: boolean;
  loading;

  // VARIABLE FOR CALCULATIONS
  public addAmount: boolean;
  public total: number;
  public totalSD: number;
  public totalTax: number;
  public discountTemp: number;
  public moneda: string;
  public itemCode: string;
  public numberCode: number;
  public purchaseLine: any[] = new Array();

  // VARIABLE FOR SELECT 
  public providerList: any[] = new Array();
  public selectedItemsProvider = [];
  public dropdownSettingsProvider = {};

  constructor(
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _purchaseService: PurchaseService,
    private _providerService: ProviderService,
    private _companyService: CompanyService,
    private _printService: PrintService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    // this.isImport = false;
    this.addAmount = true;
    this.isDisabled = false;
    this.moneda = "Q";
    this.itemCode = '';
    this.numberCode = 0;
    this.total = 0;
    this.totalTax = 0;
    this.totalSD = 0;
    this.discountTemp = 0;
    this.purchase.purchaseDiscount = 0;

    // LOAD INFORMATION
    this.getProviderList(this.companyCode);
    this.getSelectSettingProvider(this.selectedItemsProvider);
    await this.getCompany(this.companyCode);
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSettingProvider(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsProvider = [selected] : '';

    this.dropdownSettingsProvider = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      lazyLoading: false, //true,
      //disabled: this.isImport
    };
  };

  getProviderList(companyCode) {
    this.loading = true;

    return this._providerService.getAllByCompany(companyCode).then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.providerList.push({
              id: e.providerCode,
              itemName: e.providerName,
              email: e.providerContactEmail != null ? e.providerContactEmail : ''
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onItemSelectProvider(item: any) {
    this.purchase.purchaseProvider = item.id;
    this.purchase.providerContactEmail = item.email;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ///////////////////////FUNCTION FOR PURCHASE///////////////////////  
  // SWITCH
  onChange($event) {
    if ($event.currentValue == true) {
      //this.isImport = true;
      this.purchase.purchaseImport = 1;
      this.ApplyDiscount(event, this.purchase.purchaseDiscount);
    }
    else {
      // this.isImport = false;
      this.purchase.purchaseImport = 0;
    }
  }

  // ADD DISCOUNTS
  ApplyDiscount($event, discount) {
    $event.preventDefault();
    //console.log(discount);
    if (discount <= this.total) {
      this.total = this.total + this.discountTemp;
      this.totalSD = this.total;
      this.total = this.total - discount;
      this.totalTax = parseFloat(((this.total / 1.12) * 0.12).toFixed(2));
      this.discountTemp = this.purchase.purchaseDiscount;
    } else {
      this.purchase.purchaseDiscount = 0;
      this._utilsService.showNotification(2, 'Cantidad No permitida')
    }
  }


  // OPERATING
  calculateTax() {
    this.purchaseDetail.purchaseDetailPrice = this.purchaseDetail.purchaseDetailUnitPrice * this.purchaseDetail.purchaseDetailRequestQuantity;
    let tax = ((this.purchaseDetail.purchaseDetailPrice / 1.12) * 0.12).toFixed(2);
    this.purchaseDetail.purchaseDetailTax = parseFloat(tax);
  }

  saveLine() {
    if (this.purchaseDetail.purchaseDetailDescription != null && this.purchaseDetail.purchaseDetailDescription != "" && this.purchaseDetail.purchaseDetailRequestQuantity > 0 && this.purchaseDetail.purchaseDetailUnitPrice > 0) {

      // INCREASE CODE
      if (this.itemCode == "") {
        this.numberCode++;
        this.itemCode = "I" + this.numberCode;
      }
      this.purchaseLine.forEach((e) => {
        if (e.detailCode == this.itemCode) {
          this.total = this.total - parseFloat(e.detailPrice);
          this.totalTax = this.totalTax - parseFloat(e.detailTax);
          this.totalSD = this.total;
          this.purchaseDetail.purchaseDetailPrice = this.purchaseDetail.purchaseDetailUnitPrice * this.purchaseDetail.purchaseDetailRequestQuantity;


          e.detailDescription = this.purchaseDetail.purchaseDetailDescription,
            e.detailRequestQuantity = parseFloat(this.purchaseDetail.purchaseDetailRequestQuantity.toFixed(2)),
            e.detailUnitPrice = parseFloat(this.purchaseDetail.purchaseDetailUnitPrice.toFixed(2)),
            e.detailPrice = parseFloat(this.purchaseDetail.purchaseDetailPrice.toFixed(2)),
            e.detailTax = parseFloat(this.purchaseDetail.purchaseDetailTax.toFixed(2));

          this.total = parseFloat((this.total + this.purchaseDetail.purchaseDetailPrice).toFixed(2));
          this.totalTax = parseFloat((this.totalTax + this.purchaseDetail.purchaseDetailTax).toFixed(2));
          this.totalSD = parseFloat((this.total).toFixed(2));

          this.addAmount = false;
          this.cleanLine();
        }
      });

      if (this.addAmount == true) {
        this.purchaseLine.push({
          detailCode: this.itemCode,
          detailDescription: this.purchaseDetail.purchaseDetailDescription,
          detailRequestQuantity: parseFloat(this.purchaseDetail.purchaseDetailRequestQuantity.toFixed(2)),
          detailUnitPrice: parseFloat(this.purchaseDetail.purchaseDetailUnitPrice.toFixed(2)),
          detailPrice: parseFloat(this.purchaseDetail.purchaseDetailPrice.toFixed(2)),
          detailTax: parseFloat(this.purchaseDetail.purchaseDetailTax.toFixed(2))
        });
        this.total = parseFloat((this.total + this.purchaseDetail.purchaseDetailPrice).toFixed(2));
        this.totalTax = parseFloat((this.totalTax + this.purchaseDetail.purchaseDetailTax).toFixed(2));
        this.totalSD = parseFloat((this.total).toFixed(2));
        this.cleanLine();
      }
    } else {
      this._utilsService.showNotification(2, "Ingrese todos los datos");
    }
    this.isDisabled = false;
  }

  editLine(code) {
    this.isDisabled = true;

    this.purchaseLine.forEach((e) => {
      if (e.detailCode == code) {
        this.itemCode = e.detailCode;
        this.purchaseDetail.purchaseDetailDescription = e.detailDescription;
        this.purchaseDetail.purchaseDetailRequestQuantity = parseFloat(e.detailRequestQuantity);
        this.purchaseDetail.purchaseDetailUnitPrice = parseFloat(e.detailUnitPrice);
        this.purchaseDetail.purchaseDetailDiscount = parseFloat(e.detailDiscount);
        this.purchaseDetail.purchaseDetailTax = parseFloat(e.detailTax);
      }
    });
    this.isDisabled = false;
  }

  deleteLine(code) {
    this.isDisabled = true;

    this.purchaseLine.forEach((e, index) => {
      if (e.detailCode == code) {

        this.total = this.total - parseFloat(e.detailPrice);
        this.totalTax = this.totalTax - parseFloat(e.detailTax);
        this.total = parseFloat(this.total.toFixed(2));
        this.totalTax = parseFloat(this.totalTax.toFixed(2));

        this.purchaseLine.splice(index, 1);
      }
    })
    this.isDisabled = false;
  }

  cleanLine() {
    this.addAmount = true;
    this.itemCode = '';
    this.purchaseDetail.purchaseDetailDescription = null;
    this.purchaseDetail.purchaseDetailRequestQuantity = null;
    this.purchaseDetail.purchaseDetailUnitPrice = null;
    this.purchaseDetail.purchaseDetailDiscount = null;
    this.purchaseDetail.purchaseDetailPrice = null;
    this.purchaseDetail.purchaseDetailTax = null;
  }

  savePurchase() {
    this.purchase.purchaseImport ? this.purchase.purchaseImport = 1 : this.purchase.purchaseImport = 0;
    let count = this.purchaseLine.length;

    if (this.purchase.purchaseProvider >= 0 || this.purchase.purchaseImport == 1) {
      if (this.purchase.purchaseDiscount >= 0 && count >= 1) {
        this.isDisabled = true;
        this.loading = true;
        let detailPurchase = new Array();

        this.purchaseLine.forEach(e => {
          let purchaseDetailDetailLot = 0;
          let purchaseDetailDueDate = '';
          let purchaseDetailPrice = e.detailPrice;
          let purchaseDetailTax = e.detailTax;
          let purchaseDetailDiscount = 0;
          let purchaseDetailRequestQuantity = e.detailRequestQuantity;
          let purchaseDetailUnitPrice = e.detailUnitPrice;
          let purchaseDetailDescription = e.detailDescription;

          detailPurchase.push({
            purchaseDetailDetailLot: purchaseDetailDetailLot,
            purchaseDetailDueDate: purchaseDetailDueDate,
            purchaseDetailStatus: 1,
            purchaseDetailPrice: purchaseDetailPrice,
            purchaseDetailTax: purchaseDetailTax,
            purchaseDetailDiscount: purchaseDetailDiscount,
            purchaseDetailRequestQuantity: purchaseDetailRequestQuantity,
            purchaseDetailUnitPrice: purchaseDetailUnitPrice,
            purchaseDetailDescription: purchaseDetailDescription,
            purchaseDetailProductCode: 1
          });
        });

        const json = {
          "header": {
            "purchaseStore": this.storeCode,
            "purchaseCompany": this.companyCode,
            "purchaseProvider": this.purchase.purchaseProvider,
            "purchaseProviderEmail": this.purchase.providerContactEmail,
            "purchaseStatus": 1,
            "purchasePassword": "",
            "purchaseTotal": this.total,
            "purchaseDiscount": this.purchase.purchaseDiscount,
            "purchaseTax": this.totalTax,
            "purchaseImport": this.purchase.purchaseImport,
            "purchaseCreatedUser": this.userCode
          },
          "detail": detailPurchase
        }

        console.log(json);

        this._purchaseService.insert(json).then(
          async (response) => {
            if (response.result == true) {              
              this.cleanModel();
              this._utilsService.showNotification(1, response.message);
              this._utilsService.showNotification(1, 'Orden de Compra No. ' + response.records[0][0].insertId);
              //this._printService.purchaseNail(this.purchase, this.purchaseDetail, this.company, json);

              this.isDisabled = false;
            } else {
              this.isDisabled = false;
              // Mostrar notificación
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.isDisabled = false;

            // Mostrar notificacion
            this._utilsService.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
        });
      } else {
        this._utilsService.showNotification(2, 'Falto llenar un campo');
      }
    }
    else {
      this._utilsService.showNotification(2, 'Falto llenar un campo');
    }
  }

  // CLEAR MODAL
  cleanModel() {
    this.isDisabled = false;
    // this.isImport = false;
    this.addAmount = true;
    this.selectedItemsProvider = null;
    this.total = 0;
    this.totalTax = 0;
    this.isDisabled = false;
    this.itemCode = '';
    this.numberCode = 0;
    this.totalSD = 0;
    this.discountTemp = 0;

    this.purchase = {} as Purchase;
    this.purchaseLine = [];
    this.purchase.purchaseDiscount = 0;
  }
}
