import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { PayRegister } from 'src/app/models/pay-register';
import { AccountingService } from '../../../services/accounting.service';
import { InvoiceHeader } from 'src/app/models/invoiceHeader';
import { InvoiceService } from '../../../services/invoice.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PrintService } from 'src/app/services/print.service';
import { Company } from "src/app/models/company";
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment-invoice.component.html',
  styleUrls: ['./payment-invoice.component.scss'],
  providers: [UtilsService, AccountingService, InvoiceService, PrintService, CompanyService]
})
export class PaymentInvoiceComponent implements OnInit {
  // MODELS VARIABLE 
  company = {} as Company;
  public payRegister = {} as PayRegister;
  public invoice = {} as InvoiceHeader;
  public payList: any[];
  public isViewBeginning: boolean;
  public isViewInvoiceDueQ: boolean;
  public isViewPayRegister: boolean;
  public isViewNewPayRegister: boolean;
  public disabledDuesQ = false;
  public isDisabled: boolean;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public cash: boolean;
  public totalPayment = 0;
  public saldox = 0;
  public invoiceCodeThis = ""; 
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      factura: {
        title: 'Factura'
      },
      clientTaxDocument: {
        title: 'Nit'
      },
      clientName: {
        title: 'Cliente'
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      pagado: {
        title: 'Pagado',
        type: 'number'
      },
      saldo: {
        title: 'Saldo',
        type: 'number'
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;

  // VARIABLE SELECTED
  public paymentMethodList: any[];
  public companyLogoUrl: string;

  constructor(
    private _utilsService: UtilsService,
    private _invoiceService: InvoiceService,
    private _accoutingService: AccountingService,
    private _printService: PrintService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));

    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.cash = false
    this.isViewBeginning = true;
    this.payRegister.payRegisterMethodCode = 2;

    await this.getCompany(this.companyCode);
    this.getAll();
    this.getPaymentMethod();
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];          
          this.company.companyLogo != 'undefined' ? this.companyLogoUrl = this.company.companyLogoUrl : '';
          console.log('Company -> ', this.company);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.payList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getPaymentMethod() {
    return this._accoutingService.getPaymentMethod().then(
      response => {
        if (response.result) {
          this.paymentMethodList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      });
  }

  ///////////////////////VALIDAR CANTIDAD///////////////////////  
  validateQuantity($event, total, pagado, saldo, monto) {
    $event.preventDefault();

    if (monto + pagado > parseFloat(total)) {
      this.payRegister.payRegisterAmount = 0;
      this._utilsService.showNotification(2, "Cantidad no permitida")
    }
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL PAYMENT
  getAll() {
    this.loading = true;

    const json =
    {
      "storeCode": this.storeCode
    };

    return this._accoutingService.getAllPaymentInvoice(json).then(
      async response => {
        if (response.result) {

          this.payList = await response.records.map(function(x) {    
            x.fecha === null && (x.fecha = '01/01/2000');
            x.fecha === '00/00/0000' && (x.fecha = '01/01/2000');    
            parseFloat(x.total);                     
            return x;
          });

          await  this._utilsService.orderDate(this.payList, 'fecha', '/');
          this.temp = this.payList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET REGISTER OF ONE INVOICE
  getPayRegister($event) {
    $event.preventDefault();
    this.loading = true;

    this.payRegister.payRegisterInvoiceCode = this.activeRow.invoiceCode;
    this.payRegister.payRegisterPurchaseCode = 0;

    let total = 0;
    let invoiceCodeThisx = "";
    return this._accoutingService.getAllPayRegister(this.payRegister).then(
      response => {
        if (response.result) {
          console.log('pagos invoice',response.records[1]);
          this.payRegister = response.records[1];

          response.records[1].forEach(e => {
            this.totalPayment += e.payRegisterAmount;
            total = e.total
            invoiceCodeThisx = e.payRegisterInvoiceCode;
          });
                    
          this.saldox = total - this.totalPayment;
          this.invoiceCodeThis = invoiceCodeThisx;

          this.viewPayRegister();
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  printHistory($event,invoiceCode){
    $event.preventDefault();
    console.log('invoiceCode',invoiceCode);
    console.log('payRegister print',this.payRegister);
    console.log('Total Pagado',this.totalPayment);
    console.log('saldox',this.saldox);

    this._printService.paymentInvoiceHistory(this.payRegister,this.company,this.totalPayment,this.saldox)    
  }

  // UPDATE INVOICE DUES QUANTITY
  updateInvoice() {
    this.loading = true;
    this.isDisabled = true;

    if (this.typeInvoce == 'normal') {
      const json = {
        "invoiceCode": this.invoice.invoiceCode,
        "invoiceDuesQuantity": this.invoice.invoiceDuesQuantity,
        "description": this.invoice.invoiceComment,
        "invoiceTotal": this.invoice.invoiceTotal,
        "storeCode": this.storeCode
      };

      this._invoiceService.updateInvoiceDuesQuantity(json).then(
        async (response) => {
          if (response.result == true) {
            this.getAll();
            this.viewBeginning(event);
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else if (this.typeInvoce == 'cambiaria') {
      console.log('cambiaria', this.typeInvoce)
      // this._utilsService.showNotification(2, 'El tipo factura cambiaria presenta problema en el esquema');
      const json = {
        "invoiceExchangeCode": this.invoice.invoiceCode,
        "invoiceExchangeDuesQuantity": this.invoice.invoiceDuesQuantity,
        "description": this.invoice.invoiceComment,
        "invoiceExchangeTotal": this.invoice.invoiceTotal,
        "storeCode": this.storeCode
      };

      this._invoiceService.updateInvoiceExchangeQuantity(json).then(
        async (response) => {
          if (response.result == true) {
            this.getAll();
            this.viewBeginning(event);
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
         this.loading = false;
         this.isDisabled = false;
      });
    }
  }

  onChangeSwitch($event) {
    this.payRegister.payRegisterRetentionAmount = null;
    this.payRegister.payRegisterRetentionDocument = null;
  }

  typeInvoce;
  loadData($event, option) {
    $event.preventDefault();
    this.cash = false;

    this.typeInvoce = this.activeRow.tipo;
    this.payRegister.payRegisterRetention = false;
    this.payRegister.payRegisterRetentionAmount = null;
    this.payRegister.payRegisterRetentionDocument = null;
    this.payRegister.payRegisterDateShow = this.activeRow.fecha;
    this.payRegister.payRegisterPaidShow = this.activeRow.pagado;
    this.payRegister.payRegisterTotalShow = this.activeRow.total;
    this.payRegister.payRegisterBalanceShow = this.activeRow.saldo;
    this.payRegister.payRegisterInvoiceShow = this.activeRow.factura;

    if (option == 1) {
      this.invoice.invoiceCode = this.activeRow.invoiceCode;
      this.invoice.invoiceTotal = this.activeRow.total;
      this.payRegister.payRegisterAmount = this.activeRow.total;
      this.invoice.invoiceComment = '';
      this.viewInvoiceDueQuantity();
    } else {
      this.activeRow.cuotas == 0 ? this.cash = true : '';
      this.activeRow.cuotas == 0 ? this.payRegister.payRegisterDescription = 'Al Contado' : this.payRegister.payRegisterDescription = '';

      this.payRegister.payRegisterInvoiceCode = this.activeRow.invoiceCode;
      this.payRegister.payRegisterAmount = 0;
      this.invoice.invoiceDuesQuantity = 0;
      this.invoice.invoiceComment = 'Al Contado';
      this.invoice.invoiceTotal = this.activeRow.total;
      this.invoice.invoiceCode = this.activeRow.invoiceCode;
      this.payRegister.payRegisterPurchaseCode = 0;
      this.viewNewPayRegister();
    }
  }

  // SAVE NEW REGISTER
  saveRegister() {
    this.payRegister.payRegisterPayCode = 1;
    this.payRegister.storeCode = this.storeCode;
    this.payRegister.payRegisterRetention ? this.payRegister.payRegisterRetention = 1 : this.payRegister.payRegisterRetention = 0;
    this.payRegister.payRegisterPurchaseCode = 0;
    console.log(this.payRegister)

    if (this.payRegister.payRegisterDocument != undefined && this.payRegister.payRegisterDocument != '' && this.payRegister.payRegisterAmount > 0) {
      if (!this.cash || this.payRegister.payRegisterTotalShow == (this.payRegister.payRegisterAmount + this.payRegister.payRegisterRetentionAmount)) {

        this.loading = true;
        this.isDisabled = true;
        this._accoutingService.insertPayRegister(this.payRegister).then(
          async (response) => {
            if (response.result == true) {
              //GENERATE RECEIPT IF IT IS A CREDIT            
              if (this.cash == false) {
                const recibo = response.records[0][0][2][0].recibo;
                const factura = response.records[0][0][2][0].factura;
                const fecha = response.records[0][0][2][0].payRegisterDate;
                const monto = response.records[0][0][2][0].payRegisterAmount;
                const metodo = response.records[0][0][2][0].paymentMethodName;

                const pdfWindow = window.open("", 'Comprobante de Pago', '_blank');
                let css = "<style>table {  border: 1px solid black; border-collapse: collapse; margin: 0px auto; padding: 15px; border-radius: 1em;}";
                css += "td {padding: 15px;}</style>";
                let html = "<table border='1'><tr><th colspan='4'>COMPROBANTE DE PAGO:</th></tr><tr><th colspan='1'>Código</th><td colspan='3'>" + recibo + "</td></tr>";
                html += "<tr><th>Factura</th> <th>Fecha</th> <th>Monto</th> <th>Metodo de Pago</th></tr>";
                html += "<tr><td>" + factura + "</td> <td>" + fecha + "</td> <td> Q" + monto + "</td> <td>" + metodo + "</td></tr></table>";
                pdfWindow.document.write(css + html);
                pdfWindow.window.print();
              } else {
                await this.updateInvoice();
              }
              this.getAll();
              this.viewBeginning(event);
              this._utilsService.showNotification(1, response.message);
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this._utilsService.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
        //*/
      } else { this._utilsService.showNotification(2, 'El pago a contado no corresponde'); }
    } else {
      this._utilsService.showNotification(2, 'No. Documento / Monto no ingresado');
    }
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //  VIEWS
  viewBeginning($event) {
    $event.preventDefault();
    this.isViewBeginning = true;
    this.isViewInvoiceDueQ = false;
    this.isViewPayRegister = false;
    this.isViewNewPayRegister = false;
    this.cleanModel();
  }

  viewInvoiceDueQuantity() {
    this.isViewBeginning = true;
    this.isViewInvoiceDueQ = true;
    this.isViewPayRegister = false;
    this.isViewNewPayRegister = false;

    this.invoice.invoiceDuesQuantity = this.activeRow.invoiceDuesQuantity;
    this.activeRow.invoiceDuesQuantity >= 1 ? this.disabledDuesQ = true : this.disabledDuesQ = false;
  }

  viewNewPayRegister() {
    this.isViewBeginning = true;
    this.isViewInvoiceDueQ = false;
    this.isViewPayRegister = false;
    this.isViewNewPayRegister = true;
  }

  viewPayRegister() {
    this.isViewBeginning = false;
    this.isViewPayRegister = true;
    this.isViewInvoiceDueQ = false;
    this.isViewNewPayRegister = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.payRegister = {} as PayRegister;
    this.invoice = {} as InvoiceHeader;

    this.payRegister.payRegisterMethodCode = 2;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item) => {
        if (this.settings.columns[item].type === "") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-cuentas-por-cobrar.xlsx');
  }
}