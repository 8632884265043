<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
  <div class="row link-header ml-5">
    <div>
      <a class="a-home" routerLink="/home">Home/</a>
    </div>
    <div>
      <a class="text-info" routerLink="/payment">Cuentas por Cobrar</a>
    </div>
  </div>
</div>

<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <!-- EDIT INVOICE -->
        <div class="col-md-12" *ngIf="isViewInvoiceDueQ">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-11 font-weight-bold">
                  Dividir en Pagos
                </div>
                <div class="col-1 text-right">
                  <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp" (click)="viewBeginning($event)"><i
                      class="fas fa-times"></i>
                  </a>
                </div>
                <div class="col-sm-3">
                  <label>Fecha</label>
                  <p>{{payRegister.payRegisterDateShow}}</p>
                </div>
                <div class="col-sm-3">
                  <label>Factura</label>
                  <p>{{ payRegister.payRegisterInvoiceShow }} </p>
                </div>
                <div class="col-sm-3">
                  <label>Total de Factura</label>
                  <p>Q {{ payRegister.payRegisterAmount | number:'1.2-2' }} </p>
                </div>
                <div class="col-sm-3">
                  <label>Dividir # Pagos</label>
                  <input type="number" class="form-control form-control-sm" [(ngModel)]="invoice.invoiceDuesQuantity"
                    [disabled]="disabledDuesQ" />
                </div>               
                <div class="col-md-9" *ngIf="!disabledDuesQ">
                  <label>Comentario</label>
                  <input type="text" class="form-control form-control-sm" [(ngModel)]="invoice.invoiceComment" />
                </div>
                <div class="col-md-3 text-center">
                  <button type="submit" class="text-center btn btn-info" (click)="updateInvoice()" [disabled]="isDisabled"> 
                    DIVIDIR EN PAGO ✔ 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- NEW REGISTER -->
        <div class="col-md-12" *ngIf="isViewNewPayRegister">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-11 font-weight-bold">
                  Registrar Pago
                  <span *ngIf="cash"> de Contado</span>
                </div>
                <div class="col-1 text-right">
                  <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp" (click)="viewBeginning($event)"><i
                      class="fas fa-times"></i>
                  </a>
                </div>
                <div class="col-md-3">
                  <label>Fecha</label>
                  <p>{{payRegister.payRegisterDateShow}}</p>
                </div>
                <div class="col-md-3">
                  <label>Factura</label>
                  <p>{{payRegister.payRegisterInvoiceShow}}</p>
                </div>
                <div class="col-md-3">
                  <label>Metodo de pago</label>
                  <select class="form-control form-control-sm" [(ngModel)]="payRegister.payRegisterMethodCode">
                    <option *ngFor="let i of paymentMethodList" [selected]="i.paymentMethodCode == 2"
                      [value]=i.paymentMethodCode>
                      {{i.paymentMethodName}}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <label>No. Documento</label>
                  <input type="text" class="form-control form-control-sm"
                    [(ngModel)]="payRegister.payRegisterDocument" />
                </div>
                <div class="col-md-3">
                  <label>Total</label>
                  <input type="text" class="form-control form-control-sm" [(ngModel)]="payRegister.payRegisterTotalShow"
                    disabled />
                </div>
                <div class="col-md-3">
                  <label>Saldo</label>
                  <input type="number" class="form-control form-control-sm"
                    [(ngModel)]="payRegister.payRegisterBalanceShow" disabled />
                </div>
                <div class="col-md-3">
                  <label>Monto</label>
                  <input type="number" class="form-control form-control-sm" [(ngModel)]="payRegister.payRegisterAmount"
                    (blur)="validateQuantity($event, payRegister.payRegisterTotalShow, payRegister.payRegisterPaidShow,
                    payRegister.payRegisterBalanceShow, payRegister.payRegisterAmount)" />
                </div>
                <div class="col-md-3">
                  <label>Retenciones</label>
                  <div class="form-group">
                    <bSwitch [switch-on-color]="'success'" [(ngModel)]="payRegister.payRegisterRetention"
                      [switch-disabled]="payRegister.payRegisterPaidShow != null"
                      switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                      switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>"
                      (changeState)="onChangeSwitch($event)">
                    </bSwitch>
                   &nbsp;<label *ngIf="payRegister.payRegisterPaidShow != null">(*Bloqueado)</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>Monto de retención</label>
                  <input type="number" class="form-control form-control-sm"
                    [(ngModel)]="payRegister.payRegisterRetentionAmount"
                    [disabled]="payRegister.payRegisterRetention == false" />
                </div>
                <div class="col-md-3">
                  <label>Documento de retención</label>
                  <input type="text" class="form-control form-control-sm"
                    [(ngModel)]="payRegister.payRegisterRetentionDocument"
                    [disabled]="payRegister.payRegisterRetention == false" />
                </div>
                <div class="col-md-6">
                  <label>Descripción</label>
                  <input type="text" class="form-control form-control-sm"
                    [(ngModel)]="payRegister.payRegisterDescription" />
                </div>
                <div class="col-md-12 text-center">
                  <br>
                  <button type="submit" class="text-center btn btn-info" (click)="saveRegister()" [disabled]="isDisabled"> 
                    REGISTRAR PAGO ✔ 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- VIEW REGISTER -->
        <div class="col-md-12" *ngIf="isViewPayRegister">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-11 font-weight-bold">
                  Registros de Pago
                </div>
                <div class="col-1 text-right">
                  <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp" (click)="viewBeginning($event)"><i
                      class="fas fa-times"></i>
                  </a>
                </div>
                <div class="col-4 text-right">
                  <a class="btn btn-warning btn-block btn-gen" role="button" (click)="printHistory($event,invoiceCodeThis)"><i class="now-ui-icons files_paper"></i> Imprimir</a>
                </div>
                <div class="col-sm-11">
                  <div class="row payRegisterList" *ngFor="let e of payRegister, let i=index">
                    <div class="col-md-3 itemBlock">
                      <b class="text-info">Pago {{i+1}} de {{payRegister.length}}</b>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>Factura</label>
                      <p>{{ e.factura }}</p>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>Fecha</label>
                      <p>{{ e.payRegisterDate | date:'dd-MM-yyyy'}}</p>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>Monto</label>
                      <p>Q {{ e.payRegisterAmount | number:'1.2-2' }} </p>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>Método de pago</label>
                      <p>{{ e.paymentMethodName }}</p>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>No. Documento</label>
                      <p>{{ e.payRegisterDocument }}</p>
                    </div>
                    <div class="col-md-9 itemBlock">
                      <label>Descripción</label>
                      <p>{{ e.payRegisterDescription }}</p>
                    </div>                    
                  </div>
                  <b class="text-info">Total Pagos Q.{{totalPayment}} </b><br>
                  <b class="text-info">Saldo Q.{{saldox}} </b><br>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- TABLE -->
        <div class="col-md-12" *ngIf="isViewBeginning">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-8">
                  <h4 class="card-title">Listado de cuentas por cobrar</h4>
                  <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                  </h6>
                </div>
                <div class="col-md-4">
                  <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                    [disabled]="isDisabled">
                    Descargar Excel
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="dataTables_wrapper">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="datatable_length">
                        <label>
                          Show
                          <select name="datatable_length" aria-controls="datatable" class="form-control form-control-sm"
                            (change)="entriesChange($event)">
                            <option value="10" [selected]="entries==10">10</option>
                            <option value="25" [selected]="entries==25">25</option>
                            <option value="50" [selected]="entries==50">50</option>
                            <option value="-1" [selected]="entries==-1">All</option>
                          </select>
                          entries
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="datatable_filter" class="dataTables_filter">
                        <label>
                          <input type="search" class="form-control form-control-sm" placeholder="Search records"
                            aria-controls="datatable" (keyup)="filterTable($event)" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                    [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [scrollbarH]="true"
                    (activate)="onActivate($event)" [rows]="temp">
                    <ngx-datatable-column name="Correlativo" [maxWidth]="90"></ngx-datatable-column>
                    <ngx-datatable-column name="Letra" [maxWidth]="90"></ngx-datatable-column>
                    <ngx-datatable-column name="Fecha" [maxWidth]="90"></ngx-datatable-column>
                    <ngx-datatable-column name="Factura Serie - No." prop="factura" [minWidth]="200"></ngx-datatable-column>
                    <ngx-datatable-column name="Nit" prop="clientTaxDocument"></ngx-datatable-column>
                    <ngx-datatable-column name="Cliente" prop="clientName" [minWidth]="200"></ngx-datatable-column>
                    <ngx-datatable-column name="Total"></ngx-datatable-column>
                    <ngx-datatable-column name="Pagado"></ngx-datatable-column>
                    <ngx-datatable-column name="Saldo"></ngx-datatable-column>
                    <ngx-datatable-column name="Cuotas"></ngx-datatable-column>
                    <ngx-datatable-column name="Pagadas"></ngx-datatable-column>
                    <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                      [resizeable]="false">
                      <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                        let-selectFn="selectFn">
                        Acciones
                      </ng-template>
                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                        let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <a href="#" class="btn btn-round btn-warning btn-icon btn-sm like" (click)="loadData($event, 1)"
                          ngbTooltip="Fraccionar en Pagos"  *ngIf="row.cuotas == 0">
                          <i class="fas fa-cubes"></i>
                        </a>
                        <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit" (click)="loadData($event, 2)"
                          ngbTooltip="Registrar Pago" [disabled]="cancelledInvoice == 1"><i
                            class="now-ui-icons files_paper"></i>
                        </a>
                        <a href="#" class="btn btn-round btn-info btn-icon btn-sm remove"
                          (click)="getPayRegister($event)" ngbTooltip="Ver Registros" placement="left"
                          [disabled]="cancelledInvoice == 1">
                          <i class="fas fa-eye"></i>
                        </a>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>