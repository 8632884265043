import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from "sweetalert2";
import { Role } from 'src/app/models/role';
import { RoleAccess } from 'src/app/models/roleAccess';
import { RoleService } from 'src/app/services/role.service';
import { AccessService } from 'src/app/services/access.service';
import { CompanyService } from 'src/app/services/company.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
  providers: [RoleService, AccessService, CompanyService, UtilsService]
})
export class PermissionComponent implements OnInit {
  // MODELS VARIABLE 
  public role = {} as Role;
  public roleAccess = {} as RoleAccess;
  public isViewBeginning: boolean;
  public isViewAccess: boolean;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isEditRole: boolean;
  public isDisabled: boolean;
  loading;

  // VARIABLE FOR INSERT/UPDATE ROLE - ACCESS
  public roleCodeTemp: number = null;
  public success: boolean = false;
  public message: string = '';

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public tempRole = [];
  public tempAccess = [];
  public activeRow: any;
  public roleList: any[] = new Array();
  public accessList = new Array();

  // VARIABLE FOR SELECT 
  public companyList: any[] = new Array();
  public selectedItemsCompany = [];
  public dropdownSettingsCompany = {};

  // ROLE - ACCESS SELECT
  public arrayAccess = new Array;
  public accessSelectList: any[] = new Array();
  public selectedItemsAccess = [];
  public dropdownSettingsAccess = {};

  constructor(
    private cdref: ChangeDetectorRef,
    private _roleService: RoleService,
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _companyService: CompanyService
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    // LOAD INFORMATION
    await this.getCompanyList();
    if (this.companyCode > 1) {
      this.getAllRoleByCompany();
      const _selectedItemCompany = this.companyList.find(x => x.id == this.companyCode);
      this.getSelectSettingCompany(_selectedItemCompany);
    } else {
      this.getAllRole();
      this.getSelectSettingCompany(this.selectedItemsCompany);
    }

    this.viewBeginning();
    await this.getAccessList();
    // this.companyCode != 33 ? await this.getAccessList() : (this.accessSelectList = this.listSylvania); 
  }

  public listSylvania = [
    { id: 8, accessRoute: 'company', itemName: 'Empresa' },
    { id: 13, accessRoute: 'store', itemName: 'Sucursal' },
    { id: 28, accessRoute: 'user', itemName: 'Usuario' },
    { id: 33, accessRoute: 'role', itemName: 'Roles' },
    { id: 145, accessRoute: 'xml-wm', itemName: 'Walmart' },
    { id: 146, accessRoute: 'xml-fcam', itemName: 'Cambiaria' },
    { id: 147, accessRoute: 'xml-ncred', itemName: 'Nota Credito' },
    { id: 148, accessRoute: 'reprint-exchange-bill', itemName: 'Reimprimir FCAM' }, 
    { id: 149, accessRoute: 'reprint-credit-note', itemName: 'Reimprimir NCRED' },
    { id: 150, accessRoute: 'cancellation-exchange-bill', itemName: 'Anular' },
    { id: 151, accessRoute: 'report-sale', itemName: 'Rep. ventas' },
    { id: 152, accessRoute: 'report-book', itemName: 'Asiste Libros' },
  ];

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.tempRole = this.roleList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSettingCompany(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsCompany = [selected] : '';

    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
      disabled: this.companyCode > 1 ? true : false
    };
  };

  getSelectSettingAccess(selected) {
    this.selectedItemsAccess = selected;

    this.dropdownSettingsAccess = {
      singleSelection: false,
      text: "Seleccione...",
      badgeShowLimit: 4,
      searchPlaceholderText: 'Buscar',
      selectAllText: 'Seleccionar todos',
      enableCheckAll: false,
      unSelectAllText: 'Deseleccionar todos',
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };

  getCompanyList() {
    return this._companyService.getAll().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.companyList.push({
              id: e.companyCode,
              itemName: e.companyBusinessName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getAccessList() {
    this.loading = true;

    return this._accessService.getAllAccess().then(
      response => {
        if (response.result == true) {          
          response.records.forEach(e => {
            this.accessSelectList.push({
              id: e.accessCode,
              itemName: e.accessName + ' - ' + e.tipo,
              accessRoute: e.accessRoute
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onItemSelectCompany(item: any) {
    this.role.roleCompanyCode = item.id;
  }

  onItemSelectAccess(item: any) {
    this.arrayAccess.push({
      RoleAccessAccessCode: item.id
    });
  }

  onItemDeSelectAccess(item: any) {
    this.arrayAccess.forEach((e, index) => {
      if (e.RoleAccessAccessCode == item.id) this.arrayAccess.splice(index, 1);
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL ROLE
  getAllRole() {
    this.loading = true;

    return this._roleService.getAll().then(
      response => {
        if (response.result) {
          this.roleList = response.records;
          this.tempRole = this.roleList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ALL ROLE BY COMPANY
  getAllRoleByCompany() {
    this.loading = true;

    return this._roleService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result) {
          this.roleList = response.records;
          this.tempRole = this.roleList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE ROLE - ACCESS
  async accessFunction($event) {
    $event.preventDefault();

    //Sylvania?
    // this.activeRow.roleCompanyCode === 33 && (this.accessSelectList = this.listSylvania); //DEMO
    this.activeRow.roleCompanyCode === 20 && (this.accessSelectList = this.listSylvania); //PRODUCCION

    this.role.roleName = this.activeRow.roleName;
    this.roleAccess.roleAccessRoleCode = this.activeRow.roleCode;
    this.roleCodeTemp = this.activeRow.roleCode;

    await this.getOneRoleAccess(this.activeRow.roleCode);
    this.viewAccess();
  }

  getOneRoleAccess(role) {
    this.loading = true;
    this.arrayAccess = new Array;

    return this._roleService.getOneRoleAccess(role).then(
      async response => {
        if (response.result) {
          let count = response.records.length;
          let _selectedItemAccess = new Array;
          if (count > 0) {
            response.records.forEach(e => {
              let items = this.accessSelectList.find(x => x.id == e.RoleAccessAccessCode);

              _selectedItemAccess.push({
                id: items.id,
                itemName: items.itemName
              });
              this.arrayAccess.push({
                RoleAccessAccessCode: items.id
              });
            });
            await this.getSelectSettingAccess(_selectedItemAccess);
          }
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.viewAccess();
      this.loading = false;
    });
  }

  // INSERT ROLE
  saveRole() {
    this.isDisabled = true;
    this.loading = true;

    this.companyCode != 1 ? this.role.roleCompanyCode = this.companyCode : '';
    this.role.roleStatus = 1;
    this.role.roleDeleted = 0;

    this._roleService.insert(this.role).then(
      async (response) => {
        if (response.result == true) {
          this.companyCode != 1 ? this.getAllRoleByCompany() : this.getAllRole();
          this.cancelUpdateRole(event);
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // INSERT ROLE - ACCESS
  saveRoleAccess() {
    this.isDisabled = true;
    this.success = false;
    this.loading = true;

    // UPDATE ROLE => DELETED ACCESS
    this.roleAccess.roleAccessRoleCode = this.roleCodeTemp;
    this._roleService.updateRoleAccess(this.roleAccess).then(
      async (response) => {

        // INSERT ACCESS ROLE
        this.roleAccess = {} as RoleAccess;
        this.arrayAccess.forEach(e => {
          this.roleAccess.roleAccessRoleCode = this.roleCodeTemp;
          this.roleAccess.roleAccessAccessCode = e.RoleAccessAccessCode;
          this._roleService.insertRoleAccess(this.roleAccess).then(
            async (response) => {
              if (response.result == true) {
                this.success = true;
                this.message = response.message;
              } else {
                this.success = false;
                this.message = response.errorMessage;
                console.log(response.errorMessage);
              }
            },
            error => {
              this.success = false;
              this.message = error;
              console.log(error);
            }
          );
          this.roleAccess = {} as RoleAccess;
        });
      },
      error => {
        console.log("Update", error);
      }
    );

    if (this.success = true) {
      this.isDisabled = false;
      this.loading = false;
      this._utilsService.showNotification(1, 'Operación Exitosa');
      this.cancelUpdateRoleAccess(event);
    } else if (this.success = false) {
      this.isDisabled = false;
      this.loading = false;
      this._utilsService.showNotification(3, this.message);
    }
  }

  // UPDATE ROLE
  updateRole() {
    this.isDisabled = true;
    this.loading = true;

    this._roleService.update(this.role).then(
      async (response) => {
        if (response.result == true) {
          this.companyCode != 1 ? this.getAllRoleByCompany() : this.getAllRole();
          this.cancelUpdateRole(event);
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // LOAD DATA TO EDIT ROLE
  editFunctionRole($event) {
    $event.preventDefault();
    this.isEditRole = true;

    this.role.roleCode = this.activeRow.roleCode;
    this.role.roleName = this.activeRow.roleName;
    this.role.roleStatus = this.activeRow.roleStatus;
    this.role.roleDeleted = this.activeRow.roleDeleted;
    this.role.roleCompanyCode = this.activeRow.roleCompanyCode;

    const _selectedItemCompany = this.companyList.find(x => x.id == this.activeRow.roleCompanyCode);
    this.getSelectSettingCompany(_selectedItemCompany);
  }

  // DELETED ROLE
  deleteFunctionRole($event) {
    $event.preventDefault();

    this.role.roleCompanyCode = this.activeRow.roleCompanyCode;
    this.role.roleCode = this.activeRow.roleCode;
    // this.role.roleName = this.activeRow.roleName;
    this.role.roleStatus = 0;
    this.role.roleDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "El rol se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.updateRole();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //  VIEWS
  viewBeginning() {
    this.isViewBeginning = true;
    this.isViewAccess = false;
  }

  viewAccess() {
    this.isViewBeginning = false;
    this.isViewAccess = true;
  }

  // //CANCEL EDIT ROLE
  cancelUpdateRole($event) {
    this.isDisabled = false;
    this.isEditRole = false;
    this.role = {} as Role;
    this.companyCode == 1 ? this.selectedItemsCompany = null : '';
  }

  // //CANCEL EDIT ROLE ACCESS
  cancelUpdateRoleAccess($event) {
    this.isDisabled = false;
    this.isEditRole = false;
    this.role = {} as Role;
    this.roleAccess = {} as RoleAccess;
    this.selectedItemsAccess = null;

    this.viewBeginning();
  }
}