import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { Client } from 'src/app/models/client';
import { InvoiceHeader } from 'src/app/models/invoiceHeader';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { ClientService } from 'src/app/services/client.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Store } from 'src/app/models/store';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { StoreService } from 'src/app/services/store.service';
import { UtilsService } from 'src/app/services/utils.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from '@angular/router';

interface InfoFees {
  amountFees;
  dueDate;
  due: any[];
}

@Component({
  selector: 'app-description-exchange-bill',
  templateUrl: './description-exchange-bill.component.html',
  styleUrls: ['./description-exchange-bill.component.scss'],
  providers: [ClientService, InvoiceService, StoreService, CompanyService, UtilsService],
})
export class DescriptionExchangeBillComponent implements OnInit {
  // Variables auxiliares
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  infoFees = {} as InfoFees;
  amountFees;
  dateFees;
  date;
  public nowDay: string;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  loading;

  public isBeginning: boolean;
  public isBilling: boolean;
  public isDisabled: boolean;
  public recurrentClient: boolean;
  public insertNewClient: boolean = false;
  public calculateTrue: boolean = false;

  // Variables de Linea de Compra
  public itemCode: string;
  public itemNumberCode: number;
  public moneda: string;
  public invoiceLine: any = new Array();
  public addAmount: boolean;

  public disabled = false;
  public disabledEXT = false;
  public facturando: boolean;
  public url: any;

  public tipoCliente = 0;
  public tipoPago = 0;

  // DATA SELECT PAYMENT
  public idPayment: number;
  public itemNamePayment: string;
  public selectedItemsPayment = [];
  public PaymentList: any[] = new Array();
  public dropdownSettingsPayment: IDropdownSettings = {};
  closeDropdownSelectionPayment = false;
  
  public PaymentLine: any = new Array;
  public PaymentType = 0;
  public NamePaymentType = 0;
  public MontoPayment: number = 0.00;
  public MontoTotalPayment: number = 0.00;
  public Autorizacion: string = "0";
  public disabledPay = false;
  public isDisabledInvoice = false;
  public isMethodPay = false;

  constructor(
    private toastr: ToastrService,
    private _utilsService: UtilsService,    
    private _clientService: ClientService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
    private _storeService: StoreService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.addAmount = true;
    this.isDisabled = false;
    this.insertNewClient = false;
    this.moneda = 'Q';
    this.itemCode = 'I';
    this.itemNumberCode = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceDetail.invoiceDetailSubtotal = 0;
    this.invoiceDetail.invoiceDetailTax = 0;
    this.invoiceDetail.invoiceDetailQuantity = 1;
    this.invoiceHeader.invoiceCurrency = 'GTQ';
    this.invoiceHeader.invoiceExchangeRate = null;

    this.invoiceHeader.invoiceDate = this._utilsService.dateTime('yyy-MM-dd');
    this.nowDay = this.invoiceHeader.invoiceDate;

    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.viewBeginning();
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
    await this.getPaymetList(this.storeCode);
    this.getSelectSettingPaymet(this.selectedItemsPayment);

    // console.log('Select -> ', this.selectedItems);
    console.log('Select Tipo pagos -> ', this.selectedItemsPayment);
    console.log('Tipo pagos -> ', this.PaymentList);

    await this.Pagado();

    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.disabledEXT = true;

    this.client.clientType = 0;
    this.disabledEXT = true;

    this.url = this.router.parseUrl(this.router.url);
    if (this.url.queryParams['nit'] != undefined) {

      if (localStorage.getItem('clientData') !== null) {
        console.log('si hay data de cliente');

        let _dataCli = JSON.parse(localStorage.getItem('clientData'));

        console.log('localstorage -> ', _dataCli.clientName);

        this.client.clientName = _dataCli.clientName;
        this.client.clientStatus = 1;
        this.client.clientDeleted = 0;
        this.client.clientPhone = _dataCli.clientPhone;
        this.client.clientEmail = _dataCli.clientEmail;
        this.client.clientAddress = _dataCli.clientAddress;
        this.client.clientCode =  _dataCli.clientCode;
        this.client.clientTaxDocument = _dataCli.clientTaxDocument;        
        this.tipoCliente = _dataCli.clientType;
        this.client.clientType = _dataCli.clientType;

        this.viewBilling();
        this.loading = false;
        this.isDisabled =false;

        console.log('Cliente -> ', this.client);
        console.log('Tipo Cliente -> ', this.tipoCliente);
  
        this.insertNewClient = false;
        this.recurrentClient = true;
      }

      // this.isParamsUrl = true;
      // this.code = this.url.queryParams['nit'];
      // await this.buscarNITLocal(event, this.code);

      // if (this.url.queryParams['transfer']) {
      //   let transfer = this.url.queryParams['transfer'];
      //   await this.cargarTrasnferencia(transfer);
      // }

    }
  }

  async onItemSelectPaymet(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItemPayment = await this.PaymentList.find(x => x.id == item.id);
    console.log(_selectedItemPayment);

    this.tipoPago = item.payMethodBusinessCode;
    console.log('Tipo Pago -> ', this.tipoPago);

    this.PaymentType = this.tipoPago;
    this.NamePaymentType = item.payMethodName;

    // this.itemCode = item.idPayment;
    // this.invoiceDetail.invoiceDetailDescription = item.itemNamePayment;
    // this.stock = _selectedItem.inventoryQuantity;
    // this.Minstock = _selectedItem.inventoryMin;
  }

  getPaymetList(code) {
    return this._storeService.getPaymets(code).then(
      response => {
        if (response.result) {
          this.PaymentList = response.records;
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getSelectSettingPaymet(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItemsPayment = [selected] : '';

    this.dropdownSettingsPayment = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'payMethodBusinessCode',
      textField: 'payMethodName'
    };
  }

  async addPaymetDetail(){

    this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment + (this.MontoPayment))).toFixed(6));
    console.log(this.MontoTotalPayment);

    await this.PaymentLine.push({
      typePayment: this.PaymentType,
      NamePaymentType: this.NamePaymentType,
      MontoPayment: this.MontoPayment,
      Autorizacion: this.Autorizacion
    });

    this.MontoPayment = 0;
    this.Autorizacion = "0";
    this.selectedItemsPayment = [];

    this.Pagado();

    console.log(this.PaymentLine);
  }

  async deletePaymetDetail(item) {

    await this.PaymentLine.forEach(async (e, index) => {
      if (e.typePayment === item) {

        this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment - (e.MontoPayment))).toFixed(6));        
        this.PaymentLine.splice(index, 1);
      }
    })

    this.Pagado();
        
    console.log(this.PaymentLine);
  }

  async Pagado(){

    if(this.company.companyPaymentType == 1){
      this.isMethodPay = true;
      if((this.MontoTotalPayment == this.invoiceHeader.invoiceTotal) && this.MontoTotalPayment > 0){
        this.isDisabledInvoice = false;
      }
      else if(this.MontoTotalPayment > this.invoiceHeader.invoiceTotal){
        this.isDisabledInvoice = true;
        this._utilsService.showNotification(2,"Total pagado es mayor al Total de la factura")
      }
      else{
        this.isDisabledInvoice = true;
      }
    }
    else{
      this.isMethodPay = false;
      this.isDisabledInvoice = false;
    }
  }

  public taxToPay: number = 0;
  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      (response) => {
        if (response.result) {
          console.log(response.records[0]);
          this.company = response.records[0];
          this.company.companyTaxToPay == 12 ? this.taxToPay = 1.12 : this.taxToPay = 1.05;
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      (response) => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  // onChange(event) {
  //   this.invoiceHeader.invoiceCoin = event;

  //   if (this.invoiceHeader.invoiceCoin == 0) {
  //     this.moneda = 'Q';
  //     this.invoiceHeader.invoiceExchangeRate = null;
  //   } else if (this.invoiceHeader.invoiceCoin == 1) {
  //     this.moneda = '$';
  //   }
  // }

  onChange(event) {
    this.invoiceHeader.invoiceCoin = event;

    if (this.invoiceHeader.invoiceCoin == 0) {
      this.moneda = "Q";
      this.invoiceHeader.invoiceCurrency = 'GTQ';
      this.invoiceHeader.invoiceExchangeRate = null;
    } else if (this.invoiceHeader.invoiceCoin == 1) {
      this.moneda = "$"
      this.invoiceHeader.invoiceCurrency = 'USD';
    }
  }

  ///////////////////////FUNCTION VALIDATE DATE///////////////////////
  validateDate($event) {
    $event.preventDefault();

    var nowDate = this.nowDay.split('-');
    var date = this.invoiceHeader.invoiceDate.split('-');

    if (
      date[0] == nowDate[0] &&
      date[1] == nowDate[1] &&
      parseFloat(date[2]) <= parseFloat(nowDate[2]) + 5 &&
      parseFloat(date[2]) >= parseFloat(nowDate[2]) - 5
    ) {
    } else {
      this.invoiceHeader.invoiceDate = this.nowDay;
      this.showNotification(2, 'Fecha fuera de rango');
    }
  }

  ///////////////////////FUNCTION CHANGE INTERAL SERIAL///////////////////////
  onChangeInternSerial(event) {
    this.invoiceHeader.invoiceInternSerial = event;
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isBilling = false;
    this.client.clientType = 0;
  }

  viewBilling() {
    this.isBeginning = false;
    this.isBilling = true;
  }

  viewCF() {
    this.isBeginning = true;
    this.isBilling = true;
    this.disabled = true;
  }

  ValidarCF() {
    /*J11 - Validar CF*/
    console.log(this.invoiceHeader.invoiceTotal);
    console.log(this.client.clientTaxDocument);

    if(this.invoiceHeader.invoiceTotal >= 2500){
      if(this.client.clientTaxDocument == "CF"){
        this._utilsService.showNotification(5, "No puede facturar como CF, el monto es mayor a 2500");
        this.viewCF();
      }
      else{
        this.disabled = false;
      }
    }
  }

  // CALCULAR IMPUESTO DE LINEA
  calculateTax() {
    let subTotalLine;

    subTotalLine =
      this.invoiceDetail.invoiceDetailQuantity *
      this.invoiceDetail.invoiceDetailUnitPrice;

    if (this.invoiceDetail.invoiceDetailDiscount > 0) {
      if (this.invoiceDetail.invoiceDetailDiscount <= subTotalLine) {
        subTotalLine = subTotalLine - this.invoiceDetail.invoiceDetailDiscount;
      } else {
        this.showNotification(2, 'Cantidad No permitida');
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }
    }

    let tax;

    tax = (subTotalLine - subTotalLine / this.taxToPay).toFixed(4);
    this.invoiceDetail.invoiceDetailTax = parseFloat(tax);
  }

  // GUARDAR UNA LINEA DE COMPRA
  saveLineInvoice() {
    if (this.invoiceDetail.invoiceDetailDescription != '' && this.invoiceDetail.invoiceDetailQuantity > 0 && this.invoiceDetail.invoiceDetailUnitPrice > 0) {
      //Aumentar Codigo si no viene uno
      if (this.itemCode == 'I') {
        this.itemNumberCode++;
        this.itemCode += this.itemNumberCode;
      }

      //Calcular subtotal de linea
      this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice;

      if (this.invoiceDetail.invoiceDetailDiscount != undefined && this.invoiceDetail.invoiceDetailDiscount != null && this.invoiceDetail.invoiceDetailDiscount > 0) {
        this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailDiscount;
        this.invoiceDetail.invoiceDetailTax = this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay;
      } else {
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }

      //Actualizar
      this.invoiceLine.forEach((value) => {
        if (value.itemCode == this.itemCode) {
          this.invoiceHeader.invoiceTotal -= value.total;

          value.itemCode = this.itemCode;
          value.measure = 'UNI';
          value.taxCode = 1;
          value.taxName = 'IVA';
          value.isService = this.invoiceDetail.invoiceDetailType;
          value.quantity = parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(4));
          value.description = this.invoiceDetail.invoiceDetailDescription;
          value.unitPrice = parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4));
          value.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(4));
          value.discount = parseFloat(this.invoiceDetail.invoiceDetailDiscount.toFixed(4));
          value.taxAmount = parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4));
          value.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4));
          value.total = parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4));

          // this.total = this.total + this.subTotal;
          this.addAmount = false;
        }
      });

      //Insertar
      if (this.addAmount == true) {
        this.invoiceLine.push({
          itemCode: this.itemCode,
          measure: 'UNI',
          taxCode: 1,
          taxName: 'IVA',
          isService: this.invoiceDetail.invoiceDetailType,
          quantity: parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(4)),
          description: this.invoiceDetail.invoiceDetailDescription,
          unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)),
          price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(4)),
          discount: parseFloat(this.invoiceDetail.invoiceDetailDiscount.toFixed(4)),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4)),
          taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4)),
          total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4)),
        });
      }
      // console.log(this.invoiceLine);
      // Sumar Total
      this.invoiceHeader.invoiceTotal += this.invoiceDetail.invoiceDetailSubtotal;
      this.invoiceHeader.invoiceTax += this.invoiceDetail.invoiceDetailTax;
      this.cleanLineInvoice();
    }

    this.ValidarCF();

    this.isDisabled = false;
  }

  // APLICAR DESCUENTO EN TIEMPO REAL
  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (parseFloat(descuento) <= temporal) {
          this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.total);
          e.total = temporal - parseFloat(descuento);
          e.taxableAmount= parseFloat((parseFloat(e.total) / this.taxToPay).toFixed(4));
          e.taxAmount = parseFloat(((parseFloat(e.total) / this.taxToPay) * (this.taxToPay - 1)).toFixed(4));
          this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal + parseFloat(e.total);
        } else {
          this.showNotification(2, 'Cantidad No permitida');
        }
      }
    });
  }

  // CARGAR DATOS A EDITAR
  editLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        this.itemCode = e.itemCode;
        this.invoiceDetail.invoiceDetailType = e.isService;
        this.invoiceDetail.invoiceDetailDescription = e.description;
        this.invoiceDetail.invoiceDetailQuantity = e.quantity;
        this.invoiceDetail.invoiceDetailUnitPrice = e.unitPrice;
        this.invoiceDetail.invoiceDetailSubtotal = e.price;
        this.invoiceDetail.invoiceDetailDiscount = e.discount;
        this.invoiceDetail.invoiceDetailTax = e.taxAmount;
      }
    });
    this.isDisabled = false;
  }

  // ELIMINAR UNA LINEA
  deleteLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e, index) => {
      if (e.itemCode == item) {
        this.invoiceHeader.invoiceTotal =
          this.invoiceHeader.invoiceTotal - parseFloat(e.price);
        this.invoiceLine.splice(index, 1);
      }
    });
    this.isDisabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.addAmount = true;

    this.itemCode = 'I';
    this.invoiceDetail.invoiceDetailDescription = null;
    this.invoiceDetail.invoiceDetailDiscount = null;
    this.invoiceDetail.invoiceDetailQuantity = null;
    this.invoiceDetail.invoiceDetailUnitPrice = null;
    this.invoiceDetail.invoiceDetailTax = null;
    this.invoiceDetail.invoiceDetailQuantity = 1;
  }

  onChangeTypeClient(event) {    
    this.client.clientType = event;

    if(event == 2){
      this.disabledEXT = false;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
    else{
      this.disabledEXT = true;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
  }

  ///////////////////////Funcion de Buscar por NIT///////////////////////
  buscarNITLocal($event, nit) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    if(this.client.clientType == 2){

      this.client.clientName = this.client.clientName;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientPhone = "00000000";
      this.client.clientEmail = "info@clever.com";
      this.client.clientAddress = "CIUDAD";

      this.insertNewClient = false;
      this.recurrentClient = false;
      this.viewBilling();
      this._utilsService.showNotification(4, "SE EMITIRA FACTURA CON PASAPORTE");
      this.loading = false;

    }
    else{
      // return this._clientService.getOneByNit(nit).then(
      return this._clientService.getOneByCompanyNit(this.companyCode, nit).then(
        response => {
          // console.log(response.records)
          if (response.records.length > 0) {
            this.client = response.records[0];
            this.recurrentClient = true;
            this.insertNewClient = false;
            this.viewBilling();
            this._utilsService.showNotification(1, response.message);
          } else {
            return this._clientService.searchNitSAT(nit, this.client.clientType).then(
              async response => {
                if (response.tipo_respuesta == 0) {
                  //this.client.clientName = response.nombre[0];
                  //console.log(response.nombre[0]);
                  this.client.clientName =  response.nombre[0];
                  this.client.clientStatus = 1;
                  this.client.clientDeleted = 0;
                  this.client.clientPhone = "00000000";
                  this.client.clientEmail = "info@clever.com";
                  this.client.clientAddress = "CIUDAD";

                  this.insertNewClient = true;
                  this.recurrentClient = false;
                  this.viewBilling();
                  this._utilsService.showNotification(4, "Cliente Nuevo");
                } else {
                  this._utilsService.showNotification(3, response.listado_errores[0].error[0].desc_error[0]);
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;

        this.ValidarCF();
      });
    }
  }

  // CALCULO DE CUOTAS
  async calculateFees() {
    /* Validamos que ingreso la cantidad de abonos y la fecha de vencimiento */
    if (!this.infoFees.amountFees || !this.infoFees.dueDate) {
      this.showNotification(3, 'Campo cantidad de abonos y fecha de vencimiento ¡Obligatorios!');
    } else {
      /* Calculamos el monto por cuota que debe entregar */
      this.amountFees = parseFloat(this.invoiceHeader.invoiceTotal.toFixed(4)) / this.infoFees.amountFees;

      // Inicializamos fecha, arreglo del due y nombres de arreglo
      this.date = new Date(this.invoiceHeader.invoiceDate);
      this.infoFees.due = new Array();

      /* Realizamos un ciclo para ingresar la información al arreglo de las cuotas y fechas */
      for (let i = 0; i < this.infoFees.amountFees; i++) {
        var _date;
        if (i == 0) {
          _date = await this.date.setDate(this.date.getDate() + this.infoFees.dueDate + 1);
        } else {
          _date = await this.date.setDate(this.date.getDate() + this.infoFees.dueDate);
        }
        // console.log(this.amountFees, this.date);

        await this.infoFees.due.push(
          {
            id: i + 1,
            amount: this.amountFees,
            dateFee: _date
          }
        );
      }
      // console.log(this.infoFees);
      this.calculateTrue = true;
    }
  }

  invoiceExchangeNumber;
  invoiceExchangeDate;
  invoiceExchangeAmount;
  public invoiceExchange = [];
  async insertInvoice() {
    if (Object.keys(this.infoFees).length > 0) {
      this.loading = true;
      this.isDisabled = true;

      //Crear Cliente en la DB
      if (this.insertNewClient) {
        let nombre;
        nombre = await this.client.clientName.replace(/&/g, '&amp;');
        nombre = await this.client.clientName.replace(/'/g, '&apos;');
        nombre = await this.client.clientName.replace(/"/g, '&quot;');

        this.client.clientName = nombre;
        this.client.clientCredit = 0;
        this.client.clientStatus = 1;
        this.client.clientDeleted = 0;
        this.client.clientBlocked = 0;
        this.client.clientInterest = 0;
        this.client.clientDiscount = 0;
        this.client.clientCategory = 2;
        this.client.clientCreditTime = 0;
        this.client.clientCreditAmount = 0;
        this.client.clientCompanyCode = this.companyCode;
        this.client.clientType = this.client.clientType;
        this.client.clientNameFrequent = '-';

        if (this.client.clientTaxDocument && this.client.clientName) {
          const response = await this._clientService.insert(this.client);
          this.client.clientCode = response.records[0].insertId;
        };
      }

      // Pequeño Contribuyente
      let transmitterType = "FCAM";
      let transmitterMembership = "GEN";
      this.company.companyTaxToPay === 5 ? transmitterMembership = "PEQ" : '';

      // this.invoiceHeader.invoiceTax = (this.invoiceHeader.invoiceTotal / this.taxToPay) * (this.taxToPay - 1);
      this.invoiceHeader.invoiceTax = 0;
      this.invoiceLine.forEach((e) => {
        e.itemCode = 0;
        // this.invoiceHeader.invoiceTax += e.taxableAmount;
        this.invoiceHeader.invoiceTax += e.taxAmount;
      });

      for await (const e of this.infoFees.due) {
        const d = new Date(e.dateFee)
        var dd = String(d.getDate()).padStart(2, '0');
        var mm = String(d.getMonth() + 1).padStart(2, '0');
        var yyyy = d.getFullYear();

        this.invoiceExchange.push({
          invoiceExchangeSubscriptionNumber: e.id,
          invoiceExchangeSubscriptionDate: yyyy + '-' + mm + '-' + dd,
          invoiceExchangeSubscriptionAmount: parseFloat((e.amount).toFixed(4))
        })
      }

      if(this.company.companyPaymentType == 0){
        await this.PaymentLine.push({
          typePayment: 0,
          NamePaymentType: 'Efectivo',
          MontoPayment: parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4))
        });
      }

      const hour = await this._utilsService.dateTime('HH:mm:ss');

      const json = {
        "store": {
          "storeCode": this.storeCode
        },
        "user": {
          "userCode": this.userCode
        },
        "transmitter": {
          "transmitterMembership": transmitterMembership, 
          "transmitterType": transmitterType,
          "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
          "transmitterEmail": this.company.companyEmail,
          "transmitterTaxDocument": this.company.companyTaxDocument,
          "transmitterComercialName": this.store.storeName,
          "transmitterName": this.company.companyName,
          "transmitterAddress": this.store.storeAddress,
          "transmitterZipCode": this.store.storeZipCode,
          "transmitterMunicipality": this.store.storeMunicipality,
          "transmitterDepartment": this.store.storeDepartment,
          "transmitterCountry": 'GT',
          "transmitterUser": this.company.companyPrefix,
          "transmitterPrefix": this.company.companyPrefix,
          "transmitterKey": this.company.companyKey,
          "transmitterToken": this.company.companyToken,
          "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
          "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
        },
        "receiver": {
          "receiverAddress": this.client.clientAddress,
          "receiverCode": this.client.clientCode,
          "receiverDeleted": 0,
          "receiverEmail": this.client.clientEmail,
          "receiverName": this.client.clientName,
          "receiverStatus": 1,
          "receiverTaxDocument": this.client.clientTaxDocument,
          "receiverTypeTaxDocument": this.client.clientType,
          "receiverZipCode": 0,
          "receiverCountry": "GT"
        },
        "invoice": {
          "invoiceInternSerial": this.invoiceHeader.invoiceInternSerial
        },
        "invoiceExchange": {
          "invoiceExchangeCode": 1,
          "invoiceExchangeCurrency": this.invoiceHeader.invoiceCurrency,
          "invoiceExchangeChangeRate": this.invoiceHeader.invoiceExchangeRate,
          "invoiceExchangeTaxShortName": "IVA",
          "invoiceExchangeTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(4)),
          "invoiceExchangeTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4)),
          "invoiceExchangeComplementId": "Cambiaria",
          "invoiceExchangeComplementName": "Cambiaria",
          "invoiceExchangeDate": this.invoiceHeader.invoiceDate + ' ' + hour,
          "invoiceExchangeInternSerial": this.invoiceHeader.invoiceInternSerial
        },
        "phrase": this.company.phrase,
        "detail": this.invoiceLine,
        "paymentType": this.PaymentLine,
        "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
        "detailsComplement": this.invoiceExchange,
      };

      this.loading = false;
      this.isDisabled = false;
      console.log(json);
      //* Insertar Venta
      if (this.company.companyCertifier == 0) {
        this._invoiceService.insertInvoiceExchange(json).then(
          async (response) => {
            if (response.result) {
              let uuid = response.invoiceExchangeAuthorization;
              window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
      else if (this.company.companyCertifier == 1) {
        this._invoiceService.insertInvoiceExchange(json).then(
          async (response) => {
            if (response.result) {
              var pdfResult = response.invoiceExchangePdf;

              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
      //*/

      this.insertNewClient = false;
    }
  }

  // Limpiar Campos
  cleanModel() {
    this.itemCode = 'I';
    this.itemNumberCode = 0;

    this.isDisabled = false;
    this.insertNewClient = false;
    this.recurrentClient = false;
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceLine = [];
    this.moneda = 'Q';
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.invoiceCurrency = 'GTQ';
    this.invoiceHeader.invoiceExchangeRate = null;
    this.invoiceHeader.invoiceDate = this.nowDay;
    this.invoiceExchangeDate = this.nowDay;
    this.invoiceExchangeNumber = 1;
    this.invoiceExchangeAmount = 0;
    this.invoiceExchange = [];
    this.infoFees = {} as InfoFees;

    this.addAmount = true;
  }

  //Mostrar Notificacion
  showNotification(type, message) {
    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' +
          message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-success alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 4:
        this.toastr.info(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Info</b> - ' +
          message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-info alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' +
          message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-warning alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' +
          message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-danger alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
    }
  }
}
