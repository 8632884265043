import { Component, OnInit } from '@angular/core';
import * as printJS from 'print-js';
import * as xlsx from 'xlsx';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { AccessService } from 'src/app/services/access.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-reprint-exchange-bill',
  templateUrl: './reprint-exchange-bill.component.html',
  styleUrls: ['./reprint-exchange-bill.component.scss'],
  providers: [UtilsService, PrintService, InvoiceService, CompanyService, AccessService]
})
export class ReprintExchangeBillComponent implements OnInit {
  loading;
  // Variables auxiliares
  company = {} as Company;
  public isBeginning: boolean;
  public isReprint: boolean;
  public isPrint: boolean;
  public isDisabled: boolean;
  public userCommerceCode: number;
  public begDate: string;
  public endDate: string;
  public reasonForCancellation;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public storeName: string;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      serie: {
        title: 'Serie Fel',
      },
      factura: {
        title: 'No. Factura Fel'
      },
      invoiceInternSerial: {
        title: 'Serie Interna'
      },
      invoiceInternNumber: {
        title: 'Correlativo Interna',
      },
      invoiceSubtotal: {
        title: 'Sub Total',
        type: 'number'
      },
      invoiceTax: {
        title: 'IVA',
        type: 'number'
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      nit: {
        title: 'Nit',
      },
      cliente: {
        title: 'Cliente',
      },
      estado: {
        title: 'Estado',
      },
      usuario: {
        title: 'Usuario',
      }
    }
  };

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp = new Array();
  public invoiceList: any[] = new Array();

  //Reprint
  public dte: any;
  public fel: any;
  public felDetail: any;
  public felComplement: any;

  constructor(
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _accessService: AccessService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    //DATE
    this.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');

    this.viewBeginning();
    await this.getCompany(this.companyCode);
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isReprint = false;
    this.isPrint = false;
  }

  viewReprint() {
    this.isBeginning = false;
    this.isReprint = true;
    this.isPrint = false;
  }

  viewPrint() {
    this.isBeginning = false;
    this.isReprint = false;
    this.isPrint = true;
  }

  printTest() {
    printJS({
      printable: "printSection",
      type: "html",
      style: "#printSection{width: 224px; text-align: center}",
    });
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.invoiceList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////CRUD///////////////////////
  //Ver todas las factura
  getAll() {
    this.isDisabled = true;
    if (this.begDate && this.endDate) {
      this.loading = true;

      let json = {
        "storeCode": this.storeCode,
        "begDate": this.begDate,
        "endDate": this.endDate
      }

      this._invoiceService.getAllExchangeByCompany(json).then(
        async (response) => {
          if (response.result == true) {
            this.invoiceList = response.records;
            await this._utilsService.orderDate(this.invoiceList, 'fecha', '-');
            this.temp = this.invoiceList;
            this.isDisabled = false;
            this.isReprint = true;
            this._utilsService.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this.isDisabled = false;
      // Mostrar notificación
      this._utilsService.showNotification(2, "Ingresar Datos");
    }
  }

  // Obtener una Factura
  async searchInvoice($event) {
    $event.preventDefault();
    this.loading = true;

    //INFILE
    if (this.company.companyCertifier == 0) {
      await this._printService.printInfile(this.activeRow.invoiceExchangeAuthorization);
      this.loading = false;
    }
    //MEGAPRINT
    else if (this.company.companyCertifier == 1) {
      this._invoiceService.getOneMegaprintExchange(this.activeRow.id).then(
        async (response) => {
          if (response.result == true) {
            if (this.company.companyInvoiceFuel == 1 || this.company.companyPDFType == 1) {
              this.fel = response.records;
              this.felDetail = response.records.detail;
              this.felComplement = this.activeRow;
              this.viewPrint();
            } else {
              // var pdfResult = response.invoiceExchangePdf;
              const pdfResult = response.records.pdfBase64;
              const pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>");
            }
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });
    }

  }


  ///////////////////////DESCARGAR XML FIRMADO///////////////////////
  downloadFunction($event: Event, invoiceExchangeAuthorization: string, invoceExchangeCode: string) {
    // https://variabletecnica.com/2020/09/21/descargar-archivos-con-javascript/
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    //records: queryResult[0][1][0]['invoiceExchangeRegisterResponse']

    // if (this.company.companyCertifier == 1) { }
    this._invoiceService.xmlInvoiceExchange(invoceExchangeCode).then(
      async (response) => {
        if (response.result == true) {
          // const string = response.records[0].invoiceExchangeRegisterResponse;
          const xmlData = response.records;
          const xmlStart = xmlData.indexOf("<?xml");
          const xmlEnd = xmlData.indexOf("</dte:GTDocumento>") + 18;
          const xmlSubstring = xmlData.substring(xmlStart, xmlEnd);
          console.log(xmlSubstring);

          const dataXML = [xmlSubstring];
          const data = new Blob(dataXML, { type: 'text/xml' });

          const save = document.createElement('a');
          save.download = invoiceExchangeAuthorization;
          save.target = '_blank';
          save.href = URL.createObjectURL(data);

          var eventoClic = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });
          save.dispatchEvent(eventoClic);
          URL.revokeObjectURL(save.href);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.isDisabled = false;
      this.loading = false;
    });
  }

  ///////////////////////DESCARGAR EXCEL///////////////////////
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-impresion-anulacion-factura-cambiaria.xlsx');
  }
}
