<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/invoice-massive">Facturación Masiva</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- CARGA XML -->
    <div class="row justify-content-center" *ngIf="!error">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <p class="text-center font-weight-bold">Ingrese el archivo de Excel con el formato proporcionado</p>
                    <div class="form-group mt-4">
                        <span class="btn btn-block btn-round btn-file btn-info">
                            <span class="font-weight-bold">Cargar Archivos</span>
                            <input type='file' #file_input (change)="onFileChanged($event)" multiple="false" [disabled]="disabled" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- NON-CERTIFIED DOCUMENTS TABLE -->
    <div class="row" *ngIf="error">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="text-center text-danger">Documentos que no se certificaron. Total: {{errorCount}}.
                        </div>
                        <ngx-datatable class="bootstrap selection-cell bg-red" [columnMode]="'force'"
                            [footerHeight]="33" [rowHeight]="'auto'" [limit]="5" [scrollbarH]="true"
                            [rows]="rowsDocumentError">
                            <ngx-datatable-column name="No. Fila" prop="no"></ngx-datatable-column>
                            <ngx-datatable-column name="Nombre" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Identificación" prop="identificacion"></ngx-datatable-column>
                            <ngx-datatable-column name="Error" prop="error"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- WARNING-CERTIFIED DOCUMENTS TABLE -->
    <div class="row" *ngIf="warning">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="text-center text-danger">Documentos con incidentes pero SI CERTIFICADOS. Total: {{warningCount}}.
                        </div>
                        <ngx-datatable class="bootstrap selection-cell bg-red" [columnMode]="'force'"
                            [footerHeight]="33" [rowHeight]="'auto'" [limit]="5" [scrollbarH]="true"
                            [rows]="rowsDocumentWarning">
                            <ngx-datatable-column name="Nombre" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Error" prop="error"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">                                    
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="printInvoiceMega($event, row.pdfUUID)"
                                        ngbTooltip="Imprimir Factura" placement="left"><i class="fas fa-clone"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="downloadFunction($event, row.name, row.xml)" *ngIf="row.xml"
                                        ngbTooltip="XML firmado" placement="left">
                                        <i class='fas fa-download'></i>
                                    </a>                                                                   
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>    

    <!-- TABLA DOCUMENTS -->
    <div class="row" *ngIf="rowsDocumentCertified.length <= 0">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de facturas</h4>
                            <h5 class="card-title text-info">Documentos a procesar {{documentCount}}</h5>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Buscar..."</h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success rounded-pill w-100 font-weight-bold" (click)="generarFacturas()"
                                [disabled]="disabled || rowsDocument.length === 0" *ngIf="rowsDocumentCertified.length === 0">
                                Generar {{nameDTE}}
                            </button>
                            <button class="btn btn-info rounded-pill w-100 font-weight-bold" (click)="cleanModel()"
                                [disabled]="disabled" *ngIf="rowsDocument.length > 0">
                                Limpiar Modulo
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Ver
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">Todas</option>
                                        </select>
                                        entradas
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Buscar..." aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [scrollbarH]="true" [rows]="rowsDocument" (activate)="onActivate($event)">
                            <ngx-datatable-column name="No. Fila" prop="no"></ngx-datatable-column>
                            <ngx-datatable-column name="Cliente" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Identificacion" prop="identificacion"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <button class="btn btn-round btn-info btn-icon btn-sm like" (click)="viewFunctionWl(content, row.name)"
                                        *ngIf="!row.pdf && walmartDTE === true" ngbTooltip="Ingresar datos WL" placement="left">
                                        <i class="fas fa-sliders-h"></i>                                        
                                    </button>
                                    <!-- <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewNCFunction($event)" *ngIf="!row.pdf && typeDTE == 'NOTACREDITO'"
                                        ngbTooltip="Ver Datos" placement="left"><i class="fas fa-sliders-h"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewFunction($event)" *ngIf="!row.pdf && typeDTE == 'FACTURA' && walmartDTE == false"
                                        ngbTooltip="Ver Datos" placement="left"><i class="fas fa-eye"></i>
                                    </a> -->
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="printInvoice($event)" *ngIf="row.url"
                                        ngbTooltip="Imprimir Factura" placement="left"><i class="fas fa-clone"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="deleteFunction($event)" *ngIf="!row.url"
                                        ngbTooltip="Eliminar Documento" placement="left"><i class="fas fa-times"></i>
                                    </a>                                    
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TABLA DOCUMENTS -->
    <div class="row" *ngIf="rowsDocumentCertified.length > 0" >
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de facturas generadas</h4>
                            <h4 class="card-title text-info">Total facturas generadas {{CertifiedCount}}</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Buscar..."</h6>
                        </div>
                        <div class="col-md-4">                            
                            <button class="btn btn-info rounded-pill w-100 font-weight-bold" (click)="cleanModel()"
                                [disabled]="disabled" *ngIf="error || rowsDocumentCertified.length > 0 || rowsDocumentError.length > 0 || rowsDocumentWarning.length > 0">
                                Limpiar Modulo
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Ver
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">Todas</option>
                                        </select>
                                        entradas
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Buscar..." aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [scrollbarH]="true" [rows]="rowsDocumentCertified" (activate)="onActivate($event)">
                            <ngx-datatable-column name="No. Fila" prop="no"></ngx-datatable-column>
                            <ngx-datatable-column name="Cliente" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="Identificacion" prop="identificacion"></ngx-datatable-column>
                            <ngx-datatable-column name="Autorización" prop="url"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <!-- <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewNCFunction($event)" *ngIf="!row.pdf && typeDTE == 'NOTACREDITO'"
                                        ngbTooltip="Ver Datos" placement="left"><i class="fas fa-sliders-h"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewFunction($event)" *ngIf="!row.pdf && typeDTE == 'FACTURA' && walmartDTE == false"
                                        ngbTooltip="Ver Datos" placement="left"><i class="fas fa-eye"></i>
                                    </a> -->
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="printInvoiceMassive($event)" *ngIf="row.url"
                                        ngbTooltip="Imprimir Factura" placement="left"><i class="fas fa-clone"></i>
                                    </a>                                  
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>