<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">    
    <div class="row link-header ml-5">        
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="a-return" routerLink="/reprint">Reimpresion/</a>
        </div>
        <div>
            <a class="text-info">{{reprint_note}}</a>
        </div>
    </div>
</div>
<br />
<div class="main-content">
    <!-- FILTER -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">                        
                        <div class="col-sm-12">
                            <b style="font-size: 1rem;">{{data.storeName}}</b>
                        </div>
                        <div class="col-md-4">
                            <label>Del:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="filter.begDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Para:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="filter.endDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <br>
                            <div class="form-group">
                                <button class="btn btn-info font-weight-bold w-100 btn-erp" [disabled]="disabled"
                                    (click)="getAll('getAllCreditNoteByCompany')">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END FILTER -->


    <!-- TABLA -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de {{reprint_note}}</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success font-weight-bold w-100 btn-erp"(click)="downloadToExcel()" 
                                [disabled]="disabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="rows" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Correlativo" prop="correlativo"></ngx-datatable-column>
                            <ngx-datatable-column name="Letra" prop="letra"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Factura" prop="autorizacionFactura"></ngx-datatable-column>                            
                            <ngx-datatable-column name="Nit" prop="nit"></ngx-datatable-column>
                            <ngx-datatable-column name="Cliente" prop="cliente"></ngx-datatable-column>
                            <ngx-datatable-column name="Concepto" prop="concepto"></ngx-datatable-column>                            
                            <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="true"
                                [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        *ngIf="row.debitNoteAuthorization"
                                        (click)="reprint($event, row.debitNoteAuthorization)" ngbTooltip="Ver Nota Debito..." placement="left">
                                        <i class="now-ui-icons files_paper"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        *ngIf="row.creditNoteAuthorization"
                                        (click)="reprint($event, row.creditNoteAuthorization)" ngbTooltip="Ver Nota Credito..." placement="left">
                                        <i class="now-ui-icons files_paper"></i>
                                    </a>
                                    <!-- <a href="#" class="btn btn-round btn-gray btn-icon btn-sm edit"                                        
                                        (click)="reprint($event, row.autorizacionFactura)" ngbTooltip="Ver Factura..." placement="left">
                                        <i class="now-ui-icons files_paper"></i>
                                    </a> -->
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="downloadFunction($event, row.creditNoteAuthorization, row.creditNoteCode)" 
                                        ngbTooltip="XML firmado" placement="left" *ngIf="row.creditNoteStoreCode == 35 || row.debitNoteStoreCode == 35">
                                        <i class='fas fa-download'></i>
                                    </a>
                                    
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLA -->
</div>