import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class PhraseStageService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL OWNER
    getAll(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-phrase', { headers: headers }).toPromise();
    } 

    // GET ALL COMPANY OWNER
    getOneCompany(code): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-phrase/company/' + code, { headers: headers }).toPromise();
    } 

    // INSERT OWNER
    insert(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-phrase', params, { headers: headers }).toPromise();
    }

    // UPDATE OWNER
    update(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-phrase', params, { headers: headers }).toPromise();
    }
}