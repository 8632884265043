import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as xlsx from 'xlsx';
import { WarehouseService } from '../../../services/warehouse.service';

@Component({
  selector: 'app-transfer-reports',
  templateUrl: './transfer-reports.component.html',
  styleUrls: ['./transfer-reports.component.scss'],
  providers: [WarehouseService, UtilsService]
})
export class TransferReportsComponent implements OnInit {
  // MODELS VARIABLE 
  public isDisabled: boolean;
  public isReport: boolean;
  public isStoreReport: boolean;
  public storeCode: number;
  public companyCode: number;
  public warehouseCode: number;
  public transferType: number = 0;
  public tranferStatus: number = 999;
  public product: string;

  public settings = {
    columns: {
      transferBusinessCode: {
        title: 'No. Traslado'
      },
      tipoTexto: {
        title: 'Movimiento'
      },
      transferDate: {
        title: 'Fecha'
      },
      Sucursal: {
        title: 'Sucursal',
      },
      bodega: {
        title: 'Bodega'
      },
      estadoTexto: {
        title: 'Estado'
      },
      transferInventoryDetailQuantity: {
        title: 'Cantidad',
        type: 'number'
      },
      transferInventoryDetailDescription: {
        title: 'Producto'
      },
      Total: {
        title: 'Total'
      },
      usuario: {
        title: 'Usuario'
      }
    }
  };
  loading;

  // DATA SELECT
  public warehouseList: any[] = new Array();
  public selectedItemsWarehouse = [];
  public dropdownSettingsWarehouse = {};

  // DATA SELECT TIPO TRANSFERENCIA
  public transferTypeList: any[] = new Array();
  public selectedItemstransferType = [];
  public dropdownSettingstransferType = {};

  // DATA SELECT ESTADO TRANSFERENCIA
  public transferStatusList: any[] = new Array();
  public selectedItemstransferStatus = [];
  public dropdownSettingstransferStatus = {};

  public begDate: string;
  public endDate: string;

  // VARIABLE FOR DATA TABLE //GET ALL //GET PENDING 
  public temp = [];
  public activeRow: any;
  public selected: any[] = [];
  public entries: number = 10;
  public allList = new Array();
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;

  constructor(
    private _utilsService: UtilsService,
    private _warehouseService: WarehouseService
  ) { }

  ngOnInit(): void {
    this.isDisabled = false;
    this.warehouseCode = 0;

    let _data = JSON.parse(localStorage.getItem('data'));
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    this.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');

    this.viewReport();
    this.getSelectSetting();
    this.getAllWarehouse();
    this.getSelectSettingTransferType();
    this.getAllTransferType();
    this.getSelectSettingTransferStatus();
    this.getAllTransferStatus();
  }


  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.allList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////SELECT///////////////////////
  getSelectSetting() {
    this.dropdownSettingsWarehouse = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: false,
    };
  }

  onItemSelectWarehouse(item: any) {
    this.warehouseCode = item.id;
    console.log(this.warehouseCode);
  }

  onDeSelectWarehouse() {
    this.warehouseCode = null;
  }  

  getAllWarehouse() {
    return this._warehouseService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result == true) {
          response.records[1].forEach(e => {
            this.warehouseList.push({
              id: e.warehouseCode,
              itemName: e.warehouseName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////SELECT TRANSFER TYPES///////////////////////
  getSelectSettingTransferType() {
    this.dropdownSettingstransferType = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: false,
    };
  }

  onItemSelectTransferType(item: any) {
    this.transferType = item.id;
    console.log(this.transferType);
  }

  onDeSelectTransferType() {
    this.transferType = null;
  }

  getAllTransferType(){
    this.transferTypeList.push({
      id: "0",
      itemName: "Todos"
    });

    this.transferTypeList.push({
      id: "1",
      itemName: "Salida"
    });

    this.transferTypeList.push({
      id: "2",
      itemName: "Ingreso"
    });
  }

   ///////////////////////SELECT TRANSFER STATUS///////////////////////
   getSelectSettingTransferStatus() {
    this.dropdownSettingstransferStatus = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: false,
    };
  }

  onItemSelectTransferStatus(item: any) {
    this.tranferStatus = item.id;
    console.log(this.tranferStatus);
  }

  onDeSelectTransferStatus() {
    this.tranferStatus = null;
  }

  getAllTransferStatus(){
    this.transferStatusList.push({
      id: "999",
      itemName: "Todos"
    });

    this.transferStatusList.push({
      id: "1",
      itemName: "Pendiente"
    });

    this.transferStatusList.push({
      id: "2",
      itemName: "Facturada"
    });

    this.transferStatusList.push({
      id: "3",
      itemName: "Procesada"
    });

    this.transferStatusList.push({
      id: "4",
      itemName: "Operada Anteriormente"
    });

    this.transferStatusList.push({
      id: "0",
      itemName: "Cancelada"
    });
  }

  getReport() {

    this.loading = true;
    this.isDisabled = true;

    this.transferType == null ? this.transferType = 0 : this.transferType = this.transferType;
    this.warehouseCode == null ? this.warehouseCode = 0 : this.warehouseCode = this.warehouseCode;
    this.tranferStatus == null ? this.tranferStatus = 999 : this.tranferStatus = this.tranferStatus;
    this.product == null ? this.product = "0" : this.product = this.product;
    this.product == "" ? this.product = "0" : this.product = this.product;

    console.log('type',this.transferType);
    console.log('client',this.warehouseCode);
    console.log('status',this.tranferStatus);
    console.log('product',this.product);
    console.log('begDate',this.begDate);
    console.log('endDate',this.endDate);

    const json = {
      "report": {
        "companyCode": this.companyCode,
        "transferType": this.transferType,
        "warehouseCode": this.warehouseCode,
        "tranferStatus": this.tranferStatus,
        "product": this.product,
        "begDate": this.begDate,
        "endDate": this.endDate
      }
    };

    console.log(json);

    return this._warehouseService.reportsTransfer(json).then(
      response => {
        if (response.result) {
          this.allList = response.records;
          this.temp = this.allList;

          console.log('Reporte ->', this.allList)
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.viewReport();
      this.loading = false;
      this.isDisabled = false;
    });

    // if (this.transferType != 0) {
      
    // } else {
    //   this._utilsService.showNotification(2, 'Selecciona una bodega');
    // }
  }

  getAllReport() {
    this.loading = true;
    this.isDisabled = true;

    return this._warehouseService.reportAllTransfer(this.companyCode).then(
      response => {
        if (response.result) {
          this.allList = response.records;
          this.temp = this.allList;
          console.log('dos', this.allList)
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.viewReport();
      this.loading = false;
      this.isDisabled = false;
    });
  }

  getAllReportInStore() {
    this.loading = true;
    this.isDisabled = true;

    return this._warehouseService.getTransferByCompany(this.companyCode, 0).then(
      response => {
        if (response.result) {
          console.log(response.records)

          // response.records.forEach(e => {
          //   e.detail.forEach(i => {
          //     this.allList.push({
          //       salio: e.salio,
          //       entro: e.entro,
          //       fecha: e.fecha,
          //       recibo: e.serie,
          //       descripcion: i.transferInventoryDetailDescription
          //     })
          //   });
          // });

          for (const e of response.records){
            e.detail.forEach(i => {
              this.allList.push({
                salio: e.salio,
                entro: e.entro,
                fecha: e.fecha,
                recibo: e.serie,
                descripcion: i.transferInventoryDetailDescription
              })
            });
          };
          console.log('tres', this.allList)
          this.temp = this.allList;
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.viewStoreReport();
      this.loading = false;
      this.isDisabled = false;
    });
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'Reporte-Trasnferencias.xlsx');
  }

  viewReport() {
    this.isReport = true;
    this.isStoreReport = false;
  }

  viewStoreReport() {
    this.isReport = false;
    this.isStoreReport = true;
  }
}
