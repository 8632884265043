import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Provider } from '../models/provider';
import { ProviderCategory } from '../models/providerCategory';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProviderService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL PROVIDER
    getAll(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-provider', { headers: headers }).toPromise();
    }

    // GET ALL PROVIDER BY COMPANY
    getAllByCompany(company): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-provider/company/' + company, { headers: headers }).toPromise();
    }

    // GET ALL PROVIDER
    getAllProviderCategory(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-providerCategory', { headers: headers }).toPromise();
    }

    // INSERT PROVIDER
    insert(provider: Provider): Promise<any> {
        let params = JSON.stringify(provider);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-provider', params, { headers: headers }).toPromise();
    }

    // INSERT PROVIDER CATEGORY
    insertProviderCategory(providerCategory: ProviderCategory): Promise<any> {
        let params = JSON.stringify(providerCategory);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-providerCategory', params, { headers: headers }).toPromise();
    }

    // UPDATE PROVIDER
    update(provider: Provider): Promise<any> {
        let params = JSON.stringify(provider);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-provider', params, { headers: headers }).toPromise();
    }

    // UPDATE PROVIDER CATEGORY
    updateProviderCategory(providerCategory: ProviderCategory): Promise<any> {
        let params = JSON.stringify(providerCategory);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-providerCategory', params, { headers: headers }).toPromise();
    }
}