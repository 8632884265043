import { Component, OnInit } from '@angular/core';
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { UserService } from '../../../services/user.service';
import { CompanyService } from '../../../services/company.service';
import { UserStore } from 'src/app/models/userStore';
import { UtilsService } from 'src/app/services/utils.service';

interface MultiCompanyUser {
  userCompanyCode
  userCompanyUserCode
  userCompanyCompanyCode
  userCompanyDeleted
}

@Component({
  selector: 'app-multi-company-user',
  templateUrl: './multi-company-user.component.html',
  styleUrls: ['./multi-company-user.component.scss'],
  providers: [UserService, CompanyService, UtilsService]
})

export class MultiCompanyUserComponent implements OnInit {
  multiCompanyUser = {} as MultiCompanyUser;
  userStore = {} as UserStore
  public isDisabled: boolean;
  public userCode: number;
  public companyCode: number;
  loading;
  public settings = {
    columns: {
      nombre: {
        title: 'Empresa'
      },
      correo: {
        title: 'Usuario'
      },
      rol: {
        title: 'Rol'
      },
      telefono: {
        title: 'Telefono'
      }
    }
  };

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public multiCompanyUserList = new Array();

  // VARIABLE FOR SELECT 
  public companyList: any[] = new Array();
  public selectedItemsCompany = [];
  public dropdownSettingsCompany = {};
  public userList: any[] = new Array();
  public selectedItemsUser = [];
  public dropdownSettingsUser = {};


  constructor(
    private _utilsService: UtilsService,
    private _userService: UserService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.isDisabled = false;

    // GET ID USER & ID COMPANY & ID STORE
    let _data = JSON.parse(localStorage.getItem('data'));
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    // LOAD INFORMATION
    this.getSelectSetting();
    await this.getMultiCompanyUser();
    await this.getCompany();
    await this.getUserByCompany(this.companyCode);
    this.loading = false;
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.multiCompanyUserList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getCompany() {
    return this._companyService.getAll().then(
      async response => {
        if (response.result) {

          let filtro = await response.records.filter(
            x => x.companyCompanyCreationCode === this.companyCode || x.companyCode === this.companyCode
          );

          for await (const e of filtro) {
            this.companyList.push({id: e.companyCode, itemName: e.companyName});
          }
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  getUserByCompany(companyCode) {
    return this._userService.getAllByCompany(companyCode).then(
      response => {
        if (response.result) {
          for (const e of response.records) {
            this.userList.push({
              id: e.userCode,
              itemName: e.userName
            })
          }
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  getSelectSetting() {
    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione una empresa...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };

    this.dropdownSettingsUser = {
      singleSelection: true,
      text: "Seleccione un usuario...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  }

  onItemSelectCompany(item: any) {
    this.multiCompanyUser.userCompanyCompanyCode = item.id;
  }

  onItemDeSelectCompany(item: any) {
    this.multiCompanyUser.userCompanyCompanyCode = null;
  }

  onItemSelectUser(item: any) {
    this.multiCompanyUser.userCompanyUserCode = item.id;
  }

  onItemDeSelectUser(item: any) {
    this.multiCompanyUser.userCompanyUserCode = null;
  }

  ///////////////////////CRUD///////////////////////  
  getMultiCompanyUser() {
    return this._userService.getAllMultiCompanyUser().then(
      response => {
        if (response.result) {
          this.multiCompanyUserList = response.records;
          this.temp = this.multiCompanyUserList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  async validation() {
    if (this.multiCompanyUser.userCompanyCompanyCode > 0 && this.multiCompanyUser.userCompanyUserCode > 0) {

      let isNew = await this.temp.findIndex(x => x.userCompanyUserCode === this.multiCompanyUser.userCompanyUserCode)

      if (isNew === -1) {
        if (this.multiCompanyUser.userCompanyCompanyCode === this.companyCode) {
          await this.deletedStore(this.multiCompanyUser.userCompanyUserCode);
          await this.save();
        } else {
          this._utilsService.showNotification(3, 'Falto realizar la primera aisgnacion');
        }
      } else {
        await this.save();
      }
    } else {
      this._utilsService.showNotification(2, 'Falto llenar datos');
    }
  }

  save() {
    // console.log('p2. llegaste al save')
    this.loading = true;
    this.isDisabled = true;
    this._userService.insertMultiCompanyUser(this.multiCompanyUser).then(
      async (response) => {
        if (response.result) {
          this.cleanModel();
          this.getMultiCompanyUser();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  deleteFunction($event) {
    $event.preventDefault();

    swal.fire({
      title: "Esta seguro",
      text: "El usuario multi empresa elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.deletedMultiCompany(this.activeRow.userCompanyCode);
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  deletedMultiCompany(code) {
    this.loading = true;
    this.isDisabled = true;

    this.multiCompanyUser.userCompanyCode = code;
    this.multiCompanyUser.userCompanyDeleted = 1;

    this._userService.updateMultiCompanyUser(this.multiCompanyUser).then(
      async (response) => {
        if (response.result == true) {
          this.cleanModel();
          this.getMultiCompanyUser();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  deletedStore(code) {
    this.userStore.userStoreUserCode = code;
    // console.log('p1. deletedStore');

    this._userService.updateUserStore(this.userStore).then(
      (response) => {
        if (response.result) {
          console.log('deletedStore exito');
        }
      },
      error => {
        console.log("deletedStore", error);
      }
    );
  }

  ///////////////////////COMPLEMENT///////////////////////
  cleanModel() {
    this.selectedItemsCompany = null;
    this.selectedItemsUser = null;
    this.multiCompanyUser = {} as MultiCompanyUser;
  }

  // DOWNLOAD EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        json[this.settings.columns[item].title] = element[item];
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-usuarios.xlsx');
  }
}
