import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { AccessService } from 'src/app/services/access.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-retentions-invoice-report',
  templateUrl: './retentions-invoice-report.component.html',
  styleUrls: ['./retentions-invoice-report.component.scss'],
  providers: [InvoiceService, AccessService, UtilsService]
})
export class RetentionsInvoiceReportComponent implements OnInit {
  loading;
  // Variables auxiliares
  public isBeginning: boolean;
  public isTable: boolean;
  public isDisabled: boolean;
  public begDate: string;
  public endDate: string;
  // public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public storeName: string;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      serie: {
        title: 'Serie Fel',
      },
      factura: {
        title: 'No. Factura Fel'
      },
      invoiceInternSerial: {
        title: 'Serie Interna'
      },
      invoiceInternNumber: {
        title: 'Correlativo Interna',
      },
      invoiceSubtotal: {
        title: 'Sub Total',
        type: 'number'
      },
      invoiceTax: {
        title: 'IVA',
        type: 'number'
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      nit: {
        title: 'Nit'
      },
      cliente: {
        title: 'Cliente'
      },
      estado: {
        title: 'Estado'
      },
      usuario: {
        title: 'Usuario'
      },
      retencion: {
        title: '# Retencion'
      },
      valor: {
        title: 'Q. Retencion',
        type: 'number'
      },
      documento: {
        title: '# Deposito'
      },
      deposito: {
        title: 'Q. Deposito Neto',
        type: 'number'
      }
    }
  };

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp = new Array();
  public invoiceList: any[] = new Array();


  constructor(
    private _utilsService: UtilsService,
    private _invoiceService: InvoiceService,
    private _accessService: AccessService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;

    this.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');
    this.isBeginning = true;
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.invoiceList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////
  getAll() {
    this.isDisabled = true;
    if (this.begDate && this.endDate) {
      this.loading = true;

      let json = {
        "storeCode": this.storeCode,
        "begDate": this.begDate,
        "endDate": this.endDate
      }

      this._invoiceService.getAllByCompanyRetention(json).then(
        async (response) => {
          if (response.result == true) {
            await  this._utilsService.order(response.records, 'correlativo'); 
            // await  this._utilsService.orderDate(this.invoiceList, 'fecha', '-');          
            this.invoiceList = response.records;
            this.temp = this.invoiceList;
            this.isDisabled = false;
            this.isTable = true;
            this._utilsService.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this.isDisabled = false;
      this._utilsService.showNotification(2, "Ingresar Datos");
    }
  }

  ///////////////////////DESCARGAR EXCEL///////////////////////
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-retenciones-y-facturas.xlsx');
  }

}
