import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import * as printJS from 'print-js';
import { Store } from 'src/app/models/store';
import { Client } from "src/app/models/client";
import { Company } from "src/app/models/company";
import { StoreService } from 'src/app/services/store.service';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { InvoiceSpecialHeader } from '../../../../models/invoiceSpecialHeader';
import { InvoiceSpecialDetail } from '../../../../models/invoiceSpecialDetail';
import { InvoiceService } from 'src/app/services/invoice.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { LetrasPipe } from 'src/app/pipe/letras/letras.pipe';
import { UtilsService } from 'src/app/services/utils.service';
import swal from "sweetalert2";


import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Console } from 'console';
import { ConnectableObservable } from 'rxjs';

@Component({
  selector: 'app-inventory-special-billing',
  templateUrl: './inventory-special-billing.component.html',
  styleUrls: ['./inventory-special-billing.component.scss'],
  providers: [ClientService, StoreService, CompanyService, InventoryService, InvoiceService, WarehouseService, UtilsService]
})
export class InventoryBillingSpecialComponent implements OnInit {
  // Variables auxiliares
  pipeLetra = new LetrasPipe();
  pipeFecha = new DatePipe('en-US');
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  invoiceSpecialHeader = {} as InvoiceSpecialHeader;
  invoiceSpecialDetail = {} as InvoiceSpecialDetail;
  public nowDay: string;
  public userCode: number;
  public userName: string;
  public storeCode: number;
  public storeName: string;
  public companyCode: number;
  public files = {};
  public temp = [];
  loading;

  public isBeginning: boolean;
  public isBilling: boolean;
  public isPrintT: boolean;
  public isPrintL: boolean;
  public isEdit: boolean;
  public isDisabled: boolean;
  public recurrentClient: boolean;
  public insertNewClient: boolean = false; //crear cliente si no existe
  public activeRow: any;
  public isNailCenter: boolean;

  // DATA SELECT
  public id: number;
  public itemName: string;
  public selectedItems = [];
  public inventoryList: any[] = new Array();
  public dropdownSettings: IDropdownSettings = {};
  closeDropdownSelection = false;
  //public dropdownSettingsProduct = {};

  // DATA SELECT SERIAL
  public selectedItemsSerial = [];
  public serialList: any[] = new Array();
  public dropdownSettingsSerial: IDropdownSettings = {};
  closeDropdownSelectionSerial = false;
  public serialSelected = new Array;
  public serialSelectedTemp = new Array;

  // Variables de Linea de Compra
  public itemCode: string;
  public stock: number;
  public Minstock: number;
  public moneda: string;
  public invoiceSpecialLine: any = new Array;
  public addAmount: boolean;
  public idp: number;
  public subIdp: number;
  public subsidio: number;
  public subsidioTemp: number;
  public isOil: number;
  public petroleo: string;
  public taxOilCode: number;
  public taxOilAmount: number;
  public dte: any;
  public fel: any;
  public felDetail: any;
  public priceType: number;
  public companyLogoUrl: string;
  public taxToPay: number = 0
  public regimenMessage: string = '';

  //FACTURAR DESDE CLIENT
  public code: number = 0;
  public url: any;
  activatedRoute: ActivatedRoute | null | undefined;

  //FACTURA DESDE TRANSFERENCIA
  public isParamsUrl: boolean = false;
  public transferCode: any;
  public transferDetail: any[];
  public total: number;

  //Prueba de impresion
  public infile: any;
  public megaprint: any;

  public disabled = false;
  public disabledEXT = false;
  public facturando: boolean;

  public tipoCliente = 0;
  public tipoPago = 0;
  public OrdenCompra = "0";
  public entries: number = 10;

  // DATA SELECT PAYMENT
  public idPayment: number;
  public itemNamePayment: string;
  public selectedItemsPayment = [];
  public PaymentList: any[] = new Array();
  public dropdownSettingsPayment: IDropdownSettings = {};
  closeDropdownSelectionPayment = false;
  
  public PaymentLine: any = new Array;
  public PaymentType = 0;
  public NamePaymentType = 0;
  public MontoPayment: number = 0.00;
  public MontoTotalPayment: number = 0.00;
  public Autorizacion: string = "0";
  public disabledPay = false;
  public isDisabledinvoiceSpecial = false;
  public isMethodPay = false;

  // DATA SELECT SALES PERSON
  public selectedItemsSalesP = [];
  public SalesPList: any[] = new Array();
  public dropdownSettingsSalesP: IDropdownSettings = {};
  //public dropdownSettingsSalesP = {};
  closeDropdownSelectionSalesP = false;
  public SalesPerson = 0;

  constructor(
    private _utilsService: UtilsService,
    private _storeService: StoreService,
    private _clientService: ClientService,
    private _companyService: CompanyService,
    private _invoiceService: InvoiceService,
    private _inventoryService: InventoryService,
    private _warehouseService: WarehouseService,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private _pipeNumero: DecimalPipe
  ) { 
  }

  async ngOnInit() {
    this.isNailCenter = false;
    this.addAmount = true;
    this.isEdit = false;
    this.isDisabled = false;
    this.disabled = false;
    this.jumpSerial = false;
    this.insertNewClient = false;
    this.idp = 0;
    this.subIdp = 0;
    this.subsidio = 0;
    this.subsidioTemp = 0;
    this.taxToPay = 0;
    this.moneda = "Q";
    this.priceType = 1;
    this.petroleo = null;
    this.taxOilAmount = 0;
    this.taxOilCode = null;
    this.invoiceSpecialHeader.invoiceSpecialIDP = 0;
    this.invoiceSpecialHeader.invoiceSpecialCoin = 0;
    this.invoiceSpecialHeader.invoiceSpecialTax = 0;
    this.invoiceSpecialHeader.invoiceSpecialTotal = 0;
    this.invoiceSpecialHeader.invoiceSpecialCurrency = 'GTQ';
    this.invoiceSpecialDetail.invoiceSpecialDetailType = 'B';
    this.invoiceSpecialDetail.invoiceSpecialDetailQuantity = 1;
    this.invoiceSpecialHeader.invoiceSpecialInternSerial = 'A';
    this.invoiceSpecialHeader.paymentType = 0;
    this.invoiceSpecialHeader.invoiceSpecialExchangeRate = 0;
    this.tipoPago = 0;

    this.nowDay = this._utilsService.dateTime('yyy-MM-dd');
    this.invoiceSpecialHeader.invoiceSpecialDate = this.nowDay;

    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    const _user = JSON.parse(localStorage.getItem('user'));
    this.userName = _user.userName ? _user.userName.toString() : '';
    this.invoiceSpecialHeader.invoiceSpecialInternSerial = _user.userSerial ? _user.userSerial.toString() : 'A';

    this.loading = true;
    this.viewBeginning();    
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);    
    await this.getInventoryList(this.storeCode);   
    await this.getSelectSetting(this.selectedItems);
    this.getSelectSettingSerial(this.selectedItemsSerial);

    await this.getSalesPList(this.storeCode);
    await this.getPaymetList(this.storeCode);    
    this.getSelectSettingPaymet(this.selectedItemsPayment);
    this.getSelectSettingSalesP(this.selectedItemsSalesP);    

    console.log('Select -> ', this.selectedItems);
    console.log('Select Tipo pagos -> ', this.selectedItemsPayment);
    console.log('Tipo pagos -> ', this.PaymentList);

    await this.Pagado();

    this.SKUx = "";
    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.disabledEXT = true;
    this.disabled = false;
    // console.log('CompanyCode-> ', this.companyCode);

    if(this.companyCode == 22){
      this.isNailCenter = true;
      this.getAll(this.storeCode);
    }
    this.loading = false;

    this.url = this.router.parseUrl(this.router.url);
    if (this.url.queryParams['nit'] != undefined) {

      if (localStorage.getItem('clientData') !== null) {
        console.log('si hay data de cliente');

        let _dataCli = JSON.parse(localStorage.getItem('clientData'));

        console.log('localstorage -> ', _dataCli.clientName);

        this.client.clientName = _dataCli.clientName;
        this.client.clientStatus = 1;
        this.client.clientDeleted = 0;
        this.client.clientPhone = _dataCli.clientPhone;
        this.client.clientEmail = _dataCli.clientEmail;
        this.client.clientAddress = _dataCli.clientAddress;
        this.client.clientCode =  _dataCli.clientCode;
        this.client.clientTaxDocument = _dataCli.clientTaxDocument;        
        this.tipoCliente = _dataCli.clientType;
        this.client.clientType = _dataCli.clientType;

        this.viewBilling();
        this.loading = false;
        this.isDisabled =false;

        console.log('Cliente -> ', this.client);
        console.log('Tipo Cliente -> ', this.tipoCliente);
  
        this.insertNewClient = false;
        this.recurrentClient = true;
      }

      // this.isParamsUrl = true;
      // this.code = this.url.queryParams['nit'];
      // await this.buscarNITLocal(event, this.code);

      if (this.url.queryParams['transfer']) {
        let transfer = this.url.queryParams['transfer'];
        await this.cargarTrasnferencia(transfer);
      }

    } 
  }

  async onItemSelectPaymet(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItemPayment = await this.PaymentList.find(x => x.id == item.id);
    console.log(_selectedItemPayment);

    this.tipoPago = item.payMethodBusinessCode;
    console.log('Tipo Pago -> ', this.tipoPago);

    this.PaymentType = this.tipoPago;
    this.NamePaymentType = item.payMethodName;

    // this.itemCode = item.idPayment;
    // this.invoiceSpecialDetail.invoiceSpecialDetailDescription = item.itemNamePayment;
    // this.stock = _selectedItem.inventoryQuantity;
    // this.Minstock = _selectedItem.inventoryMin;
  }

  getPaymetList(code) {
    return this._storeService.getPaymets(code).then(
      response => {
        if (response.result) {
          this.PaymentList = response.records;
          // console.log("paymentList ->" + JSON.stringify(response.records));
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getSelectSettingPaymet(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItemsPayment = [selected] : '';

    this.dropdownSettingsPayment = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'payMethodBusinessCode',
      textField: 'payMethodName'
    };
  }

  async addPaymetDetail(){

    this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment + (this.MontoPayment))).toFixed(6));
    console.log(this.MontoTotalPayment);

    await this.PaymentLine.push({
      typePayment: this.PaymentType,
      NamePaymentType: this.NamePaymentType,
      MontoPayment: this.MontoPayment,
      Autorizacion: this.Autorizacion
    });

    this.MontoPayment = 0;
    this.Autorizacion = "0";
    this.selectedItemsPayment = [];

    this.Pagado();

    console.log(this.PaymentLine);
  }

  async deletePaymetDetail(item) {

    await this.PaymentLine.forEach(async (e, index) => {
      if (e.typePayment === item) {

        this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment - (e.MontoPayment))).toFixed(6));        
        this.PaymentLine.splice(index, 1);
      }
    })

    this.Pagado();
        
    console.log(this.PaymentLine);
  }

  async Pagado(){

    if(this.company.companyPaymentType == 1){
      this.isMethodPay = true;
      if((this.MontoTotalPayment == this.invoiceSpecialHeader.invoiceSpecialTotal) && this.MontoTotalPayment > 0){
        this.isDisabledinvoiceSpecial = false;
      }
      else if(this.MontoTotalPayment > this.invoiceSpecialHeader.invoiceSpecialTotal){
        this.isDisabledinvoiceSpecial = true;
        this._utilsService.showNotification(2,"Total pagado es mayor al Total de la factura")
      }
      else{
        this.isDisabledinvoiceSpecial = true;
      }
    }
    else{
      this.isMethodPay = false;
      this.isDisabledinvoiceSpecial = false;
    }
  }

  /////////////////////FUNCTIONS SALES PERSONS/////////////////////////////////
  getSalesPList(code) {    
    return this._storeService.getSalesPerson(code,1).then(
      response => {
        if (response.result) {
          this.SalesPList = response.records;
          console.log('vendedores', this.SalesPList);
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  async onItemSelectSalesP(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItemSalesP = await this.SalesPList.find(x => x.id == item.id);
    console.log(_selectedItemSalesP);

    this.SalesPerson = item.salesPersonCodeUser;
    console.log('SalesPerson -> ', this.SalesPerson);
  }

  getSelectSettingSalesP(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItemsSalesP = [selected] : '';

    this.dropdownSettingsSalesP = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'salesPersonCodeUser',
      textField: 'salesPersonName'
    };

    // this.dropdownSettingsSalesP = {
    //   singleSelection: true,
    //   text: "Seleccione...",
    //   searchPlaceholderText: 'Buscar',
    //   noDataLabel: "Resultados...",
    //   enableCheckAll: false,
    //   enableSearchFilter: true,
    //   lazyLoading: true,
    //   idField: 'salesPersonCodeUser',
    //   textField: 'salesPersonName',
    //   searchBy: ['salesPersonCodeUser', 'salesPersonName']
    // };
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.inventoryList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  /////////////////////AGREGAR LINEA DESCRIPCION AL DETALLE FACTURA///////////////////////////
  saveLineinvoiceSpecialDesc() {
    if (this.invoiceSpecialDetail.invoiceSpecialDetailDescription != "" && this.invoiceSpecialDetail.invoiceSpecialDetailQuantity > 0 && this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice > 0) {
      //Aumentar Codigo si no viene uno
      // if (this.itemCode == "I") {
      //   this.itemNumberCode++;
      //   this.itemCode += this.itemNumberCode;
      // }

      //Calcular subtotal de linea
      this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice;

      if (this.invoiceSpecialDetail.invoiceSpecialDetailDiscount != undefined && this.invoiceSpecialDetail.invoiceSpecialDetailDiscount != null && this.invoiceSpecialDetail.invoiceSpecialDetailDiscount > 0) {
        this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - this.invoiceSpecialDetail.invoiceSpecialDetailDiscount;
        this.invoiceSpecialDetail.invoiceSpecialDetailTax = this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - (this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal / this.taxToPay);
      } else {
        this.invoiceSpecialDetail.invoiceSpecialDetailDiscount = 0;
      }

      //Actualizar
      // this.invoiceSpecialLine.forEach((e) => {
      //   console.log('actualiza descripcion');
      //   if (e.itemCode == this.itemCode) {

      //     this.invoiceSpecialHeader.invoiceSpecialTotal -= e.total;

      //     e.itemCode = 0;
      //     e.measure = "UNI";
      //     e.taxCode = 1;
      //     e.taxName = "IVA";
      //     e.isService = this.invoiceSpecialDetail.invoiceSpecialDetailType;
      //     e.quantity = parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailQuantity.toFixed(6));
      //     e.description = this.invoiceSpecialDetail.invoiceSpecialDetailDescription;
      //     e.unitPrice = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice).toFixed(6));
      //     e.price = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + this.invoiceSpecialDetail.invoiceSpecialDetailDiscount).toFixed(6));
      //     e.discount = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailDiscount).toFixed(6));
      //     e.taxAmount = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(6));
      //     e.taxableAmount = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(6));
      //     e.total = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal).toFixed(6));

      //     // this.total = this.total + this.subTotal;
      //     this.addAmount = false;
      //   }
      // })

      //Insertar
      if (this.addAmount == true) {
        console.log('Inserta descripcion');
        this.invoiceSpecialLine.push({
          subsidio: this.subsidioTemp,
          isOil: this.isOil,
          taxOilUP: this.idp,
          taxOilAmount: this.subIdp,
          taxOilName: this.petroleo,
          taxOilCode: this.taxOilCode,
          taxOilQuantity: this.invoiceSpecialDetail.invoiceSpecialDetailQuantity,          
          itemCode: 0,//this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: "B",
          quantity: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailQuantity.toFixed(4)),
          description: this.invoiceSpecialDetail.invoiceSpecialDetailDescription,
          unitPrice: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice.toFixed(4)),
          price: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + this.invoiceSpecialDetail.invoiceSpecialDetailDiscount).toFixed(4)),
          discount: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailDiscount.toFixed(4)),
          taxAmount: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(4)),
          taxableAmount: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(4)),
          total: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal.toFixed(4))
        });
      }
      // console.log(this.invoiceSpecialLine);
      // Sumar Total
      this.invoiceSpecialHeader.invoiceSpecialTotal += this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal;
      this.invoiceSpecialHeader.invoiceSpecialTax += this.invoiceSpecialDetail.invoiceSpecialDetailTax;
      this.cleanLineinvoiceSpecial();
    }

    this.ValidarCF();
    this.Pagado();

    this.isDisabled = false;
  }

  // CALCULAR IMPUESTO DE LINEA
  calculateTax() {
    let subTotalLine;

    subTotalLine = (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice);

    if (this.invoiceSpecialDetail.invoiceSpecialDetailDiscount > 0) {
      if (this.invoiceSpecialDetail.invoiceSpecialDetailDiscount <= subTotalLine) {
        subTotalLine = subTotalLine - this.invoiceSpecialDetail.invoiceSpecialDetailDiscount;
      } else {
        this._utilsService.showNotification(2, "Cantidad No permitida");
        this.invoiceSpecialDetail.invoiceSpecialDetailDiscount = 0;
      }
    }

    let tax;
    tax = (subTotalLine - (subTotalLine / this.taxToPay)).toFixed(6);
    this.invoiceSpecialDetail.invoiceSpecialDetailTax = parseFloat(tax);
  }

  public SKUx: string;
  async CargarSKU() {
    console.log('----Cargar inventory---', this.inventoryList);
    var sku;
    sku = this.SKUx;
    //alert(sku);

    const SKUs = this.inventoryList.find(({ inventoryBusinessCode }) => inventoryBusinessCode === sku);
    console.log('SKU Buscado', SKUs);
    //console.log('SKU Buscado STOCK', SKUs.stock);

    if(SKUs == undefined){
      this._utilsService.showNotification(2, 'Producto no disponible, validar stock en inventario');
    }
    else{

      if(sku.length > 3){
        //const _selectedItem = await this.inventoryList.find(x => x.id == sku);
        const _selectedItem = SKUs
        console.log('----_selectedItem---', _selectedItem);
  
        //SI ES PETROLIO
        this.idp = 0;
        this.subIdp = 0;
        this.subsidioTemp = 0;
        this.isOil = null;
        this.petroleo = null;
        if (_selectedItem.inventoryProduct == 3) {
          this.isOil = 1
          this.idp = 4.7;
          this.taxOilCode = 1;
          this.petroleo = "PETROLEO";
        } else if (_selectedItem.inventoryProduct == 4) {
          this.idp = 4.6;
          this.subsidioTemp = 2.5;
          this.taxOilCode = 2;
          this.petroleo = "PETROLEO";
        } else if (_selectedItem.inventoryProduct == 5) {
          this.idp = 1.3;
          this.subsidioTemp = 5;
          this.taxOilCode = 4;
          this.petroleo = "PETROLEO";
        }
  
        this.itemCode = _selectedItem.inventoryCode;
        this.invoiceSpecialDetail.invoiceSpecialDetailDescription = _selectedItem.itemName;
        this.invoiceSpecialDetail.invoiceSpecialDetailType = 'B';
        this.stock = _selectedItem.inventoryQuantity;
        this.Minstock = _selectedItem.inventoryMin;
  
        if (_selectedItem.inventorySerialFlag == 1) {
          this.loading = true;
          this.serialSelectedTemp = [];
          this.serialList = [];
          const response = await this._inventoryService.getItemSerial(this.itemCode);
          this.serialList = response.records[1];
  
          this.serial = true;
          this.loading = false;
        } else {
          this.serial = false
        }
  
        await this.saveLineinvoiceSpecial();
        this.SKUx = "";      
      }

    }    
    
  }

  public jumpSerial: boolean;
  async cargarTrasnferencia(code) {
    this.loading = true;
    this.isDisabled = true;
    this.disabled = true;

    await this._warehouseService.getOneTransfer(code).then(
      response => {
        this.transferCode = response.records[0].transferCode;
        this.transferDetail = response.records[0].detail;
      });

    for (const e of this.transferDetail) {
      const _selectedItem = await this.inventoryList.find(x => x.id == parseFloat(e.transferInventoryDetailInventoryCode));
      this.invoiceSpecialDetail.invoiceSpecialDetailType = 'B';
      this.itemCode = e.transferInventoryDetailInventoryCode;
      this.invoiceSpecialDetail.invoiceSpecialDetailQuantity = parseFloat(e.transferInventoryDetailQuantity);
      this.invoiceSpecialDetail.invoiceSpecialDetailDescription = _selectedItem.inventoryBusinessCode + ' - ' + e.transferInventoryDetailDescription;
      this.stock = _selectedItem.inventoryQuantity;
      this.priceType = 1;

      // Nuevo
      if (parseFloat(e.transferDetailType) === 1) {
        await this.saveLineinvoiceSpecial();
      } else {
        let series = await this._warehouseService.getOneSerial(this.transferCode, e.transferInventoryDetailInventoryCode);
        for (const i of series.records[0].detail) {
          await this.serialSelected.push({
            serialCode: parseFloat(i.trasferSerialDetailSerialCode),
            inventorySerialSerial: i.trasferSerialDetailDescription
          });
          await this.serialSelectedTemp.push({
            serialCode: parseFloat(i.trasferSerialDetailSerialCode),
            inventorySerialSerial: i.trasferSerialDetailDescription
          });
        }
        this.jumpSerial = true;
        await this.saveLineinvoiceSpecialSerial();
        this.serialSelectedTemp = [];
      }
      this.jumpSerial = true;
    };

    this.loading = false;
    this.isDisabled = false;
    this.disabled = false;
  }

  onChangepaymentType(event) {
    this.invoiceSpecialHeader.paymentType = event;
    this.tipoPago = event;
    // console.log(this.tipoPago);
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
          this.company.companyTaxToPay == 12 ? this.taxToPay = 1.12 : this.taxToPay = 1.05;
          this.company.companyTaxToPay == 12 ? this.regimenMessage = "Sujeto a pagos trimestrales ISR" : this.regimenMessage = "*No genera derecho a crédito fiscal";
          this.company.companyLogo != 'undefined' ? this.companyLogoUrl = this.company.companyLogoUrl : '';
          console.log('Company -> ', this.company);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      response => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////SELECT///////////////////////
  getSelectSetting(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'itemName'
    };

    // this.dropdownSettingsProduct = {
    //   singleSelection: true,
    //   text: "Seleccione...",
    //   searchPlaceholderText: 'Buscar',
    //   noDataLabel: "Resultados...",
    //   enableCheckAll: false,
    //   enableSearchFilter: true,
    //   lazyLoading: true,
    //   idField: 'id',
    //   textField: 'itemName',
    //   searchBy: ['id', 'itemName']
    // };
  }

  getSelectSettingSerial(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsSerial = [selected] : '';

    this.dropdownSettingsSerial = {
      idField: 'InventorySerialCode',
      textField: 'inventorySerialSerial',
      itemsShowLimit: 3,
      enableCheckAll: false,
      singleSelection: false,
      allowSearchFilter: true,
      searchPlaceholderText: "Buscar",
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  getInventoryList(code) {
    this.inventoryList = [];
    return this._inventoryService.getAllByStore(code).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  serial = false;
  async onItemSelect(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItem = await this.inventoryList.find(x => x.id == item.id);

    //SI ES PETROLIO
    this.idp = 0;
    this.subIdp = 0;
    this.subsidioTemp = 0;
    this.isOil = null;
    this.petroleo = null;
    if (_selectedItem.inventoryProduct == 3) {
      this.isOil = 1
      this.idp = 4.7;
      this.taxOilCode = 1;
      this.petroleo = "PETROLEO";
    } else if (_selectedItem.inventoryProduct == 4) {
      this.idp = 4.6;
      this.subsidioTemp = 2.5;
      this.taxOilCode = 2;
      this.petroleo = "PETROLEO";
    } else if (_selectedItem.inventoryProduct == 5) {
      this.idp = 1.3;
      this.subsidioTemp = 5;
      this.taxOilCode = 4;
      this.petroleo = "PETROLEO";
    }

    this.itemCode = item.id;
    this.invoiceSpecialDetail.invoiceSpecialDetailDescription = item.itemName;
    this.invoiceSpecialDetail.invoiceSpecialDetailType = 'B';
    this.stock = _selectedItem.inventoryQuantity;
    this.Minstock = _selectedItem.inventoryMin;

    if (_selectedItem.inventorySerialFlag == 1) {
      this.loading = true;
      this.serialSelectedTemp = [];
      this.serialList = [];
      const response = await this._inventoryService.getItemSerial(this.itemCode);
      this.serialList = response.records[1];

      this.serial = true;
      this.loading = false;
    } else {
      this.serial = false
    }
  }

  onItemSelectSerial(item: any) {
    this.serialSelected.push({
      serialCode: item.InventorySerialCode,
      inventorySerialSerial: item.inventorySerialSerial
    });

    this.serialSelectedTemp.push({
      serialCode: item.InventorySerialCode,
      inventorySerialSerial: item.inventorySerialSerial
    });
  }

  async onItemDeSelectSerial(item: any) {
    this.serialSelected.forEach((e, index) => {
      if (e.serialCode == item.InventorySerialCode)
        this.serialSelected.splice(index, 1);
    });

    this.serialSelectedTemp.forEach((i, indece) => {
      if (i.serialCode == item.InventorySerialCode)
        this.serialSelectedTemp.splice(indece, 1);
    });
  }

  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  onChange(event) {
    this.invoiceSpecialHeader.invoiceSpecialCoin = event;

    if (this.invoiceSpecialHeader.invoiceSpecialCoin == 0) {
      this.moneda = "Q";
      this.invoiceSpecialHeader.invoiceSpecialCurrency = 'GTQ';
      this.invoiceSpecialHeader.invoiceSpecialExchangeRate = 0;
    } else if (this.invoiceSpecialHeader.invoiceSpecialCoin == 1) {
      this.moneda = "$"
      this.invoiceSpecialHeader.invoiceSpecialCurrency = 'USD';
    }
  }

  ///////////////////////FUNCTION CHANGE PRICE///////////////////////
  onChangePrice(event) {
    this.priceType = parseFloat(event);
  }

  ///////////////////////FUNCTION VALIDATE DATE///////////////////////
  validateDate($event) {
    $event.preventDefault();
    var valid = false;
    var now = this.nowDay.split('-');
    var date = this.invoiceSpecialHeader.invoiceSpecialDate.split('-');

    if (date[0] == now[0] || parseFloat(date[0]) == parseFloat(now[0]) - 1) {
      if (date[1] == now[1]) {
        if (parseFloat(date[2]) <= parseFloat(now[2]) + 5 && parseFloat(date[2]) >= parseFloat(now[2]) - 5) {
          valid = true;
        }
      }
      else if (parseFloat(date[1]) == parseFloat(now[1]) - 1 || parseFloat(date[1]) == 12) {
        if (parseFloat(date[2]) >= 26) {
          valid = true;
        }
      }
    }

    if (valid == false) {
      this._utilsService.showNotification(2, 'Fecha fuera de rango')
      this.invoiceSpecialHeader.invoiceSpecialDate = this.nowDay;
    };
  }

  ///////////////////////FUNCTION CHANGE INTERAL SERIAL///////////////////////
  onChangeInternSerial(event) {
    this.invoiceSpecialHeader.invoiceSpecialInternSerial = event;
  }

  // GUARDAR UNA LINEA DE COMPRA
  async saveLineinvoiceSpecial() {
    this.isDisabled = true;
    this.disabled = true;

    if(this.company.companyCode == 24){ //Manejo de decimales ETISA

      if (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity > 0) {

        //Actualizar?
        let update = false;
        console.log("---invoiceSpecialLine---");
        console.log(this.invoiceSpecialLine);
        console.log("---invoiceSpecialDetail---");
        console.log(this.invoiceSpecialDetail);
        console.log("---itemCode---");
        console.log(this.itemCode);
        for await (const e of this.invoiceSpecialLine) {
          if (e.itemCode == this.itemCode) {
            //Validacion
            let quantity;
            this.isEdit === true ? quantity = this.invoiceSpecialDetail.invoiceSpecialDetailQuantity : quantity = e.quantity + this.invoiceSpecialDetail.invoiceSpecialDetailQuantity;
  
            const response = await this._inventoryService.getPriceByItem(this.itemCode, quantity);
            switch (this.priceType) {
              case 1: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.precio;
                break;
              case 2: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryPriceDiscount;
                break;
              case 3: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventorySalePrice;
                break;
              case 4: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryminprice;
                break;
            }
  
            if (this.company.companyExistenceFlag === 1 || quantity <= this.stock) {
              //Restar Encabezado
              this.subsidio -= e.subsidio;            
              this.invoiceSpecialHeader.invoiceSpecialIDP -= parseFloat((e.taxOilAmount).toFixed(6));
              this.invoiceSpecialHeader.invoiceSpecialTotal -= parseFloat((e.total).toFixed(6));
              this.invoiceSpecialHeader.invoiceSpecialTax -= parseFloat((e.taxAmount).toFixed(6));
  
              //Realizar Calculos
              if (parseFloat(e.discount) > 0) {
                this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = parseFloat((quantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice).toFixed(6));
                this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - e.discount).toFixed(6));
                this.invoiceSpecialDetail.invoiceSpecialDetailTax = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - (this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal / this.taxToPay)).toFixed(6));
              } else {
                this.invoiceSpecialDetail.invoiceSpecialDetailDiscount = e.discount;
                this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = quantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice;
                this.invoiceSpecialDetail.invoiceSpecialDetailTax = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - (this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal / this.taxToPay)).toFixed(6));
              }
  
              //Editar Linea
              e.quantity = quantity;
              e.unitPrice = this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice;
              e.price = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal).toFixed(6));
              e.taxAmount = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(6));
              e.taxableAmount = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(6));
              e.taxOilAmount = parseFloat((quantity * this.idp).toFixed(6)),
              e.total = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (parseFloat((quantity * this.idp).toFixed(6)))).toFixed(6)),
              e.taxOilQuantity = quantity;
              e.subsidio = (quantity * this.subsidioTemp);
  
              //Sumar Encabezado 
              this.subsidio += e.subsidio;
              this.invoiceSpecialHeader.invoiceSpecialIDP += parseFloat((quantity * this.idp).toFixed(6));
              this.invoiceSpecialHeader.invoiceSpecialTotal += parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (quantity * this.idp)).toFixed(6));
              this.invoiceSpecialHeader.invoiceSpecialTax += parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(6));
              this.selectedItems = [];
              this.cleanLineinvoiceSpecial();
            } else {
              this._utilsService.showNotification(2, 'Cantidad Excede Exitencia');
            }
            update = true;
            this.isEdit = false;
            this.addAmount = false;
            this.isDisabled = false;
            this.disabled = false;
          }
        };
  
        //Nuevo Item
        if (this.addAmount === true && update === false) {
          const response = await this._inventoryService.getPriceByItem(this.itemCode, this.invoiceSpecialDetail.invoiceSpecialDetailQuantity);
          switch (this.priceType) {
            case 1: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.precio;
              break;
            case 2: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryPriceDiscount;
              break;
            case 3: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventorySalePrice;
              break;
            case 4: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryminprice;
              break;
          }
  
          this.subIdp = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp).toFixed(6));
          this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice).toFixed(6));
          this.invoiceSpecialDetail.invoiceSpecialDetailTax = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - (this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal / this.taxToPay)).toFixed(6));
  
          console.log('linea subtotal ', this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal);
          console.log('linea unit ', this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice);
          //Validacion
          if (this.company.companyExistenceFlag === 1 || this.invoiceSpecialDetail.invoiceSpecialDetailQuantity <= this.stock) {                     
  
            //Guardar Linea
            await this.invoiceSpecialLine.push({
              //Area de combustible
              subsidio: this.subsidioTemp,
              isOil: this.isOil,
              taxOilUP: this.idp,
              taxOilAmount: this.subIdp,
              taxOilName: this.petroleo,
              taxOilCode: this.taxOilCode,
              taxOilQuantity: this.invoiceSpecialDetail.invoiceSpecialDetailQuantity,
              itemCode: this.itemCode,
              measure: "UNI",
              taxCode: 1,
              taxName: "IVA",
              isService: this.invoiceSpecialDetail.invoiceSpecialDetailType,
              quantity: this.invoiceSpecialDetail.invoiceSpecialDetailQuantity,
              description: this.invoiceSpecialDetail.invoiceSpecialDetailDescription,
              discount: 0,
              unitPrice: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice.toFixed(6)),
              price: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal).toFixed(6)),
              taxAmount: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(6)),
              taxableAmount: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(6)),
              total: parseFloat(((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp))).toFixed(6)),
            });
            this.subsidio += (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.subsidioTemp);
            this.invoiceSpecialHeader.invoiceSpecialIDP += parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp).toFixed(6));
            this.invoiceSpecialHeader.invoiceSpecialTotal += parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp).toFixed(6)))).toFixed(6));
            this.invoiceSpecialHeader.invoiceSpecialTax += parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(6));
            this.selectedItems = [];
            this.cleanLineinvoiceSpecial();
          } else {
            this._utilsService.showNotification(2, 'Cantidad Excede Exitencia');
          }
          this.isDisabled = false;
          this.disabled = false;
        }
      } else {
        this._utilsService.showNotification(2, "Ingresa una cantidad")
      }
      this.addAmount = true;
      this.isDisabled = false;
      this.disabled = false;
      this.ValidarCF();

    }
    else{ //Resto de empresas que no manejan decimales

      if (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity > 0) {

        //Actualizar?
        let update = false;
        console.log("---invoiceSpecialLine---");
        console.log(this.invoiceSpecialLine);
        console.log("---invoiceSpecialDetail---");
        console.log(this.invoiceSpecialDetail);
        console.log("---itemCode---");
        console.log(this.itemCode);
        for await (const e of this.invoiceSpecialLine) {
          if (e.itemCode == this.itemCode) {
            //Validacion
            let quantity;
            this.isEdit === true ? quantity = this.invoiceSpecialDetail.invoiceSpecialDetailQuantity : quantity = e.quantity + this.invoiceSpecialDetail.invoiceSpecialDetailQuantity;
  
            const response = await this._inventoryService.getPriceByItem(this.itemCode, quantity);
            switch (this.priceType) {
              case 1: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.precio;
                break;
              case 2: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryPriceDiscount;
                break;
              case 3: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventorySalePrice;
                break;
              case 4: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryminprice;
                break;
            }
  
            if (this.company.companyExistenceFlag === 1 || quantity <= this.stock) {
              //Restar Encabezado
              this.subsidio -= e.subsidio;            
              this.invoiceSpecialHeader.invoiceSpecialIDP -= parseFloat((e.taxOilAmount).toFixed(4));
              this.invoiceSpecialHeader.invoiceSpecialTotal -= parseFloat((e.total).toFixed(4));
              this.invoiceSpecialHeader.invoiceSpecialTax -= parseFloat((e.taxAmount).toFixed(4));
  
              //Realizar Calculos
              if (parseFloat(e.discount) > 0) {
                this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = parseFloat((quantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice).toFixed(4));
                this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - e.discount).toFixed(4));
                this.invoiceSpecialDetail.invoiceSpecialDetailTax = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - (this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal / this.taxToPay)).toFixed(4));
              } else {
                this.invoiceSpecialDetail.invoiceSpecialDetailDiscount = e.discount;
                this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = quantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice;
                this.invoiceSpecialDetail.invoiceSpecialDetailTax = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - (this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal / this.taxToPay)).toFixed(4));
              }
  
              //Editar Linea
              e.quantity = quantity;
              e.unitPrice = this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice;
              e.price = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal).toFixed(4));
              e.taxAmount = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(4));
              e.taxableAmount = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(4));
              e.taxOilAmount = parseFloat((quantity * this.idp).toFixed(4)),
              e.total = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (parseFloat((quantity * this.idp).toFixed(4)))).toFixed(4)),
              e.taxOilQuantity = quantity;
              e.subsidio = (quantity * this.subsidioTemp);
  
              //Sumar Encabezado 
              this.subsidio += e.subsidio;
              this.invoiceSpecialHeader.invoiceSpecialIDP += parseFloat((quantity * this.idp).toFixed(4));
              this.invoiceSpecialHeader.invoiceSpecialTotal += parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (quantity * this.idp)).toFixed(4));
              this.invoiceSpecialHeader.invoiceSpecialTax += parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(4));
              this.selectedItems = [];
              this.cleanLineinvoiceSpecial();
            } else {
              this._utilsService.showNotification(2, 'Cantidad Excede Exitencia');
            }
            update = true;
            this.isEdit = false;
            this.addAmount = false;
            this.isDisabled = false;
            this.disabled = false;
          }
        };
  
        //Nuevo Item
        if (this.addAmount === true && update === false) {
          const response = await this._inventoryService.getPriceByItem(this.itemCode, this.invoiceSpecialDetail.invoiceSpecialDetailQuantity);
          switch (this.priceType) {
            case 1: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.precio;
              break;
            case 2: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryPriceDiscount;
              break;
            case 3: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventorySalePrice;
              break;
            case 4: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryminprice;
              break;
          }
  
          this.subIdp = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp).toFixed(4));
          this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice).toFixed(4));
          this.invoiceSpecialDetail.invoiceSpecialDetailTax = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - (this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal / this.taxToPay)).toFixed(4));
  
          console.log('linea subtotal ', this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal);
          console.log('linea unit ', this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice);
          //Validacion
          if (this.company.companyExistenceFlag === 1 || this.invoiceSpecialDetail.invoiceSpecialDetailQuantity <= this.stock) {
  
            let DifInventario = this.stock - this.invoiceSpecialDetail.invoiceSpecialDetailQuantity;

            console.log(DifInventario, this.stock, this.invoiceSpecialDetail.invoiceSpecialDetailQuantity);
            console.log(this.Minstock);   

            if(this.company.companyExistenceFlag === 0 && DifInventario <= this.Minstock){
              this._utilsService.showNotification(2, 'El STOCK MINIMO ha llegado a su limite configurado.');
              //enviar correo
            }

            //Guardar Linea
            await this.invoiceSpecialLine.push({
              //Area de combustible
              subsidio: this.subsidioTemp,
              isOil: this.isOil,
              taxOilUP: this.idp,
              taxOilAmount: this.subIdp,
              taxOilName: this.petroleo,
              taxOilCode: this.taxOilCode,
              taxOilQuantity: this.invoiceSpecialDetail.invoiceSpecialDetailQuantity,
              itemCode: this.itemCode,
              measure: "UNI",
              taxCode: 1,
              taxName: "IVA",
              isService: this.invoiceSpecialDetail.invoiceSpecialDetailType,
              quantity: this.invoiceSpecialDetail.invoiceSpecialDetailQuantity,
              description: this.invoiceSpecialDetail.invoiceSpecialDetailDescription,
              discount: 0,
              unitPrice: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice.toFixed(4)),
              price: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal).toFixed(4)),
              taxAmount: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(4)),
              taxableAmount: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(4)),
              total: parseFloat(((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp))).toFixed(4)),
            });
            this.subsidio += (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.subsidioTemp);
            this.invoiceSpecialHeader.invoiceSpecialIDP += parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp).toFixed(4));
            this.invoiceSpecialHeader.invoiceSpecialTotal += parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp).toFixed(4)))).toFixed(4));
            this.invoiceSpecialHeader.invoiceSpecialTax += parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(6));
            this.selectedItems = [];
            this.cleanLineinvoiceSpecial();
          } 
          else {
            this._utilsService.showNotification(2, 'Cantidad Excede Exitencia');
          }
          this.isDisabled = false;
          this.disabled = false;
        }
      } else {
        this._utilsService.showNotification(2, "Ingresa una cantidad")
      }
      this.addAmount = true;
      this.isDisabled = false;
      this.disabled = false;
      this.ValidarCF();
      this.Pagado();

    }

    
  }

  // GUARDAR LINEA CON SERIES
  async saveLineinvoiceSpecialSerial() {
    this.isDisabled = true;
    this.disabled = true;    

    let res_find = await this.invoiceSpecialLine.find(x => x.itemCode === this.itemCode);

    if (!res_find) {
      if (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity == this.serialSelectedTemp.length) {

        if (!this.jumpSerial) {
          this.invoiceSpecialDetail.invoiceSpecialDetailDescription += ' SERIES: ';
          for await (const e of this.serialSelectedTemp) {
            this.invoiceSpecialDetail.invoiceSpecialDetailDescription += e.inventorySerialSerial + ', ';
          }
        }

        const response = await this._inventoryService.getPriceByItem(this.itemCode, this.invoiceSpecialDetail.invoiceSpecialDetailQuantity);
        switch (this.priceType) {
          case 1: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.precio;
            break;
          case 2: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryPriceDiscount;
            break;
          case 3: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventorySalePrice;
            break;
          case 4: this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice = response.records.inventoryminprice;
            break;
        }

        this.subIdp = 0;
        this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice).toFixed(4));
        this.invoiceSpecialDetail.invoiceSpecialDetailTax = parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - (this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal / this.taxToPay)).toFixed(4));

        await this.invoiceSpecialLine.push({
          //Area de Serie
          serial: this.serialSelectedTemp,
          //Area de combustible
          isOil: this.isOil,
          taxOilUP: this.idp,
          taxOilAmount: this.subIdp,
          taxOilName: this.petroleo,
          taxOilCode: this.taxOilCode,
          taxOilQuantity: this.invoiceSpecialDetail.invoiceSpecialDetailQuantity,
          itemCode: this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: this.invoiceSpecialDetail.invoiceSpecialDetailType,
          quantity: this.invoiceSpecialDetail.invoiceSpecialDetailQuantity,
          description: this.invoiceSpecialDetail.invoiceSpecialDetailDescription,
          discount: 0,
          unitPrice: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailUnitPrice.toFixed(4)),
          price: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal).toFixed(4)),
          taxAmount: parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(4)),
          taxableAmount: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal - this.invoiceSpecialDetail.invoiceSpecialDetailTax).toFixed(4)),
          total: parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp)).toFixed(4)),
        });
        this.invoiceSpecialHeader.invoiceSpecialIDP += parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp).toFixed(4));
        this.invoiceSpecialHeader.invoiceSpecialTotal += parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailSubtotal + (parseFloat((this.invoiceSpecialDetail.invoiceSpecialDetailQuantity * this.idp).toFixed(4)))).toFixed(4));
        this.invoiceSpecialHeader.invoiceSpecialTax += parseFloat(this.invoiceSpecialDetail.invoiceSpecialDetailTax.toFixed(4));
        this.cleanLineinvoiceSpecial();

      } else {
        this._utilsService.showNotification(2, 'Por favor revisa las cantidad de series seleccionadas');
      }
    } else {
      this._utilsService.showNotification(3, 'Item ya ingresado, por favor eliminalo antes.');
    }
    this.isDisabled = false;
    this.disabled = false;
  }

  // APLICAR DESCUENTO EN TIEMPO REAL
  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    if(this.company.companyCode == 24){ //Manejo de decimales ETISA
      
      this.invoiceSpecialLine.forEach((e) => {
        if (e.itemCode == item) {
          let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);
  
          if (parseFloat(descuento) <= temporal) {
            this.invoiceSpecialHeader.invoiceSpecialTotal = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal - parseFloat(e.total)).toFixed(6));
            this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax - parseFloat(e.taxAmount)).toFixed(6));
            e.total = parseFloat((temporal - parseFloat(descuento)).toFixed(6));
            e.taxAmount = parseFloat((parseFloat(e.total) - (parseFloat(e.total) / this.taxToPay)).toFixed(6));
            e.taxableAmount = parseFloat((parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(6));
            this.invoiceSpecialHeader.invoiceSpecialTotal = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal + parseFloat(e.total)).toFixed(6));
            this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax + parseFloat(e.taxAmount)).toFixed(6));
          } else {
            this._utilsService.showNotification(2, "Cantidad No permitida");
          }
        }
      });
      
    }
    else{
      this.invoiceSpecialLine.forEach((e) => {
        if (e.itemCode == item) {
          let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

          let DifInventario = this.stock - e.quantity;

          if(this.company.companyExistenceFlag === 0 && DifInventario <= this.Minstock){
            this._utilsService.showNotification(2, 'El STOCK MINIMO ha llegado a su limite configurado.');
            //enviar correo
          }
  
          if (parseFloat(descuento) <= temporal) {
            this.invoiceSpecialHeader.invoiceSpecialTotal = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal - parseFloat(e.total)).toFixed(4));
            this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax - parseFloat(e.taxAmount)).toFixed(4));
            e.total = parseFloat((temporal - parseFloat(descuento)).toFixed(4));
            e.taxAmount = parseFloat((parseFloat(e.total) - (parseFloat(e.total) / this.taxToPay)).toFixed(4));
            e.taxableAmount = parseFloat((parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(4));
            this.invoiceSpecialHeader.invoiceSpecialTotal = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal + parseFloat(e.total)).toFixed(4));
            this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax + parseFloat(e.taxAmount)).toFixed(4));
          } else {
            this._utilsService.showNotification(2, "Cantidad No permitida");
          }
        }
      });
    }    

    this.ValidarCF();
  }

  ApplyQuantity($event, item, cantidad){
    $event.preventDefault();

    this.invoiceSpecialLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (cantidad > 0) {
          this.invoiceSpecialHeader.invoiceSpecialTotal = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal - parseFloat(e.total)).toFixed(4));
          this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax - parseFloat(e.taxAmount)).toFixed(4));
          e.total = parseFloat((temporal).toFixed(4));
          e.taxAmount = parseFloat((parseFloat(e.total) - (parseFloat(e.total) / this.taxToPay)).toFixed(4));
          e.taxableAmount = parseFloat((parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(4));
          this.invoiceSpecialHeader.invoiceSpecialTotal = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal + parseFloat(e.total)).toFixed(4));
          this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax + parseFloat(e.taxAmount)).toFixed(4));
        } else {
          this._utilsService.showNotification(2, "Cantidad No permitida");
        }
      }
    });

    this.ValidarCF();    
  }

  // ELIMINAR UNA LINEA
  async deleteLine(item) {
    this.isDisabled = true;
    this.disabled = true;

    await this.invoiceSpecialLine.forEach(async (e, index) => {
      if (e.itemCode === item) {

        if (e.taxOilName != undefined) {          
          this.subsidio -= parseFloat(e.subsidio);
          this.invoiceSpecialHeader.invoiceSpecialIDP = this.invoiceSpecialHeader.invoiceSpecialIDP - parseFloat(e.taxOilAmount);
        }

        if (e.serial) {
          for (const i of e.serial) {
            let indice = this.serialSelected.findIndex(x => x.serialCode === i.serialCode);
            this.serialSelected.splice(indice, 1);
          };
        }

        this.invoiceSpecialHeader.invoiceSpecialTotal = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal - parseFloat(e.price)).toFixed(4));
        this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax - parseFloat(e.taxAmount)).toFixed(4));
        this.invoiceSpecialLine.splice(index, 1);
      }
    })
    this.cleanLineinvoiceSpecial();
    this.isDisabled = false;
    this.disabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineinvoiceSpecial() {
    this.jumpSerial = false;
    this.isDisabled = false;
    this.disabled = false;
    this.addAmount = true;
    this.priceType = 1;
    this.isEdit = false;
    this.serial = false;
    this.selectedItemsSerial = null;
    this.selectedItems = [];
    this.invoiceSpecialDetail = {} as InvoiceSpecialDetail;
    this.invoiceSpecialDetail.invoiceSpecialDetailQuantity = 1;
  }

  onChangeTypeClient(event) {    
    this.client.clientType = event;
    this.tipoCliente = event;
    console.log('----->', this.tipoCliente);

    if(event == 2){
      this.disabledEXT = false;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
    else{
      this.disabledEXT = true;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
  }

  ///////////////////////Funcion de Buscar por NIT///////////////////////
  buscarNITLocal($event, nit) {
    $event.preventDefault();
    this.isDisabled = true;
    this.disabled = true;
    this.loading = true;
    // console.log(this.client.clientType);

    if(this.client.clientType == 2){

      this.client.clientName = this.client.clientName;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientPhone = "00000000";
      this.client.clientEmail = "info@clever.com";
      this.client.clientAddress = "CIUDAD";

      this.insertNewClient = false;
      this.recurrentClient = false;
      this.viewBilling();
      this._utilsService.showNotification(4, "SE EMITIRA FACTURA CON PASAPORTE");
      this.loading = false;
      this.client.clientType = 2;
      this.tipoCliente = 2;

      this.isDisabled =false;

    }
    else{
      if(nit == "CF"){
        this.client.clientType = 0;
        this.tipoCliente = 0;
      }
      // return this._clientService.getOneByNit(nit).then(
      return this._clientService.getOneByCompanyNit(this.companyCode, nit).then(
        response => {
          // console.log(response.records)
          if (response.records.length > 0) {
            this.client = response.records[0];
            this.recurrentClient = true;
            this.insertNewClient = false;
            this.viewBilling();
            this._utilsService.showNotification(1, response.message);
          } else {            
            return this._clientService.searchNitSAT(nit, this.client.clientType).then(
              async response => {
                if (response.tipo_respuesta == 0) {
                  //this.client.clientName = response.nombre[0];
                  //console.log(response.nombre[0]);
                  this.client.clientName =  response.nombre[0];
                  this.client.clientStatus = 1;
                  this.client.clientDeleted = 0;
                  this.client.clientPhone = "00000000";
                  this.client.clientEmail = "info@clever.com";
                  this.client.clientAddress = "CIUDAD";

                  this.insertNewClient = true;
                  this.recurrentClient = false;
                  this.viewBilling();
                  this._utilsService.showNotification(4, "Cliente Nuevo");
                } else {
                  this._utilsService.showNotification(3, response.listado_errores[0].error[0].desc_error[0]);
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;

        this.ValidarCF();
      });
    }
  }  

  async insertinvoiceSpecial() {
    this.loading = true;
    this.isDisabled = true;
    this.disabled = true;

    
    const _data = JSON.parse(localStorage.getItem('data'));

    if(_data != undefined && !_data.isStoreOpen && this.companyCode == 22){
      swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se puede realizar una venta hasta que abra la caja....',
      })
      return;
    }    

    console.log('TipoCliente -> ', this.tipoCliente);


    //Crear Cliente en la DB
    if (this.insertNewClient) {
      // Resolver problema con DATA BASE
      let nombre;
      nombre = await this.client.clientName.replace(/&/g, '&amp;');
      nombre = await this.client.clientName.replace(/'/g, '&apos;');
      nombre = await this.client.clientName.replace(/"/g, '&quot;');

      this.client.clientName = nombre;
      this.client.clientCredit = 0;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientBlocked = 0;
      this.client.clientInterest = 0;
      this.client.clientDiscount = 0;
      this.client.clientCategory = 2;
      this.client.clientCreditTime = 0;
      this.client.clientCreditAmount = 0;
      this.client.clientCompanyCode = this.companyCode;
      this.client.clientType = this.tipoCliente;
      this.client.clientNameFrequent = '-';

      if (this.client.clientTaxDocument && this.client.clientName) {
        const response = await this._clientService.insert(this.client);
        this.client.clientCode = response.records[0].insertId;
      };
    }

    // Si es factura de pequeño contribuyente
    let transmitterType = "FESP";
    let transmitterMembership = "GEN";
    if (this.company.companyTaxToPay == 5) {
      transmitterType = "FPEQ";
      transmitterMembership = "PEQ";
    }

    if(this.company.companyCode == 24){ //ETISA
      if (this.invoiceSpecialHeader.invoiceSpecialIDP > 0) {
        this.invoiceSpecialHeader.invoiceSpecialTax = 0;
        this.invoiceSpecialLine.forEach(e => {
          this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax + e.taxAmount).toFixed(2))
        });
      } else {
        this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat(((this.invoiceSpecialHeader.invoiceSpecialTotal / this.taxToPay) * (this.taxToPay - 1)).toFixed(2))
      }
    }
    else{ //Resto de Empresas
      if (this.invoiceSpecialHeader.invoiceSpecialIDP > 0) {
        this.invoiceSpecialHeader.invoiceSpecialTax = 0;
        this.invoiceSpecialLine.forEach(e => {
          this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax + e.taxAmount).toFixed(4))
        });
      } else {
        this.invoiceSpecialHeader.invoiceSpecialTax = parseFloat(((this.invoiceSpecialHeader.invoiceSpecialTotal / this.taxToPay) * (this.taxToPay - 1)).toFixed(4))
      }
    }    

    const hour = await this._utilsService.dateTime('HH:mm:ss');

    let FormaPagoTexto = "";
    if(this.tipoPago == 0){
      FormaPagoTexto = "Efectivo";
    }
    else if(this.tipoPago == 1){
      FormaPagoTexto = "Tarjeta";
    }
    else if(this.tipoPago == 1){
      FormaPagoTexto = "Crédito";
    }

    if(this.company.companyPaymentType == 0){
      await this.PaymentLine.push({
        typePayment: 0,
        NamePaymentType: 'Efectivo',
        MontoPayment: parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal).toFixed(4))
      });
    }

    let TotalISR = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal).toFixed(4)) * 0.05;
    let TotalIVA = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal).toFixed(4)) * 0.12;
    let TotalMenosRetenciones = parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal).toFixed(4)) - TotalISR - TotalIVA;

    console.log('TotalISR',TotalISR);
    console.log('TotalIVA',TotalIVA);
    console.log('TotalMenosRetenciones',TotalMenosRetenciones);

    let ADENDASUMMARY = undefined;

    let json;

    if(this.company.companyCode == 24){ //ETISA

      ADENDASUMMARY = {
        Valor1: this.OrdenCompra,
        Valor2: FormaPagoTexto,
        Valor3: this.invoiceSpecialHeader.invoiceSpecialExchangeRate == undefined ? 0 : this.invoiceSpecialHeader.invoiceSpecialExchangeRate,
        Valor4: this.invoiceSpecialHeader.invoiceSpecialCurrency
      };


      json = {
        "store": {
          "storeCode": this.storeCode
        },
        "user": {
          "userCode": this.userCode
        },
        "transmitter": {
          "transmitterMembership": transmitterMembership,
          "transmitterType": transmitterType,
          "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
          "transmitterEmail": this.company.companyEmail,
          "transmitterTaxDocument": this.company.companyTaxDocument,
          "transmitterComercialName": this.store.storeName,
          "transmitterName": this.company.companyName,
          "transmitterAddress": this.store.storeAddress,
          "transmitterZipCode": this.store.storeZipCode,
          "transmitterMunicipality": this.store.storeMunicipality,
          "transmitterDepartment": this.store.storeDepartment,
          "transmitterCountry": 'GT',
          "transmitterPrefix": this.company.companyPrefix,
          "transmitterUser": this.company.companyPrefix,
          "transmitterKey": this.company.companyKey,
          "transmitterToken": this.company.companyToken,
          "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
          "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
        },
        "receiver": {
          "receiverAddress": this.client.clientAddress,
          "receiverCode": this.client.clientCode,
          "receiverDeleted": 0,
          "receiverEmail": this.client.clientEmail,
          "receiverName": this.client.clientName,
          "receiverStatus": 1,
          "receiverTaxDocument": this.client.clientTaxDocument,
          "receiverTypeTaxDocument": this.tipoCliente,
          "receiverZipCode": 0,
          "receiverCountry": "GT"
        },
        "invoiceSpecial": {
          "invoiceSpecialCode": 1,
          "invoiceSpecialCurrency": this.invoiceSpecialHeader.invoiceSpecialCurrency,
          "invoiceSpecialChangeRate": this.invoiceSpecialHeader.invoiceSpecialExchangeRate == undefined ? 0 : this.invoiceSpecialHeader.invoiceSpecialExchangeRate,
          "invoiceSpecialTaxShortName": "IVA",
          // "invoiceSpecialDate": this.invoiceSpecialHeader.invoiceSpecialDate,
          "invoiceSpecialDate": this.invoiceSpecialHeader.invoiceSpecialDate + ' ' + hour,
          "invoiceSpecialInternSerial": this.invoiceSpecialHeader.invoiceSpecialInternSerial,
          "invoiceSpecialTaxValue": parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax).toFixed(4)),
          "invoiceSpecialTotal": parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal).toFixed(4)),
          "invoiceSpecialOilTaxShortName": this.invoiceSpecialHeader.invoiceSpecialIDP > 0 ? "PETROLEO" : undefined,
          "invoiceSpecialOilTaxValue": parseFloat((this.invoiceSpecialHeader.invoiceSpecialIDP).toFixed(4)),
          "invoiceSpecialDuesQuantity": 0,
          "invoiceSpecialpaymentType": this.tipoPago,
          "invoiceSpecialISR": parseFloat((TotalISR).toFixed(4)),
          "invoiceSpecialRetencion": parseFloat((TotalIVA).toFixed(4)),
          "invoiceSpecialTotalsinRet": parseFloat((TotalMenosRetenciones).toFixed(4)),
        },
        "phrase": this.company.phrase,
        "detail": this.invoiceSpecialLine,
        "paymentType": this.PaymentLine,
        "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
        "serial": this.serialSelected,
        "addendum": ADENDASUMMARY,
        "salesPerson": this.SalesPerson
      };
  
      console.log(json);
  
      const logo = await this._companyService.getOne(this.companyCode);
      logo.records[0].companyLogoUrl != 'undefined' && (this.companyLogoUrl = logo.records[0].companyLogoUrl);

    }
    else{ //Resto de Empresas

      json = {
        "store": {
          "storeCode": this.storeCode
        },
        "user": {
          "userCode": this.userCode
        },
        "transmitter": {
          "transmitterMembership": transmitterMembership,
          "transmitterType": transmitterType,
          "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
          "transmitterEmail": this.company.companyEmail,
          "transmitterTaxDocument": this.company.companyTaxDocument,
          "transmitterComercialName": this.store.storeName,
          "transmitterName": this.company.companyName,
          "transmitterAddress": this.store.storeAddress,
          "transmitterZipCode": this.store.storeZipCode,
          "transmitterMunicipality": this.store.storeMunicipality,
          "transmitterDepartment": this.store.storeDepartment,
          "transmitterCountry": 'GT',
          "transmitterPrefix": this.company.companyPrefix,
          "transmitterUser": this.company.companyPrefix,
          "transmitterKey": this.company.companyKey,
          "transmitterToken": this.company.companyToken,
          "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
          "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
        },
        "receiver": {
          "receiverAddress": this.client.clientAddress,
          "receiverCode": this.client.clientCode,
          "receiverDeleted": 0,
          "receiverEmail": this.client.clientEmail,
          "receiverName": this.client.clientName,
          "receiverStatus": 1,
          "receiverTaxDocument": this.client.clientTaxDocument,
          "receiverTypeTaxDocument": this.tipoCliente,
          "receiverZipCode": 0,
          "receiverCountry": "GT"
        },
        "invoiceSpecial": {
          "invoiceSpecialCode": 1,
          "invoiceSpecialCurrency": this.invoiceSpecialHeader.invoiceSpecialCurrency,
          "invoiceSpecialChangeRate": this.invoiceSpecialHeader.invoiceSpecialExchangeRate == undefined ? 0 : this.invoiceSpecialHeader.invoiceSpecialExchangeRate,
          "invoiceSpecialTaxShortName": "IVA",
          // "invoiceSpecialDate": this.invoiceSpecialHeader.invoiceSpecialDate,
          "invoiceSpecialDate": this.invoiceSpecialHeader.invoiceSpecialDate + ' ' + hour,
          "invoiceSpecialInternSerial": this.invoiceSpecialHeader.invoiceSpecialInternSerial,
          "invoiceSpecialTaxValue": parseFloat((this.invoiceSpecialHeader.invoiceSpecialTax).toFixed(4)),
          "invoiceSpecialTotal": parseFloat((this.invoiceSpecialHeader.invoiceSpecialTotal).toFixed(4)),
          "invoiceSpecialOilTaxShortName": this.invoiceSpecialHeader.invoiceSpecialIDP > 0 ? "PETROLEO" : undefined,
          "invoiceSpecialOilTaxValue": parseFloat((this.invoiceSpecialHeader.invoiceSpecialIDP).toFixed(4)),
          "invoiceSpecialDuesQuantity": 0,
          "invoiceSpecialpaymentType": this.tipoPago,
          "invoiceSpecialISR": parseFloat((TotalISR).toFixed(4)),
          "invoiceSpecialRetencion": parseFloat((TotalIVA).toFixed(4)),
          "invoiceSpecialTotalsinRet": parseFloat((TotalMenosRetenciones).toFixed(4)),
        },
        "phrase": this.company.phrase,
        "detail": this.invoiceSpecialLine,
        "paymentType": this.PaymentLine,
        "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
        "serial": this.serialSelected,
        "addendum": ADENDASUMMARY,
        "salesPerson": this.SalesPerson
      };
  
      console.log(json);
  
      const logo = await this._companyService.getOne(this.companyCode);
      logo.records[0].companyLogoUrl != 'undefined' && (this.companyLogoUrl = logo.records[0].companyLogoUrl);

    }    
    
    console.log(json);
    // this.loading = false;
    // this.isDisabled = false;
    // Insertar Venta    
    if (this.company.companyCertifier == 0) { //INFILE
      this._invoiceService.insertSpecialBilling(json).then(
        (response) => {
          if (response.result) {
            // console.log(response);
            if (this.company.companyPDFType == 1) {
              this.fel = response.infileBody;
              this.felDetail = response.infileBody.detail;
              this.dte = {
                serie: response.records.infileServiceResponse.registerResponse.serie,
                noFactura: response.records.infileServiceResponse.registerResponse.numero,
                autorizacion: response.invoiceSpecialAuthorization,
                certificador: "INFILE, S.A.",
                nitCertificador: 12521337,
                fechaCertificacion: response.records.infileServiceResponse.registerResponse.fecha
              }
              this.viewPrint();
            } else {
              let uuid = response.invoiceSpecialAuthorization;
              window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');
              this.viewBeginning();
              this.cleanModel();
            }
            this._utilsService.showNotification(1, response.message);
            // 
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
        this.disabled = false;        
      });
    }
    else if (this.company.companyCertifier == 1) { //Megaprint
      this._invoiceService.insertSpecialBilling(json).then(
        (response) => {
          if (response.result) {
            // console.log(response);
            if (this.company.companyInvoiceFuel == 1 || this.company.companyPDFType == 1) {
              this.fel = response.megaprintBody;
              this.felDetail = response.megaprintBody.detail;
              let dte = response.records.megaprintServiceResponse.jsonXml['dte:GTDocumento']['dte:SAT'][0]['dte:DTE'][0]['dte:Certificacion'][0];
              this.dte = {
                serie: dte['dte:NumeroAutorizacion'][0]['Serie'][0],
                noFactura: dte['dte:NumeroAutorizacion'][0]['Numero'][0],
                autorizacion: dte['dte:NumeroAutorizacion'][0]['_'],
                certificador: dte['dte:NombreCertificador'][0],
                nitCertificador: dte['dte:NITCertificador'][0],
                fechaCertificacion: dte['dte:FechaHoraCertificacion'][0]
              }
              this.viewPrint();
            } else {
              var pdfResult = response.invoiceSpecialPdf;
              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")
              this.viewBeginning();
              this.cleanModel();
            }
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
        this.disabled = false;
      });
    }
    this.isDisabled = false;
    this.disabled = false;
    this.insertNewClient = false;
    
    if(this.companyCode == 22){
      this.isNailCenter = true;
      this.getAll(this.storeCode);

      var amountsByPayType = JSON.parse(localStorage.getItem('salesAmounts'));
      this.PaymentLine.forEach(element => {
        amountsByPayType.paymentType.forEach(payType => {
          console.log(amountsByPayType.paymentType)
          if(payType.id == element.typePayment){
            payType.amount += element.MontoPayment
          }
        });
      });

      localStorage.setItem('salesAmounts', JSON.stringify(amountsByPayType))
    }
  }

  // GET ALL INVENTORY
  getAll(storeCode) {
    this.loading = true;
    // console.log(storeCode, "este es el store code para nail center")
    return this._inventoryService.getAllByStore(storeCode).then(
      response => {
        if (response.result) {
          //this.inventoryList = response.records;
          this.temp = response.records;
          console.log(this.inventoryList);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE INVENTORY
  viewFunction($event) {
    $event.preventDefault();
    let series;
    this.activeRow.inventorySerialFlag == 1 ? series = 'SI' : series = 'NO';

    swal.fire({
      title: "Item:",
      imageUrl: `https://storage.googleapis.com/nail-center/Imagenes/${this.activeRow.inventoryBusinessCode}.jfif`,
      imageHeight: 300,
      html:
        '<div class="text-left">' +
        "<b>Código: </b>" +
        this.activeRow.inventoryBusinessCode +
        "<br>" +
        "<b>Nombre: </b>" +
        this.activeRow.inventoryName +
        "<br>" +
        "<b>Series: </b>" +
        series +
        "<hr>" +
        "<b>Precio Venta: </b>" +
        this.activeRow.inventoryPrice +
        "<br>" +
        "<b>Precio Minimo de Venta: </b>" +
        this.activeRow.inventoryMinPrice +
        "<br>" +
        "<b>Precio con Descuento: </b>" +
        this.activeRow.inventoryPriceDiscount +
        "<br>" +
        "<b>Precio de Promoción: </b>" +
        this.activeRow.inventorySalePrice +
        "<hr>" +
        "<b>Stock: </b>" +
        this.activeRow.inventoryQuantity +
        "<br>" +
        "<b>Stock Mínimo: </b>" +
        this.activeRow.inventoryMin +
        "<br>" +
        "<b>Stock Máximo: </b>" +
        this.activeRow.inventoryMax +
        "<hr>" +
        "<b>Cód. de Orden: </b>" +
        this.activeRow.inventoryPurchaseDetail +
        "<br>" +
        "<b>Descripción: </b>" +
        this.activeRow.inventoryDescription +
        "<br></div>",
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false,
    });
  }
  
  onActivate(event) {
    this.activeRow = event.row;
  }

  async printLetter() {
    const pdfWindow = window.open("", 'Factura', '_blank');
    let NCE = "";
    if(this.tipoCliente == 0){
      NCE = "NIT: "
    }
    else if(this.tipoCliente == 1){
      NCE = "CUI: "
    }
    else if(this.tipoCliente == 2){
      NCE = "EXT: "
    }

    const css = `<style>   
        td { font-size: 12px; padding: 2px;}     
        .brDescr {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF; border-bottom: 1px solid  #2CA8FF;}
        .brCompl {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF;}
      </style>`;
    var html = `
    <head><title>Factura Clever "${this.company.companyName + ' - ' + this.storeName}"</title></head>
    <body style="width: 21cm; height: 27cm; margin: auto; font-family: sans-serif, system-ui, monospace;"> 
      <div style="border: 2px solid #2CA8FF; border-radius: 10px; width: auto;">
        <table style="width: 100%;">
              <tr>
                  <td rowspan="5" style="width: 19%;">
                      <img style="width: 110px; margin:10px auto; display:block;" src="${this.companyLogoUrl}">
                  </td>
                  <th style="width: 62%; font-size: 19px; text-align: cent  er;">                      
                      ${this.fel.transmitter.transmitterComercialName}
                  </th>
                  <td style="width: 19%; font-size: 10px;">
                      Vendedor: ${this.userName}
                  </td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                    ${this.fel.transmitter.transmitterName}
                  </td>
                  <td style="font-size: 10px;">Factura Electronica Fel</td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      NIT: ${this.fel.transmitter.transmitterTaxDocument}
                  </td>
                  <td>Serie: ${this.dte.serie}</td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      Tel: ${this.store.storePhone}<br>
                      ${this.fel.transmitter.transmitterAddress}. ${this.fel.transmitter.transmitterMunicipality}, ${this.fel.transmitter.transmitterDepartment}
                  </td>
                  <td>No. DTE: ${this.dte.noFactura}</td>
              </tr>
              <tr>
                  <th style="text-align: center;">FACTURA</th>
                  <td>Fecha: ${this.pipeFecha.transform(this.fel.invoiceSpecial.invoiceSpecialDate, 'dd-MM-yyy')}</td>
              </tr>
        </table>
      </div>
      <br>
      <div style="border: 2px solid #2CA8FF; border-radius: 10px 10px 0px 0px; width: auto; min-height: 750px" >
        <table cellspacing="0" cellpadding="0" style="width: 100%;">
            <tr style="max-height: 15px;">
              <td colspan="4"> Nombre: ${this.fel.receiver.receiverName}</td>
              <td colspan="3"> Nit: ${this.fel.receiver.receiverTaxDocument}</td>
            </tr>
            <tr style="max-height: 15px;">
              <td colspan="7"> Dirección: ${this.fel.receiver.receiverAddress}</td>
            </tr>
            <tr style="font-size: 11px; text-align: center;">
              <th class="brDescr">CANTIDAD</th>
              <th class="brDescr">TIPO</th>
              <th class="brDescr" style="width: 320px;">DESCRIPCIÓN</th>
              <th class="brDescr">PRECIO UNI.</th>
              <th class="brDescr">IMPUESTO</th>
              <th class="brDescr">DESCUENTO</th>
              <th class="brDescr" style="width: 120px;">TOTAL</th>
            </tr>`;
    
    for (const e of this.felDetail) {
      html += `<tr">
        <td style="text-align: center; "> ${this._pipeNumero.transform(e.quantity, '1.2-2')}</td>
        <td style="text-align: center;"> ${e.isService}</td>
        <td>${e.description}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(e.unitPrice, '1.2-2')}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(e.taxAmount, '1.2-2')}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(e.discount, '1.2-2')}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(e.total, '1.2-2')}</td>
        </tr>`
    };

    html += `          
        </table>
      </div>
      <div style="border: 2px solid #2CA8FF; border-radius: 0px 0px 10px 10px; width: auto;">
        <table cellspacing="0" style="width: 100%;">
              <tr>
                <td colspan="7" style="text-align: center; font-size: 10px;">${this.regimenMessage}</td>
              </tr>
              <tr style="height: 30px;">
                <td class="brCompl" style="max-width: 22px; padding-left: 5px;">Total en Letras:</td>
                  <td class="brCompl" style="font-size: 10px max-width: 65px; text-align: left;">${this.pipeLetra.transform(this.fel.invoiceSpecial.invoiceSpecialTotal)}</td>
                  <th style="font-size: 14px; max-width: 65px; text-align: right; border-top: 1px solid  #2CA8FF;">
                    TOTAL: Q ${this._pipeNumero.transform(this.fel.invoiceSpecial.invoiceSpecialTotal, '1.2-2')}
                  </th>
              </tr>
          </table>
      </div> 
      <br>
      <table style="border: 2px solid #2CA8FF; border-radius: 10px; width: 100%;" padding: 10px;" >
          <tr><td>
            No. de Autorización: ${this.dte.autorizacion}<br>
            Fecha de Certificación: ${this.dte.fechaCertificacion}  
          </td></tr>
          <tr><td style="text-align: center">Certificador: ${this.dte.certificador}. Nit: ${this.dte.nitCertificador}</td></tr>
      </table>
    </body>`;

    await pdfWindow.document.write(css + html);  //setTimeout( () => {pdfWindow.window.open()}, 1100)
    this.continue();
  }

  // Limpiar Campos
  async cleanModel() {    
    
    this.addAmount = true;
    this.isDisabled = false;
    this.disabled = false;
    this.insertNewClient = false;
    this.recurrentClient = false;
    this.client = {} as Client;
    this.invoiceSpecialHeader = {} as InvoiceSpecialHeader;
    this.invoiceSpecialDetail = {} as InvoiceSpecialDetail;
    this.invoiceSpecialLine = [];
    this.moneda = "Q";
    this.petroleo = null;
    this.taxOilAmount = 0;
    this.taxOilCode = null;
    this.invoiceSpecialHeader.invoiceSpecialIDP = 0;
    this.invoiceSpecialHeader.invoiceSpecialTax = 0;
    this.invoiceSpecialHeader.invoiceSpecialTotal = 0;
    this.invoiceSpecialHeader.invoiceSpecialCoin = 0;
    this.invoiceSpecialHeader.invoiceSpecialCurrency = 'GTQ';
    this.invoiceSpecialDetail.invoiceSpecialDetailType = 'B';    
    this.invoiceSpecialDetail.invoiceSpecialDetailQuantity = 1;
    this.invoiceSpecialHeader.invoiceSpecialInternSerial = 'A';
    this.invoiceSpecialHeader.paymentType = 0;
    this.tipoPago = 0;
    this.invoiceSpecialHeader.invoiceSpecialDate = this.nowDay;
    this.client.clientType = 0;
    this.OrdenCompra = "0";

    this.jumpSerial = false;
    this.idp = 0;
    this.subIdp = 0;
    this.subsidio = 0;
    this.subsidioTemp = 0;
    //this.taxToPay = 0;
    this.priceType = 1;
    this.PaymentLine = [];
    this.MontoTotalPayment = 0;

    if (this.isParamsUrl) {
      this.router.navigate([], { relativeTo: this.activatedRoute });
    }

    //await this.getAll(this.storeCode);
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isBilling = false;
    this.isPrintT = false;
    this.isPrintL = false;
    console.log('Tipo ', this.client.clientType);
    this.client.clientType = 0;
    this.tipoCliente = 0;    
  }

  viewBilling() {
    this.isBeginning = false;
    this.isBilling = true;
    this.isPrintT = false;
    this.isPrintL = false;
  }

  viewPrint() {
    this.isBeginning = false;
    this.isBilling = false;
    // this.company.companyinvoiceSpecialPrint === 1 ? this.isPrintT = true : this.isPrintL = true;
    this.company.companyInvoicePrint === 1 ? this.isPrintT = true : this.printLetter();
  }

  viewCF() {
    this.isBeginning = true;
    this.isBilling = true;
    this.isPrintT = false;
    this.isPrintL = false;
    this.disabled = true;
    this.isDisabled = true;
    console.log('TP CF-> ', this.tipoCliente);
    this.client.clientType = this.tipoCliente;
  }

  ValidarCF() {
    /*J11 - Validar CF*/
    console.log(this.invoiceSpecialHeader.invoiceSpecialTotal);
    console.log(this.client.clientTaxDocument);

    if(this.invoiceSpecialHeader.invoiceSpecialTotal >= 2500){
      if(this.client.clientTaxDocument == "CF"){
        this._utilsService.showNotification(5, "No puede facturar como CF, el monto es mayor a 2500");
        this.viewCF();
      }
      else{
        this.disabled = false;
      }
    }
  }

  continue() {
    this.viewBeginning();
    this.cleanModel();
  }

  // PRINT
  printTest() {
    printJS({
      printable: "printSection",
      type: "html",
      style: "#printSection{width: 224px; text-align: center};",
    });
  }
}
