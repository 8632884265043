import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import swal from "sweetalert2";
import { PhraseStageService } from 'src/app/services/phrase-stage.service';
import { CompanyService } from 'src/app/services/company.service';
import { UtilsService } from 'src/app/services/utils.service';

interface PhraseStage {
  phraseCode: number;
  phraseCompany: number;
  phraseStage: number;
  phrase: number;
  phraseStatus: number;
  phraseDeleted: number;
}

@Component({
  selector: 'app-phrase-stage',
  templateUrl: './phrase-stage.component.html',
  styleUrls: ['../../../app.component.scss'],
  providers: [UtilsService, PhraseStageService, CompanyService]
})
export class PhraseStageComponent implements OnInit {
  @ViewChild('formulario') formulario!: NgForm;

  // MODELS VARIABLE 
  public phraseStage = {} as PhraseStage;
  public isEdit: boolean = false;
  public loading: boolean = false;
  public isDisabled: boolean = false;
  public companyCode: number;
  public tooltip_msg: string = "Los arreglos de frases, escenarios son reglas y validaciones. Se solicitan al ente certificador. Ejemplo: Frase: 1. Escenario: 1.";
  
  // VARIABLE FOR DATA TABLE
  public temp = [];
  public activeRow: any;
  public entries: number = 10;
  public selected: any[] = [];
  public phraseStageList = new Array();
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;

  // VARIABLE FOR SELECT 
  public companyList: any[] = new Array();
  public selectedItemsCompany = [];
  public dropdownSettingsCompany = {};
  public companySelected = null;

  constructor(
    private _utilsService: UtilsService,
    private _phraseStageService: PhraseStageService,
    private _companyService: CompanyService,
  ) { }


  ngOnInit(): void {
    // GET ID USER & ID COMPANY    
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    // SELECT
    this.getCompanyList();
    this.getSelectSettingCompany(this.selectedItemsCompany);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    const val = $event.target.value.toLowerCase();
    this.temp = this.phraseStageList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////
  getCompanyList() {
    return this._companyService.getAll().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            if (this.companyCode === e.companyCompanyCreationCode || this.companyCode === e.companyCode || this.companyCode === 1) {
              this.companyList.push({
                id: e.companyCode,
                itemName: e.companyName
              });
            }
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getSelectSettingCompany(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsCompany = [selected] : '';

    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };

  onItemSelectCompany(item: any) {
    this.getRegister(item.id);
    this.phraseStage.phraseCompany = item.id;
  }

  onDeItemSelectCompany() {
    this.phraseStage.phraseCompany = null;
  }

  onItemSelectRegister(item: any) {
    this.getRegister(item.id);
    this.companySelected = item.id;
  }

  ///////////////////////VALIDATION///////////////////////  
  validate_msg(type: number, name: string): boolean {
    switch (type) {
      case 1: return this.formulario?.controls[name]?.invalid
        && this.formulario?.controls[name]?.touched;
        break;
      case 2: return this.formulario?.controls[name]?.value < 0;
        break;
    }
  }

  validate_number(type: number, name: string, name_two?: string): void {
    switch (type) {
      case 1: (this.phraseStage[name] < 0) && (this.phraseStage[name] = 0);
        break;
    }
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL TYPE COMPANY
  getRegister(companyCode) {
    this.loading = true;

    return this._phraseStageService.getOneCompany(companyCode).then(
      response => {
        if (response.result) {
          this.phraseStageList = [];
          this.phraseStageList = response.records;
          this.temp = this.phraseStageList;
        } else {
          console.log(response.errorMessage);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // INSERT
  save() {
    if (this.formulario.valid && this.phraseStage.phrase > 0 && this.phraseStage.phraseStage > 0 && this.phraseStage.phraseCompany > 0) {
      this.loading = true;
      this.isDisabled = true;

      this.phraseStage.phraseStatus = 1;
      this.phraseStage.phraseDeleted = 0;

      this._phraseStageService.insert(this.phraseStage).then(
        async (response) => {
          if (response.result == true) {
            this.cleanModel();
            this.getRegister(this.companySelected);
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this.formulario.control.markAllAsTouched();
      this._utilsService.showNotification(2, 'Error al llenar formulario!');
    }
  }

  // UPDATE
  update() {
    if (this.formulario.valid && this.phraseStage.phrase > 0 && this.phraseStage.phraseStage > 0 && this.phraseStage.phraseCompany > 0) {
      this.loading = true;
      this.isDisabled = true;

      this._phraseStageService.update(this.phraseStage).then(
        async (response) => {
          if (response.result == true) {
            this.cleanModel();
            this.getRegister(this.companySelected);
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this.formulario.control.markAllAsTouched();
      this._utilsService.showNotification(2, 'Error al llenar formulario!');
    }
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.phraseStage.phraseStatus = 0;
    this.phraseStage.phraseDeleted = 1;
    this.phraseStage.phraseCode = this.activeRow.phraseCode;

    swal.fire({
      title: "Esta seguro",
      text: "Se eliminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.phraseStage.phrase = this.activeRow.phrase;
    this.phraseStage.phraseCode = this.activeRow.phraseCode;
    this.phraseStage.phraseStage = this.activeRow.phraseStage;
    this.phraseStage.phraseCompany = this.activeRow.phraseCompany;
  }

  ///////////////////////COMPLEMENT///////////////////////  
  // CLEAR MODAL
  cleanModel(cancel?: boolean) {
    (!cancel) && this.getRegister(this.companySelected);
    this.isEdit = false;
    this.isDisabled = false;
    this.formulario.resetForm();
    this.phraseStage = {} as PhraseStage;
    this.phraseStage.phraseCompany = this.companySelected;
  }
}
