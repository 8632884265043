import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'letras'
})
export class LetrasPipe implements PipeTransform {


  transform(value: number): any {
    var literal = '';

    if (value >= 0 && value < 1) {
      literal = 'CERO QUETZALES'
    }

    else if(value > 0 && value >= 1 && value < 2){
      literal += numeroALetras(value) + " QUETZAL";     
    }else{
      literal += numeroALetras(value) + " QUETZALES";
    }    

    var dec = (value.toString()).split(".");
    if (parseFloat(dec[1]) > 0) {
      var decimales = ' CON ' + dec[1] + '/100';
      literal += decimales;
    }

    function numeroALetras(value) {
      var num = parseInt((value * 100) + '');
      var numero = parseInt(value + '');
      var respuesta = '';

      if (numero > 999) {
        if ((numero + '').length > 6) {

          var residuo = parseInt((numero + '')) % 1000000;
          var x = numero / 1000000;
          if (x == 1) {
            respuesta = ' UN MILLON ' + numeroALetras(residuo) + ' DE';
          }
          else {
            respuesta = numeroALetras(x) + ' MILLONES ' + numeroALetras(residuo);
          }
        } else if ((numero + '').length > 3) {
          var residuo = parseInt((numero + '')) % 1000;
          var x = numero / 1000;

          if (x == 1) {
            respuesta = ' MIL' + numeroALetras(residuo);
          } else {
            respuesta = numeroALetras(x) + ' MIL ' + numeroALetras(residuo);
          }
        }
      } else {
        if (numero == 100) {
          respuesta = 'CIEN';
        } else if (numero > 100) {
          var cen = numero / 100;
          var dec = numero % 100;

          respuesta = ' ' + centenas_nal((cen.toString().charAt(0))) + ' ' + numeroALetras(dec);
        } else {
          var dec = numero % 100;
          if (dec < 30) {
            respuesta = ' ' + unidades_nal(dec);
          } else {
            var unis = dec % 10;
            var ddec = dec / 10;
            if (unis != 0) {
              respuesta = ' ' + decenas_nal(ddec.toString().charAt(0)) + ' Y ' + unidades_nal(unis);
            } else {
              respuesta = ' ' + decenas_nal(ddec.toString().charAt(0));
            }
          }
        }
      }
      return respuesta;
    }

    // UNIDADES
    function unidades_nal(n) {
      if (n + '' == '1') {
        return 'UNO'
      }
      if (n + '' == '2') {
        return 'DOS'
      }
      if (n + '' == '3') {
        return 'TRES'
      }
      if (n + '' == '4') {
        return 'CUATRO'
      }
      if (n + '' == '5') {
        return 'CINCO'
      }
      if (n + '' == '6') {
        return 'SEIS'
      }
      if (n + '' == '7') {
        return 'SIETE'
      }
      if (n + '' == '8') {
        return 'OCHO'
      }
      if (n + '' == '9') {
        return 'NUEVE'
      }


      if (n + '' == '10') {
        return 'DIEZ'
      }
      if (n + '' == '11') {
        return 'ONCE'
      }
      if (n + '' == '12') {
        return 'DOCE'
      }
      if (n + '' == '13') {
        return 'TRECE'
      }
      if (n + '' == '14') {
        return 'CATORCE'
      }
      if (n + '' == '15') {
        return 'QUINCE'
      }
      if (n + '' == '16') {
        return 'DIECISEIS'
      }
      if (n + '' == '17') {
        return 'DIECISIETE'
      }
      if (n + '' == '18') {
        return 'DIECIOCHO'
      }
      if (n + '' == '19') {
        return 'DIECINUEVE'
      }


      if (n + '' == '20') {
        return 'VEINTE'
      }
      if (n + '' == '21') {
        return 'VEINTIÚN'
      }
      if (n + '' == '22') {
        return 'VEINTIDÓS'
      }
      if (n + '' == '23') {
        return 'VEINTITRÉS'
      }
      if (n + '' == '24') {
        return 'VEINTICUATRO'
      }
      if (n + '' == '25') {
        return 'VEINTICINCO'
      }
      if (n + '' == '26') {
        return 'VEINTISÉIS'
      }
      if (n + '' == '27') {
        return 'VEINTISIETE'
      }
      if (n + '' == '28') {
        return 'VEINTIOCHO'
      }
      if (n + '' == '29') {
        return 'VEINTINUEVE'
      }

      return '';
    }

    // DECENAS
    function decenas_nal(n) {
      if (n + '' == '1') {
        return 'DIEZ'
      }
      if (n + '' == '2') {
        return 'VEINTI'
      }
      if (n + '' == '3') {
        return 'TREINTA'
      }
      if (n + '' == '4') {
        return 'CUARENTA'
      }
      if (n + '' == '5') {
        return 'CINCUENTA'
      }
      if (n + '' == '6') {
        return 'SESENTA'
      }
      if (n + '' == '7') {
        return 'SETENTA'
      }
      if (n + '' == '8') {
        return 'OCHENTA'
      }
      if (n + '' == '9') {
        return 'NOVENTA'
      }

      return '';
    }

    // CENTENAS  
    function centenas_nal(n) {
      if (n + '' == '1') {
        return 'CIENTO'
      }
      if (n + '' == '2') {
        return 'DOCIENTOS'
      }
      if (n + '' == '3') {
        return 'TRECIENTOS'
      }
      if (n + '' == '4') {
        return 'CUATROCIENTOS'
      }
      if (n + '' == '5') {
        return 'QUINIENTOS'
      }
      if (n + '' == '6') {
        return 'SEISCIENTOSD'
      }
      if (n + '' == '7') {
        return 'SETECIENTOS'
      }
      if (n + '' == '8') {
        return 'OCHOCIENTOS'
      }
      if (n + '' == '9') {
        return 'NOVECIENTOS'
      }

      return '';
    }



    return literal;
  }

}
