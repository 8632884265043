<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/reprint">Reimpresion</a>
        </div>
    </div>
</div>
<br />
<div class="main-content">
    <!-- LISTAR FACTURAS -->
    <!-- <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg" routerLink="/reprint-invoice">Factura</button>
                        </div>
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg" routerLink="/reprint-exchange-bill">Factura Cambiaria</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- FIN LISTAR FACTURAS -->

    <!-- Nueva interfaz -->
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-10">
            <div class="card">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h4 class="card-title">SELECCIONE UNA OPCIÓN</h4>
                        </div>                            
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg w-100 font-weight-bold" routerLink="/reprint-invoice">Factura</button>
                        </div>
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg w-100 font-weight-bold" routerLink="/reprint-exchange-bill">Factura Cambiaria</button>
                        </div>
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg w-100 font-weight-bold" routerLink="/reprint-credit-note">Nota de Crédito</button>
                        </div>
                        <div class="col-md-6 text-center">
                            <button class="btn btn-info btn-lg w-100 font-weight-bold" routerLink="/reprint-debit-note" >Nota de Debito</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>