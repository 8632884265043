import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { environment } from 'src/environments/environment';
import { AccessService } from '../../services/access.service';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from '../../services/company.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  providers: [UtilsService, ClientService, AccessService, CompanyService]
})
export class ClientComponent implements OnInit {
  // MODELS VARIABLE
  public client = {} as Client;
  public company = {} as Company;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isEdit: boolean;
  public isDisabled: boolean;
  public noCredit: boolean;
  public settings = {
    columns: {
      clientBusinessCode: {
        title: 'Código'
      },
      clientName: {
        title: 'Nombre'
      },
      clientTaxDocument: {
        title: 'Nit'
      },
      clientNameFrequent: {
        title: 'Nombre Cliente Frecuente'
      },
      clientPhone: {
        title: 'Teléfono'
      },
      clientPhone2: {
        title: 'Teléfono2'
      },
      clientAddress: {
        title: 'Dirección'
      },
      clientCreditTime: {
        title: 'Días de Crédito'
      },
      clientCreditAmount: {
        title: 'Monto de Crédito',
        type: 'number'
      }
    }
  };
  public url: string;
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public clientList: Client[] = new Array();

  // VARIABLE FOR SELECT 
  public categoryList: any[] = new Array();
  public selectedItems = [];
  public dropdownSettings = {};

  public tipoCliente: number;

  constructor(
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _clientService: ClientService,
    private _companyService: CompanyService
  ) { 
    this.url = environment.url;
    //this.url = 'http://localhost:4200/';
  }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    // SET VARIABLE
    this.isEdit = false;
    this.noCredit = true;
    this.isDisabled = false;
    this.client.clientDiscount = 0;
    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.client.clientEmail = 'info@clever.com';
    this.client.clientAddress = 'CIUDAD';

    // LOAD INFORMATION
    this.companyCode === 1 ? this.getAll() : this.getAllByCompany();
    this.getClientCategory();
    this.getSelectSetting(this.selectedItems);
    await this.getCompany(this.companyCode);
    //this.selectedItems = [{ 'id': 1, 'itemName': 'Tipo A' }]
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.clientList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSetting(selected) {
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: ""
    };
  };

  getClientCategory() {
    return this._clientService.getAllClientCategory().then(
      response => {
        if (response.result) {
          response.records.forEach(e => {
            this.categoryList.push({
              id: e.clientCategoryCode,
              itemName: e.clientCategoryName
            });
          });

        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onItemSelect(item: any) {
    this.client.clientCategory = item.id;
  }

  ///////////////////////CRUD///////////////////////
  // GET ALL CLIENT
  getAll() {
    this.loading = true;
    return this._clientService.getAll().then(
      response => {
        if (response.result) {
          this.clientList = response.records;
          this.temp = this.clientList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ALL CLIENT BY COMPANY
  getAllByCompany() {
    this.loading = true;
    return this._clientService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result) {
          console.log(response.records);
          this.clientList = response.records;
          this.temp = this.clientList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE CLIENT
  viewFunction($event) {
    $event.preventDefault();

    swal.fire({
      title: "Empresa:",
      html:
        '<div class="text-left">' +
        '<b>Nombre: </b>' + this.activeRow.clientName + '<br>' +
        '<b>Teléfono: </b>' + this.activeRow.clientPhone + '<br>' +
        '<b>Teléfono: </b>' + this.activeRow.clientPhone2 + '<br>' +
        '<b>Correo: </b>' + this.activeRow.clientEmail + '<br>' +
        '<b>Dirección: </b>' + this.activeRow.clientAddress + '<br></div>',
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    })
  }

  onChangeTypeClient(event) {    
    this.client.clientType = event;
    this.tipoCliente = event;
    console.log(this.tipoCliente);    
  }

  // INSERT
  save() {
    console.log(this.client.clientCategory);
    this.loading = true;
    this.isDisabled = true;
    this.client.clientStatus = 1;
    this.client.clientDeleted = 0;

    this.client.clientCompanyCode = this.companyCode;
    this.client.clientCredit ? this.client.clientCredit = 1 : this.client.clientCredit = 0;
    this.client.clientBlocked ? this.client.clientBlocked = 1 : this.client.clientBlocked = 0;
    this.client.clientCreditTime == undefined ? this.client.clientCreditTime = 0 : '';
    this.client.clientCreditAmount == undefined ? this.client.clientCreditAmount = 0 : '';
    this.client.clientInterest == undefined ? this.client.clientInterest = 0 : '';
    this.client.clientPhone2 == undefined ? this.client.clientPhone2 = "0000-0000" : '';
    this.client.clientAddressFrequent == undefined ? this.client.clientAddressFrequent = "CIUDAD" : '';
    this.client.clientCategory == undefined ? this.client.clientCategory = 1 : '';

    console.log(this.clientList);

    if(this.client.clientTaxDocument.toUpperCase() == "CF"){

      if(this.client.clientName.toUpperCase() == "CONSUMIDOR FINAL"){
        this._utilsService.showNotification(3, "CF no pueden tener como nombre Consumidor Final.");
        this.isDisabled = false;
        this.loading = false;
      }
      else{
        console.log('model client', this.client);
        this.client.clientTaxDocument = this.client.clientTaxDocument.toUpperCase();
        this._clientService.insert(this.client).then(
          (response) => {
            if (response.result == true) {
              this.companyCode === 1 ? this.getAll() : this.getAllByCompany();
              this.cleanModel();
              this._utilsService.showNotification(1, response.message);
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this._utilsService.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.isDisabled = false;
          this.loading = false;
        });
      }

    }
    else{
      
      return this._clientService.getOneByCompanyNit(this.companyCode, this.client.clientTaxDocument).then(
        response => {
          if (response.records.length > 0) {
            //Cliente ya existe
            this._utilsService.showNotification(3, "Documento Tributario del Cliente ya existe.");
            this.isDisabled = false;
            this.loading = false;
          }
          else {
            
            // const Client = this.clientList.find(({ clientEmail }) => clientEmail == this.client.clientEmail);
            // console.log('Cliente Buscado', Client);
            // if(Client == undefined){
              //No existe
              const Client2 = this.clientList.find(({ clientPhone }) => clientPhone == this.client.clientPhone);
              console.log('Cliente Buscado 2', Client2);
              if(Client2 == undefined){
                //No existe
                //Cliente nuevo
  
                this._clientService.searchNitSAT(this.client.clientTaxDocument, this.client.clientType).then(
                  async response => {
                    if (response.tipo_respuesta == 0) {
                      //this.client.clientName = response.nombre[0];
                      //console.log(response.nombre[0]);
                      this.client.clientName =  response.nombre[0];
                      this.client.clientNameFrequent == undefined ? this.client.clientNameFrequent = this.client.clientName : '';
                      this._utilsService.showNotification(4, "Cliente Nuevo");
  
                      console.log('model client', this.client);
                      this._clientService.insert(this.client).then(
                        (response) => {
                          if (response.result == true) {
                            this.companyCode === 1 ? this.getAll() : this.getAllByCompany();
                            this.cleanModel();
                            this._utilsService.showNotification(1, response.message);
                          } else {
                            this._utilsService.showNotification(3, response.errorMessage);
                          }
                        },
                        error => {
                          this._utilsService.showNotification(2, error);
                          console.log(error);
                        }
                      ).finally(() => {
                        this.isDisabled = false;
                        this.loading = false;
                      });
  
                    } else {
                      this._utilsService.showNotification(3, response.listado_errores[0].error[0].desc_error[0]);
                    }
                  },
                  error => {
                    console.log(error);
                  }
                );              
              }
              else{
                //Existe
                this._utilsService.showNotification(3, "Número de Telefono de Cliente ya existe.");
                this.isDisabled = false;
                this.loading = false;
              }
            // }
            // else{
            //   //Existe
            //   this._utilsService.showNotification(3, "Correo del cliente ya existe.");
            //   this.isDisabled = false;
            //   this.loading = false;
            // }
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.isDisabled = false;
        this.loading = false;
      }); 
      
    }         
  }

  // UPDATE
  update() {
    this.loading = true;
    this.isDisabled = true;

    this.client.clientCredit ? this.client.clientCredit = 1 : this.client.clientCredit = 0;
    this.client.clientBlocked ? this.client.clientBlocked = 1 : this.client.clientBlocked = 0;
    this.client.clientAddressFrequent == undefined ? this.client.clientAddressFrequent = "0000-0000" : '';
    this.client.clientPhone2 == undefined ? this.client.clientPhone2 = "0000-0000" : '';

    // console.log(this.client);
    this._clientService.update(this.client).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          this.companyCode === 1 ? this.getAll() : this.getAllByCompany();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        console.log(error);
        this._utilsService.showNotification(2, error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.client.clientCode = this.activeRow.clientCode;
    this.client.clientStatus = 0;
    this.client.clientDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "El cliente se eliminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;
    
    this.client.clientCode = this.activeRow.clientCode;
    this.client.clientCompanyCode = this.activeRow.clientCompanyCode;
    this.client.clientBussinesCode = this.activeRow.clientBussinesCode;
    this.client.clientName = this.activeRow.clientName;
    this.client.clientTaxDocument = this.activeRow.clientTaxDocument;
    this.client.clientEmail = this.activeRow.clientEmail;
    this.client.clientPhone = this.activeRow.clientPhone;
    this.client.clientPhone2 = this.activeRow.clientPhone2;
    this.client.clientAddress = this.activeRow.clientAddress;
    this.client.clientAddressFrequent = this.activeRow.clientAddressFrequent;
    this.client.clientCategory = this.activeRow.clientCategory;
    this.client.clientCreditTime = this.activeRow.clientCreditTime;
    this.client.clientCreditAmount = this.activeRow.clientCreditAmount;
    this.client.clientBlocked = this.activeRow.clientBlocked;
    this.client.clientType = this.activeRow.clientType;
    this.client.clientNameFrequent = this.activeRow.clientNameFrequent;

    this.activeRow.clientCredit == 1 ? this.client.clientCredit = true : this.client.clientCredit = false;
    this.activeRow.clientBlocked == 1 ? this.client.clientBlocked = true : this.client.clientBlocked = false;

    const _selectedItem = this.categoryList.find(x => x.id == this.activeRow.clientCategory);
    this.getSelectSetting(_selectedItem);
  }

  billing($event) {
    $event.preventDefault();
    swal.fire({
      title: 'Nota',
      text: "Se abrira una pestaña nueva",
      icon: "warning",
      width: 750,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: 'Factura Normal',
      denyButtonText: 'Fatura Cambiaria',
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-success",
        denyButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
    }).then((result) => {
      if (result.isDismissed) {
        //console.log('candelado')
        console.log(this.company);
        console.log(this.activeRow);        
      } else {
        let billingType;        
        this.company.companyInvoiceType == 1 ? billingType = 'inventory' : billingType = 'description';

        localStorage.setItem(
          "clientData",
          JSON.stringify(this.activeRow)
        );

        if (result.isConfirmed) {
          window.open(this.url + billingType + '-billing?nit=' + this.activeRow.nit);
        } else if (result.isDenied) {
          window.open(this.url + billingType + '-exchange-note?nit=' + this.activeRow.nit);
        }
      }
    })
  }

  //BILLING CLIENT
  // billing($event) {
  //   $event.preventDefault();
  //   let billingType;
  //   this.company.companyInvoiceType == 1 ? billingType = 'inventory' : billingType = 'description';
  //   window.open(this.url + billingType + '-billing?nit=' + this.activeRow.nit);
  // }

  ///////////////////////COMPLEMENT///////////////////////
  creditFunction() {
    this.client.clientCredit == true ? this.noCredit = false : this.noCredit = true;
    this.client.clientInterest = null;
    this.client.clientCreditTime = null;
    this.client.clientCreditAmount = null;
  }

  //CANCEL EDIT
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.isDisabled = false;
    this.client = {} as Client;
    this.selectedItems = null;

    //this.selectedItems = [{ 'id': 1, 'itemName': 'Tipo A' }]
    // this.client.clientCategory = 1;
    this.client.clientDiscount = 0;
    this.client.clientCompanyCode = this.companyCode;
    this.client.clientAddress = 'CIUDAD';
    this.client.clientDiscount = 0;
    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.client.clientEmail = 'info@clever.com';
    this.isEdit = false;
    this.noCredit = true;
    this.isDisabled = false;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-cliente.xlsx');
  }
}
