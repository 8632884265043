import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Owner } from '../models/owner';
import { CompanyOwner } from '../models/companyOwner';
import { environment } from 'src/environments/environment';

@Injectable()
export class OwnerService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL OWNER
    getAll(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-owner', { headers: headers }).toPromise();
    } 

    // GET ALL COMPANY OWNER
    getAllCompanyOwner(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-companyOwner', { headers: headers }).toPromise();
    } 

    // INSERT OWNER
    insert(owner: Owner): Promise<any> {
        let params = JSON.stringify(owner);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-owner', params, { headers: headers }).toPromise();
    }

    // INSERT OWNER
    insertCompanyOwner(companyOwner: CompanyOwner): Promise<any> {
        let params = JSON.stringify(companyOwner);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-companyOwner', params, { headers: headers }).toPromise();
    }

    // UPDATE OWNER
    update(owner: Owner): Promise<any> {
        let params = JSON.stringify(owner);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-owner', params, { headers: headers }).toPromise();
    }

    // UPDATE COMPANY OWNER
    updateCompanyOwner(companyOwner: CompanyOwner): Promise<any> {
        let params = JSON.stringify(companyOwner);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-companyOwner', params, { headers: headers }).toPromise();
    }
}