import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { Data, Router } from '@angular/router';
import swal from "sweetalert2";
import { Store } from 'src/app/models/store';
import { Company } from 'src/app/models/company';
import { XmlService } from 'src/app/services/xml.service';
import { MassiveService } from 'src/app/services/massive.service';
import { PrintService } from 'src/app/services/print.service';
import { StoreService } from 'src/app/services/store.service';
import { UtilsService } from 'src/app/services/utils.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { CompanyService } from 'src/app/services/company.service';
import * as XLSX from "xlsx";


//import { factura } from 'src/app/services/factura';

interface Walmart {
  WMEnviarGLN: string,
  WMFechaOrden: string,
  WMNumeroOrden: string,
  WMNumeroVendedor: string,
  WMNumeroRecepcion: string,
}

@Component({
  selector: 'app-invoice-massive',
  templateUrl: './invoice-massive.component.html',
  styleUrls: ['./invoice-massive.component.scss', '../../../../app.component.scss'],
  providers: [UtilsService, PrintService, CompanyService, StoreService, InvoiceService, XmlService]
})
export class InvoiceMassive implements OnInit {
  public store: Store;
  public company: Company;
  public data: Data = {} as Data;
  public walmart: Walmart = {} as Walmart;
  public typeDTE: string = '';
  public nameDTE: string = '';
  public closeResult: string = '';
  public loading: boolean = false;
  public disabled: boolean = false;
  public walmartDTE: boolean = false;

  public file: any = {};
  @ViewChild('file_input') file_input: ElementRef;

  // variables data table
  public activeRow: any = {};
  public selected: any[] = [];
  public entries: number = 10;
  public rowsDocument: any[] = new Array();
  public documentList: any[] = new Array();
  public documentCount: number = 0;
  public error: boolean = false;
  public warning: boolean = false;
  public errorCount: number = 0;
  public warningCount: number = 0;
  public rowsDocumentError: any[] = new Array();
  public rowsDocumentCertified: any[] = new Array();
  public CertifiedCount: number = 0;
  public rowsDocumentWarning: any[] = new Array();

  constructor(
    public router: Router,
    public config: NgbModalConfig,
    private modalService: NgbModal,
    private _xmlService: XmlService,
    private _massiveService: MassiveService,
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _storeService: StoreService,
    private _companyService: CompanyService,
    private _invoiceService: InvoiceService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    //this.respuesta = factura.megaprint;
  }

  //public respuesta: any;

  async ngOnInit(): Promise<void> {
    // const urlTree = this.router.parseUrl(this.router.url);
    // const location = urlTree.root.children.primary.segments[0].path;

    this.nameDTE = 'Facturas';
    this.typeDTE = 'FACTURA';

    this.loading = true;
    const _data = JSON.parse(localStorage.getItem('data'));
    this.data.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.data.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.data.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    const store = await this._storeService.getOne(this.data.storeCode);
    const company = await this._companyService.getOne(this.data.companyCode);
    this.store = store.records[0];
    this.company = company.records[0];
    this.loading = false;

    // console.log(this.company);
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.rowsDocument = this.documentList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  deleteFunction($event) {
    $event.preventDefault();
    const row: any = this.activeRow;
    const index = this.rowsDocument.findIndex(x => x.name === row.name);
    this.rowsDocument.splice(index, 1);
    this.documentCount--;
  }

  async onFileChanged(evt: any){

    this.loading = true;
    this.disabled = true;

    const target: DataTransfer = <DataTransfer>(evt.target);

    // console.log ('archivo', target);

    let data;

    if (target.files.length > 1) {
      alert('Multiple files are not allowed');
      return;
    }
    else {
      
      const reader: FileReader = new FileReader();
      reader.onload = async (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        data = (XLSX.utils.sheet_to_json(ws, { header: 2 }));
        // Print the Excel Data
        // console.log(data);

        // console.log('Row',data[1]);              

        await data.forEach(async (r, i) => {
          setTimeout(async () => {
            console.log('rows',e);
            const json: any = await this._massiveService.convertXLStoJson(r, this.data.userCode, this.company, this.store);
            console.log('json invoice', json);

            let error = ""

            if(json.hasOwnProperty('errorDate')){
              this._utilsService.showNotification(5, json.errorDetail + ' Registro No. ' + r.No);

              this.error = true;
              this.errorCount++;
              this.rowsDocumentError.push({ name: r.NombreCliente, identificacion: r.Identificacion, error: json.errorDetail, no: r.No });
              this.rowsDocumentError = [...this.rowsDocumentError];
            }
            else{
              this.rowsDocument.push({
                name: r.NombreCliente,
                identificacion: r.Identificacion,
                type: 'FE',
                error: error,
                json: json,
                no: r.No
              });
              this.documentCount++;
            }

            this.rowsDocument = [...this.rowsDocument];
            this.documentList = this.rowsDocument;
          }, i * 350);
          // console.log('rows',e);
          // const json: any = await this._massiveService.convertXLStoJson(r, this.data.userCode, this.company, this.store);
          // console.log('json invoice', json);

          // let error = ""

          // if(json.hasOwnProperty('errorDate')){
          //   this._utilsService.showNotification(5, json.errorDetail + ' Registro No. ' + r.No);

          //   this.error = true;
          //   this.errorCount++;
          //   this.rowsDocumentError.push({ name: r.NombreCliente, identificacion: r.Identificacion, error: json.errorDetail, no: r.No });
          //   this.rowsDocumentError = [...this.rowsDocumentError];
          // }
          // else{
          //   this.rowsDocument.push({
          //     name: r.NombreCliente,
          //     identificacion: r.Identificacion,
          //     type: 'FE',
          //     error: error,
          //     json: json,
          //     no: r.No
          //   });
          //   this.documentCount++;
          // }

          // this.rowsDocument = [...this.rowsDocument];
          // this.documentList = this.rowsDocument;
        });
        
        this.file_input.nativeElement.value = null;
        console.log('rowsDocument', this.rowsDocument);

        // const json: any = await this._massiveService.convertXLStoJson(data[1], this.data.userCode, this.company, this.store);        
        
        // console.log('json invoice', json);
      }

      await reader.readAsBinaryString(target.files[0]);      

      this.file = {};

    }

    this.loading = false;
    this.disabled = false;

  }

  async generarFacturas(){

    this.loading = true;
    this.disabled = true;

    console.log('rowsDocument', this.rowsDocument);

    for (const json of this.rowsDocument) {
      console.log('jsonName->',json.name);
      console.log('json->', json);

      let jsonInvoice = json.json;

      await this._invoiceService.insertBilling(jsonInvoice).then(
        (response) => {
          if (response.result) {
  
            console.log('respuesta firma',{ name: json.name, identificacion: json.identificacion, url: response.invoiceAuthorization });
            this.rowsDocumentCertified.push({ name: json.name, identificacion: json.identificacion, url: response.invoiceAuthorization, status: true, no: json.no });
            this.CertifiedCount++;

            console.log('rowsDocumentCertified',this.rowsDocumentCertified);
  
            // console.log(response);
            // if (this.company.companyPDFType == 1) {
            //   this.fel = response.infileBody;
            //   this.felDetail = response.infileBody.detail;
            //   this.dte = {
            //     serie: response.records.infileServiceResponse.registerResponse.serie,
            //     noFactura: response.records.infileServiceResponse.registerResponse.numero,
            //     autorizacion: response.invoiceAuthorization,
            //     certificador: "INFILE, S.A.",
            //     nitCertificador: 12521337,
            //     fechaCertificacion: response.records.infileServiceResponse.registerResponse.fecha
            //   }
            //   //this.viewPrint();
            // } else {
            //   let uuid = response.invoiceAuthorization;
            //   window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');
            //   //this.viewBeginning();
            //   //this.cleanModel();
            // }

            this._utilsService.showNotification(1, response.message);
            // 
          } else {
            //this._utilsService.showNotification(3, response.errorMessage);
            this.error = true;
            this.errorCount++;
            //console.log(`Document: ${json.name}. Error: ${response.errorMessage}`);              
            this.rowsDocumentError.push({ name: json.name, identificacion: json.identificacion, error: response.errorMessage, no: json.no });
            this.rowsDocumentError = [...this.rowsDocumentError];
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.rowsDocumentError = [...this.rowsDocumentError];
        this.rowsDocumentCertified = [...this.rowsDocumentCertified];              
      });

    }

    this.loading = false;
    this.disabled = false;

    if (this.error) {
      this._utilsService.showNotification(2, `Hubieron ${this.errorCount} documentos no certificados. ¡Intenta de nuevo!`);
    } else {
      this._utilsService.showNotification(1, `¡Todos los documentos se certificaron exitosamente!`);
    }

  }

  printInvoiceMassive($event) {
    $event.preventDefault();
    const line: any = this.activeRow;
    let uuid = line.url;
    window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');
  }

  ///////////////////////FUNCION LEER DOCUMENTOS///////////////////////
  async fileChanged(f) {
    this.loading = true;
    this.disabled = true;

    for (let i = 0; i < f.target.files.length; i++) {
      const file = f.target.files.item(i)
      const base64 = await this.convertBase64(file);
      const text = await file.text();

      const company = await this._companyService.getOne(this.data.companyCode);
      this.company = company.records[0];
      
      console.log('Company Frases', this.company.phrase);
      const json: any = await this._xmlService.convertXMLtoJSON(this.typeDTE, this.walmartDTE, text, this.data.userCode, this.company, this.store, base64.toString());
      const error = '';

      console.log('json');
      console.log(json);
      console.log('clientError',json.hasOwnProperty('errorClient'));
      console.log('documentError',json.hasOwnProperty('errorDocument'));      

      if (json != false) {
        if(json == true){
          console.log("error monto");
          this._utilsService.showNotification(5, 'Revise la información de NIT/CUI/EXT y el MONTO TOTAL; no se permite operar como CF documentos mayores a Q.2,500.00 ');
        }
        else{
          if(json.hasOwnProperty('errorClient')){
            this._utilsService.showNotification(5, json.errorDetail);
          }
          else if(json.hasOwnProperty('errorDocument')){
            //let mensaje = json.errorDetail + ' ${f.target.files[i].name}';
            this._utilsService.showNotification(5, `Documento ${f.target.files[i].name} ya fue generado anteriormente`);
          }
          else{
            this.rowsDocument.push({
              name: f.target.files[i].name,
              type: f.target.files[i].type,
              text: text.toString(),
              error: error,
              json: json
            });
          }          
        }
        
      }
      else {
        this._utilsService.showNotification(2, `Documento ${f.target.files[i].name} no permitido`);
      }
    };
    this.rowsDocument = [...this.rowsDocument];
    this.documentList = this.rowsDocument;
    this.file_input.nativeElement.value = null;
    this.disabled = false;
    this.loading = false;
    this.file = {};
  }

  convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  ///////////////////////FACTURAR XML///////////////////////
  async generate() {
    this.error = false;
    this.errorCount = 0;
    this.loading = true;
    this.disabled = true;

    for (const json of this.rowsDocument) {
      console.log('jsonName->',json.name);
      console.log('json->', json);
            
      const hour = await this._utilsService.dateTime('HH:mm:ss');

      let adenda_walmart = false;
      if (this.walmartDTE === true) {
        ((json.json.walmart.WMEnviarGLN).length == 0) && (adenda_walmart = true);
        ((json.json.walmart.WMFechaOrden).length == 0) && (adenda_walmart = true);
        ((json.json.walmart.WMNumeroOrden).length == 0) && (adenda_walmart = true);
        ((json.json.walmart.WMNumeroVendedor).length == 0) && (adenda_walmart = true);
        ((json.json.walmart.WMNumeroRecepcion).length == 0) && (adenda_walmart = true);
      }

      if (this.typeDTE == 'NOTACREDITO') {
        //json.json.creditNote.creditNoteDate += ` ${hour}`;

        await this._invoiceService.insertCreditNote(json.json).then(
          async (response) => {
            if (response.result) {              
              const xml = response.records.megaprintServiceResponse.signResponse.signMegaprint.FirmaDocumentoResponse.xml_dte[0];
              this.rowsDocumentCertified.push({ name: json.name, pdf: response.creditNotePdf, xml: xml })              
            } else {
              this.error = true;
              this.errorCount++;
              console.log(`Document: ${json.name}. Error: ${response.errorMessage}`);
              this.rowsDocumentError.push({ name: json.name, error: response.errorMessage })
              this.rowsDocumentError = await [...this.rowsDocumentError];              
            }
          },
          error => {
            console.log(`Document: ${json.name}. Error: ${error}`);
          });
      } else if (this.typeDTE == 'FACTURA' && adenda_walmart == false) { 
        json.json.invoiceExchange.invoiceExchangeDate += ` ${hour}`;

        await this._invoiceService.insertInvoiceExchange(json.json).then(
          async (response) => {
            if (response.result) {

              console.log('response ->', response);

              if(response.error == "1"){

                this.warning = true;
                this.warningCount++;
                const xml = response.records.megaprintServiceResponse.signResponse.signMegaprint.FirmaDocumentoResponse.xml_dte[0];
                this.rowsDocumentWarning.push({ name: json.name, pdfUUID: response.uuidRegister, xml: xml, error: response.messageError });
                this.rowsDocumentWarning = await [...this.rowsDocumentWarning];

              }
              else if(response.error == "0"){

                const xml = response.records.megaprintServiceResponse.signResponse.signMegaprint.FirmaDocumentoResponse.xml_dte[0];
                this.rowsDocumentCertified.push({ name: json.name, pdf: response.invoiceExchangePdf, xml: xml });

              }              

            } else {
              this.error = true;
              this.errorCount++;
              console.log(`Document: ${json.name}. Error: ${response.errorMessage}`);              
              this.rowsDocumentError.push({ name: json.name, error: response.errorMessage })
              this.rowsDocumentError = await [...this.rowsDocumentError];              
            }
          },
          error => {
            console.log(`Document: ${json.name}. Error: ${error}`);
          });
      } 
      else if (adenda_walmart == true) {        
        this.error = true;
        this.errorCount++;
        this.rowsDocumentError.push({ name: json.name, error: 'Adendas Walmart no ingresadas' })
        this.rowsDocumentError = await [...this.rowsDocumentError];
      }
    }

    if (this.error) {
      this._utilsService.showNotification(2, `Hubieron ${this.errorCount} documentos no certificados. ¡Intenta de nuevo!`);
    } else {
      this._utilsService.showNotification(1, `¡Todos los documentos se certificaron exitosamente!`);
    }
    this.rowsDocument = await [...this.rowsDocumentCertified];

    this.loading = false;
    this.disabled = false;
  }



  ///////////////////////FUNCION MODALES///////////////////////
  viewNCFunction($event) {
    $event.preventDefault();

    console.log(this.activeRow);
    let detail = '';
    for (const e of this.activeRow.json.detail) {
      detail += `<tr><td>${e.description}</td><td>${e.total}</td></tr>`;
    }

    swal.fire({
      title: "Datos:",
      html:
        `<div>
        <b>Nit/CUI/EXT: </b> ${this.activeRow.json.receiver.receiverTaxDocument}<br>
        <b>Nombre: </b> ${this.activeRow.json.receiver.receiverName}<br>
        <b>Dirección: </b> ${this.activeRow.json.receiver.receiverAddress}<hr>
        <table style="width:100%">
        <tr><th>Item</th><th>Subtotal</th></tr>
        ${detail}
        </table>
        <hr>Moneda: ${this.activeRow.json.creditNote.creditNoteCurrency}.  Cambio: ${this.activeRow.json.creditNote.creditNoteChangeRate}<br> 
        <b>Total: ${this.activeRow.json.creditNote.creditNoteChangeRate}</b><hr>
        <b>Fecha Nota: </b> ${this.activeRow.json.creditNote.creditNoteDate}<br>
        <b>Fecha Factura: </b>${this.activeRow.json.creditNote.creditNoteSourceDocumentIssuanceDate}<br>
        <b>Concepto Nota: </b>${this.activeRow.json.creditNote.creditNoteFitMotif}<br>        
        <b>Autorización: </b>${this.activeRow.json.creditNote.creditNoteOrigialDocumentAuthorizationNumber}<br>
        <b>Autorización: </b>
        <input type="text" class="form-control form-control-sm" value='${this.activeRow.json.creditNote.creditNoteOrigialDocumentAuthorizationNumber}/>
        </div>`,
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    });
  }

  viewFunction($event) {
    $event.preventDefault();

    let detail = '';
    for (const e of this.activeRow.json.detail) {
      detail += `<tr><td>${e.description}</td><td>${e.total}</td></tr>`;
    }

    swal.fire({
      title: "Datos:",
      html:
        `<div>
          <b>Emisión: </b> ${this.activeRow.json.invoiceExchange.invoiceExchangeDate}  |  <b>Vencimiento: </b> ${this.activeRow.json.detailsComplement[0].invoiceExchangeSubscriptionDate}<hr>
          <b>Nit/CUI/EXT: </b> ${this.activeRow.json.receiver.receiverTaxDocument}<br>
          <b>Nombre: </b> ${this.activeRow.json.receiver.receiverName}<br>
          <b>Dirección: </b> ${this.activeRow.json.receiver.receiverAddress}<hr>
            <table style="width:100%">
              <tr><th>Item</th><th>Subtotal</th></tr>
              ${detail}
            </table>
          <hr>Moneda: ${this.activeRow.json.invoiceExchange.invoiceExchangeCurrency}.  Cambio: ${this.activeRow.json.invoiceExchange.invoiceExchangeChangeRate}<br> 
          <b>Total: ${this.activeRow.json.invoiceExchange.invoiceExchangeTotal}</b>
        </div>`,
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    });
  }

  viewFunctionWl(content, documentName) {
    this.walmart.WMEnviarGLN = this.activeRow.json.walmart.WMEnviarGLN;
    this.walmart.WMFechaOrden = this.activeRow.json.walmart.WMFechaOrden;
    this.walmart.WMNumeroOrden = this.activeRow.json.walmart.WMNumeroOrden;
    this.walmart.WMNumeroVendedor = this.activeRow.json.walmart.WMNumeroVendedor;
    this.walmart.WMNumeroRecepcion = this.activeRow.json.walmart.WMNumeroRecepcion;

    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then(
      async (result) => {
        const index = await this.rowsDocument.findIndex(x => x.name === documentName);
        this.rowsDocument[index].json.walmart.WMEnviarGLN = this.walmart.WMEnviarGLN;
        this.rowsDocument[index].json.walmart.WMFechaOrden = this.walmart.WMFechaOrden;
        this.rowsDocument[index].json.walmart.WMNumeroOrden = this.walmart.WMNumeroOrden;
        this.rowsDocument[index].json.walmart.WMNumeroVendedor = this.walmart.WMNumeroVendedor;
        this.rowsDocument[index].json.walmart.WMNumeroRecepcion = this.walmart.WMNumeroRecepcion;

        // hacer el push y limpiar variable
        this.walmart = {} as Walmart;
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.walmart = {} as Walmart;
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ///////////////////////FUNCION IMPRIMIR DTE///////////////////////
  printInvoice($event) {
    $event.preventDefault();
    const line: any = this.activeRow;
    this._printService.printMegaprint(line.pdf);
  }

  async printInvoiceMega($event, UUID) {
    $event.preventDefault();
    const line: any = this.activeRow;
    console.log('line: ', UUID);

    let jsonPDF = {
      "transmitter": {
        "transmitterTaxDocument": this.company.companyTaxDocument,
        "transmitterUser": this.company.companyPrefix,
        "transmitterPrefix": this.company.companyPrefix, //estaba oculto
        "transmitterToken": this.company.companyToken, //estaba oculto
        "transmitterKey": this.company.companyKey
      },
      "authUuid": UUID
    };
    let Base64 = await this._invoiceService.getPDFMegaprintExchange(jsonPDF);
    console.log('Base64: ', Base64);
    console.log('Base64 ->', Base64.RetornaPDFResponse.pdf[0])
    this._printService.printMegaprint(Base64.RetornaPDFResponse.pdf[0]);
  }

  ///////////////////////DESCARGAR XML FIRMADO///////////////////////
  downloadFunction($event: Event, name: string, xml: string) {
    // https://variabletecnica.com/2020/09/21/descargar-archivos-con-javascript/
    $event.preventDefault();

    const dataXML = [xml];
    const data = new Blob(dataXML, { type: 'text/xml' });

    const save = document.createElement('a');
    save.download = name;
    save.target = '_blank';
    save.href = URL.createObjectURL(data);

    var eventoClic = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': true
    });
    save.dispatchEvent(eventoClic);
    URL.revokeObjectURL(save.href);
  }

  ///////////////////////FUNCION LIMPIAR MODELO///////////////////////
  async cleanModel() {
    this.file = {};
    this.error = false;
    this.errorCount = 0;
    this.documentCount = 0;
    this.CertifiedCount = 0;
    this.warning = false;
    this.warningCount = 0;
    this.documentList = []
    this.rowsDocument = [];
    this.rowsDocumentError = [];
    this.rowsDocumentCertified = [];
    this.rowsDocumentWarning = [];

    const company = await this._companyService.getOne(this.data.companyCode);
    this.company = company.records[0];
    console.log(this.company);
  }
}
