import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import * as printJS from 'print-js';
import { Store } from 'src/app/models/store';
import { Client } from "src/app/models/client";
import { Company } from "src/app/models/company";
import { StoreService } from 'src/app/services/store.service';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { InvoiceHeader } from '../../../../models/invoiceHeader';
import { InvoiceDetail } from '../../../../models/invoiceDetail';
import { InvoiceService } from 'src/app/services/invoice.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-inventory-fuel',
  templateUrl: './inventory-fuel.component.html',
  styleUrls: ['./inventory-fuel.component.scss'],
  providers: [UtilsService, ClientService, StoreService, CompanyService, InventoryService, InvoiceService]

})
export class InventoryFuelComponent implements OnInit {
  // Variables auxiliares
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  public nowDay: string;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  loading;

  public isBeginning: boolean;
  public isBilling: boolean;
  public isPrint: boolean;
  public isEdit: boolean;
  public isDisabled: boolean;
  public recurrentClient: boolean;
  public insertNewClient: boolean = false; //crear cliente si no existe

  // DATA SELECT
  public id: number;
  public itemName: string;
  public selectedItems = [];
  public inventoryList: any[] = new Array();
  public dropdownSettings: IDropdownSettings = {};
  closeDropdownSelection = false;

  // Variables de Linea de Compra
  public itemCode: string;
  public stock: number;
  public moneda: string;
  public invoiceLine: any = new Array;
  public addAmount: boolean;
  public idp: number;
  public subIdp: number;
  public subsidio: number;
  public subsidioTemp: number;
  public petroleo: string;
  public taxOilCode: number;
  public taxOilAmount: number;
  public dte: any;
  public fel: any;
  public felDetail: any;

  public disabled = false;
  public disabledEXT = false;
  public facturando: boolean;

  public tipoCliente = 0;
  public FraseFact: string;
  public FormaCalculo: number;
  public tipoPago = 0;

  // DATA SELECT PAYMENT
  public idPayment: number;
  public itemNamePayment: string;
  public selectedItemsPayment = [];
  public PaymentList: any[] = new Array();
  public dropdownSettingsPayment: IDropdownSettings = {};
  closeDropdownSelectionPayment = false;
  
  public PaymentLine: any = new Array;
  public PaymentType = 0;
  public NamePaymentType = 0;
  public MontoPayment: number = 0.00;
  public MontoTotalPayment: number = 0.00;
  public Autorizacion: string = "0";
  public disabledPay = false;
  public isDisabledInvoice = false;
  public isMethodPay = false;

  constructor(
    private _utilsService: UtilsService,
    private _storeService: StoreService,
    private _clientService: ClientService,
    private _companyService: CompanyService,
    private _invoiceService: InvoiceService,
    private _inventoryService: InventoryService,
    private cdref: ChangeDetectorRef,
    private router: Router
  ) { }

  async ngOnInit() {
    this.addAmount = true;
    this.isEdit = false;
     this.isDisabled = false;
    this.disabled = false;
    this.insertNewClient = false;
    this.idp = 0;
    this.subIdp = 0;
    this.subsidio = 0;
    this.subsidioTemp = 0;
    this.moneda = "Q";
    this.petroleo = null;
    this.taxOilAmount = 0;
    this.taxOilCode = null;
    this.invoiceHeader.invoiceIDP = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceDetail.invoiceDetailQuantity = 1;
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.paymentType = 0;
    this.tipoPago = 0;
    this.FormaCalculo = 0;

    // DATE
    this.nowDay = this._utilsService.dateTime('yyy-MM-dd');
    this.invoiceHeader.invoiceDate = this.nowDay;

    // GET ID USER & ID COMPANY
    const  _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.viewBeginning();
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
    await this.getPaymetList(this.storeCode);
    await this.getInventoryFuelList(this.storeCode);
    this.getSelectSetting(this.selectedItems);
    this.getSelectSettingPaymet(this.selectedItemsPayment);

    console.log('Select -> ', this.selectedItems);
    console.log('Select Tipo pagos -> ', this.selectedItemsPayment);
    console.log('Tipo pagos -> ', this.PaymentList);

    await this.Pagado();

    this.SKUx = "";
    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.disabledEXT = true;
  }

  async onItemSelectPaymet(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItemPayment = await this.PaymentList.find(x => x.id == item.id);
    console.log(_selectedItemPayment);

    this.tipoPago = item.payMethodBusinessCode;
    console.log('Tipo Pago -> ', this.tipoPago);

    this.PaymentType = this.tipoPago;
    this.NamePaymentType = item.payMethodName;

    // this.itemCode = item.idPayment;
    // this.invoiceDetail.invoiceDetailDescription = item.itemNamePayment;
    // this.stock = _selectedItem.inventoryQuantity;
    // this.Minstock = _selectedItem.inventoryMin;
  }

  getPaymetList(code) {
    return this._storeService.getPaymets(code).then(
      response => {
        if (response.result) {
          this.PaymentList = response.records;
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getSelectSettingPaymet(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItemsPayment = [selected] : '';

    this.dropdownSettingsPayment = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'payMethodBusinessCode',
      textField: 'payMethodName'
    };
  }

  async addPaymetDetail(){

    this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment + (this.MontoPayment))).toFixed(6));
    console.log(this.MontoTotalPayment);

    await this.PaymentLine.push({
      typePayment: this.PaymentType,
      NamePaymentType: this.NamePaymentType,
      MontoPayment: this.MontoPayment,
      Autorizacion: this.Autorizacion
    });

    this.MontoPayment = 0;
    this.Autorizacion = "0";
    this.selectedItemsPayment = [];

    this.Pagado();

    console.log(this.PaymentLine);
  }

  async deletePaymetDetail(item) {

    await this.PaymentLine.forEach(async (e, index) => {
      if (e.typePayment === item) {

        this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment - (e.MontoPayment))).toFixed(6));        
        this.PaymentLine.splice(index, 1);
      }
    })

    this.Pagado();
        
    console.log(this.PaymentLine);
  }

  async Pagado(){

    if(this.company.companyPaymentType == 1){
      this.isMethodPay = true;
      if((this.MontoTotalPayment == this.invoiceHeader.invoiceTotal) && this.MontoTotalPayment > 0){
        this.isDisabledInvoice = false;
      }
      else if(this.MontoTotalPayment > this.invoiceHeader.invoiceTotal){
        this.isDisabledInvoice = true;
        this._utilsService.showNotification(2,"Total pagado es mayor al Total de la factura")
      }
      else{
        this.isDisabledInvoice = true;
      }
    }
    else{
      this.isMethodPay = false;
      this.isDisabledInvoice = false;
    }
  }

  public SKUx: string;
  async CargarSKU() {
    var sku;
    sku = this.SKUx;
    //alert(sku);

    if(sku.length > 3){
      const _selectedItem = await this.inventoryList.find(x => x.id == sku);

      //SI ES PETROLIO
      this.idp = 0;
      this.subIdp = 0;
      this.subsidioTemp = 0;
      this.petroleo = null;
      if (_selectedItem.inventoryProduct == 3) {
        this.idp = 4.7;
        this.taxOilCode = 1;
        this.petroleo = "PETROLEO";
      } else if (_selectedItem.inventoryProduct == 4) {
        this.idp = 4.6;
        this.subsidioTemp = 2.5;
        this.taxOilCode = 2;
        this.petroleo = "PETROLEO";
      } else if (_selectedItem.inventoryProduct == 5) {
        this.idp = 1.3;
        this.subsidioTemp = 5;
        this.taxOilCode = 4;
        this.petroleo = "PETROLEO";
      }

      this.itemCode = sku;
      this.invoiceDetail.invoiceDetailDescription = _selectedItem.itemName;
      this.invoiceDetail.invoiceDetailType = 'B';
      this.stock = _selectedItem.inventoryQuantity;

      if (_selectedItem.inventorySerialFlag == 1) {
        this.loading = true;
        const response = await this._inventoryService.getItemSerial(this.itemCode);

        this.loading = false;
      } else {
      }

      await this.saveLineInvoice();
      this.SKUx = "";      
    }
    
  }

  onChangepaymentType(event) {
    this.invoiceHeader.paymentType = event;
    this.tipoPago = event;
    console.log(this.tipoPago);
  }

  setPhase(PhraseType){
    if(PhraseType == "1"){
      this.FraseFact = "Sujeto a pagos trimestrales ISR";
      console.log(this.FraseFact);
    }
    else if(PhraseType == "2"){
      this.FraseFact = "Agente retenedor de IVA";
      console.log(this.FraseFact);
    }
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          // console.log(response.records[0]);
          this.company = response.records[0];
          this.setPhase(this.company.phrase[1].phrase);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      response => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////SELECT///////////////////////
  getSelectSetting(selected) {
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'itemName'
    };
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  getInventoryFuelList(store) {
    return this._inventoryService.getFuelByStore(store).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  async onItemSelect(item: any) {
    const _selectedItem = this.inventoryList.find(x => x.id == item.id);

    //QUE PETROLIO ES
    this.idp = 0;
    this.subIdp = 0;
    this.subsidioTemp = 0;
    this.petroleo = undefined;
    if (_selectedItem.inventoryProduct == 3) {
      this.idp = 4.7;
      this.taxOilCode = 1;
      this.petroleo = "PETROLEO";
    } else if (_selectedItem.inventoryProduct == 4) {
      this.idp = 4.6;
      this.taxOilCode = 2;
      // this.subsidioTemp = 2.5;
      this.petroleo = "PETROLEO";
    } else if (_selectedItem.inventoryProduct == 5) {
      this.idp = 1.3;
      this.taxOilCode = 4;
      this.subsidioTemp = 5;
      this.petroleo = "PETROLEO";
    }

    this.itemCode = item.id;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.stock = _selectedItem.inventoryQuantity;
    this.invoiceDetail.invoiceDetailDescription = _selectedItem.inventoryName;
    this.invoiceDetail.invoiceDetailUnitPrice = _selectedItem.inventoryPrice;
  }

  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  onChange(event) {
    this.invoiceHeader.invoiceCoin = event;

    if (this.invoiceHeader.invoiceCoin == 0) {
      this.moneda = "Q";
      this.invoiceHeader.invoiceExchangeRate = null;
    } else if (this.invoiceHeader.invoiceCoin == 1) {
      this.moneda = "$"
    }
  }

  onChangeForma(event) {
    this.FormaCalculo = event;
  }

  ///////////////////////FUNCTION VALIDATE DATE///////////////////////
  validateDate($event) {
    $event.preventDefault();
    var valid = false;
    var now = this.nowDay.split('-');
    var date = this.invoiceHeader.invoiceDate.split('-');

    if (date[0] == now[0] || parseFloat(date[0]) == parseFloat(now[0]) - 1) {
      if (date[1] == now[1]) {
        if (parseFloat(date[2]) <= parseFloat(now[2]) + 5 && parseFloat(date[2]) >= parseFloat(now[2]) - 5) {
          valid = true;
        }
      }
      else if (parseFloat(date[1]) == parseFloat(now[1]) - 1 || parseFloat(date[1]) == 12) {
        if (parseFloat(date[2]) >= 26) {
          valid = true;
        }
      }
    }

    if (valid == false) {
      this._utilsService.showNotification(2, 'Fecha fuera de rango')
      this.invoiceHeader.invoiceDate = this.nowDay;
    };
  }

  ///////////////////////FUNCTION CHANGE INTERAL SERIAL///////////////////////
  onChangeInternSerial(event) {
    this.invoiceHeader.invoiceInternSerial = event;
  }

  // GUARDAR UNA LINEA DE COMPRA
  saveLineInvoice() {
    //Evaluar que venga mayor a 0 la cantidad
    if (this.invoiceDetail.invoiceDetailQuantity > 0) {

      //Actualizar?
      let update = false;
      this.invoiceLine.forEach((e) => {

        if (e.itemCode == this.itemCode) {
          //Validacion
          let quantity;
          this.isEdit === true ? quantity = this.invoiceDetail.invoiceDetailQuantity : quantity = e.quantity + this.invoiceDetail.invoiceDetailQuantity;
          if (this.company.companyExistenceFlag === 1 || quantity <= this.stock) {
            //Restar Encabezado
            this.subsidio -= e.subsidio;   
            this.invoiceHeader.invoiceTotal -= parseFloat((e.total).toFixed(6));
            this.invoiceHeader.invoiceIDP -= parseFloat((e.quantity * this.idp).toFixed(6)); //e.taxOilAmount
            //this.invoiceHeader.invoiceTax -= parseFloat((e.taxAmount).toFixed(6));

            //Realizar Calculos
            if (parseFloat(e.discount) > 0) {
              this.invoiceDetail.invoiceDetailSubtotal = parseFloat((quantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
              this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - e.discount).toFixed(6));
              this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / 1.12)).toFixed(6));
            } else {
              this.invoiceDetail.invoiceDetailDiscount = e.discount;
              this.invoiceDetail.invoiceDetailSubtotal = quantity * this.invoiceDetail.invoiceDetailUnitPrice;
              this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / 1.12)).toFixed(6));
            }

            //Editar Linea
            e.quantity = quantity;
            e.taxOilQuantity = quantity;
            e.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6));
            e.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(6));
            e.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6));
            e.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal + (parseFloat((quantity * this.idp).toFixed(6)))).toFixed(6));
            e.taxOilAmount = parseFloat((quantity * this.idp).toFixed(6));
            e.subsidio = (quantity * this.subsidioTemp);

            //Sumar Encabezado 
            this.subsidio += e.subsidio;
            this.invoiceHeader.invoiceIDP += parseFloat((quantity * this.idp).toFixed(6));
            this.invoiceHeader.invoiceTotal += parseFloat((this.invoiceDetail.invoiceDetailSubtotal + (parseFloat((quantity * this.idp).toFixed(6)))).toFixed(6));
            //this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(6));
            this.cleanLineInvoice();
          } else {
            this._utilsService.showNotification(2, 'Cantidad Excede Exitencia');
          }
          update = true;
          this.isEdit = false;
          this.addAmount = false;
          this.isDisabled = false;
          this.disabled = false;
        }
      });

      //Nuevo Item
      if (this.addAmount === true && update === false) {
        console.log(this.invoiceDetail);
        console.log(this.idp);
        console.log('FormaCalc-> ', this.FormaCalculo);

        if(this.FormaCalculo == 0){
          //Calculo por monto
          let CantidadGlns = 0.000000;
          let precioUnit_C_Idp = parseFloat((this.invoiceDetail.invoiceDetailUnitPrice + this.idp).toFixed(6));
          CantidadGlns = parseFloat((this.invoiceDetail.invoiceDetailQuantity / precioUnit_C_Idp).toFixed(6));
          let total = parseFloat((CantidadGlns * precioUnit_C_Idp).toFixed(2));
          console.log('Cantidad-> ', CantidadGlns);
          console.log('Total Q-> ', total);

          /******/
          this.subIdp = parseFloat((CantidadGlns * this.idp).toFixed(6));
          this.invoiceDetail.invoiceDetailSubtotal = parseFloat((CantidadGlns * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
          this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / 1.12)).toFixed(6));
          this.invoiceDetail.invoiceDetailQuantity = CantidadGlns;
          /******/
        }
        else{
           //Realizar Calculos
          this.subIdp = parseFloat((this.invoiceDetail.invoiceDetailQuantity * this.idp).toFixed(6));
          this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
          this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / 1.12)).toFixed(6));
        }

        //Validacion
        if (this.company.companyExistenceFlag === 1 || this.invoiceDetail.invoiceDetailQuantity <= this.stock) {

          //Guardar Linea
          this.invoiceLine.push({
            //Area de combustible
            subsidio: this.subsidioTemp,
            isOil: 1,
            taxOilUP: this.idp,
            taxOilAmount: this.subIdp,
            taxOilName: this.petroleo,
            taxOilCode: this.taxOilCode,
            taxOilQuantity: this.invoiceDetail.invoiceDetailQuantity,
            itemCode: this.itemCode,
            measure: "UNI",
            taxCode: 1,
            taxName: "IVA",
            isService: this.invoiceDetail.invoiceDetailType,
            quantity: this.invoiceDetail.invoiceDetailQuantity,
            description: this.invoiceDetail.invoiceDetailDescription,
            discount: 0,
            unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(6)),
            price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6)),
            taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(6)),
            taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6)),
            total: parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.subIdp).toFixed(6)),
          });
          this.subsidio += (this.invoiceDetail.invoiceDetailQuantity * this.subsidioTemp);
          this.invoiceHeader.invoiceIDP = parseFloat((this.invoiceHeader.invoiceIDP + this.subIdp).toFixed(6));
          //this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax + this.invoiceDetail.invoiceDetailTax).toFixed(6));
          this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + this.invoiceDetail.invoiceDetailSubtotal + this.subIdp).toFixed(6));
          this.cleanLineInvoice();
        } else {
          this._utilsService.showNotification(2, 'Cantidad Excede Exitencia');
        }
         this.isDisabled = false;
    this.disabled = false;
      }
    } else {
      this._utilsService.showNotification(2, "Ingresa una cantidad")
    }
    this.ValidarCF();
  }

  // APLICAR DESCUENTO EN TIEMPO REAL
  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (parseFloat(descuento) <= temporal) {
          // if (parseFloat(descuento) <= e.price) {  
          this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal - parseFloat(e.price)).toFixed(6));

          e.discount = parseFloat(descuento.toFixed(6));
          e.price = parseFloat((temporal - e.discount).toFixed(6)); //parseFloat((e.price - e.discount).toFixed(6)); 
          e.total = parseFloat((e.total - e.discount).toFixed(6)); //parseFloat((e.total - e.discount).toFixed(6)); 
          e.taxAmount = parseFloat((e.price - (e.price / 1.12)).toFixed(6))
          e.taxableAmount = parseFloat((e.price - e.taxAmount).toFixed(6));

          this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + e.price).toFixed(6));
        } else {
          this._utilsService.showNotification(2, "Cantidad No permitida");
        }
      }
    });
    this.ValidarCF();
  }

  // ELIMINAR UNA LINEA
  deleteLine(item) {
    this.isDisabled = true;
    this.disabled = true;

    this.invoiceLine.forEach((e, index) => {
      if (e.itemCode == item) {
        this.subsidio -= parseFloat(e.subsidio);
        //this.invoiceHeader.invoiceTax = this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount);
        this.invoiceHeader.invoiceIDP = parseFloat((this.invoiceHeader.invoiceIDP - parseFloat(e.taxOilAmount)).toFixed(6));
        this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal - parseFloat(e.price) - parseFloat(e.taxOilAmount)).toFixed(6));
        this.invoiceLine.splice(index, 1);
      }
    })
    this.cleanLineInvoice();
     this.isDisabled = false;
    this.disabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.addAmount = true;
    this.selectedItems = null;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceDetail.invoiceDetailQuantity = 1;
  }

  onChangeTypeClient(event) {    
    this.client.clientType = event;
    this.tipoCliente = event;
    console.log(this.tipoCliente);

    if(event == 2){
      this.disabledEXT = false;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
    else{
      this.disabledEXT = true;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
  }

  ///////////////////////Funcion de Buscar por NIT///////////////////////
  buscarNITLocal($event, nit) {
    $event.preventDefault();
    this.isDisabled = true;
    this.disabled = true;
    this.loading = true;
    console.log(this.client.clientType);

    if(this.client.clientType == 2){

      this.client.clientName = this.client.clientName;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientPhone = "00000000";
      this.client.clientEmail = "info@clever.com";
      this.client.clientAddress = "CIUDAD";

      this.insertNewClient = false;
      this.recurrentClient = false;
      this.viewBilling();
      this._utilsService.showNotification(4, "SE EMITIRA FACTURA CON PASAPORTE");
      this.loading = false;

    }
    else{
      if(nit == "CF"){
        this.client.clientType = 0;
        this.tipoCliente = 0;
      }
      // return this._clientService.getOneByNit(nit).then(
      return this._clientService.getOneByCompanyNit(this.companyCode, nit).then(
        response => {
          // console.log(response.records)
          if (response.records.length > 0) {
            this.client = response.records[0];
            this.recurrentClient = true;
            this.insertNewClient = false;
            this.viewBilling();
            this._utilsService.showNotification(1, response.message);
          } else {
            return this._clientService.searchNitSAT(nit, this.client.clientType).then(
              async response => {
                if (response.tipo_respuesta == 0) {
                  //this.client.clientName = response.nombre[0];
                  //console.log(response.nombre[0]);
                  this.client.clientName =  response.nombre[0];
                  this.client.clientStatus = 1;
                  this.client.clientDeleted = 0;
                  this.client.clientPhone = "00000000";
                  this.client.clientEmail = "info@clever.com";
                  this.client.clientAddress = "CIUDAD";

                  this.insertNewClient = true;
                  this.recurrentClient = false;
                  this.viewBilling();
                  this._utilsService.showNotification(4, "Cliente Nuevo");
                } else {
                  this._utilsService.showNotification(3, response.listado_errores[0].error[0].desc_error[0]);
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;

        this.ValidarCF();
      });
    }
  }

  async insertInvoice() {
    this.loading = true;
    this.isDisabled = true;
    this.disabled = true;

    console.log('TipoCliente -> ', this.tipoCliente);

    //Crear Cliente en la DB
    if (this.insertNewClient) {
      // Resolver problema con DATA BASE
      let nombre;
      nombre = await this.client.clientName.replace(/&/g, '&amp;');
      nombre = await this.client.clientName.replace(/'/g, '&apos;');
      nombre = await this.client.clientName.replace(/"/g, '&quot;');

      this.client.clientName = nombre;
      this.client.clientCredit = 0;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientBlocked = 0;
      this.client.clientInterest = 0;
      this.client.clientDiscount = 0;
      this.client.clientCategory = 2;
      this.client.clientCreditTime = 0;
      this.client.clientCreditAmount = 0;
      this.client.clientCompanyCode = this.companyCode;
      this.client.clientType = this.tipoCliente;
      this.client.clientNameFrequent = '-';

      if (this.client.clientTaxDocument && this.client.clientName) {
        const response = await this._clientService.insert(this.client);
        this.client.clientCode = response.records[0].insertId;
      };
    }

    let transmitterMembership = "GEN";
    let transmitterType = "FACT";

    // Si es factura de pequeño contribuyente
    if (this.company.companyTaxToPay == 5) {
      transmitterMembership = "PEQ";
      transmitterType = "FPEQ";
    }

    this.invoiceHeader.invoiceTax = 0;
    this.invoiceLine.forEach(e => {
      this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax + e.taxAmount).toFixed(6))
    });

    if(this.company.companyPaymentType == 0){
      await this.PaymentLine.push({
        typePayment: 0,
        NamePaymentType: 'Efectivo',
        MontoPayment: parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4))
      });
    }

    const hour = await this._utilsService.dateTime('HH:mm:ss');

    const json = {
      "store": {
        "storeCode": this.storeCode
      },
      "user": {
        "userCode": this.userCode
      },
      "transmitter": {
        "transmitterMembership": transmitterMembership,
        "transmitterType": transmitterType,
        "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
        "transmitterEmail": this.company.companyEmail,
        "transmitterTaxDocument": this.company.companyTaxDocument,
        "transmitterComercialName": this.store.storeName,
        "transmitterName": this.company.companyName,
        "transmitterAddress": this.store.storeAddress,
        "transmitterZipCode": this.store.storeZipCode,
        "transmitterMunicipality": this.store.storeMunicipality,
        "transmitterDepartment": this.store.storeDepartment,
        "transmitterCountry": 'GT',
        "transmitterPrefix": this.company.companyPrefix,
        "transmitterUser": this.company.companyPrefix,
        "transmitterKey": this.company.companyKey,
        "transmitterToken": this.company.companyToken,
        "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
        "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
      },
      "receiver": {
        "receiverAddress": this.client.clientAddress,
        "receiverCode": this.client.clientCode,
        "receiverDeleted": 0,
        "receiverEmail": this.client.clientEmail,
        "receiverName": this.client.clientName,
        "receiverStatus": 1,
        "receiverTaxDocument": this.client.clientTaxDocument,
        "receiverTypeTaxDocument": this.tipoCliente,
        "receiverZipCode": 0,
        "receiverCountry": "GT"
      },
      "invoice": {
        "invoiceCode": 1,
        "invoiceCurrency": "GTQ",
        "invoiceTaxShortName": "IVA",
        "invoiceDate": this.invoiceHeader.invoiceDate + ' ' + hour,
        "invoiceInternSerial": this.invoiceHeader.invoiceInternSerial,
        "invoiceTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(6)),
        "invoiceTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(6)),
        "invoicepaymentType": this.tipoPago,
        "invoiceOilTaxShortName": "PETROLEO",
        "invoiceOilTaxValue": parseFloat((this.invoiceHeader.invoiceIDP).toFixed(6)),
      },
      "detail": this.invoiceLine,
      "paymentType": this.PaymentLine,
      "phrase": this.company.phrase,
      "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
      "serial": [],
    };

    //console.log(json);
    // Insertar Venta    
    // console.log(json);
    // this._invoiceService.insertBilling(json).then(
    //   async (response) => {
    //     if (response.result) {
    //       this.fel = response.megaprintBody;
    //       this.felDetail = response.megaprintBody.detail;
    //       // this.dte = response.records.megaprintServiceResponse.jsonXml['dte:GTDocumento']['dte:SAT'][0]['dte:DTE'][0]['dte:Certificacion'][0];
    //       let dte = response.records.megaprintServiceResponse.jsonXml['dte:GTDocumento']['dte:SAT'][0]['dte:DTE'][0]['dte:Certificacion'][0];
    //       this.dte = {
    //         serie: dte['dte:NumeroAutorizacion'][0]['Serie'][0],
    //         noFactura: dte['dte:NumeroAutorizacion'][0]['Numero'][0],
    //         autorizacion: dte['dte:NumeroAutorizacion'][0]['_'],
    //         certificador: dte['dte:NombreCertificador'][0],
    //         nitCertificador: dte['dte:NITCertificador'][0],
    //         fechaCertificacion: dte['dte:FechaHoraCertificacion'][0]
    //       }
    //       this.viewPrint();
    //       this._utilsService.showNotification(1, response.message);
    //     } else {
    //       this._utilsService.showNotification(3, response.errorMessage);
    //     }
    //   },
    //   error => {
    //     this._utilsService.showNotification(2, error);
    //     console.log(error);
    //   }
    // ).finally(() => {
    //   this.loading = false;
    //    this.isDisabled = false;
    // this.disabled = false;
    // });


    if (this.company.companyCertifier == 0) { //INFILE
      this._invoiceService.insertBilling(json).then(
        (response) => {
          if (response.result) {
            // console.log(response);
            if (this.company.companyPDFType == 1) {
              this.fel = response.infileBody;
              this.felDetail = response.infileBody.detail;
              this.dte = {
                serie: response.records.infileServiceResponse.registerResponse.serie,
                noFactura: response.records.infileServiceResponse.registerResponse.numero,
                autorizacion: response.invoiceAuthorization,
                certificador: "INFILE, S.A.",
                nitCertificador: 12521337,
                fechaCertificacion: response.records.infileServiceResponse.registerResponse.fecha
              }
              this.viewPrint();
            } else {
              let uuid = response.invoiceAuthorization;
              window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');
              this.viewBeginning();
              this.cleanModel();
            }
            this._utilsService.showNotification(1, response.message);
            // 
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
        this.disabled = false;        
      });
    }
    else if (this.company.companyCertifier == 1) { //Megaprint
      this._invoiceService.insertBilling(json).then(
        (response) => {
          if (response.result) {
            // console.log(response);
            if (this.company.companyInvoiceFuel == 1 || this.company.companyPDFType == 1) {
              this.fel = response.megaprintBody;
              this.felDetail = response.megaprintBody.detail;
              let dte = response.records.megaprintServiceResponse.jsonXml['dte:GTDocumento']['dte:SAT'][0]['dte:DTE'][0]['dte:Certificacion'][0];
              this.dte = {
                serie: dte['dte:NumeroAutorizacion'][0]['Serie'][0],
                noFactura: dte['dte:NumeroAutorizacion'][0]['Numero'][0],
                autorizacion: dte['dte:NumeroAutorizacion'][0]['_'],
                certificador: dte['dte:NombreCertificador'][0],
                nitCertificador: dte['dte:NITCertificador'][0],
                fechaCertificacion: dte['dte:FechaHoraCertificacion'][0]
              }
              this.viewPrint();
            } else {
              var pdfResult = response.invoicePdf;
              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")
              this.viewBeginning();
              this.cleanModel();
            }
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
        this.disabled = false;
      });
    }
    this.isDisabled = false;
    this.disabled = false;


    this.insertNewClient = false;
  }

  // Limpiar Campos
  cleanModel() {
    this.addAmount = true;
     this.isDisabled = false;
    this.disabled = false;
    this.insertNewClient = false;
    this.recurrentClient = false;
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceLine = [];
    this.moneda = "Q";
    this.petroleo = null;
    this.taxOilAmount = 0;
    this.taxOilCode = null;
    this.invoiceHeader.invoiceIDP = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.paymentType = 0;

    this.invoiceHeader.invoiceDate = this.nowDay;
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isBilling = false;
    this.isPrint = false;
    this.client.clientType = 0;
    this.disabledEXT = false;
    this.isDisabled = false;
  }

  viewBilling() {
    this.isBeginning = false;
    this.isBilling = true;
    this.isPrint = false;
  }

  viewPrint() {
    this.isBeginning = false;
    this.isBilling = false;
    this.isPrint = true;
  }

  viewCF() {
    this.isBeginning = true;
    this.isBilling = true;
    this.disabled = true;
    //this.isDisabled = true;
    this.disabledEXT = true;
    this.isDisabled = false;
    this.client.clientType = 0;
  }

  ValidarCF() {
    /*J11 - Validar CF*/
    console.log(this.invoiceHeader.invoiceTotal);
    console.log(this.client.clientTaxDocument);

    if(this.invoiceHeader.invoiceTotal >= 2500){
      if(this.client.clientTaxDocument == "CF"){
        this._utilsService.showNotification(5, "No puede facturar como CF, el monto es mayor a 2500");
        this.viewCF();
      }
      else{
        this.disabled = false;
      }
    }
  }

  continue() {
    this.viewBeginning();
    this.cleanModel();
  }

  // PRINT
  printTest() {
    printJS({
      printable: "printSection",
      type: "html",
      style: "#printSection{width: 224px; text-align: center}",
    });
  }
}
