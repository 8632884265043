import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as xlsx from 'xlsx';
import { AccountingService } from 'src/app/services/accounting.service';
import { UtilsService } from 'src/app/services/utils.service';

interface dairyRegisterList {
  dairyRegisterListDate;
  dairyRegisterListTypeEstablishment;
}

interface dairyRegister {
  dairyRegisterAccount; //dairyRegisterCompanyAccount / dairyRegisterStoreAccount : id catalogo de cuentas
  dairyRegisterDate; //dairyRegisterCompanyDate / dairyRegisterStoreDate : fecha
  dairyRegisterDescription; //dairyRegisterCompanyDescription / dairyRegisterStoreDescription : descripcion opcional
  dairyRegisterDocument; //dairyRegisterCompanyDocument / dairyRegisterStoreDocument : documento valida registro
  dairyRegisterType; //dairyRegisterCompanyType / dairyRegisterStoreType : 1=Debe 2=Haber
  dairyRegisterAmount; //dairyRegisterCompanyAmount / dairyRegisterStoreAmount : monto Q
  //dairyRegisterEstablishment; //dairyRegisterCompany / dairyRegisterStore : empresa/sucursal pertenece el registro
  dairyRegisterTypeEstablishment; //
}

@Component({
  selector: 'app-dairy',
  templateUrl: './dairy.component.html',
  styleUrls: ['./dairy.component.scss'],
  providers: [AccountingService, UtilsService]
})
export class DairyComponent implements OnInit {
  // Variables auxiliares
  public dairyRegister = {} as dairyRegister;
  public dairyRegisterList = {} as dairyRegisterList;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public loading;
  public now;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      cuenta: {
        title: 'Cuenta'
      },
      documento: {
        title: 'Documento'
      },
      monto: {
        title: 'Monto',
        type: 'number'
      },
      tipo: {
        title: 'Tipo'        
      },
      descripcion: {
        title: 'descripcion'      
      }
    }
  };

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public diaryList: any[];

  // VARIABLE SELECTED
  public accountTypeList: any[];

  constructor(
    private _utilsService: UtilsService,
    private _accountingService: AccountingService,
  ) { }

  ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    // DATE
    this.now = this._utilsService.dateTime('yyy-MM-dd');

    this.dairyRegister.dairyRegisterType = 1;
    this.dairyRegister.dairyRegisterAmount = 0;
    this.dairyRegister.dairyRegisterAccount = 1;
    this.dairyRegister.dairyRegisterDocument = '';
    this.dairyRegister.dairyRegisterDate = this.now
    this.dairyRegister.dairyRegisterDescription = '';
    this.dairyRegister.dairyRegisterTypeEstablishment = 1;

    this.dairyRegisterList.dairyRegisterListDate = this.now;
    this.dairyRegisterList.dairyRegisterListTypeEstablishment = 1;

    this.getAccountType();
    this.getDairyRegister();
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.diaryList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getAccountType() {
    return this._accountingService.getAccountType().then(
      response => {
        if (response.result) {
          this.accountTypeList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      });
  }

  getDairyRegister(){
    let json;

    if (this.dairyRegisterList.dairyRegisterListTypeEstablishment == 1) {
      json = {
        "date": this.dairyRegisterList.dairyRegisterListDate,
        "store": this.storeCode
      }
    } else {
      json = {
        "date": this.dairyRegisterList.dairyRegisterListDate,
        "company": this.companyCode
      }
    }

    this.loading = true;

      this._accountingService.getDairyRegister(json, this.dairyRegisterList.dairyRegisterListTypeEstablishment).then(
        async (response) => {
          if (response.result == true) {
            this.diaryList = response.records;
            this.temp = this.diaryList;
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });

  }

  // SAVE NEW REGISTER
  saveRegister() {
    let json;

    if (this.dairyRegister.dairyRegisterTypeEstablishment == 1) {
      json = {
        'dairyRegisterStoreAccount': this.dairyRegister.dairyRegisterAccount,
        'dairyRegisterStoreDate': this.dairyRegister.dairyRegisterDate,
        'dairyRegisterStoreDescription': this.dairyRegister.dairyRegisterDescription,
        'dairyRegisterStoreDocument': this.dairyRegister.dairyRegisterDocument,
        'dairyRegisterStoreType': this.dairyRegister.dairyRegisterType,
        'dairyRegisterStoreAmount': this.dairyRegister.dairyRegisterAmount,
        'dairyRegisterStore': this.storeCode
      }
    } else {
      json = {
        'dairyRegisterCompanyAccount': this.dairyRegister.dairyRegisterAccount,
        'dairyRegisterCompanyDate': this.dairyRegister.dairyRegisterDate,
        'dairyRegisterCompanyDescription': this.dairyRegister.dairyRegisterDescription,
        'dairyRegisterCompanyDocument': this.dairyRegister.dairyRegisterDocument,
        'dairyRegisterCompanyType': this.dairyRegister.dairyRegisterType,
        'dairyRegisterCompanyAmount': this.dairyRegister.dairyRegisterAmount,
        'dairyRegisterCompany': this.companyCode
      }
    }

    //console.log(json);
    if (this.dairyRegister.dairyRegisterAmount > 0) {
      this.loading = true;

      this._accountingService.insertDairyRegister(json, this.dairyRegister.dairyRegisterTypeEstablishment).then(
        async (response) => {
          if (response.result == true) {                    
            if(parseFloat(this.dairyRegister.dairyRegisterTypeEstablishment) == parseFloat(this.dairyRegisterList.dairyRegisterListTypeEstablishment)){
              this.getDairyRegister();
            }
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this._utilsService.showNotification(2, 'Llena los campo con *');
    }
  }

  ///////////////////////COMPLEMENT///////////////////////  

  // CLEAR MODAL
  cleanModel() {
    this.dairyRegister = {} as dairyRegister;
    this.dairyRegister.dairyRegisterType = 1;
    this.dairyRegister.dairyRegisterAmount = 0;
    this.dairyRegister.dairyRegisterAccount = 1;
    this.dairyRegister.dairyRegisterDocument = '';
    this.dairyRegister.dairyRegisterDate = this.now
    this.dairyRegister.dairyRegisterDescription = '';
    this.dairyRegister.dairyRegisterTypeEstablishment = 1;
  }

   // DESCARGAR EXCEL
   async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item) => {
        if (this.settings.columns[item].type === "") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-libro-diario.xlsx');
  }
}
