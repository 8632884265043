<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/purchase-view">Procesar Compra</a>
        </div>
    </div>
</div>

<div class="main-content" *ngIf="isViewBeginning">
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado ordenes de compra autorizadas para procesar</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTablePending($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="No." prop="purchaseCode"></ngx-datatable-column>
                            <ngx-datatable-column name="Ingresado"></ngx-datatable-column>
                            <ngx-datatable-column name="Proveedor"></ngx-datatable-column>
                            <ngx-datatable-column name="Monto"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn" let-row="row">
                                    <a href="#" class="btn btn-round btn-warning btn-icon btn-sm edit"
                                        (click)="checkFunctionPDF($event)" ngbTooltip="PDF" placement="left"><i class="fas fa-sticky-note"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm edit"
                                        (click)="checkFunction($event)" ngbTooltip="Revisar" placement="left"><i class="fas fa-eye"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-content" *ngIf="isViewPurchase">
    <div class="text-right">
        <button class="btn btn-success btn-sale" (click)="saveProcess()" [disabled]="isDisabled">
            ✔ Aceptar
        </button>
        <!-- &nbsp;
        <button class="btn btn-grey btn-sale" (click)="reload(purchase.purchaseCode)" [disabled]="isDisabled">
            <i class="fas fa-retweet"></i> Restablecer
        </button> -->
        &nbsp;
        <a class="btn btn-round btn-warning btn-icon btn-sm success btn-erp" ngbTooltip="Salir" placement="left"
            (click)="viewBeginning($event)"><i class="fas fa-times"></i>
        </a>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header ">
                            <div class="row">
                                <div class="col-sm-4 col-md-6">
                                    <b class="text-success">No. {{purchase.purchaseCode}}</b><br>
                                    <b class="text-success">Estado: {{purchase.estado}}</b>
                                    <br>Proveedor: {{purchase.proveedor}}
                                    <br>Importado: {{purchase.purchaseImport == 1 ? 'Si' : 'No'}}                                    
                                </div>
                                <div class="col-sm-4 col-md-3">
                                    <label>
                                        <b>Ingresado</b>
                                        <br>Fecha: {{purchase.purchaseCreatedDate | date:'dd-MM-yyyy'}}
                                        <br>Usuario: {{purchase.createdUser}} &nbsp;
                                    </label>
                                    <label>
                                        <b>Autorizado</b>
                                        <br>Fecha: {{purchase.purchaseAuthorizedDate | date:'dd-MM-yyyy'}}
                                        <br>Usuario: {{purchase.authorizedUser}}
                                    </label>
                                </div>
                                <!-- <div class="col-sm-4 col-md-3">
                                    <label>No. Factura</label>
                                    <input type="number" class="form-control" [(ngModel)]="noInvoice"
                                        placeholder="*Campo Obligatorio" />
                                    <label></label>
                                    <span class="btn btn-block btn-round btn-file"
                                        [ngClass]="{'btn-gray' : isObjectEmpty(state), 'btn-success' : !isObjectEmpty(state)}">
                                        <span class="fileinput-new">PDF/JPEG</span>
                                        <input type="file" (change)="addFile($event)" matTooltip='Permissions "Disk"' accept="application/pdf, image/jpeg"/>
                                    </span>
                                    <label class="text-danger">Revisa no tener 2 extenciones</label>
                                </div> -->
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-12 col-md-3">
                                    <label>No. Factura</label>
                                    <input type="text" class="form-control" [(ngModel)]="noInvoice"
                                        placeholder="*Campo Obligatorio" />
                                    <label></label>
                                    <span class="btn btn-block btn-round btn-file"
                                        [ngClass]="{'btn-gray' : isObjectEmpty(state), 'btn-success' : !isObjectEmpty(state)}">
                                        <span class="fileinput-new">PDF/JPEG</span>
                                        <input type="file" (change)="addFile($event)" matTooltip='Permissions "Disk"' accept="application/pdf, image/jpeg"/>
                                    </span>
                                    <!-- Revisa que el doc. solo tenga una extencion pdf o jpeg -->
                                    <label class="text-danger">Revisa no tener 2 extenciones</label>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <label>Serie Factura</label>
                                    <input type="text" class="form-control" [(ngModel)]="serieInvoice"
                                        placeholder="*Campo Obligatorio" />
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <label>Autorización Factura</label>
                                    <input type="text" class="form-control" [(ngModel)]="authInvoice"
                                        placeholder="*Campo Obligatorio" />
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <label>¿Es Servicio?</label>
                                    <div class="form-group">
                                        <bSwitch [switch-on-color]="'success'" [(ngModel)]="isService"
                                            switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                            switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                        </bSwitch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive small">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr style="font-weight: bold;">
                                                    <td>No.</td>
                                                    <td>U. Solicitadas</td>
                                                    <td>U. Recibidas</td>
                                                    <td>Descripción</td>
                                                    <td>Lote</td>
                                                    <td>Vence</td>
                                                    <td class="text-center">P/U con IVA {{moneda}}</td>
                                                    <td class="text-center">Total {{moneda}}</td>
                                                    <td class="text-center">Impuesto {{moneda}}</td>
                                                    <td class="text-center">Opción</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let e of purchaseDetail, let i=index"
                                                    [ngClass]="{'text-info' : e.purchaseFlag == true, 'text-danger' : e.purchaseDetailStatus == 0}">
                                                    <td class="text-center">{{i+1}}</td>
                                                    <td class="text-center">{{e.purchaseDetailRequestQuantity}}
                                                    </td>
                                                    <td style="text-align: right;">
                                                        <input type="number" class="form-control"
                                                            [(ngModel)]="e.purchaseDetailRecieveQuantity"
                                                            (blur)="changeQuantity($event, i, e.purchaseDetailRecieveQuantity)"
                                                            [disabled]="e.purchaseDetailStatus === 0"
                                                            style="max-width: 90px; margin: 0px auto; color: #4a5170;" />
                                                    </td>
                                                    <td>{{e.purchaseDetailDescription}}</td>
                                                    <td style="text-align: center;">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="e.purchaseDetailLot"
                                                            [disabled]="e.purchaseDetailStatus === 0"
                                                            style="max-width: 90px; margin: 0px auto; color: #4a5170;" />
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <input type="date"
                                                            class="form-control form-control-sm form-date"
                                                            [(ngModel)]="e.purchaseDetailDueDate"
                                                            placeholder="dd/mm/aaaa"
                                                            style="max-width: 90px; margin: 0px auto; color: #4a5170;"
                                                            [disabled]="e.purchaseDetailStatus === 0" />
                                                    </td>
                                                    <td class="text-right">{{e.purchaseDetailUnitPrice |
                                                        number:'1.2-2'}}</td>
                                                    <td class="text-right">{{e.purchaseDetailPrice | number:'1.2-2'}}
                                                    </td>
                                                    <td class="text-right" style="color: #b7b7b7;">
                                                        {{e.purchaseDetailTax | number:'1.2-2'}}
                                                    </td>
                                                    <td class="text-center">
                                                        <ng-template #content let-modal>
                                                            <div class="row modal-body">
                                                                <div class="col-8" *ngIf="isNewItem">
                                                                    <button type="button" class="btn btn-info"
                                                                        (click)="inventoryItem()">Inventario</button>
                                                                </div>
                                                                <div class="col-8" *ngIf="!isNewItem">
                                                                    <!-- <button type="button" class="btn btn-info"
                                                                        (click)="newItem()">Nuevo Ítem</button> -->
                                                                </div>
                                                                <div class="col-4 text-right">
                                                                    <a class="btn btn-round btn-warning btn-icon btn-sm remove btn-erp"
                                                                        (click)="modal.dismiss('Cross click')"><i
                                                                            class="fas fa-times"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <!-- ITEM INVENTORY -->
                                                            <div class="modal-body justify-content-end"
                                                                *ngIf="!isNewItem">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <angular2-multiselect [data]="inventoryList"
                                                                                [(ngModel)]="selectedItemsInventory"
                                                                                [settings]="dropdownSettingsInventory"
                                                                                (onSelect)="onItemSelectInventory($event)">
                                                                            </angular2-multiselect>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-12 text-center">
                                                                        <button type="button" class="btn btn-success"
                                                                            (click)="modal.close('Save click', e.purchaseDetailCode)">Aceptar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- ITEM NEW -->
                                                            <div class="modal-body" *ngIf="isNewItem">
                                                                <div class="row">
                                                                    <!-- <form ngNativeValidate>                                                     -->
                                                                        <div class="col-md-12">
                                                                            <label>Código</label>
                                                                            <div class="form-group">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="inventory.inventoryBusinessCode"                                                                                    
                                                                                    (blur)="regularPhrase($event, inventory.inventoryBusinessCode)" required/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <label>Descripción</label>
                                                                            <div class="form-group">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="inventory.inventoryDescription"
                                                                                    autocomplete="off" required/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label>Precio de Venta</label>
                                                                            <div class="form-group">
                                                                                <input type="number"
                                                                                    class="form-control"
                                                                                    [(ngModel)]="inventory.inventoryPrice"
                                                                                    autocomplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label>Precio con descuento</label>
                                                                            <div class="form-group">
                                                                                <input type="number"
                                                                                    class="form-control"
                                                                                    [(ngModel)]="inventory.inventoryPriceDiscount"
                                                                                    autocomplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label>Precio de promoción</label>
                                                                            <div class="form-group">
                                                                                <input type="number"
                                                                                    class="form-control"
                                                                                    [(ngModel)]="inventory.inventorySalePrice"
                                                                                    autocomplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label>Stock Minimo</label>
                                                                            <div class="form-group">
                                                                                <input type="number"
                                                                                    class="form-control"
                                                                                    [(ngModel)]="inventory.inventoryMin"
                                                                                    autocomplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label>Stock Maximo</label>
                                                                            <div class="form-group">
                                                                                <input type="number"
                                                                                    class="form-control"
                                                                                    [(ngModel)]="inventory.inventoryMax"
                                                                                    autocomplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label>Categoría</label>
                                                                            <div class="form-group">
                                                                                <angular2-multiselect
                                                                                    [data]="productList"
                                                                                    [(ngModel)]="selectedItemsProduct"
                                                                                    [settings]="dropdownSettingsProduct"
                                                                                    (onSelect)="onItemSelectProduct($event)">
                                                                                </angular2-multiselect>
                                                                            </div>
                                                                        </div>
                                                                    <!-- </form> -->
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-12 text-center">
                                                                        <button type="button" class="btn btn-success" [disabled]="aceptBottom == false"
                                                                            (click)="modal.close('Save click', e.purchaseDetailCode)">Aceptar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <button class="btn btn-info btn-sm btn-s"
                                                            [disabled]="e.purchaseDetailRecieveQuantity <= 0"
                                                            (click)="open(content, e.purchaseDetailCode)"
                                                            ngbTooltip="Código" placement="left">
                                                            <!-- <i class="now-ui-icons ui-1_zoom-bold"></i> -->
                                                            <i class="fas fa-qrcode"></i>
                                                        </button>
                                                        <br>
                                                        <button class="btn btn-success btn-sm btn-s"
                                                            *ngIf="e.purchaseDetailStatus == 0"
                                                            (click)="activeLine(e.purchaseDetailCode)"
                                                            ngbTooltip="Recuperar" placement="left">
                                                            <i class="now-ui-icons arrows-1_share-66"></i>
                                                        </button>
                                                        <button class="btn btn-danger btn-sm btn-s"
                                                            *ngIf="e.purchaseDetailStatus == 1"
                                                            (click)="cancelLine(e.purchaseDetailCode)"
                                                            ngbTooltip="Eliminar" placement="left">
                                                            <i class="now-ui-icons ui-1_simple-remove"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="text-align: right;">
                                    <span style="font-weight: bold; font-size: 17px; color: darkgray;">
                                        Descuento <small style="font-weight: bold; font-size: 13px;">Q</small>
                                        {{purchase.purchaseDiscount | number:'1.2-2'}}
                                    </span>
                                </div>
                                <div class="col-md-4" style="text-align: right; color: darkgray;">
                                    <span style="font-weight: bold; font-size: 17px;">
                                        Impuesto <small style="font-weight: bold; font-size: 13px;">Q</small>
                                        {{purchase.purchaseTax | number:'1.2-2'}}
                                    </span>
                                </div>
                                <div class="col-md-4" style="text-align: right;">
                                    <span style="font-weight: bold; font-size: 25px;">
                                        Total <small style="font-weight: bold; font-size: 20px;">Q</small>
                                        {{purchase.purchaseTotal | number:'1.2-2'}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <textarea class="form-control" rows="12" placeholder="Comentario"
                                        [(ngModel)]="purchaseModel.purchaseComment"></textarea>
                                </div>
                                <div class="col-sm-12 text-right">
                                    <label>
                                        <span class="text-danger"><i class="fas fa-square"></i> Articulo Eliminado.
                                        </span>
                                        |
                                        <span class="text-info"> <i class="fas fa-square"></i> Articulo con
                                            Código.</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>