//Librerias de Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { TagInputModule } from "ngx-chips";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { ArchwizardModule } from "angular-archwizard";
import { NgQrScannerModule } from 'angular2-qrscanner';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import {DecimalPipe} from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPrintModule } from 'ngx-print';

//Impotaciones del Sistema
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { FooterComponent } from './core/footer/footer.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth-guard.service';
import { UserAutoConfirmComponent } from './pages/auth/user-auto-confirm/user-auto-confirm.component';

//Importacionse de Modulos
import { HomeComponent } from './pages/home/home.component';
import { UserComponent } from './pages/user/user/user.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PermissionComponent } from './pages/user/permission/permission.component';
import { CompanyComponent } from './pages/company/company/company.component';
import { CompanyTypeComponent } from './pages/catalogue/company-type/company-type.component';
import { CompanyPlanComponent } from './pages/catalogue/company-plan/company-plan.component';
import { ProviderComponent } from './pages/provider/provider.component';
import { OwnerComponent } from './pages/company/owner/owner.component';
import { ManagerComponent } from './pages/company/manager/manager.component';
import { StoreComponent } from './pages/company/store/store.component';
import { ClientComponent } from './pages/client/client.component';
import { PurchaseInsertComponent } from './pages/purchase/purchase-insert/purchase-insert.component';
import { PurchaseViewComponent } from './pages/purchase/purchase-view/purchase-view.component';
import { LoadingComponent } from './core/loading/loading.component';
import { ClientCategoryComponent } from './pages/catalogue/client-category/client-category.component';
import { CancellationComponent } from './pages/invoice/cancellation/cancellation.component';
import { ProductComponent } from './pages/catalogue/product/product.component';
import { InventoryComponent } from './pages/inventory/inventory/inventory.component';
import { InventoryLogComponent } from './pages/inventory/inventory-log/inventory-log.component';
import { InventoryAllComponent } from './pages/inventory/inventory-all/inventory-all.component';
import { PurchaseProcessComponent } from './pages/purchase/purchase-process/purchase-process.component';
import { ProviderCategoryComponent } from './pages/catalogue/provider-category/provider-category.component';
import { PurchasePendingComponent } from './pages/purchase/purchase-pending/purchase-pending.component';
import { LetrasPipe } from './pipe/letras/letras.pipe';
import { AccessComponent } from './pages/user/access/access.component';
import { InventoryExchangeBillComponent } from './pages/invoice/inventory/inventory-exchange-bill/inventory-exchange-bill.component';
import { InventoryCreditNoteComponent } from './pages/invoice/inventory/inventory-credit-note/inventory-credit-note.component';
import { InventoryDebitNoteComponent } from './pages/invoice/inventory/inventory-debit-note/inventory-debit-note.component';
import { InventoryExportInvoiceComponent } from './pages/invoice/inventory/inventory-export-invoice/inventory-export-invoice.component';
import { DescriptionExchangeBillComponent } from './pages/invoice/description/description-exchange-bill/description-exchange-bill.component';
import { DescriptionCreditNoteComponent } from './pages/invoice/description/description-credit-note/description-credit-note.component';
import { DescriptionDebitNoteComponent } from './pages/invoice/description/description-debit-note/description-debit-note.component';
import { DescriptionExportInvoiceComponent } from './pages/invoice/description/description-export-invoice/description-export-invoice.component';
import { PaymentInvoiceComponent } from './pages/accounting/payment-invoice/payment-invoice.component';
import { PaymentRecordsComponent } from './pages/accounting/payment-records/payment-records.component';
import { PaymentPurchaseComponent } from './pages/accounting/payment-purchase/payment-purchase.component';
import { BalanceSheetComponent } from './pages/accounting/balance-sheet/balance-sheet.component';
import { InventoryBillingComponent } from './pages/invoice/inventory/inventory-billing/inventory-billing.component';
import { DescriptionBillingComponent } from './pages/invoice/description/description-billing/description-billing.component';
import { DairyComponent } from './pages/accounting/dairy/dairy.component';
import { WarehouseComponent } from './pages/inventory/warehouse/warehouse.component';
import { TransferComponent } from './pages/inventory/transfer/transfer.component';
import { InventoryFuelComponent } from './pages/invoice/inventory/inventory-fuel/inventory-fuel.component';
import { TransferProcessComponent } from './pages/inventory/transfer-process/transfer-process.component';
import { MultiCompanyUserComponent } from './pages/user/multi-company-user/multi-company-user.component';
import { ReportStoreComponent } from './pages/invoice/invoice-report/report-store/report-store.component';
import { ReportCompanyComponent } from './pages/invoice/invoice-report/report-company/report-company.component';
import { CancellationInvoiceComponent } from './pages/invoice/cancellation/cancellation-invoice/cancellation-invoice.component';
import { CancellationExchangeBillComponent } from './pages/invoice/cancellation/cancellation-exchange-bill/cancellation-exchange-bill.component';
import { ReprintComponent } from './pages/invoice/reprint/reprint.component';
import { PurchaseItemReportComponent } from './pages/purchase/purchase-item-report/purchase-item-report.component';
import { TransferReportComponent } from './pages/inventory/transfer-report/transfer-report.component';
import { TransferReportsComponent } from './pages/inventory/transfer-reports/transfer-reports.component';
import { PhraseStageComponent } from './pages/catalogue/phrase-stage/phrase-stage.component';
import { ReprintInvoiceComponent } from './pages/invoice/reprint/reprint-invoice/reprint-invoice.component';
import { ReprintExchangeBillComponent } from './pages/invoice/reprint/reprint-exchange-bill/reprint-exchange-bill.component';
import { ReprintNotesComponent } from './pages/invoice/reprint/notes/reprint-notes.component';
import { RetentionsReportComponent } from './pages/accounting/retentions-report/retentions-report.component';
import { PurchaseDetailComponent } from './pages/purchase/purchase-detail/purchase-detail.component';
import { RetentionsInvoiceReportComponent } from './pages/accounting/retentions-invoice-report/retentions-invoice-report.component';
import { InvoiceRecurrentComponent } from './pages/invoice/invoice-recurrent/invoice-recurrent.component';
import { InvoiceRecurrentInsertComponent } from './pages/invoice/invoice-recurrent/invoice-recurrent-insert/invoice-recurrent-insert.component';
import { InvoiceRecurrentMaintenanceComponent } from './pages/invoice/invoice-recurrent/invoice-recurrent-maintenance/invoice-recurrent-maintenance.component';
import { XmlLoadComponent } from './pages/xml-load/xml-load.component';
import { ReportSylvaniaComponent } from './pages/xml-load/report-sylvania/report-sylvania.component';
import { PreventaComponent } from "./pages/invoice/inventory/preventa/preventa.component";
import { RestaurantComponent } from "./pages/invoice/inventory/restaurant/restaurant.component";
import { InventoryBillingSpecialComponent } from './pages/invoice/inventory/inventory-special-billing/inventory-special-billing.component';
import { InventoryBillingReceiptComponent } from './pages/invoice/inventory/inventory-billing-receipt/inventory-billing-receipt.component';
import { CancellationReceiptComponent } from './pages/invoice/cancellation/cancellation-receipt/cancellation-receipt.component';
import { ReportReceiptCompanyComponent } from './pages/invoice/invoice-report/report-receipt-company/report-receipt-company.component';
import { InvoiceMassive } from './pages/invoice/invoice-massive/invoice-massive-inventory/invoice-massive.component';
import { InventoryBillingCardsComponent } from './pages/invoice/inventory/inventory-billing-cards/inventory-billing-cards.component';
import { PurchaseProcessBiokalComponent } from './pages/purchase/purchase-process-biokal/purchase-process-biokal.component';
import { ReportCompanySummaryComponent } from './pages/invoice/invoice-report/report-company-summary/report-company-summary.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    LoginComponent,
    UserAutoConfirmComponent,
    HomeComponent,  
    UserComponent,
    ProfileComponent,
    PermissionComponent,
    CompanyComponent,
    CompanyTypeComponent,
    CompanyPlanComponent,
    ProviderComponent,
    OwnerComponent,
    ManagerComponent,
    StoreComponent,
    ClientComponent,
    PurchaseInsertComponent,
    PurchaseViewComponent,
    LoadingComponent,
    ClientCategoryComponent,
    DescriptionBillingComponent,
    CancellationComponent,
    ProductComponent,
    InventoryComponent,
    InventoryLogComponent,
    InventoryAllComponent,
    PurchaseProcessComponent,
    ProviderCategoryComponent,
    PurchasePendingComponent,
    InventoryBillingComponent,
    LetrasPipe,
    AccessComponent,
    InventoryExchangeBillComponent,
    InventoryCreditNoteComponent,
    InventoryDebitNoteComponent,
    InventoryExportInvoiceComponent,
    DescriptionExchangeBillComponent,
    DescriptionCreditNoteComponent,
    DescriptionDebitNoteComponent,
    DescriptionExportInvoiceComponent,
    PaymentInvoiceComponent,
    PaymentRecordsComponent,
    PaymentPurchaseComponent,
    BalanceSheetComponent,
    DairyComponent,
    WarehouseComponent,
    TransferComponent,
    InventoryFuelComponent,
    TransferProcessComponent,
    MultiCompanyUserComponent,
    ReportCompanyComponent,
    ReportStoreComponent,
    CancellationInvoiceComponent,
    CancellationExchangeBillComponent,
    ReprintComponent,
    PurchaseItemReportComponent,
    TransferReportComponent,
    TransferReportsComponent,
    PhraseStageComponent,
    ReprintInvoiceComponent,
    ReprintExchangeBillComponent,
    RetentionsReportComponent,
    PurchaseDetailComponent,
    RetentionsInvoiceReportComponent,
    InvoiceRecurrentComponent,
    InvoiceRecurrentInsertComponent,
    InvoiceRecurrentMaintenanceComponent,
    XmlLoadComponent,
    ReprintNotesComponent,
    ReportSylvaniaComponent,
    PreventaComponent,
    RestaurantComponent,
    InventoryBillingSpecialComponent,
    InventoryBillingReceiptComponent,
    CancellationReceiptComponent,
    ReportReceiptCompanyComponent,
    InvoiceMassive,
    InventoryBillingCardsComponent,
    PurchaseProcessBiokalComponent,
    ReportCompanySummaryComponent
  ],
  imports: [    
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgxDocViewerModule,
    NgxDatatableModule,
    NgQrScannerModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    NgbModule,
    JwBootstrapSwitchNg2Module,
    AngularMultiSelectModule,    
    ArchwizardModule,
    AlifeFileToBase64Module,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NgxPrintModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [UserService, AuthGuard, DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
