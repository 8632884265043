import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Subscription } from 'rxjs/Subscription';
import { Location, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import PerfectScrollbar from 'perfect-scrollbar';
import { UserService } from "./services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  public _router: Subscription;
  public lastPoppedUrl: string;
  public yScrollStack: number[] = [];
  public isLoginPage = false;
  public isCollapsed = false;
  isLogged: boolean;
  loading;
  outLogged;

  constructor(
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    private userService: UserService
  ) { }

  async ngOnInit() {
    await this.verifyLogged();

    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
    if (isWindows) {
      document.getElementsByTagName('html')[0].classList.add('perfect-scrollbar-on');
    } else {

      document.getElementsByTagName('html')[0].classList.remove('perfect-scrollbar-off');
    }
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);

        if (event.url == '/userLogin') {
          this.isLoginPage = true;
        } else {
          this.isLoginPage = false;
        }
      }
    });
    if (this.isLoginPage == true) {
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      });

      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
        let ps = new PerfectScrollbar(elemMainPanel);
        ps = new PerfectScrollbar(elemSidebar);
      }
    }
  }

  async verifyLogged() {
    this.userService.userLogged.subscribe(async (isLogged) => {
      this.isLogged = isLogged;
      if (!isLogged) {
        const pathUrl = await this.location.path().split('/');
        //console.log(pathUrl);
        if (
          !(pathUrl[1] == 'userAutoConfirm' ||
            pathUrl[1] == 'forgotPassword' ||
            pathUrl[1] == 'profile-view'
          )) {
          this.router.navigate(['/userLogin']);
        }else{
          this.outLogged = true;
        }
      }
    });
    await this.userService.initUserSecurity();
  }
  
  runOnRouteChange(): void {
    if (this.isLoginPage == true) {
      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const ps = new PerfectScrollbar(elemMainPanel);
        ps.update();
      }
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
  ngAfterViewInit() {
    this.runOnRouteChange();
  }
}
