import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from '@angular/router';
//import { NgForm } from '@angular/forms';
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  focus;
  focus2;
  loading = false;

  public user: User;
  public show: boolean;
  public isDisabled: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.user = new User(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  ngOnInit() {
    // window.location = window.location.href+'?eraseCache=true';

    this.show = false;
    const $page = document.getElementsByClassName("full-page")[0];
    let image_src;
    const image_container = document.createElement("div");
    image_container.classList.add("full-page-background");
    image_container.style.backgroundImage = "url(assets/img/portada-login.jpg)";
    $page.appendChild(image_container);
    $page.classList.add("login-page");
    //console.log("Ver. 17052023.1");
  }

  ngOnDestroy() {
    const $page = document.getElementsByClassName("full-page")[0];
    $page.classList.remove("login-page");
  }
  
  async validate() {
    this.loading = true;
    this.isDisabled = true;
    // console.log('Validate, clear storage')
    await localStorage.clear();
    
    //console.log(this.user.userEmail, this.user.userPassword);
    //console.warn("Cambio en front");
    this.userService.userSignin(this.user.userEmail, this.user.userPassword);

    setTimeout(async () => {
      this.loading = false;
      this.isDisabled = false; 
    }, 1200);

    // const result = await this.userService.userSignin(this.user.userEmail, this.user.userPassword);
    // console.log(result);
  }

  changeText() {
    const elemento: any = document.getElementById('pass');

    if (this.show) {
      this.show = false;
      elemento.type = "password";
    }
    else {
      this.show = true;
      elemento.type = "text";
    }

  }
}
