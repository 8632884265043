import { Component, OnInit } from "@angular/core";
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { User } from "src/app/models/user";
import { UserStore } from "src/app/models/userStore";
import { UserService } from "src/app/services/user.service";
import { StoreService } from "src/app/services/store.service";
import { RoleService } from "src/app/services/role.service";
import { CompanyService } from "src/app/services/company.service";
import { AccessService } from '../../../services/access.service';
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [UserService, StoreService, RoleService, CompanyService, AccessService, UtilsService]
})

export class UserComponent implements OnInit {
  // MODELS VARIABLE 
  public user = {} as User;
  public userStore = {} as UserStore;
  public isEdit: boolean;
  public isDisabled: boolean;
  public changeArrayStore: boolean;
  public userCode: number;
  public companyCode: number;
  public companyCodeTemp: number;
  public settings = {
    columns: {
      nombre: {
        title: 'Nombre'
      },
      correo: {
        title: 'correo'
      },
      telefono: {
        title: 'Telefono'
      },
      rol: {
        title: 'Rol'
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public userList = new Array();

  // VARIABLE FOR SELECT 
  public companyList: any[] = new Array();
  public selectedItemsCompany = [];
  public dropdownSettingsCompany = {};

  public storeList: any[] = new Array();
  public selectedItemsStore = [];
  public dropdownSettingsStore = {};
  public arrayUserStore: any[] = new Array();

  public roleList: any[] = new Array();
  public selectedItemsRole = [];
  public dropdownSettingsRole = {};

  constructor(
    private _utilsService: UtilsService,
    private _userService: UserService,
    private _storeService: StoreService,
    private _roleService: RoleService,
    private _companyService: CompanyService,
    private _accessService: AccessService
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY & ID STORE
    const _data = JSON.parse(localStorage.getItem('data'));
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.companyCodeTemp = this.companyCode;

    // LOAD VARIABLE
    this.isEdit = false;
    this.isDisabled = false;
    this.changeArrayStore = false;
    
    // LOAD INFORMATION SELECT
    this.getRoleList(this.companyCode);
    this.getSelectSettingRole(this.selectedItemsRole);
    await this.getStoreList(this.companyCode);
    this.getSelectSettingStore(this.selectedItemsStore); //await
    this.getCompanyList(); //await

    // LOAD INFORMATION TABLE
    await this.getAllByCompany(this.companyCode);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.userList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSettingCompany(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsCompany = [selected] : '';

    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione una empresa...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: false,
      disabled: this.companyList.length === 1 ? true : false
    };
  };

  getSelectSettingRole(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsRole = [selected] : '';

    this.dropdownSettingsRole = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };

  getSelectSettingStore(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsStore = selected : '';

    this.dropdownSettingsStore = {
      badgeShowLimit: 3,
      text: "Seleccione...",
      singleSelection: false,
      enableCheckAll: false, // selectAllText: 'Seleccionar todos',
      enableUnCheckAll: false, // unSelectAllText: 'Deseleccionar todos',      
      enableSearchFilter: true,
      searchPlaceholderText: 'Buscar',
      noDataLabel: 'Sin resultados',
      enableFilterSelectAll: false,
      lazyLoading: false,
      escapeToClose: true,
      autoPosition: true,
      showCheckbox: false,
      // primaryKey: 'storeCode'
    };
  };

  getCompanyList() {
    return this._companyService.getAll().then(
      async response => {
        if (response.result == true) {

          let filtro = await response.records.filter(
            x => x.companyCompanyCreationCode === this.companyCode || x.companyCode === this.companyCode || this.companyCode === 1
          );

          for await (const e of filtro) {
            this.companyList.push({id: e.companyCode, itemName: e.companyName});
          }
          
          let _selectedItemsCompany = await this.companyList.find(x => x.id == this.companyCode);
          this.getSelectSettingCompany(_selectedItemsCompany);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getRoleList(companyCode) {
    return this._roleService.getAllByCompany(companyCode).then(
      response => {
        if (response.result == true) {
          // this.roleList = [];
          response.records.forEach(e => {
            this.roleList.push({
              id: e.roleCode,
              itemName: e.roleName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getStoreList(company) {
    this.loading = true;

    return this._storeService.getAllByCompany(company).then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.storeList.push({
              id: e.storeCode,
              itemName: e.storeName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onItemSelectCompany(item: any) {
    this.companyCodeTemp = item.id; 
    this.userList = [];
    this.roleList = [];
    this.storeList = [];
    this.getAllByCompany(item.id); 
    this.getRoleList(item.id); 
    this.getStoreList(item.id);
  }

  onItemSelectRole(item: any) {
    this.user.userRole = item.id;
  }

  onItemDeSelectRole() {
    this.user.userRole = undefined;
  }

  onItemSelectStore(item: any) {
    this.changeArrayStore = true;

    this.arrayUserStore.push({
      userStoreStoreCode: item.id
    });
  }

  onItemDeSelectStore(item: any) {
    this.changeArrayStore = true;

    const index = this.arrayUserStore.findIndex(e => e.userStoreStoreCode == item.id);
    this.arrayUserStore.splice(index, 1);
  }

  onDeSelectAllStore() {
    this.changeArrayStore = true;
    this.arrayUserStore = [];
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL USER BY COMPANY
  getAllByCompany(companyCode) {
    this.loading = true;

    return this._userService.getAllByCompany(companyCode).then(
      response => {
        if (response.result) {
          this.userList = response.records;
          this.temp = this.userList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  save() {
    let name = false;
    let email = false;

    this.user.userName == '' || this.user.userName == undefined ? '' : name = true;
    this.user.userEmail == '' || this.user.userEmail == undefined ? '' : email = true;

    if (name && email && this.user.userRole > 0 && this.arrayUserStore.length > 0) {
      this.isDisabled = true;
      this.loading = true;

      this.user.userStatus = 1;
      this.user.userDeleted = 0;
      this.user.useLogInAttempts = 3;

      this._userService.insert(this.user).then(
        async (response) => {
          if (response.result == true) {
            this._utilsService.showNotification(1, response.message);
            await this.saveUserStore(response.userCode, true);
            await this.getAllByCompany(this.companyCodeTemp);
            this.cleanModel();
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this._utilsService.showNotification(2, error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this._utilsService.showNotification(2, 'Falto llenar un campo requerido');
    }
  }

  async saveUserStore(userStoreUserCode, isNew?) {
    this.userStore.userStoreUserCode = userStoreUserCode;
    console.log('llega a user store');
    if (isNew) {
      for await (const e of this.arrayUserStore) {
        this.userStore.userStoreStoreCode = e.userStoreStoreCode;
        const response = await this._userService.insertUserStore(this.userStore);
        response.errorMessage ? this._utilsService.showNotification(2, 'Fallo al asignar sucursal') : '';
      }
    } else {      
      const update = await this._userService.updateUserStore(this.userStore);
      if (update.result == true) {
        for await (const e of this.arrayUserStore) {
          this.userStore.userStoreStoreCode = e.userStoreStoreCode;
          const response = await this._userService.insertUserStore(this.userStore);
          response.errorMessage ? this._utilsService.showNotification(2, 'Fallo al asignar sucursal') : '';
        }
      } else {
        this._utilsService.showNotification(3, 'Fallo al actualizar sucursales');
      }
    }
  }

  async update(deleted?) {
    if (this.user.userName != '' && this.user.userEmail != '' && this.user.userRole > 0 || deleted) {
      this.loading = true;
      this.isDisabled = true;      

      this._userService.update(this.user).then(        
        async (response) => {
          if (response.result == true) {
            console.log('entra a update...');
            this._utilsService.showNotification(1, response.message);
            this.changeArrayStore == true ? await this.saveUserStore(this.user.userCode, false) : '';
            await this.getAllByCompany(this.companyCodeTemp);
            this.isEdit = false;
            this.cleanModel();
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this._utilsService.showNotification(2, 'Falto llenar un campo requerido');
    }
  }

  deleteFunction($event) {
    $event.preventDefault();
    this.user.userStatus = 0;
    this.user.userDeleted = 1;
    this.user.userCode = this.activeRow.userCode;
    this.user.userName = this.activeRow.userName;
    this.user.userRole = this.activeRow.userRole;
    this.user.userCognitoCode = this.activeRow.userCognitoCode;

    swal.fire({
      title: "Esta seguro",
      text: "El usuario se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update(true);
        swal.fire({
          title: "Eliminado!",
          text: "El usuario se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  ///////////////////////COMPLEMENTOS///////////////////////
  async editFunction($event) {
    $event.preventDefault();
    this.loading = true;
    this.isDisabled = true;

    const stores = await this._userService.getOneUserStore(this.activeRow.userCode);
    if (stores.records.length > 0) {
      let _selectedItemStore: any[] = [];
      for await (const e of stores.records) {
        _selectedItemStore.push({
          id: e.userStoreStoreCode,
          itemName: e.storeName
        });
        this.arrayUserStore.push({
          userStoreStoreCode: e.userStoreStoreCode
        });
      }
      this.getSelectSettingStore(_selectedItemStore);
    }

    const _selectedItemRole = await this.roleList.find(x => x.id == this.activeRow.userRole);
    this.getSelectSettingRole(_selectedItemRole);

    this.user.userRole = this.activeRow.userRole;
    this.user.userCode = this.activeRow.userCode;
    this.user.userName = this.activeRow.userName;
    this.user.userPhone = this.activeRow.userPhone;
    this.user.userEmail = this.activeRow.userEmail;
    this.user.userTaxDocument = this.activeRow.userTaxDocument;
    this.user.userCognitoCode = this.activeRow.userCognitoCode;
    this.isEdit = true;
    this.loading = false;
    this.isDisabled = false;
  }

  //Cacelar Actualización
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
  }

  //Limpiar Modal
  cleanModel() {
    this.user = {} as User;
    this.arrayUserStore = [];
    this.isDisabled = false;
    this.changeArrayStore = false;
    this.selectedItemsRole = undefined;
    this.selectedItemsStore = undefined;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        json[this.settings.columns[item].title] = element[item];
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-usuarios.xlsx');
  }
}
