<app-loading *ngIf="loading"></app-loading>
<div class="main-content">
    <div class="container">
        <div class="col-md-4 ml-auto mr-auto">
            <div class="form">
                <div class="card card-login card-plain">
                    <div class="card-header">
                        <img class="mx-auto d-block logo-head" style="width: 80%; padding-bottom: 15px;"
                            src="assets/img/Clever-blanco-nombre-clever.png" />
                        <img class="mx-auto d-block logo-head" src="assets/img/Clever-blanco-nombre.png" />
                    </div>
                    <br>
                    <div class="card-body ">
                        <div class="input-group no-border form-control-lg">
                            <span class="input-group-prepend">
                                <div class="input-group-text">
                                    <i class="now-ui-icons users_circle-08"></i>
                                </div>
                            </span>
                            <input type="text" class="form-control" placeholder="Usuario"
                                [(ngModel)]="user.userEmail" />
                        </div>

                        <div class="input-group no-border form-control-lg">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <a (click)="changeText()">
                                        <i class="now-ui-icons gestures_tap-01"></i>
                                    </a>
                                </div>
                            </div>
                            <input type="password" id="pass" placeholder="Contraseña" class="form-control"
                                [(ngModel)]="user.userPassword" (keyup.enter)="validate()" />
                        </div>
                    </div>

                    <div class="card-footer ">
                        <button class="btn btn-primary btn-round btn-lg btn-block mb-3 btn-info" (click)="validate()"
                            [disabled]="isDisabled">Ingresar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>