import { Injectable } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import * as xlsx from 'xlsx';

import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http'; //eliminar
import { Company } from '../models/company';
import { Store } from '../models/store';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    pipeDate = new DatePipe('en-US');

    constructor(
        private toastr: ToastrService
    ) { }

    dateTime(format) {
        const today = new Date();
        return this.pipeDate.transform(today, format);
    }

    dateES_US(date, split) {
        const date_split = date.split(split);
        const newDate = `${date_split[2]}-${date_split[1]}-${date_split[0]}`;
        return newDate;
    }


    // ORDENAR ARRAY
    async order(array, key, keytwo?) {
        await array.sort((a, b) => a[key] < b[key] ? -1 : 1).sort((a, b) => a[keytwo] < b[keytwo] ? -1 : 1);       
    }

    async orderDate(array, key, split) {
        await array.sort((a, b) => {
            const sA = a[key].split(split);
            const sB = b[key].split(split);
            const nA = `${sA[2]}/${sA[1]}/${sA[0]}`;
            const nB = `${sB[2]}/${sB[1]}/${sB[0]}`;

            const tA = new Date(nA);
            const tB = new Date(nB);

            return tA < tB ? -1 : tA > tB ? 1 : 0;
        })
    }


    // Reemplazar Caracteres especiales
    async replaceCharter(text: string) {
        text = text.trim();
        text = await text.replace(/&/g, 'Y');
        text = await text.replace(/'/g, '');
        text = await text.replace(/"/g, '');

        return text;
    }

    // Reemplazar caracteres en nit
    replaceCharterTax(nit: string) {
        nit = nit.trim();
        nit = nit.replace(/[abcdefghijlmnopqrstuvwxyz ]/g, '')
        return nit;
    }

    // Exportar a excel
    async downloadToExcel(list: any, bookName: string, reportName: string) {
        const exportFile = list;

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportFile);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, bookName);
        xlsx.writeFile(wb, `${reportName}.xlsx`);
    }

    // Notificaciónes
    showNotification(type: number, message: string) {
        switch (type) {
            case 1:
                this.toastr.success(
                    '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
                    "",
                    {
                        timeOut: 5000,
                        closeButton: true,
                        enableHtml: true,
                        toastClass: "alert alert-success alert-with-icon",
                        positionClass: "toast-top-right"
                    }
                );
                break;
            case 2:
                this.toastr.warning(
                    '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
                    "",
                    {
                        timeOut: 5000,
                        closeButton: true,
                        enableHtml: true,
                        toastClass: "alert alert-warning alert-with-icon",
                        positionClass: "toast-top-right"
                    }
                );
                break;
            case 3:
                this.toastr.error(
                    '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
                    "",
                    {
                        timeOut: 5000,
                        closeButton: true,
                        enableHtml: true,
                        toastClass: "alert alert-danger alert-with-icon",
                        positionClass: "toast-top-right"
                    }
                );
                break;
            case 4:
                this.toastr.info(
                    '<span class="now-ui-icons ui-1_bell-53"></span><b>Info</b> - ' + message,
                    "",
                    {
                        timeOut: 5000,
                        closeButton: true,
                        enableHtml: true,
                        toastClass: "alert alert-info alert-with-icon",
                        positionClass: "toast-top-right"
                    }
                );
                break;
            case 5:
                this.toastr.error(
                    '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
                    "",
                    {
                    timeOut: 15000,
                    closeButton: true,
                    enableHtml: true,
                    toastClass: "alert alert-danger alert-with-icon",
                    positionClass: "toast-top-right"
                    }
                );
                break;
        }
    }
}