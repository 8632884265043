import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { Password } from '../../models/password';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [UserService, UtilsService]
})
export class ProfileComponent implements OnInit {
    // variables iniciales
    public user: User;
    public password: Password;
    public isDisabled: boolean;
    public show: boolean;
    public showTwo: boolean;


    constructor(
        private userService: UserService,
        private _utilsService: UtilsService
    ) {
        this.user = new User(null, null, null, null, null, null, null, null, null, null,  null, null, null, null, null);
        this.password =  new Password(null, null, null);
    }

    ngOnInit() {
        // retorno de información del local storage
        const _user = JSON.parse(localStorage.getItem('user'));
        //console.log(_user);

        // variables para mostrar
        this.user.userEmail = _user.userRole ? _user.userEmail : null;
        this.user.userName = _user.userRole ? _user.userName : null;
        this.user.userCognitoCode = _user.userCognitoCode ? _user.userCognitoCode :  null;
        this.user.userCode = _user.userCode;
        this.isDisabled = false;

        console.log(this.user.userCode);
    }

    changeText() {
        let elemento: any = document.getElementById('passPrevius');
    
        if (this.show) {
          this.show = false;
          elemento.type = "password";
        }
        else {
          this.show = true;
          elemento.type = "text";
        }
    
      }

    changeTextTwo(){
        let elemento :any = document.getElementById('passProposed');
  
        if (this.showTwo) {
          this.showTwo = false;
          elemento.type = "password";
        }
        else {
          this.showTwo = true;
          elemento.type = "text";
        }
      }
    
      changePassword() {
        this.isDisabled = true;
        return this.userService.updatePassword(
            this.user.userCode, 
            this.password.previousPassword, 
            this.password.proposedPassword).then(
            response => {
                if(response.result == true){
                    this._utilsService.showNotification(1, response.message);
                    this.isDisabled = false;
                    this.password.previousPassword = null;
                    this.password.proposedPassword = null;
                }else{
                    console.log(response.result);
                    this._utilsService.showNotification(2, response.message);
                    this.isDisabled = false;
                }
            }, error => {
                console.log(error);
                this._utilsService.showNotification(3, error);
                this.isDisabled = false;
            }
        );
    }
}