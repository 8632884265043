import { Component, OnInit } from '@angular/core';
import * as printJS from 'print-js';
import * as xlsx from 'xlsx';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { AccessService } from 'src/app/services/access.service';
import { LetrasPipe } from 'src/app/pipe/letras/letras.pipe';
import { DatePipe, DecimalPipe } from '@angular/common';
import { UtilsService } from 'src/app/services/utils.service';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-reprint-invoice',
  templateUrl: './reprint-invoice.component.html',
  styleUrls: ['./reprint-invoice.component.scss'],
  providers: [UtilsService, PrintService, InvoiceService, CompanyService, AccessService]
})
export class ReprintInvoiceComponent implements OnInit {
  loading;
  // Variables auxiliares
  pipeLetra = new LetrasPipe();
  pipeFecha = new DatePipe('en-US');
  company = {} as Company;
  public isBeginning: boolean;
  public isReprint: boolean;
  public isPrintT: boolean;
  public isDisabled: boolean;
  public userCommerceCode: number;
  public begDate: string;
  public endDate: string;
  public reasonForCancellation;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public storeName: string;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      invoiceAuthorization: {
        title: 'No. de Autorización'
      },
      serie: {
        title: 'Serie Fel',
      },
      factura: {
        title: 'No. Factura Fel'
      },
      invoiceInternSerial: {
        title: 'Serie Interna'
      },
      invoiceInternNumber: {
        title: 'Correlativo Interna',
      },
      invoiceSubtotal: {
        title: 'Sub Total',
        type: 'number'
      },
      invoiceTax: {
        title: 'IVA',
        type: 'number'
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      nit: {
        title: 'Nit',
      },
      cliente: {
        title: 'Cliente',
      },
      estado: {
        title: 'Estado',
      },
      usuario: {
        title: 'Usuario',
      }
    }
  };

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp = new Array();
  public invoiceList: any[] = new Array();

  //Reprint
  public fel: any;
  public felDetail: any;
  public felComplement: any;
  public dte: {
    serie,
    noFactura,
    autorizacion,
    certificador,
    nitCertificador,
    fechaCertificacion,
  };
  public companyLogoUrl: string;

  constructor(
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
    private _accessService: AccessService,
    private _pipeNumero: DecimalPipe
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    //DATE
    this.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');
    this.companyLogoUrl = '../../../assets/img/Clever-azul-original.png'

    this.viewBeginning();
    await this.getCompany(this.companyCode);
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isReprint = false;
    this.isPrintT = false;
  }

  viewReprint() {
    this.isBeginning = false;
    this.isReprint = true;
    this.isPrintT = false;
  }

  viewPrint() {
    this.isBeginning = false;
    this.isReprint = false;
    this.company.companyInvoicePrint === 1 ? this.isPrintT = true : this.printLetter();
  }

  printTest() {
    printJS({
      printable: "printSection",
      type: "html",
      style: "#printSection{width: 224px; text-align: center}",
    });
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.invoiceList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
          this.company.companyLogo != 'undefined' ? this.companyLogoUrl = this.company.companyLogoUrl : '';
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////CRUD///////////////////////
  //Ver todas las factura
  getAll() {
    this.isDisabled = true;
    if (this.begDate && this.endDate) {
      this.loading = true;

      let json = {
        "storeCode": this.storeCode,
        "begDate": this.begDate,
        "endDate": this.endDate
      }

      this._invoiceService.getAllByCompany(json).then(
        async (response) => {
          if (response.result == true) {
            this.invoiceList = response.records;
            await  this._utilsService.orderDate(this.invoiceList, 'fecha', '-'); 
            this.temp = this.invoiceList;
            this.isDisabled = false;
            this.isReprint = true;
            this._utilsService.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this.isDisabled = false;
      // Mostrar notificación
      this._utilsService.showNotification(2, "Ingresar Datos");
    }
  }

  // Obtener una Factura
  async searchInvoice($event) {
    $event.preventDefault();
    this.loading = true;

    //INFILE
    if (this.company.companyCertifier == 0) {
      if (this.company.companyPDFType == 1) {
        this._invoiceService.getOneMegaprint(this.activeRow.id).then(
          (response) => {
            if (response.result == true) {
              this.fel = response.records;
              this.felDetail = response.records.detail;
              this.felComplement = {
                cliente: this.activeRow.cliente, nit: this.activeRow.nit, direccion: this.activeRow.clientAddress,
                certificador: "INFILE, S.A.", nitCertificador: 12521337
              };

              console.log(this.company);
              console.log(this.activeRow);
              console.log(response.records);
              // console.log(this.fel);
              // console.log(this.felComplement);
              // console.log(this.felDetail);
              
              // this._printService.printLetter();
              this.viewPrint();
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this._utilsService.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.isDisabled = false;
          this.loading = false;
        });
      } else {
        await this._printService.printInfile(this.activeRow.invoiceAuthorization);
        this.loading = false;
      }
    }
    //MEGAPRINT
    else if (this.company.companyCertifier == 1) {
      this._invoiceService.getOneMegaprint(this.activeRow.id).then(
        async (response) => {
          if (response.result == true) {
            if (this.company.companyInvoiceFuel == 1 || this.company.companyPDFType == 1) {
              this.fel = response.records;
              this.felDetail = response.records.detail;
              this.felComplement = {
                cliente: this.activeRow.cliente, nit: this.activeRow.nit, direccion: this.activeRow.clientAddress,
                certificador: "MEGAPRINT, S.A.", nitCertificador: 50510231
              };
              console.log(this.company);
              console.log(this.activeRow);
              console.log(response.records);

              /*
              const json2 = {
              companyName: this.company.companyName, 
              storeName: response.records.storeName,
              companyLogoUrl: this.company.companyLogoUrl,
              usuario: this.activeRow.usuario,
              companyTaxDocument: this.company.companyTaxDocument, //response.records.transmitterUser, //Esta bien este?
              storePhone: response.records.storePhone,
              storeAddress: response.records.storeAddress,
              storeMunicipality: response.records.storeMunicipality,
              storeDepartment: response.records.storeDepartment,
              invoiceSeries: response.records.invoiceSeries,
              invoiceNumber: response.records.invoiceNumber,
              invoiceInternNumber: response.records.invoiceInternNumber,
              invoiceInternSerial: response.records.invoiceInternSerial,
              invoiceDate: response.records.invoiceDate,              
              cliente: this.activeRow.cliente,
              nit: this.activeRow.nit,
              direccion: this.activeRow.clientAddress,              
              invoiceTotal: response.records.invoiceTotal,
              invoiceAuthorization: response.records.invoiceAuthorization,
              certificador: response.records.provider,
              nitCertificador: response.records.providerTaxDocument, //Es realmente este?
              invoiceComment: response.records.invoiceComment,
              cancelledInvoice: response.records.cancelledInvoice,
              cancelledInvoiceDate: response.records.cancelledInvoiceDate,
              felDetail: response.records.detail //Hay que transformarlo?
              }
              //*/
              this.viewPrint();
            } else {            
              await this._printService.printMegaprint(response.records.pdfBase64);
            }
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });
    }
  }

  async printLetter() {
    // const pdfWindow = window.open("", 'Factura', '_blank');
    const pdfWindow = window.open();
    const css = `<style>   
        td { font-size: 12px; padding: 2px;}     
        .brDescr {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF; border-bottom: 1px solid  #2CA8FF;}
        .brCompl {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF;}
      </style>`;
    var html = `
    <head><title>Factura Clever "${this.company.companyName + ' - ' + this.fel.storeName}"</title></head>
    <body style="width: 21cm; height: 27cm; margin: auto; font-family: sans-serif, system-ui, monospace;"> 
      <div style="border: 2px solid #2CA8FF; border-radius: 10px; width: auto;">
        <table style="width: 100%;">
              <tr>
                  <td rowspan="5" style="width: 19%;">
                      <img style="width: 110px; margin:10px auto; display:block;" src="${this.companyLogoUrl}">
                  </td>
                  <th style="width: 62%; font-size: 19px; text-align: cent  er;">
                      ${this.fel.storeName} 
                  </th>
                  <td style="width: 19%; font-size: 10px;">
                      Vendedor: ${this.activeRow.usuario}
                  </td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      ${this.company.companyName}
                  </td>
                  <td style="font-size: 10px;">Factura Electronica Fel</td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      NIT: ${this.company.companyTaxDocument}
                  </td>
                  <td>Serie: ${this.fel.invoiceSeries}</td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      Tel: ${this.fel.storePhone}<br>
                      ${this.fel.storeAddress}. ${this.fel.storeMunicipality}, ${this.fel.storeDepartment}
                  </td>
                  <td>No. DTE: ${this.fel.invoiceNumber}</td>
              </tr>
              <tr>
                  <th style="text-align: center;">FACTURA</th>
                  <td>Fecha: ${this.pipeFecha.transform(this.fel.invoiceDate, 'dd-MM-yyy')}</td>
              </tr>
        </table>
      </div>
      <br>
      <div style="border: 2px solid #2CA8FF; border-radius: 10px 10px 0px 0px; width: auto; min-height: 750px" >
        <table cellspacing="0" cellpadding="0" style="width: 100%;">
            <tr style="max-height: 15px;">
              <td colspan="4"> Nombre: ${this.felComplement.cliente}</td>
              <td colspan="3"> Nit: ${this.felComplement.nit}</td>
            </tr> 
            <tr style="max-height: 15px;">
              <td colspan="7"> Dirección: ${this.felComplement.direccion}</td>
            </tr>
            <tr style="font-size: 11px; text-align: center;">
              <th class="brDescr">CANTIDAD</th>
              <th class="brDescr">TIPO</th>
              <th class="brDescr" style="width: 320px;">DESCRIPCIÓN</th>
              <th class="brDescr">PRECIO UNI.</th>
              <th class="brDescr">IMPUESTO</th>
              <th class="brDescr">DESCUENTO</th>
              <th class="brDescr" style="width: 120px;">TOTAL</th>
            </tr>`;

    for (const e of this.felDetail) {
      html += `<tr">
        <td style="text-align: center; "> ${e.cantidadCompra}</td>
        <td style="text-align: center;"> ${e.invoiceDetailType}</td>
        <td>${e.descripcionCompra}</td>
        <td style="text-align: right;"> ${e.precioUnitarioCompra}</td>
        <td style="text-align: right;"> ${e.invoiceDetailTax}</td>
        <td style="text-align: right;"> ${e.invoiceDetailDiscount}</td>
        <td style="text-align: right;"> ${e.totalCompra}</td>
        </tr>`
    };

    html += `          
        </table>
      </div>
      <div style="border: 2px solid #2CA8FF; border-radius: 0px 0px 10px 10px; width: auto;">
        <table cellspacing="0" style="width: 100%;">
              <tr>
                <td colspan="7" style="text-align: center; font-size: 10px;">Sujeto a pagos trimestrales ISR</td>
              </tr>
              <tr style="height: 30px;">
                <td class="brCompl" style="max-width: 22px; padding-left: 5px;">Total en Letras:</td>
                  <td class="brCompl" style="font-size: 10px max-width: 65px; text-align: left;">${this.pipeLetra.transform(this.fel.invoiceTotal)}</td>
                  <th style="font-size: 14px; max-width: 65px; text-align: right; border-top: 1px solid  #2CA8FF;">
                    TOTAL: Q ${this._pipeNumero.transform(this.fel.invoiceTotal, '1.2-2')}
                  </th>
              </tr>
          </table>
      </div> 
      <br>
      <table style="border: 2px solid #2CA8FF; border-radius: 10px; width: 100%;" padding: 10px;" >
          <tr><td>
            No. de Autorización: ${this.fel.invoiceAuthorization}<br>
            Fecha de Certificación: ${this.pipeFecha.transform(this.fel.invoiceDate, 'dd-MM-yyy')}
          </td></tr>
          <tr><td style="text-align: center">Certificador: ${this.felComplement.certificador}. Nit: ${this.felComplement.nitCertificador}</td></tr>
      </table>
    </body>`;

    await pdfWindow.document.write(css + html);
  }

  ///////////////////////DESCARGAR EXCEL///////////////////////
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-de-facturas.xlsx');
  }
}
