import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-user-auto-confirm',
  templateUrl: './user-auto-confirm.component.html',
  styleUrls: ['./user-auto-confirm.component.scss']
})
export class UserAutoConfirmComponent implements OnInit {
  loading = false;
  userName: string;
  
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {
    // console.log('hola');
    this.route.paramMap.subscribe(async (params: any) => {
      const userEmail = params.params.userEmail;
      const verificationCode = params.params.verificationCode;
      if (userEmail !== undefined || verificationCode !== undefined) {
        await this.userAutoValidation(userEmail, verificationCode);
      }
    });
  }

  async userAutoValidation(userEmail, verificationCode) {
    const user = await this.userGetEmail(userEmail);
    const confirm = {
      userCognitoCode: user.userCognitoCode,
      userConfirmationCode: verificationCode
    };
    await this.userConfirm(confirm);
  }

  userGetEmail(userEmail) {
    return this.userService.oneUserEmailBackend(userEmail).then(
      (result: any) => {
        const selectedUser = result.records[0];
        return selectedUser;
      }
    ).catch(
      (err) => {
        console.log(err);
      }
    ).finally(
      () => {
      }
    );

  }

  userConfirm(confirm) {
    return this.userService.confirm(confirm).then(
      (result: any) => {
        console.log(result);
      }
    ).catch(
      (err) => {
        console.log(err);
      }
    ).finally(
      () => {
        this.router.navigate(['/login']);
      }
    );

  }
}
