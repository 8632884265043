<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/purchase-view">Autorizar-Cancelar Ordenes</a>
        </div>
    </div>
</div>

<div class="main-content" *ngIf="isViewBeginning">
    <!-- TABLE GET PENDIG -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-sm-6">
                            <h4 class="card-title">Listado ordenes de compra pendientes</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                                records"</h6>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn btn-info btn-sale" (click)="changeQuery($event, 1)"
                                [disabled]="isDisabled">
                                Pendientes
                            </button>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn btn-info btn-sale" (click)="changeQuery($event, 2)"
                                [disabled]="isDisabled">
                                Autorizadas
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="No." prop="purchaseCode"></ngx-datatable-column>
                            <ngx-datatable-column name="Ingresado"></ngx-datatable-column>
                            <ngx-datatable-column name="Proveedor"></ngx-datatable-column>
                            <ngx-datatable-column name="Monto"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn" let-row="row">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm edit"
                                        (click)="checkFunction($event)" ngbTooltip="Revisar"><i class="fas fa-eye"></i>
                                    </a>

                                    <!-- <a href="#" *ngIf="row.purchaseStatus == 1"
                                        class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="open(content, 1, $event)" ngbTooltip="Aprobar"><i
                                            class="fas fa-check"></i>
                                    </a> -->
                                    <ng-template #content let-modal>
                                        <div class="row modal-body">
                                            <div class="col-8">
                                                <h3 class="text-success">Autorizar</h3>
                                            </div>
                                            <div class="col-4 text-right">
                                                <a class="btn btn-round btn-warning btn-icon btn-sm remove btn-erp"
                                                    (click)="modal.dismiss('Cross click')"><i class="fas fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Comentario</label>
                                                        <textarea class="form-control" rows="12"
                                                            [(ngModel)]="comment"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <button type="button" class="btn btn-success"
                                                    (click)="modal.close('Save click')">Aceptar</button>
                                            </div>
                                        </div>
                                    </ng-template>

                                    <!-- <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="open(content2, 0, $event)" ngbTooltip="Denegar"><i
                                            class="fas fa-times"></i>
                                    </a> -->
                                    <ng-template #content2 let-modal>
                                        <div class="row modal-body">
                                            <div class="col-8">
                                                <h3 class="text-danger">Cancelar</h3>
                                            </div>
                                            <div class="col-4 text-right">
                                                <a class="btn btn-round btn-warning btn-icon btn-sm remove btn-erp"
                                                    (click)="modal.dismiss('Cross click')"><i class="fas fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Comentario</label>
                                                        <textarea class="form-control" rows="12" [(ngModel)]="comment"
                                                            placeholder="*Campo Obligatorio"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <button type="button" class="btn btn-success"
                                                    (click)="modal.close('Save click')">Aceptar</button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE PENDING-->
</div>

<div class="main-content" *ngIf="isViewPurchase">
    <div class="text-right">
        <button *ngIf="purchase.purchaseStatus == 1" class="btn btn-success btn-sale" (click)="open(content, 1, $event)"
            [disabled]="isDisabled">
            ✔ Autorizar
        </button>
        <ng-template #content let-modal>
            <div class="row modal-body">
                <div class="col-8">
                    <h3 class="text-success">Autorizar</h3>
                </div>
                <div class="col-4 text-right">
                    <a class="btn btn-round btn-warning btn-icon btn-sm remove btn-erp"
                        (click)="modal.dismiss('Cross click')"><i class="fas fa-times"></i>
                    </a>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Comentario</label>
                            <textarea class="form-control" rows="12" [(ngModel)]="comment"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Aceptar</button>
                </div>
            </div>
        </ng-template>
        &nbsp;
        <button class="btn btn-danger btn-sale" (click)="open(content2, 0, $event)" [disabled]="isDisabled">
            X Cancelar
        </button>
        <ng-template #content2 let-modal>
            <div class="row modal-body">
                <div class="col-8">
                    <h3 class="text-danger">Cancelar</h3>
                </div>
                <div class="col-4 text-right">
                    <a class="btn btn-round btn-warning btn-icon btn-sm remove btn-erp"
                        (click)="modal.dismiss('Cross click')"><i class="fas fa-times"></i>
                    </a>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Comentario</label>
                            <textarea class="form-control" rows="12" [(ngModel)]="comment"
                                placeholder="*Campo Obligatorio"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Aceptar</button>
                </div>
            </div>
        </ng-template>
        &nbsp;
        <a class="btn btn-round btn-warning btn-icon btn-sm success btn-erp" ngbTooltip="Salir" placement="left"
            (click)="viewBeginning($event)"><i class="fas fa-times"></i>
        </a>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header ">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b
                                                [ngClass]="{'text-info' : purchase.purchaseStatus == 1, 'text-success' : purchase.purchaseStatus == 2, 'text-danger' : purchase.purchaseStatus == 3}">
                                                Estado: {{purchase.estado}}
                                            </b>
                                            <br>Proveedor: {{purchase.proveedor}}
                                            <br>Importado: {{purchase.purchaseImport == 1 ? 'Si' : 'No'}}
                                        </div>
                                        <div class="col-md-3">
                                            <label>
                                                <b>Ingreado</b>
                                                <br>Fecha: {{purchase.purchaseCreatedDate | date:'dd-MM-yyyy'}}
                                                <br>Usuario: {{purchase.createdUser}}
                                            </label>
                                        </div>
                                        <div class="col-md-3">
                                            <label>
                                                <b>Autorizado</b>
                                                <br>Fecha: {{purchase.purchaseAuthorizedDate | date:'dd-MM-yyyy'}}
                                                <br>Usuario: {{purchase.authorizedUser}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr style="font-weight: bold;">
                                                    <td>No.</td>
                                                    <td>U. Solicitadas</td>
                                                    <td>Descripción</td>
                                                    <td class="text-center">P/U con IVA {{moneda}}</td>
                                                    <td class="text-center">Total {{moneda}}</td>
                                                    <td class="text-center">Impuesto {{moneda}}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let detail of purchaseDetail, let i=index">
                                                    <td class="text-center">{{i+1}}</td>
                                                    <td class="text-center">{{detail.purchaseDetailRequestQuantity}}
                                                    </td>
                                                    <td>{{detail.purchaseDetailDescription}}</td>
                                                    <td class="text-right">{{detail.purchaseDetailUnitPrice |
                                                        number:'1.2-2'}}</td>
                                                    <td class="text-right">{{detail.purchaseDetailPrice |
                                                        number:'1.2-2'}}</td>
                                                    <td class="text-right" style="color: #b7b7b7;">
                                                        {{detail.purchaseDetailTax |
                                                        number:'1.2-2'}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="text-align: right;">
                                    <span style="font-weight: bold; font-size: 17px; color: darkgray;">
                                        Descuento <small style="font-weight: bold; font-size: 13px;">Q</small>
                                        {{purchase.purchaseDiscount | number:'1.2-2'}}
                                    </span>
                                </div>
                                <div class="col-md-4" style="text-align: right; color: darkgray;">
                                    <span style="font-weight: bold; font-size: 17px;">
                                        Impuesto <small style="font-weight: bold; font-size: 13px;">Q</small>
                                        {{purchase.purchaseTax | number:'1.2-2'}}
                                    </span>
                                </div>
                                <div class="col-md-4" style="text-align: right;">
                                    <span style="font-weight: bold; font-size: 25px;">
                                        Total <small style="font-weight: bold; font-size: 20px;">Q</small>
                                        {{purchase.purchaseTotal | number:'1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>