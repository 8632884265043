import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Access } from '../models/access';
import { environment } from 'src/environments/environment';

@Injectable()
export class AccessService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL ACCESS
    getAllAccess(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-access', { headers: headers }).toPromise();
    }

    // GET ALL ACCESS NOT COMPANY
    getAllAccessNotCompany(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-access/notCompany', { headers: headers }).toPromise();
    }   

    // GET ALL TYPE ACCESS
    getAllTypeAccess(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-typeAccess', { headers: headers }).toPromise();
    }

    // GET ALL ROLE - ACCESS
    getAllRoleAccess(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-roleAccess', { headers: headers }).toPromise();
    }

    // GET ALL ACCESS BY ROLE
    getAllAccessByRole(code): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-roleAccess/one/' + code, { headers: headers }).toPromise();
    }

    // GET ALL ACCESS BY COMPANY AND ROLE
    getAccessByCompanyRole(company, role): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-roleAccess/company' + company + '/' + role, { headers: headers }).toPromise();
    }

    // INSERT ACCESS
    insert(access: Access): Promise<any> {
        let params = JSON.stringify(access);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-access', params, { headers: headers }).toPromise();
    }

    // UPDATE ACCESS
    update(access: Access): Promise<any> {
        let params = JSON.stringify(access);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-access', params, { headers: headers }).toPromise();
    }
}