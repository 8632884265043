import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from "sweetalert2";
import { Owner } from 'src/app/models/owner';
import { CompanyOwner } from 'src/app/models/companyOwner';
import { OwnerService } from 'src/app/services/owner.service';
import { CompanyService } from 'src/app/services/company.service';
import { AccessService } from 'src/app/services/access.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss'],
  providers: [UtilsService, OwnerService, CompanyService, AccessService]
})
export class OwnerComponent implements OnInit {
  // MODELS VARIABLE 
  public owner = {} as Owner;
  public companyOwner = {} as CompanyOwner;
  public userCode: number;
  public companyCode: number;
  public isEdit: boolean;
  public isEditCompanyOwner: boolean;
  public isDisabled: boolean;
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public entries2: number = 10;
  public selected: any[] = [];
  public temp = [];
  public tempCompanyOwner = [];
  public activeRow: any;
  public ownerList = new Array();
  public companyOwnerList = new Array();

  // VARIABLE FOR SELECT 
  public ownerListSelect: any[] = new Array();
  public selectedItemsOwner = [];
  public companyList: any[] = new Array();
  public selectedItemsCompany = [];
  public dropdownSettings = {};
  public dropdownSettingsCompany = {};

  constructor(
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _ownerService: OwnerService,
    private _accessService: AccessService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.userCompany ? parseInt(_data.userCompany.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    // LOAD VARIABLE
    this.isEdit = false;
    this.isEditCompanyOwner = false;
    this.isDisabled = false;

    // LOAD INFORMATION
    this.getAll();
    this.getAllCompanyOwner();

    // SELECT
    //SE RE-URILIZO GET ALL
    this.getCompanyList();
    this.getSelectSettingOwner(this.selectedItemsOwner);
    this.getSelectSettingCompany(this.selectedItemsCompany);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.ownerList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  entriesChange2($event) {
    this.entries2 = $event.target.value;
  }

  filterTable2($event) {
    let val = $event.target.value.toLowerCase(); 
    this.tempCompanyOwner = this.companyOwnerList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSettingOwner(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsOwner = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };

  getSelectSettingCompany(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsCompany = [selected] : '';

    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
      disabled: this.isEditCompanyOwner,
    };
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getCompanyList() {
    return this._companyService.getAll().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.companyList.push({
              id: e.companyCode,
              itemName: e.companyName
              //itemName: e.companyBusinessName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelectOwner(item: any) {
    this.companyOwner.companyOwnerOwnerCode = item.id;
  }

  onItemSelectCompany(item: any) {
    this.companyOwner.companyOwnerCompanyCode = item.id;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL OWNER
  getAll() {
    this.loading = true;

    return this._ownerService.getAll().then(
      response => {
        if (response.result) {
          this.ownerList = response.records;
          this.temp = this.ownerList;

          console.log(response.records);

          // FUNCION SELECT
          this.ownerListSelect = [];
          response.records.forEach(e => {
            this.ownerListSelect.push({
              id: e.ownerCode,
              itemName: e.ownerName
            });
          });

        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ALL COMPANY OWNER
  getAllCompanyOwner() {
    this.loading = true;

    return this._ownerService.getAllCompanyOwner().then(
      response => {
        if (response.result) {
          console.log(response.records);
          this.companyOwnerList = response.records;
          this.tempCompanyOwner = this.companyOwnerList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE OWNER
  viewFunction($event) {
    $event.preventDefault();

    swal.fire({
      title: "Propietario:",
      html:
        '<div class="row">' +
        '<div class="col-12 text-left">' +
        '<b>Nombre: </b>' + this.activeRow.ownerName + '<br>' +
        '<b>Correo: </b>' + this.activeRow.ownerEmail + '<br>' +
        '<b>Teléfono: </b>' + this.activeRow.ownerPhone + '<br>' +
        '<b>Nit: </b>' + this.activeRow.ownerTaxDocument + '<br>' +
        '<b>DPI: </b>' + this.activeRow.ownerDocument + '<br>' +
        '<b>Dirección: </b>' + this.activeRow.ownerAddress + '<br>' +
        "</div></div>",
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 450,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    })
  }

  // INSERT
  save() {
    this.isDisabled = true;
    this.loading = true;

    this.owner.ownerStatus = 1;
    this.owner.ownerDeleted = 0;

    this._ownerService.insert(this.owner).then(
      async (response) => {
        if (response.result == true) {
          // this.getAll();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    })
  }

  // INSERT COMPANY OWNER
  saveCompanyOwner() {
    this.isDisabled = true;

    this._ownerService.insertCompanyOwner(this.companyOwner).then(
      async (response) => {
        if (response.result == true) {
          // this.getAll();
          this.cleanModelCompanyOwner();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // UPDATE
  update() {
    this.isDisabled = true;

    this._ownerService.update(this.owner).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          // this.getAll();
          // this.getAllCompanyOwner();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // UPDATE COMPANY OWNER
  updateCompanyOwner() {
    this.isDisabled = true;

    this._ownerService.updateCompanyOwner(this.companyOwner).then(
      async (response) => {
        if (response.result == true) {
          this.isEditCompanyOwner = false;
          this.cleanModelCompanyOwner();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.owner.ownerCode = this.activeRow.ownerCode;
    this.owner.ownerName = this.activeRow.ownerName;
    this.owner.ownerEmail = this.activeRow.ownerEmail;
    this.owner.ownerAddress = this.activeRow.ownerAddress;
    this.owner.ownerPhone = this.activeRow.ownerPhone;
    this.owner.ownerDocument = this.activeRow.ownerDocument;
    this.owner.ownerTaxDocument = this.activeRow.ownerTaxDocument;
    this.owner.ownerStatus = 0;
    this.owner.ownerDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "El tipo de empresa se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.owner.ownerCode = this.activeRow.ownerCode;
    this.owner.ownerName = this.activeRow.ownerName;
    this.owner.ownerEmail = this.activeRow.ownerEmail;
    this.owner.ownerAddress = this.activeRow.ownerAddress;
    this.owner.ownerPhone = this.activeRow.ownerPhone;
    this.owner.ownerDocument = this.activeRow.ownerDocument;
    this.owner.ownerTaxDocument = this.activeRow.ownerTaxDocument;
    this.owner.ownerStatus = this.activeRow.ownerStatus;
    this.owner.ownerDeleted = this.activeRow.ownerDeleted;
  }

  // LOAD DATA TO EDIT COMPANY OWNER
  editFunctionCompanyOwner($event) {
    $event.preventDefault();
    this.isEditCompanyOwner = true;

    this.companyOwner.companyOwnerCode = this.activeRow.companyOwnerCode;
    this.companyOwner.companyOwnerOwnerCode = this.activeRow.companyOwnerOwnerCode;
    this.companyOwner.companyOwnerCompanyCode = this.activeRow.companyOwnerCompanyCode;

    const _selectedItemOwner = this.ownerListSelect.find(x => x.id == this.activeRow.companyOwnerOwnerCode);
    const _selectedItemCompany = this.companyList.find(x => x.id == this.activeRow.companyOwnerCompanyCode);
    this.getSelectSettingOwner(_selectedItemOwner);
    this.getSelectSettingCompany(_selectedItemCompany);
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //CANCEL EDIT
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
  }

  //CANCEL EDIT COMPANY OWNER
  cancelUpdateCompanyOwner($event) {
    this.cleanModelCompanyOwner();
    this.isEditCompanyOwner = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.getAll();
    this.getAllCompanyOwner();
    this.isDisabled = false;
    this.owner = {} as Owner;
  }

  // CLEAR MODAL
  cleanModelCompanyOwner() {
    this.getAll();
    this.getAllCompanyOwner();
    this.isDisabled = false;
    this.companyOwner = {} as CompanyOwner;
    this.selectedItemsOwner = null;
    this.selectedItemsCompany = null;

    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
      disabled: false,
    };
  }
}
