import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { PayRegister } from 'src/app/models/pay-register';
import { AccountingService } from '../../../services/accounting.service';
import { Purchase } from 'src/app/models/purchase';
import { PurchaseService } from '../../../services/purchase.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PrintService } from 'src/app/services/print.service';
import { Company } from "src/app/models/company";
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-payment-purchase',
  templateUrl: './payment-purchase.component.html',
  styleUrls: ['./payment-purchase.component.scss'],
  providers: [UtilsService, PurchaseService, AccountingService, PrintService, CompanyService]
})
export class PaymentPurchaseComponent implements OnInit {
  // MODELS VARIABLE 
  public payRegister = {} as PayRegister;
  public purchase = {} as Purchase;
  public payList: any[];
  public isViewBeginning: boolean;
  public isViewPurchaseDueQ: boolean;
  public isViewPayRegister: boolean;
  public isViewNewPayRegister: boolean;
  public disabledDuesQ = false;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public cash: boolean;
  public totalPayment = 0;
  public saldox = 0;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      codigo: {
        title: 'Codigo'        
      },
      total: {
        title: 'Total', 
        type: 'number'       
      },
      pagado: {
        title: 'Pagado',
        type: 'number'        
      },
      saldo: {
        title: 'Saldo'        
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  company = {} as Company;
  public companyLogoUrl: string;

  // VARIABLE SELECTED
  public paymentMethodList: any[];

  constructor(
    private _utilsService: UtilsService,
    private _purchaseService: PurchaseService,
    private _accoutingService: AccountingService,
    private _printService: PrintService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.cash = false
    this.isViewBeginning = true;
    this.payRegister.payRegisterMethodCode = 2;

    await this.getCompany(this.companyCode);
    this.getAll();
    this.getPaymentMethod();
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];          
          this.company.companyLogo != 'undefined' ? this.companyLogoUrl = this.company.companyLogoUrl : '';
          console.log('Company -> ', this.company);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.payList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getPaymentMethod() {
    return this._accoutingService.getPaymentMethod().then(
      response => {
        if (response.result) {
          this.paymentMethodList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      });
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL PAYMENT
  getAll() {
    this.loading = true;

    const json =
    {
      "companyCode": this.companyCode
    };

    return this._accoutingService.getAllPaymentPurchase(json).then(
      async response => {
        if (response.result) {
          console.log('datos payment',response);
          this.payList = await response.records.map(function(x) { 
            x.fecha === null && (x.fecha = '01/01/2000');
            x.fecha === '00/00/0000' && (x.fecha = '01/01/2000');                         
            return x;
          });

          await  this._utilsService.orderDate(this.payList, 'fecha', '/');
          this.temp = this.payList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET REGISTER OF AN purchase
  getPayRegister($event) {
    $event.preventDefault();
    this.loading = true;

    this.payRegister.payRegisterInvoiceCode = 0;
    this.payRegister.payRegisterPurchaseCode = this.activeRow.purchaseCode;
    
    let total = 0;
    return this._accoutingService.getAllPayRegister(this.payRegister).then(
      response => {
        if (response.result) {
          console.log(response.records[1]);
          this.payRegister = response.records[1];
          
          response.records[1].forEach(e => {
            this.totalPayment += e.payRegisterAmount;
            total = e.purchaseTotal
          });
          
          this.saldox = total - this.totalPayment;

          this.viewPayRegister();
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  printHistory($event,invoiceCode){
    $event.preventDefault();
    console.log('invoiceCode',invoiceCode);
    console.log('payRegister print',this.payRegister);
    console.log('Total Pagado',this.totalPayment);
    console.log('saldox',this.saldox);

    this._printService.paymentPurchaseHistory(this.payRegister,this.company,this.totalPayment,this.saldox)    
  }

  // UPDATE PURCHASE DUES QUANTITY
  updatePurchase() {
    console.log('entro');
    let json = {
      "purchaseCode": this.purchase.purchaseCode,
      "purchaseDuesQuantity": this.purchase.purchaseDuesQuantity,
      "description": this.purchase.purchaseComment,
      "purchaseTotal": this.purchase.purchaseTotal,
      "storeCode": this.storeCode
    };

    console.log(json);
    this._purchaseService.updateDairy(json).then(
      async (response) => {
        if (response.result == true) {
          this.getAll();
          this.viewBeginning(event);
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // SAVE NEW REGISTER
  saveRegister() {
    this.payRegister.payRegisterPayCode = 2;
    this.payRegister.storeCode = this.storeCode;

    console.log(this.payRegister);
    if (this.payRegister.payRegisterDocument != undefined && this.payRegister.payRegisterDocument != '') {
      this.loading = true;

      this._accoutingService.insertPayRegister(this.payRegister).then(
        async (response) => {
          if (response.result == true) {
            //GENERATE RECEIPT IF IT IS A CREDIT            
            if (this.cash == true) {
              await this.updatePurchase();
            }
            this.getAll();
            this.viewBeginning(event);
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this._utilsService.showNotification(2, 'Ingresar Numero de Documento');
    }
  }

  loadData($event, option) {
    $event.preventDefault();
    this.cash = false;

    this.payRegister.payRegisterPurchasePassword = this.activeRow.purchasePassword;

    console.log('saldo',this.activeRow.saldo);

    if(this.activeRow.saldo == 0){
      this._utilsService.showNotification(2,'No hay saldo pendiente');
    }
    else{
      if (option == 1) {
        this.purchase.purchaseCode = this.activeRow.purchaseCode;
        this.purchase.purchaseTotal = this.activeRow.purchaseTotal;
        this.payRegister.payRegisterAmount = this.activeRow.purchaseTotal;
        this.purchase.purchaseDuesQuantity = this.activeRow.purchaseDuesQuantity;
        this.payRegister.payRegisterPurchaseCode = this.activeRow.purchaseCode;
        this.viewPurchaseDueQuantity();
      } else {
        this.payRegister.payRegisterPurchaseCode = this.activeRow.purchaseCode;
        this.payRegister.payRegisterAmount = 0;
        this.payRegister.payRegisterDescription = '';
  
        if(this.activeRow.purchaseDuesQuantity == 0) {
          this.cash = true;
          this.purchase.purchaseComment = 'Al Contado';
          this.purchase.purchaseDuesQuantity = 0;
          this.purchase.purchaseCode = this.activeRow.purchaseCode;
          this.purchase.purchaseTotal = this.activeRow.purchaseTotal;
        }else{
          this.cash = false;
        }  
        this.viewNewPayRegister();
      }
    }    
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //  VIEWS
  viewBeginning($event) {
    $event.preventDefault();

    this.isViewBeginning = true;
    this.isViewPurchaseDueQ = false;
    this.isViewPayRegister = false;
    this.isViewNewPayRegister = false;

    this.cleanModel();
  }

  viewPurchaseDueQuantity() {
    this.isViewBeginning = true;
    this.isViewPurchaseDueQ = true;
    this.isViewPayRegister = false;
    this.isViewNewPayRegister = false;

    this.purchase.purchaseDuesQuantity = this.activeRow.purchaseDuesQuantity;
    this.activeRow.purchaseDuesQuantity >= 1 ? this.disabledDuesQ = true : this.disabledDuesQ = false;
  }

  viewNewPayRegister() {
    this.isViewBeginning = true;
    this.isViewPurchaseDueQ = false;
    this.isViewPayRegister = false;
    this.isViewNewPayRegister = true;
  }

  viewPayRegister() {
    this.isViewBeginning = false;
    this.isViewPayRegister = true;
    this.isViewPurchaseDueQ = false;
    this.isViewNewPayRegister = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.payRegister = {} as PayRegister;
    this.purchase = {} as Purchase;

    this.payRegister.payRegisterMethodCode = 2;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item) => {
        if (this.settings.columns[item].type === "") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-cuentas-por-pagar.xlsx');
  }
}
