import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as xlsx from 'xlsx';
import { WarehouseService } from '../../../services/warehouse.service';

@Component({
  selector: 'app-transfer-report',
  templateUrl: './transfer-report.component.html',
  styleUrls: ['./transfer-report.component.scss'],
  providers: [WarehouseService, UtilsService]
})
export class TransferReportComponent implements OnInit {
  // MODELS VARIABLE 
  public isDisabled: boolean;
  public isReport: boolean;
  public isStoreReport: boolean;
  public storeCode: number;
  public companyCode: number;
  public warehouseCode: number;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      sucursal: {
        title: 'Sucursal'
      },
      proveedor: {
        title: 'Proveedor'
      },
      cantidad: {
        title: 'Cantidad',
        type: 'number'
      },
      codigo: {
        title: 'Codigo'
      },
      descripcion: {
        title: 'Descripcion'
      },
      precio: {
        title: 'Precio',
        type: 'number'
      }
    }
  };

  public settingsProcesadas = {
    columns: {
      salio: {
        title: 'Salio'
      },
      entro: {
        title: 'Entro'
      },
      fecha: {
        title: 'Fecha'
      },
      recibo: {
        title: 'Recibo'
      },
      descripcion: {
        title: 'Descripcion'
      }
    }
  };

  loading;

  // DATA SELECT
  public warehouseList: any[] = new Array();
  public selectedItemsWarehouse = [];
  public dropdownSettingsWarehouse = {};


  // VARIABLE FOR DATA TABLE //GET ALL //GET PENDING 
  public temp = [];
  public activeRow: any;
  public selected: any[] = [];
  public entries: number = 10;
  public allList = new Array();
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;

  constructor(
    private _utilsService: UtilsService,
    private _warehouseService: WarehouseService
  ) { }

  ngOnInit(): void {
    this.isDisabled = false;
    this.warehouseCode = null;

    let _data = JSON.parse(localStorage.getItem('data'));
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    this.viewReport();
    this.getSelectSetting();
    this.getAllWarehouse();
  }


  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.allList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////SELECT///////////////////////
  getSelectSetting() {
    this.dropdownSettingsWarehouse = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: false,
    };
  }

  onItemSelectWarehouse(item: any) {
    this.warehouseCode = item.id;
  }

  onDeSelectWarehouse() {
    this.warehouseCode = null;
  }

  getAllWarehouse() {
    return this._warehouseService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result == true) {
          response.records[1].forEach(e => {
            this.warehouseList.push({
              id: e.warehouseCode,
              itemName: e.warehouseName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getReport() {
    if (this.warehouseCode != null) {
      this.loading = true;
      this.isDisabled = true;

      return this._warehouseService.reportTransfer(this.warehouseCode, this.companyCode).then(
        response => {
          if (response.result) {
            this.allList = response.records;
            this.temp = this.allList;

            console.log('uno', this.allList)
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.message);
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.viewReport();
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this._utilsService.showNotification(2, 'Selecciona una bodega');
    }
  }

  getAllReport() {
    this.loading = true;
    this.isDisabled = true;

    return this._warehouseService.reportAllTransfer(this.companyCode).then(
      response => {
        if (response.result) {
          this.allList = response.records;
          this.temp = this.allList;
          console.log('dos', this.allList)
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.viewReport();
      this.loading = false;
      this.isDisabled = false;
    });
  }

  getAllReportInStore() {
    this.loading = true;
    this.isDisabled = true;

    return this._warehouseService.getTransferByCompany(this.companyCode, 0).then(
      response => {
        if (response.result) {
          console.log(response.records)

          // response.records.forEach(e => {
          //   e.detail.forEach(i => {
          //     this.allList.push({
          //       salio: e.salio,
          //       entro: e.entro,
          //       fecha: e.fecha,
          //       recibo: e.serie,
          //       descripcion: i.transferInventoryDetailDescription
          //     })
          //   });
          // });

          for (const e of response.records){
            e.detail.forEach(i => {
              this.allList.push({
                salio: e.salio,
                entro: e.entro,
                fecha: e.fecha,
                recibo: e.serie,
                descripcion: i.transferInventoryDetailDescription
              })
            });
          };
          console.log('tres', this.allList)
          this.temp = this.allList;
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.viewStoreReport();
      this.loading = false;
      this.isDisabled = false;
    });
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-transferencias.xlsx');
  }

  // DESCARGAR EXCEL
  async downloadToExcelProcesadas() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settingsProcesadas.columns).forEach((item, index, arr) => {
        if (this.settingsProcesadas.columns[item].type === "number") {
          json[this.settingsProcesadas.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settingsProcesadas.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-transferencias.xlsx');
  }

  viewReport() {
    this.isReport = true;
    this.isStoreReport = false;
  }

  viewStoreReport() {
    this.isReport = false;
    this.isStoreReport = true;
  }
}
