import { SelectionType } from '@swimlane/ngx-datatable';
import { Component, OnInit } from '@angular/core';
import swal from "sweetalert2";
import { Company } from 'src/app/models/company';
import { UtilsService } from 'src/app/services/utils.service';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-invoice-recurrent-maintenance',
  templateUrl: './invoice-recurrent-maintenance.component.html',
  styleUrls: ['./invoice-recurrent-maintenance.component.scss'],
  providers: [InvoiceService, UtilsService]
})

export class InvoiceRecurrentMaintenanceComponent implements OnInit {
  // Variables auxiliares
  public company = {} as Company;
  public isBeginning: boolean;
  public isViewOne: boolean;
  public loading: boolean;
  public isDisabled: boolean;
  public userCode: number;
  public storeCode: number;
  public storeName: string;
  public companyCode: number;

  public fel: any;
  public felDetail: any;
  public now: string;
  public nowGenerate: string;

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp = new Array();
  public invoiceListOrigin: any[] = new Array();
  public invoiceList: any[] = new Array();


  constructor(
    private _utilsService: UtilsService,
    private _invoiceService: InvoiceService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    this.now = this._utilsService.dateTime('yyy-MM-dd');

    this.viewBeginning();
    await this.getAll();
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isViewOne = false;
  }

  viewOne() {
    this.isBeginning = false;
    this.isViewOne = true;
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  SelectionType = SelectionType;

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.invoiceList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  displayCheck(row) {
    //return row.jonGenerated == 0;
    return 1;
  }

  ///////////////////////CRUD///////////////////////
  //Ver todas las recurrencias
  getAll() {
    this.loading = true;
    this.isDisabled = true;

    this._invoiceService.recurrentInvoice(this.companyCode).then(
      async (response) => {
        if (response.result == true) {
          console.log(response.records);
          this.invoiceList = response.records.map(function(x) {        
            const result = {
              jobCode: x.jobCode,
              jonGenerated: x.jonGenerated,
              total: x.jobJson.invoice.invoiceTotal,
              creacion: x.jobJson.invoice.invoiceDate,
              cliente: x.jobJson.receiver.receiverName,
              nit: x.jobJson.receiver.receiverTaxDocument,
              recurrencia:  x.jobLastDay == 1 ? 'Fin de Mes' : x.jobDay,
              estado:  x.jonGenerated == 0 ? 'Pendiente' : 'Facturado',
              lasGenerated: x.jobLastGenerated
            }
            return result;
          });      
          await  this._utilsService.orderDate(this.invoiceList, 'creacion', '/');          
          this.invoiceListOrigin = response.records;
          this.temp = this.invoiceList;
          this._utilsService.showNotification(1, response.message);
          console.log(this.temp);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  async billByBatch() {
    console.log(this.selected);
    if (this.selected.length > 0) {
      for (const [i, e] of this.selected.entries()) {
        this.fel = undefined;
        this.fel = await this.invoiceListOrigin.find(x => x.jobCode === e.jobCode);

        console.log('Fel->', this.fel);

        if (this.fel) {
          const stop = (i + 1 == this.selected.length);        
          await this.billing(this.fel.jobJson, this.fel.jobCode, stop);
        }
      }
    }else{
      this._utilsService.showNotification(2, 'No selecciono ningun registro!');
    }
  }

  async billing(invoice, jobCode, stop) {
    this.loading = true;
    this.isDisabled = true;
    invoice.invoice.invoiceDate = this.now;

    var moment = require('moment');
    var nowGenerateX = moment().format("YYYY-MM-DD HH:mm:ss");    
    this.nowGenerate = nowGenerateX
    console.log(this.nowGenerate);


    // INFILE
    if (invoice.provider === 'infile') {  //this.company.companyCertifier == 0
      await this._invoiceService.insertBilling(invoice).then(
        async (response) => {
          if (response.result) {
            const uuid = response.invoiceAuthorization;
            window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid);
            await this.update(jobCode, 1, 0, 1);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this._utilsService.showNotification(2, error);
        }
      ).finally( () =>{
        console.log(stop)
        stop && (this.loading = false, this.isDisabled = false);
      })
    }
    // MEGAPRINT
    else if (invoice.provider === 'megaprint') {
      await this._invoiceService.insertBilling(invoice).then(
        async (response) => {
          if (response.result) {
            const pdfResult = response.invoicePdf;
            const pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")
            await this.update(jobCode, 1, 0, 1);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this._utilsService.showNotification(2, error);
        }
      ).finally( () =>{
        stop && (this.loading = false, this.isDisabled = false);
      })
    }
  }

  // GET ONE INVOICE
  async searchInvoice($event) {
    $event.preventDefault();
    this.loading = true;

    this.fel = await this.invoiceListOrigin.find(x => x.jobCode === this.activeRow.jobCode);
    this.felDetail = await this.fel.jobJson.detail;
    setTimeout( () => (this.loading = false, this.viewOne()), 500);    
  }

  // DELETED
  getDeleted($event) {
    $event.preventDefault();

    swal.fire({
      title: "Esta seguro",
      text: "La factura recurrente se eliminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(async result => {
      if (result.value) {
        await this.update(this.activeRow.jobCode, 0, 1, 0);
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  update(jobCode, status, deleted, generated) {
    deleted === 1 && (this.loading = true, this.isDisabled = true);
    
    const json = {
      "jobCode": jobCode,
      "jobStatus": status,
      "jobDeleted": deleted,
      "jonGenerated":  generated,
      "jobLastGenerated": this.nowGenerate
    };
    
    this._invoiceService.updateRecurrentBilling(json).then(
      (response) => {
        if (response.result == true) {
          deleted === 1 && (
            this.getAll(), 
            this._utilsService.showNotification(1, response.message)
          );
        } else {;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        console.log(error);
        this._utilsService.showNotification(2, error);
      }
    ).finally(() => {
      deleted === 1 && (this.loading = false, this.isDisabled = false);
    });
  }
}
