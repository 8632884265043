import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { PrintService } from 'src/app/services/print.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { StoreService } from 'src/app/services/store.service';

interface transferHeader {
  transferOriginStoreCode,
  transferWarehouseCode,
  transferBusinessCode,
  transferDescription,
  transferType,
  transferRazon,
  transOtherRazonDesc

  //auxiliares
  transferName
  transferPhone,
  transferAddress,
  transferAdviser,
  transferTaxDocument,
}

interface transferDetail {
  transferInventoryDetailInventoryCode,
  transferInventoryDetailQuantity,
  transferDetailType,
  description,
  transferNotaSalida,
  transferSucursal,
  //auxiliares
  transferInventoryBussiness
}

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
  providers: [UtilsService, PrintService, CompanyService, WarehouseService, InventoryService, StoreService]

  // StoreService
})
export class TransferComponent implements OnInit {
  // Variables auxiliares
  company = {} as Company;
  transferHeader = {} as transferHeader;
  transferDetail = {} as transferDetail;
  public date: string;
  public userCode: number;
  public storeCode: number;
  public storeName: string;
  public companyCode: number;
  public userName: string;
  public transferCode: number;
  public isEdit: boolean;
  public isDisabled: boolean;
  loading;

  // Variables de Linea de transfer
  public transferLine: any = new Array;
  public addAmount: boolean;
  public stock: number;
  public price: number;
  public total: number;
  public serial: boolean;
  public serialSelected = new Array;
  public Minstock: number;

  // DATA SELECT
  public warehouseList: any[] = new Array();
  public selectedItemsWarehouse = [];
  public dropdownSettingsWarehouse = {};
  
  public dropdownSettingsStore = {};

  public inventoryList: any[] = new Array();
  public selectedItems = [];
  public dropdownSettings: IDropdownSettings = {};
  closeDropdownSelection = false;

  public serialList: any[] = new Array();
  public selectedItemsSerial = [];
  public dropdownSettingsSerial: IDropdownSettings = {};
  closeDropdownSelectionSerial = false;

  // DATA SELECT TIPO TRANSFERENCIA
  public transferType: number = 0;
  public transferTypeList: any[] = new Array();
  public selectedItemstransferType = [];
  public dropdownSettingstransferType = {};

  public otherRazon: boolean = false;
  public RefNotaSalida: boolean = false;

  public storeList: any[] = new Array();
  public storeListSelect: any[] = new Array();
  public selectedItemsStore = [];
  public storeInv: number;
  public dropdownSettingsStores = {
    singleSelection: true,
    badgeShowLimit: 1,
    text: "Seleccione Sucursal...",
    searchPlaceholderText: "Buscar",
    enableCheckAll: true,
    enableUnCheckAll: true,
    selectAllText: "Seleccionar todas",
    unSelectAllText: "Deseleccionar todas",
    enableSearchFilter: true,
    lazyLoading: false,
    noDataLabel: "Resultados:",
  };

  constructor(
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _companyService: CompanyService,
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService,
    private _storeService: StoreService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.storeName = _data.storeName ? _data.storeName.toString() : '';
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    const _user = JSON.parse(localStorage.getItem('user'));
    this.userName = _user.userName ? _user.userName.toString() : '';
    this.transferHeader.transferAdviser = this.userName;

    this.price = 0;
    this.total = 0;
    this.isEdit = false;
    this.addAmount = true;
    this.transferCode = 0;
    this.date = this._utilsService.dateTime('dd-MM-yyy');    
    this.transferDetail.transferNotaSalida = 0;
    this.transferDetail.transferSucursal = 0;

    this.getAllWarehouse();
    this.getSelectSetting(this.selectedItems);
    this.getSelectSettingSerial(this.selectedItemsSerial);
    this.getSelectSettingWarehouse();
    this.getSelectSettingTransferType();
    this.getInventoryList(this.storeCode);
    await this.getCompany(this.companyCode);
    this.getAllTransferType();
    
    await this.getStoreByCompany(this.companyCode);
  }
  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onChangeRazonTransfer(event) {
    this.transferHeader.transferRazon = event;
    console.log(this.transferHeader.transferRazon);

    if(this.transferHeader.transferRazon == 4){
      this.otherRazon = true;
    }
    else{
      this.otherRazon = false;
    }
  }

  ///////////////////////SELECT TRANSFER TYPES///////////////////////
  getSelectSettingTransferType() {
    this.dropdownSettingstransferType = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      lazyLoading: false,
    };
  }

  onItemSelectTransferType(item: any) {
    this.transferType = item.id;
    if(this.transferType == 2){
      this.RefNotaSalida = true;
    }
    else{
      this.RefNotaSalida = false;
    }
    console.log(this.transferType);
  }

  onDeSelectTransferType() {
    this.transferType = null;
  }

  getAllTransferType(){
    this.transferTypeList.push({
      id: "1",
      itemName: "Salida"
    });

    this.transferTypeList.push({
      id: "2",
      itemName: "Ingreso"
    });
  }

  ///////////////////////SAVE MULTI STORE///////////////////////
  getStoreByCompany(companyCode) {
    return this._storeService.getAllByCompany(companyCode).then(
      (response) => {
        if (response.result) {
          for (const e of response.records) {
            this.storeList.push({ id: e.storeCode, itemName: e.storeName });
          }
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onItemSelectStore(item: any) {
    this.storeListSelect.push({ inventoryStore: item.id });
    this.disabledMultiStore = false;
    console.log(item);
    this.storeInv = item.id;
    console.log(this.storeInv);
    this.transferDetail.transferSucursal = this.storeInv;
    console.log('transferSucursal',this.transferDetail.transferSucursal);
  }

  onSelectAllStore() {
    this.storeListSelect = [];
    this.storeList.forEach((x) => {
      this.storeListSelect.push({ inventoryStore: x.id });
    });
  }

  onDeItemSelectStore(item: any) {
    const find = this.storeListSelect.findIndex(
      (x) => x.inventoryStore === item.id
    );
    this.storeListSelect.splice(find, 1);

    this.storeListSelect.length == 0 ? (this.disabledMultiStore = true) : "";
  }

  onDeSelectAllStore() {
    this.storeListSelect = [];
    this.selectedItemsStore = null;
  }

  disabledMultiStore = false;

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getSelectSettingWarehouse() {
    this.dropdownSettingsWarehouse = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      lazyLoading: false,
    };
  };

  getSelectSetting(selected) {
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'itemName'
    };
  }

  getSelectSettingSerial(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsSerial = [selected] : '';

    this.dropdownSettingsSerial = {
      // idField: 'InventorySerialCode',
      // textField: 'inventorySerialSerial',
      idField: 'serialCode',
      textField: 'description',
      itemsShowLimit: 3,
      enableCheckAll: false,
      singleSelection: false,
      allowSearchFilter: true,
      searchPlaceholderText: "Buscar",
    }
  }

  getAllWarehouse() {
    return this._warehouseService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result == true) {
          response.records[1].forEach(e => {
            this.warehouseList.push({
              id: e.warehouseCode,
              itemName: e.warehouseName,
              phone: e.warehousePhone,
              address: e.warehouseClientAddress,
              client: e.warehouseClientCode,
              nit: e.nit == null ? '' : e.nit
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getInventoryList(company) {
    this.loading = true;
    return this._inventoryService.getAllByStore(company).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onItemSelectWarehouse(item: any) {
    this.transferHeader.transferWarehouseCode = item.id;
    this.transferHeader.transferPhone = item.phone;
    this.transferHeader.transferName = item.itemName;
    this.transferHeader.transferAddress = item.address;
    this.transferHeader.transferTaxDocument = item.client;
    this.transferHeader.transferTaxDocument = item.nit;
  }

  async onItemSelect(item: any) {
    this.transferDetail.transferInventoryDetailInventoryCode = item.id;
    const _selectedItem = this.inventoryList.find(x => x.id == item.id);
    this.price = _selectedItem.inventoryPrice;
    this.stock = _selectedItem.inventoryQuantity;
    this.transferDetail.description = _selectedItem.inventoryName;
    this.transferDetail.transferInventoryBussiness = _selectedItem.inventoryBusinessCode;
    this.Minstock = _selectedItem.inventoryMin;

    if (_selectedItem.inventorySerialFlag == 1) {
      let res_find = await this.transferLine.find(x => x.transferInventoryDetailInventoryCode === this.transferDetail.transferInventoryDetailInventoryCode);

      if (!res_find) {
        this.loading = true;
        this.serialList = [];
        const response = await this._inventoryService.getItemSerial(item.id);
        response.records[1].forEach(e => {
          this.serialList.push({
            serialCode: e.InventorySerialCode,
            description: e.inventorySerialSerial
          })
        });
        this.serial = true;
        this.loading = false;
      } else {
        await this.cleanLineTransfer();
        this._utilsService.showNotification(3, 'Item ya ingresado, por favor eliminalo antes.');
      }
    } else {
      this.serial = false
    }
  }

  onItemDeSelect(item: any) {
    this.serial = false;
    this.transferDetail.description = undefined;
  }

  onItemSelectSerial(item: any) {
    this.serialSelected.push({
      serialCode: item.serialCode,
      description: item.description
    });
  }

  onItemDeSelectSerial(item: any) {
    this.serialSelected.forEach((e, index) => {
      if (e.serialCode == item.serialCode) this.serialSelected.splice(index, 1);
    });
  }

  // GUARDAR UNA LINEA DE COMPRA
  async saveLine() {
    this.isDisabled = true;

    if (this.transferDetail.transferInventoryDetailQuantity > 0 && this.transferDetail.description != undefined) {
      //Actualizar?
      let update = false;
      this.transferLine.forEach((e) => {
        if (e.transferInventoryDetailInventoryCode == this.transferDetail.transferInventoryDetailInventoryCode) {
          let quantity = e.transferInventoryDetailQuantity + this.transferDetail.transferInventoryDetailQuantity;
          if (this.company.companyExistenceFlag == 1 || quantity <= this.stock) {
            
            //Restar Encabezado
            this.total = parseFloat((this.total - e.subtotal).toFixed(2));
            //Editar Linea
            e.transferInventoryDetailQuantity = quantity,
            e.subtotal = parseFloat((this.price * quantity).toFixed(2));
            //Sumar Encabezado 
            this.total = this.total + parseFloat((quantity * this.price).toFixed(2));
            this.addAmount = false;
            this.cleanLineTransfer();
          } else {
            this._utilsService.showNotification(2, 'Cantidad Excede Exitencia');
          }
          update = true;
          this.addAmount = false;
          this.isDisabled = false;
          this.cleanLineTransfer();
        }
      });

      //Nuevo Item
      if (this.addAmount === true && update === false) {
        //Validacion
        if (this.company.companyExistenceFlag === 1 || this.transferDetail.transferInventoryDetailQuantity <= this.stock) {

          let DifInventario = this.stock - this.transferDetail.transferInventoryDetailQuantity;

          console.log(DifInventario, this.stock, this.transferDetail.transferInventoryDetailQuantity);
          console.log(this.Minstock);   

          if(DifInventario <= this.Minstock){
            this._utilsService.showNotification(2, 'El STOCK MINIMO ha llegado a su limite configurado.');
            //enviar correo
          }

          await this.transferLine.push({
            transferInventoryDetailInventoryCode: this.transferDetail.transferInventoryDetailInventoryCode,
            transferInventoryDetailQuantity: this.transferDetail.transferInventoryDetailQuantity,
            transferInventoryBussiness: this.transferDetail.transferInventoryBussiness,
            transferDetailType: 1,
            description: this.transferDetail.description,
            unitPrice: this.price,
            subtotal: this.price * this.transferDetail.transferInventoryDetailQuantity,
          });
          //Realizar Calculos
          this.total = this.total + parseFloat((this.transferDetail.transferInventoryDetailQuantity * this.price).toFixed(2));
          this.cleanLineTransfer();

        } else {
          this._utilsService.showNotification(2, 'Cantidad Excede Exitencia');
        }
        // this.isDisabled = false;
      }
    } else {
      this._utilsService.showNotification(2, "Ingresa una cantidad")
    }

    this.isDisabled = false;
  }

  async saveLineSerial() {
    this.isDisabled = true;
      if (this.transferDetail.transferInventoryDetailQuantity === this.serialSelected.length) { //serialSelectedTemp

        this.transferDetail.description += ' SERIE: ';
        for await (const e of this.serialSelected) {
          this.transferDetail.description += e.description + ', ';
        }

        await this.transferLine.push({
          transferInventoryDetailInventoryCode: this.transferDetail.transferInventoryDetailInventoryCode,
          transferInventoryDetailQuantity: this.transferDetail.transferInventoryDetailQuantity,
          transferInventoryBussiness: this.transferDetail.transferInventoryBussiness,
          transferDetailType: 0,
          description: this.transferDetail.description,
          unitPrice: this.price,
          subtotal: this.price * this.transferDetail.transferInventoryDetailQuantity,
          serial: this.serialSelected
        });
        this.total = this.total + parseFloat((this.transferDetail.transferInventoryDetailQuantity * this.price).toFixed(2));
        this.cleanLineTransfer();
      } else {
        this._utilsService.showNotification(2, 'Por favor revisa las cantidad de series seleccionadas');
      }
    this.isDisabled = false;
  }

  // ELIMINAR UNA LINEA
  deleteLine(item) {
    this.isDisabled = true;

    this.transferLine.forEach((e, index) => {
      if (e.transferInventoryDetailInventoryCode == item) {
        this.total = parseFloat((this.total - e.subtotal).toFixed(2));
        this.transferLine.splice(index, 1);
      }
    })
    this.cleanLineTransfer();
    this.isDisabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineTransfer() {
    this.addAmount = true;
    this.selectedItems = []; 
    //this.transferDetail = {} as transferDetail;
    this.serialSelected = [];
    this.serial = false;
    this.selectedItemsSerial = [];
  }

  async save() {
    if (this.transferHeader.transferWarehouseCode > 0 && this.transferLine.length > 0) {
      this.loading = true;
      this.isDisabled = true;

      const json = {
        "header": {
          "transferOriginStoreCode": this.storeCode,
          "transferWarehouseCode": this.transferHeader.transferWarehouseCode,
          "transferDescription": this.transferHeader.transferDescription,
          "transferUserCode": this.userCode,
          "transferType": this.transferType,
          "transferRazon": this.transferHeader.transferRazon,
          "transOtherRazonDesc": this.transferHeader.transOtherRazonDesc,
          "transferNotaSalidaRef": this.transferDetail.transferNotaSalida,
          "transferSucursalOrg": this.transferDetail.transferSucursal
        },
        "detail": this.transferLine,
      };

      const logo = await this._companyService.getOne(this.companyCode);
      logo.records[0].companyLogoUrl != 'undefined' && (this.company.companyLogoUrl = logo.records[0].companyLogoUrl);

      console.log('json save', json);
      
      //* Insertar Transferencia    
      this._warehouseService.insertTransfer(json).then(
        async (response) => {
          if (response.result) {
            this.transferCode =  response.transferencia;
            
            const transfer = {
              transferBusinessCode: response.transferencia,
              bodega: this.transferHeader.transferName,
              clientTaxDocument: this.transferHeader.transferTaxDocument,
              direccion: this.transferHeader.transferAddress,
              fecha: this.date,
              warehousePhone: this.transferHeader.transferPhone,
              usuario: this.transferHeader.transferAdviser,
              transferRazon: this.transferHeader.transferRazon,
              transOtherRazonDesc: this.transferHeader.transOtherRazonDesc,
              transferType: this.transferType
            }

            const detail = this.transferLine.map(function(x) {        
              const result = {
                code: x.transferInventoryBussiness,
                quantity: x.transferInventoryDetailQuantity,
                description: x.description,
                unitPrice: x.unitPrice,
                subtotal: x.subtotal
              }
              return result;
            }); 
            this._utilsService.showNotification(1, response.message);
            //this._printService.noteTransfer(transfer, detail, this.company);
            
            if(this.companyCode == 22){
              this._printService.noteTransferNail(transfer, detail, this.company);
            }
            else{
              this._printService.noteTransfer(transfer, detail, this.company);
            }

            this.cleanModel();
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
      //*/
    } else {
      this._utilsService.showNotification(2, 'Falto llenar datos');
    }
  }

  // Limpiar Campos
  cleanModel() {
    this.transferCode = 0;
    this.addAmount = true;
    this.isDisabled = false;
    this.transferHeader = {} as transferHeader;
    this.transferDetail = {} as transferDetail;
    this.transferLine = [];
    this.total = 0;
    this.price = 0;
    this.transferHeader.transferAdviser = this.userName;
    this.selectedItems = [];
    this.selectedItemsWarehouse = [];
    this.selectedItemsStore = [];
    this.transferDetail.transferNotaSalida = 0;
    this.transferDetail.transferSucursal = 0;
  }
}
