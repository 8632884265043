import { Component, OnInit, ViewChild } from '@angular/core';
//import { NgForm } from '@angular/forms';
import swal from "sweetalert2";

import { Data } from 'src/app/models/data';
import { Product } from 'src/app/models/product';
import { UtilsService } from 'src/app/services/utils.service';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { InventoryService } from 'src/app/services/inventory.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-inventory-log',
  templateUrl: './inventory-log.component.html',
  styleUrls: ['./inventory-log.component.scss'],
  providers: [UtilsService, InventoryService]
})

export class InventoryLogComponent implements OnInit {
  //@ViewChild('formulario') formulario!: NgForm;

  public settings = {
    columns: {
      inventoryBusinessCode: {
        title: 'Codigo'
      },
      inventoryDescription: {
        title: 'Descripción'        
      },
      inventoryName: {
        title: 'Nombre'
      },
      inventoryLogDate: {
        title: 'FechaCambio'        
      },
      userName: {
        title: 'Usuario'        
      },
      inventoryPrice: {
        title: 'Precio'        
      },
      inventoryPriceCost: {
        title: 'Costo'        
      },
      inventoryPriceDiscount: {
        title: 'PrecioDesc'        
      },
      inventoryMinPrice: {
        title: 'PrecioMin'        
      },
      inventorySalePrice: {
        title: 'PrecioVenta'        
      },
      inventoryQuantity: {
        title: 'Stock'        
      }
    }
  };
  // MODELS VARIABLE 
  public product = {} as Product;
  public data = {} as Data;
  public isEdit: boolean = false;
  public loading: boolean = false;
  public isDisabled: boolean = false;
  public userCode: number;
  public companyCode: number;

  // VARIABLE FOR DATA TABLE
  public temp = [];
  public activeRow: any;
  public entries: number = 10;
  public selected: any[] = [];
  public productList = new Array();
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;


  public id: number;
  public itemName: string;
  public selectedItems = [];
  public inventoryList: any[] = new Array();
  public dropdownSettings: IDropdownSettings = {};
  closeDropdownSelection = false;
  public storeCode: string;

  constructor(
    private _utilsService: UtilsService,
    private _inventoryService: InventoryService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY    
    const _user = JSON.parse(localStorage.getItem('user'));
    this.data.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;
    this.data.companyCode = _user.userCompany ? parseInt(_user.userCompany.toString()) : 0;
    this.storeCode = _user.storeCode;

    console.log('company', _user);
    this.companyCode = _user.companyCode;
    this.product.productCompany = _user.companyCode;

    // LOAD INFORMATION
    await this.getInventoryList(this.storeCode);
    await this.getSelectSetting(this.selectedItems);
  }

  getInventoryList(code) {
    this.inventoryList = [];
    return this._inventoryService.getAllByStore(code).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getSelectSetting(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'itemName'
    };
  }

  async onItemSelect(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItem = await this.inventoryList.find(x => x.id == item.id);

    console.log('_selectedItem',_selectedItem);

    this.id = _selectedItem.inventoryCode;
    console.log('id',this.id);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.temp.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  searchLog(){
    this.loading = true;
    this.temp = [];

    let json = {
      Inventorycode: this.id
    };

    return this._inventoryService.logInventario(json).then(
      async response => {
        if (response.result) {
          this.temp = response.records;
          console.log('temp',this.temp);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  ///////////////////////COMPLEMENT///////////////////////  
  // CLEAR MODAL
  cleanModel(cancel?: boolean) {
    //(!cancel) && this.getAll();
    this.isEdit = false;
    this.isDisabled = false;
    //this.formulario.resetForm();
    this.product = {} as Product;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item) => {
        if (this.settings.columns[item].type === "") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-inventarioLog.xlsx');
  }
}
