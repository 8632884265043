export class User {
        constructor(
                public userCode: number,
                public userName: string, 
                public userRole: number, 
                public userPhone: string, 
                public userEmail: string, 
                public userCompany: number, 
                public userPassword: string, 
                public userTaxDocument: string, 
                public userCognitoCode: string, 
                public useLogInAttempts: number, 
                public userBussinnesCode: number,
                public userSingInPasswordChange: number, 
                public userPasswordChange: number | boolean,
                
                public userStatus: number, 
                public userDeleted: number,
        ) { }
}