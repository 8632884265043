<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/company-type">Rol & Acceso</a>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="row" *ngIf="isViewBeginning">
        <div class="col-md-12">
            <div class="row">
                <!-- INPUT  -->
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header ">
                            <div class="row">
                                <div class="col-10">
                                    <b class="card-title" *ngIf="!isEditRole">Nuevo Rol</b>
                                    <b class="card-title text-warning" *ngIf="isEditRole">Modificar Rol</b>
                                </div>
                                <div class="col-2 text-right" *ngIf="isEditRole">
                                    <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                        (click)="cancelUpdateRole($event)"><i class="fas fa-times"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Empresa</label>
                                        <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItemsCompany"
                                            [settings]="dropdownSettingsCompany"
                                            (onSelect)="onItemSelectCompany($event)">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Nombre</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="role.roleName" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-info btn-block btn-erp" (click)="saveRole()"
                                        *ngIf="!isEditRole" [disabled]="isDisabled">Guardar</button>
                                    <button class="btn btn-primary btn-block btn-erp" (click)="updateRole()"
                                        *ngIf="isEditRole" [disabled]="isDisabled">Actualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END INPUT -->

                <!-- TABLE  -->
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Listado de Roles</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                                records"</h6>
                        </div>
                        <div class="card-body">
                            <div class="dataTables_wrapper">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="datatable_length">
                                            <label>
                                                Show
                                                <select name="datatable_length" aria-controls="datatable"
                                                    class="form-control form-control-sm"
                                                    (change)="entriesChange($event)">
                                                    <option value="10" [selected]="entries==10">10</option>
                                                    <option value="25" [selected]="entries==25">25</option>
                                                    <option value="50" [selected]="entries==50">50</option>
                                                    <option value="-1" [selected]="entries==-1">All</option>
                                                </select>
                                                entries
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="datatable_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm"
                                                    placeholder="Search records" aria-controls="datatable"
                                                    (keyup)="filterTable($event)" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'"
                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                    [limit]="entries != -1 ? entries:undefined" [rows]="tempRole"
                                    (activate)="onActivate($event)">
                                    <ngx-datatable-column name="Empresa"></ngx-datatable-column>
                                    <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                                    <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                        [resizeable]="false">
                                        <ng-template ngx-datatable-header-template let-value="value"
                                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                            Acciones
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-value="value"
                                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                            <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                                (click)="accessFunction($event)" ngbTooltip="Ver Accesos"><i
                                                    class="fas fa-address-book"></i>
                                            </a>
                                            <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                                (click)="editFunctionRole($event)" ngbTooltip="Editar"><i
                                                    class="fas fa-pencil-alt"></i>
                                            </a>
                                            <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                                (click)="deleteFunctionRole($event)" ngbTooltip="Eliminar"><i
                                                    class="fas fa-times"></i>
                                            </a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TABLE  -->
            </div>
        </div>
    </div>

    <!-- INPUT  - ACCESS -->
    <div class="row justify-content-center" *ngIf="isViewAccess">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header ">
                            <div class="row">
                                <div class="col-10">
                                    <b class="card-title">Asignar accesos a un Rol</b>
                                </div>
                                <div class="col-2 text-right">
                                    <a class="btn btn-round btn-warning btn-icon btn-sm remove btn-erp"
                                        (click)="cancelUpdateRoleAccess($event)"><i class="fas fa-times"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Rol</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="role.roleName" disabled />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Accesos</label>
                                        <angular2-multiselect [data]="accessSelectList"
                                            [(ngModel)]="selectedItemsAccess" [settings]="dropdownSettingsAccess"
                                            (onSelect)="onItemSelectAccess($event)"
                                            (onDeSelect)="onItemDeSelectAccess($event)">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-info btn-block btn-erp" (click)="saveRoleAccess()"
                                        [disabled]="isDisabled">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END INPUT  - ACCESS -->
</div>