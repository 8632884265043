<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/billing">Facturación de Combustible</a>
        </div>
    </div>
</div>

<div class="main-content">

    <!-- BUSCAR NIT -->
    <div class="row justify-content-center" *ngIf="isBeginning">
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <label>Tipo de Identificación</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 col-md-4 col-lg-9">
                            <select class="form-control form-control-sm" [ngModel]="client.clientType"
                                (ngModelChange)="onChangeTypeClient($event)">
                                <option [value]="0">NIT</option>
                                <option [value]="1">CUI</option>
                                <option [value]="2">Pasaporte</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <label>No. Identificación</label>
                        </div>
                    </div>
                    <div class="row">                        
                        <div class="col-sm-4 col-md-4 col-lg-9">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientTaxDocument"
                                    (keyup.enter)="buscarNITLocal($event, client.clientTaxDocument)"
                                    placeholder="*Escribe un NIT/CUI/Pasaporte sin espacios o guiones" minlength="8" />
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-9">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientName" [toggleable]="true" [disabled]="disabledEXT"
                                    placeholder="*Escribe el nombre completo del receptor" minlength="8" />
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-3">                        
                            <button class="btn btn-info btn-sale"
                                (click)="buscarNITLocal($event, client.clientTaxDocument)" [disabled]="isDisabled"
                                style="width: 100%;">Buscar</button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button class="btn btn-info btn-sale" style="width: 100%;"
                                (click)="buscarNITLocal($event, 'CF')" [disabled]="isDisabled">Consumidor Final</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN BUSCAR NIT -->

    <!-- MODULO CLIENTE -->
    <div class="row justify-content-center" *ngIf="isBilling">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-1 ma-g">
                            <div class="row">
                                <div class="col-md-12">
                                    <a class="btn btn-round btn-info btn-icon btn-back remove"
                                        (click)="viewBeginning()">
                                        <i class="now-ui-icons arrows-1_minimal-left"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-11 ma-g">
                            <div class="row">
                                <div class="col-md-2 ma-g">
                                    <label>Nit</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g"
                                            [(ngModel)]="client.clientTaxDocument" disabled />
                                    </div>
                                </div>
                                <div class="col-md-3 ma-g">
                                    <label>Nombre</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g" [(ngModel)]="client.clientName"/>
                                    </div>
                                </div>
                                <div class="col-md-3 ma-g">
                                    <label>E-mail</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g" [(ngModel)]="client.clientEmail" />
                                    </div>
                                </div>
                                <div class="col-md-4 ma-g">
                                    <label>Dirección</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g"
                                            [(ngModel)]="client.clientAddress" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN MODULO CLIENTE -->

    <!-- MODULO FORMAS DE PAGO -->
    <div class="row justify-content-center" *ngIf="isBilling && isMethodPay">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <!-- Formas de pago -->
                    <div class="col-sm-12"><b>Formas de Pago</b></div>
                    <div class="row">
                        <div class="col-md-5">
                            <label>Forma de Pago</label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccione...'"
                                [settings]="dropdownSettingsPayment" [data]="PaymentList" [(ngModel)]="selectedItemsPayment"
                                (onSelect)="onItemSelectPaymet($event)" [disabled]='isEdit'>
                            </ng-multiselect-dropdown>
                        </div>   
                        <div class="col-md-2">
                            <label>Monto {{moneda}}</label>
                            <div class="form-group">
                                <input type="number" class="form-control"
                                    [(ngModel)]='MontoPayment' placeholder="Monto" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>No. Autorización</label>
                            <div class="form-group">
                                <input type="text" class="form-control"
                                    [(ngModel)]='Autorizacion' placeholder="Monto" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label> . </label>
                            <button type="submit" class="btn btn-info btn-sale" (click)="addPaymetDetail()" [toggleable]="true" [disabled]="disabledPay" >Agregar</button>
                        </div>                       
                    </div>
                    <br>
                    <!-- Detalle de Venta -->
                    <div class="col-md-8">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr style="font-weight: bold;">
                                        <td class="text-center">No.</td>
                                        <td class="text-left">Forma de Pago</td>
                                        <td class="text-center">Monto {{moneda}}</td>
                                        <td class="text-center">Autorización</td>
                                        <td class="text-center">Opción</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of PaymentLine, let i=index">
                                        <td class="text-center">{{i+1}}</td>                                        
                                        <td>{{e.NamePaymentType}}</td>                                        
                                        <td class="text-center">{{e.MontoPayment | number:'1.2-2'}}</td>
                                        <td>{{e.Autorizacion}}</td>
                                        <td class="text-center">
                                            <button class="btn btn-danger btn-sm" (click)="deletePaymetDetail(e.typePayment)"
                                                [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                                                <i class="now-ui-icons ui-1_simple-remove"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                            Total Pagado <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                            {{MontoTotalPayment | number:'1.2-2'}}
                        </div>                        
                    </div>
                    <!-- Fin formas de pago -->                    
                </div>
            </div>
        </div>
    </div>
    <!-- FIN MODULO FORMAS DE PAGO -->

    <!-- PRODUCTOS -->
    <div class="row justify-content-center" *ngIf="isBilling">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <label>Fecha</label>
                            <input type="date" class="form-control form-control-sm"
                                [(ngModel)]="invoiceHeader.invoiceDate" (change)="validateDate($event)" />
                        </div>
                        <div class="col-md-2">
                            <label>Serie</label>
                            <select class="form-control form-control-sm" [ngModel]="invoiceHeader.invoiceInternSerial"
                                (ngModelChange)="onChangeInternSerial($event)">
                                <option value=A>A</option>
                                <option value=B>B</option>
                                <option value=C>C</option>
                                <option value=D>D</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label>Moneda</label>
                            <select class="form-control form-control-sm" [ngModel]="invoiceHeader.invoiceCoin"
                                (ngModelChange)="onChange($event)">
                                <option [value]="0">Q - Quetzal</option>
                                <option [value]="1">$ - Dolar</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label>Tasa de Cambio</label>
                            <input type="number" class="form-control form-control-sm" style="background: #FFFFFF;"
                                [(ngModel)]="invoiceHeader.invoiceExchangeRate" [disabled]="moneda == 'Q'" />
                        </div>
                        <div class="col-md-2">
                            <label>Forma Calculo</label>
                            <select class="form-control form-control-sm" [ngModel]="FormaCalculo"
                                (ngModelChange)="onChangeForma($event)">
                                <option [value]="0">Monto</option>
                                <option [value]="1">Galonaje</option>
                            </select>
                        </div>
                        <div class="col-md-2" style="text-align: end;">
                            <button class="btn btn-success btn-sale" (click)="insertInvoice()" [disabled]="isDisabled">
                                ✔ Realizar Ventas
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Forma de Pago</label>
                            <select class="form-control form-control-sm" [ngModel]="invoiceHeader.paymentType"
                                (ngModelChange)="onChangepaymentType($event)">
                                <option [value]="0">Efectivo</option>
                                <option [value]="1">Tarjeta</option>
                                <option [value]="2">Crédito</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-12 col-md-8">
                            <ng-multiselect-dropdown [placeholder]="'Seleccione...'" [settings]="dropdownSettings"
                                [data]="inventoryList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                                [disabled]='isEdit'>
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <input type="number" class="form-control"
                                    [(ngModel)]='invoiceDetail.invoiceDetailQuantity' placeholder="Cantidad" />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <button type="submit" class="btn btn-info btn-sale btn-sale" (click)="saveLineInvoice()" [disabled]="isDisabled"
                                [disabled]="isDisabled">Agregar</button>
                        </div>
                    </div>
                    <!-- Detalle de Venta -->
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr style="font-weight: bold;">
                                        <td>No.</td>
                                        <td>B/S</td>
                                        <td>Cantidad</td>
                                        <td>Descripción</td>
                                        <td class="text-center">P/U con IVA {{moneda}}</td>
                                        <td class="text-center">Descuento</td>
                                        <td class="text-center">Total {{moneda}}</td>
                                        <td class="text-center">Impuesto {{moneda}}</td>
                                        <td class="text-center">Opción</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let iDetail of invoiceLine, let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td class="text-center">{{iDetail.isService}}</td>
                                        <td class="text-center">{{iDetail.quantity | number:'1.2-2'}}</td>
                                        <td>{{iDetail.description}}</td>
                                        <td class="text-right">{{iDetail.unitPrice + iDetail.taxOilUP | number:'1.2-2'}}</td>
                                        <td style="text-align: right;">
                                            <input type="number" class="form-control" [(ngModel)]="iDetail.discount"
                                                (blur)="ApplyDiscount($event, iDetail.itemCode, iDetail.discount)"
                                                style="max-width: 90px; margin: 0px auto;" />
                                        </td>
                                        <td class="text-right">{{iDetail.price + iDetail.taxOilAmount | number:'1.2-2'}}</td>
                                        <td class="text-right" style="color: #b7b7b7;">{{iDetail.taxAmount |
                                            number:'1.2-2'}}
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger btn-sm" (click)="deleteLine(iDetail.itemCode)"
                                                [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                                                <i class="now-ui-icons ui-1_simple-remove"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12"
                            style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                            <small *ngIf="invoiceHeader.invoiceIDP > 0" style="font-weight: bold; font-size: 20px;">IDP
                                Q{{invoiceHeader.invoiceIDP | number:'1.2-2'}} &nbsp;&nbsp;</small>
                            Total <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                            {{invoiceHeader.invoiceTotal | number:'1.2-2'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin Productos -->


    <!-- Imprimir Factura -->
    <div class="row justify-content-center" *ngIf="isPrint">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-info btn-round" (click)="printTest()" style="width: 100%;">
                                <i class="now-ui-icons files_paper"></i> Imprimir
                            </button>
                        </div>
                        <div class="col-md-6">
                            <a class="btn btn-success btn-round" (click)="continue()" style="width: 100%;">
                                <i class="now-ui-icons ui-1_check"></i> Continuar
                            </a>
                        </div>
                    </div>
                    <hr>
                    <div id="printSection" #printSection style="width: 226px; margin: 0px auto;">
                        <table style="width: 90%;">                           
                            <tr>
                                <td style="width: 100%; text-align: center;">                                                                                                    
                                    <br>FACTURA
                                    <br>--DATOS DEL EMISOR--
                                    <br>{{fel.transmitter.transmitterName | uppercase}}
                                    <br>Nit: {{fel.transmitter.transmitterTaxDocument}}
                                    <br>{{fel.transmitter.transmitterComercialName | uppercase}}
                                    <br>{{fel.transmitter.transmitterAddress}},
                                    {{fel.transmitter.transmitterMunicipality}},
                                    {{fel.transmitter.transmitterDepartment}}.                                                                        
                                    <br>Serie:
                                    <br>{{dte.serie}} 
                                    <br>No. DTE:
                                    <br>{{dte.noFactura}}
                                    <!-- <br>Serie:
                                    <br>{{dte['dte:NumeroAutorizacion'][0]['Serie'][0]}}
                                    <br>No. DTE:
                                    <br>{{dte['dte:NumeroAutorizacion'][0]['Numero'][0]}} -->
                                    <br>-------------------------------------
                                    <br>DATOS DE COMPRADOR
                                    <br>{{fel.invoice.invoiceDate | date:'dd-MM-yyyy'}}
                                    <br>Nit: {{fel.receiver.receiverTaxDocument}}
                                    <br>Nombre: {{fel.receiver.receiverName}}
                                </td>
                            </tr>
                            <tr>
                                <table style="width: 100%;">
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <br>DETALLE DE VENTA
                                            <br>------------------------------------
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cant.</td>
                                        <td>Desc.</td>
                                        <td style="text-align: right;">Precio</td>
                                    </tr>
                                    <tr *ngFor="let e of felDetail">
                                        <td>{{e.quantity | number:'1.2-2'}}</td>
                                        <td>{{e.description}}</td>
                                        <td style="text-align: right;">
                                            {{e.unitPrice + e.taxOilUP | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <br>IDP: {{fel.invoice.invoiceOilTaxValue | number:'1.2-2'}}
                                            <br>IVA: {{fel.invoice.invoiceTaxValue | number:'1.2-2'}}
                                            <br><b>TOTAL: Q {{fel.invoice.invoiceTotal | number:'1.2-2'}}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <!-- <em>Esta factura incluye apoyo social temporal Decreto 20-2022 por un valor de GTQ {{subsidio | number:'1.2-2'}}</em> -->
                                            <!-- <em>Esta factura incluye apoyo social temporal Decreto 42-2022 por un valor de GTQ {{subsidio | number:'1.2-2'}}</em> -->
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                            <tr>
                                <td style="text-align: center;">
                                    -------------------------------------
                                    <br>NUMERO DE AUTORIZACIÓN
                                    <br><span style="font-weight: normal;">
                                        {{dte.autorizacion}} <!-- {{dte['dte:NumeroAutorizacion'][0]['_']}} -->
                                    </span>
                                    <br>Fecha Certificación
                                    <br>{{dte.fechaCertificacion}}<!-- {{dte['dte:FechaHoraCertificacion'][0]}} -->
                                    <!-- <br>Sujeto a pagos trimestrales ISR -->
                                    <br>{{FraseFact}}
                                    <br>-------------------------------------
                                    <br>CERTIFICADOR
                                    <br>{{dte.certificador | uppercase}}<!-- {{dte['dte:NombreCertificador'][0]}} -->
                                    <br>Nit: {{dte.nitCertificador}}<!-- {{dte['dte:NITCertificador'][0]}} -->
                                    <br>¡Gracias por tu compra!
                                    <br>---------------------                                   
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin imprimir factura -->
</div>