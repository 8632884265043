import { Component, OnInit } from '@angular/core';
// import { ToastrService } from 'ngx-toastr';
import * as xlsx from 'xlsx';
import { AccountingService } from 'src/app/services/accounting.service';
import { UtilsService } from 'src/app/services/utils.service';

interface GetData {
  total;
  cuenta;
}

interface balanceSheetRegister {
  balanceSheetRegisterCode;
  balanceSheetRegisterType;
  balanceSheetRegisterTypeShow;
  balanceSheetRegisterAmount;
  balanceSheetRegisterTotal;
  balanceSheetRegisterDescription;
  balanceSheetRegisterDate;
  balanceSheetRegisterStore;
  balanceSheetRegisterDiffAmount;
  balanceSheetRegisterDiffComment;
  balanceSheetRegisterDiffDate;
}

@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.scss'],
  providers: [AccountingService, UtilsService]
})

export class BalanceSheetComponent implements OnInit {
  // Variables auxiliares
  public balanceSheetRegister = {} as balanceSheetRegister;
  public isViewBeginning: boolean;
  public isViewRegister: boolean;
  public isViewPending: boolean;
  public isViewNewRegister: boolean;
  public isViewUpdateRegister: boolean;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public date: string;
  public loading;
  public now;
  public settingsDif = {
    columns: {
      cuenta: {
        title: 'cuenta'
      },
      fecha: {
        title: 'Fecha'
      },      
      total: {
        title: 'Total',
        type: 'number'
      },
      monto: {
        title: 'monto',
        type: 'number'        
      },
      diferencia: {
        title: 'Diferencia',
        type: 'number'        
      },
      descripcion: {
        title: 'Descripcion'       
      }
    }
  };
  public settingsDate = {
    columns: {
      cuenta: {
        title: 'cuenta'
      },
      monto: {
        title: 'monto',
        type: 'number'        
      },
      diferencia: {
        title: 'Diferencia',
        type: 'number'        
      },
      descripcion: {
        title: 'Descripcion'       
      }
    }
  };

  // variables para data table
  public getData: GetData[] = new Array();
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public tempBSPa = [];
  public tempBSPe = [];
  public activeRow: any;

  // VARIABLE SELECTED
  public paymentMethodList: any[];

  constructor(
    private _utilsService: UtilsService,
    private _accountingService: AccountingService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.now = this._utilsService.dateTime('yyy-MM-dd');
    this.date = this.now;

    this.getPaymentMethod();
  }

  // Metodos para la tabla
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.getData.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getPaymentMethod() {
    return this._accountingService.getPaymentMethod().then(
      response => {
        if (response.result) {          
          this.paymentMethodList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      });
  }

  // Metodos para obtener información de la tabla
  getBalanceSheet() {
    this.loading = true;

    let jsonPay = {
      'date': this.date,
      'storeCode': this.storeCode
    }

    this._accountingService.getPaymentBalanceSheet(jsonPay).then(
      (response) => {
        console.log(response.records);
        this.tempBSPa = response.records;
      }, (error) => {
        console.log(error);
      }
    );

    let jsonAll = {
      'date': this.date, //'2021-12-02',
      'store': this.storeCode
    }

    this._accountingService.getBalanceSheet(jsonAll).then(
      (response) => {
        console.log(response.records);
        this.temp = response.records;
      }, (error) => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.viewRegister();
    });
  }

  getpendingBS() {
    let json = {
      'store': this.storeCode
    }

    this._accountingService.getPendingBalanceSheet(json).then(
      (response) => {
        // console.log(response.records);
        this.temp = response.records;
        this.viewPending();
      }, (error) => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  loadData($event, option) {
    $event.preventDefault();

    if (option == 1) {
      this.balanceSheetRegister.balanceSheetRegisterStore = this.storeCode;
      this.balanceSheetRegister.balanceSheetRegisterAmount = 0;
      this.balanceSheetRegister.balanceSheetRegisterDiffAmount = 0;
      this.balanceSheetRegister.balanceSheetRegisterDescription = '';
      this.balanceSheetRegister.balanceSheetRegisterTotal = 0;
      this.balanceSheetRegister.balanceSheetRegisterDate = this.now;
      this.balanceSheetRegister.balanceSheetRegisterType = 2;
      // const _selectedItem = this.paymentMethodList.find(x => x.paymentMethodName === this.activeRow.metodo);
      // this.balanceSheetRegister.balanceSheetRegisterType = _selectedItem.paymentMethodCode;

      this.isViewNewRegister = true;
      this.isViewUpdateRegister = false;
    } else {
      this.balanceSheetRegister.balanceSheetRegisterDiffAmount = this.activeRow.diferencia; //0;
      this.balanceSheetRegister.balanceSheetRegisterTypeShow = this.activeRow.cuenta;
      this.balanceSheetRegister.balanceSheetRegisterStore = this.activeRow.balanceSheetRegisterStore;
      this.balanceSheetRegister.balanceSheetRegisterCode = this.activeRow.balanceSheetRegisterCode;
      this.balanceSheetRegister.balanceSheetRegisterType = this.activeRow.balanceSheetRegisterType;
      this.balanceSheetRegister.balanceSheetRegisterDate = this.activeRow.fecha; //balanceSheetRegisterDate;
      this.balanceSheetRegister.balanceSheetRegisterTotal = this.activeRow.balanceSheetRegisterTotal;
      this.balanceSheetRegister.balanceSheetRegisterAmount = this.activeRow.balanceSheetRegisterAmount;
      this.balanceSheetRegister.balanceSheetRegisterDiffComment = '';
      this.balanceSheetRegister.balanceSheetRegisterDiffDate = this.now;
      this.balanceSheetRegister.balanceSheetRegisterDescription = this.activeRow.balanceSheetRegisterDescription;

      this.isViewNewRegister = false;
      this.isViewUpdateRegister = true;
    }
  }

  saveRegister() {
    let dif;
    this.balanceSheetRegister.balanceSheetRegisterTotal == this.balanceSheetRegister.balanceSheetRegisterAmount ? dif = false : dif = true;
    this.balanceSheetRegister.balanceSheetRegisterStore = this.storeCode;

    if (!dif || this.balanceSheetRegister.balanceSheetRegisterDescription != '') {
      this.loading = true;

      // console.log(this.balanceSheetRegister);
      this._accountingService.insertBalanceSheet(this.balanceSheetRegister).then(
        async (response) => {
          if (response.result == true) {
            this.isViewPending == true ? this.getpendingBS() : this.getBalanceSheet();
            this.viewBeginning(event);
            this._utilsService.showNotification(1, response.message);

          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this._utilsService.showNotification(2, error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this._utilsService.showNotification(2, 'Ingresar motivo de la diferencia');
    }
  }

  updateRegister() {
    this.balanceSheetRegister.balanceSheetRegisterStore = this.storeCode;

    if (this.balanceSheetRegister.balanceSheetRegisterDiffComment != '') {
      this.loading = true;

      let json = {
        "balanceSheetRegisterCode": this.balanceSheetRegister.balanceSheetRegisterCode,
        "balanceSheetRegisterDiffComment": this.balanceSheetRegister.balanceSheetRegisterDiffComment,
        "balanceSheetRegisterDiffDate": this.balanceSheetRegister.balanceSheetRegisterDiffDate,
        "balanceSheetRegisterAmount": this.balanceSheetRegister.balanceSheetRegisterAmount
      };

      // console.log(this.balanceSheetRegister);
      this._accountingService.updateBalanceSheet(json).then(
        async (response) => {
          if (response.result == true) {
            this.isViewPending == true ? this.getpendingBS() : this.getBalanceSheet();
            this.viewBeginning(event);
            this._utilsService.showNotification(1, response.message);
          } else {
            console.log(response.errorMessage)
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this._utilsService.showNotification(2, error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this._utilsService.showNotification(2, 'Ingresar motivo de la diferencia');
    }
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //  VIEWS
  viewBeginning($event) {
    $event.preventDefault();
    this.isViewNewRegister = false;
    this.isViewUpdateRegister = false;
    this.cleanModel();
  }

  viewRegister() {
    this.isViewPending = false;
    this.isViewRegister = true;
    this.isViewNewRegister = false;
    this.isViewUpdateRegister = false;
  }

  viewPending() {
    this.isViewPending = true;
    this.isViewRegister = false;
    this.isViewNewRegister = false;
    this.isViewUpdateRegister = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.balanceSheetRegister = {} as balanceSheetRegister;
  }

  // DESCARGAR EXCEL
  async downloadToExcelDif() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settingsDif.columns).forEach((item) => {
        if (this.settingsDif.columns[item].type === "") {
          json[this.settingsDif.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settingsDif.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-cuentas-pendientes.xlsx');
  }

  async downloadToExcelDate() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settingsDate.columns).forEach((item) => {
        if (this.settingsDate.columns[item].type === "") {
          json[this.settingsDate.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settingsDate.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-balance-fecha-' + this.date + '.xlsx');
  }
}
