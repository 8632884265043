<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">    
    <div class="row link-header ml-5">        
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="a-return" routerLink="/invoice-recurrent">Factura Recurrente/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/invoice-recurrent-maintenance">Mantenimiento</a>
        </div>
    </div>
</div>
<br />
<div class="main-content">   
    <!-- TABLA -->
    <div class="row" *ngIf="isBeginning">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Facturas recurrentes</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="billByBatch()"
                                [disabled]="isDisabled">
                                Facturar
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)"
                            [selected]="selected" [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false"
                            [displayCheck]="displayCheck" (activate)="onActivate($event)" (select)="onSelect($event)">                        
                            <ngx-datatable-column [width]="75" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true" name="Todo">
                            </ngx-datatable-column>               
                            <ngx-datatable-column name="No." [width]="50">
                                <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                  <strong>{{ rowIndex+1 }}</strong>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- <ngx-datatable-column name="code"  prop="jobCode"></ngx-datatable-column> -->
                            <ngx-datatable-column name="Fecha Prog." prop="recurrencia"></ngx-datatable-column>
                            <ngx-datatable-column name="Creada"  prop="creacion"></ngx-datatable-column>
                            <ngx-datatable-column name="Nit"  prop="nit"></ngx-datatable-column>
                            <ngx-datatable-column name="Cliente"  prop="cliente"></ngx-datatable-column>                         
                            <ngx-datatable-column name="Estado"  prop="estado"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha Ultima Gen."  prop="lasGenerated"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="true"
                                [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="searchInvoice($event)" ngbTooltip="Ver" placement="top">
                                        <i class="now-ui-icons files_paper"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="getDeleted($event)" ngbTooltip="Eliminar"
                                        placement="left">
                                        <i class="fas fa-times"></i>
                                    </a>                                    
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN TABLA -->

    <!-- Factura -->
    <div class="row justify-content-center" *ngIf="isViewOne">    
        <div class="col-md-12">
            <a class="btn btn-round btn-info btn-icon btn-back remove"
                (click)="viewBeginning()">
                <i class="now-ui-icons arrows-1_minimal-left"></i>
            </a>
        </div>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">                    
                    <div class="row">
                        <div class="col-md-2">                            
                            <label>Recurrencia</label>
                            <p>Cada: {{activeRow.recurrencia}}</p>                            
                        </div>
                        <div class="col-md-2">                            
                            <label>Nit</label>
                            <p>{{fel.jobJson.receiver.receiverTaxDocument}}</p>                            
                        </div>
                        <div class="col-md-4">
                            <label>Nombre</label>
                            <p>{{fel.jobJson.receiver.receiverName}}</p>                            
                        </div>
                        <div class="col-md-4">
                            <label>E-mail</label>
                            <p>{{fel.jobJson.receiver.receiverEmail}}</p>
                        </div>
                        <div class="col-md-8">
                            <label>Dirección</label>
                            <p>{{fel.jobJson.receiver.receiverAddress}}</p>                            
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="billing(this.fel.jobJson, this.fel.jobCode, true)" [disabled]="isDisabled">
                                Facturar
                            </button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>

        <!-- Detalle de Venta -->
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">                                        
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr style="font-weight: bold;">
                                        <td>No.</td>
                                        <td>B/S</td>
                                        <td>Cantidad</td>
                                        <td>Descripción</td>
                                        <td class="text-center">P/U con IVA Q</td>
                                        <td class="text-center">Descuento</td>
                                        <td class="text-center">Total Q</td>
                                        <td class="text-center">Impuesto Q</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of felDetail, let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td class="text-center">{{e.isService}}</td>
                                        <td class="text-center">{{e.quantity | number:'1.2-2'}}</td>
                                        <td>{{e.description}}</td>
                                        <td class="text-right">{{e.unitPrice | number:'1.2-2'}}</td>
                                        <td style="text-align: right;">{{e.discount | number:'1.2-2'}}</td>
                                        <td class="text-right">{{e.total | number:'1.2-2'}}</td>
                                        <td class="text-right" style="color: #b7b7b7;">{{e.taxAmount | number:'1.2-2'}}</td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12"
                            style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                            Total <small style="font-weight: bold; font-size: 20px;">Q</small>
                            {{fel.jobJson.invoice.invoiceTotal | number:'1.2-2'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin Factura Recurrente -->
</div>