<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
  <div class="row link-header ml-5">
    <div>
      <a class="a-home" routerLink="/home">Home /&nbsp;</a>
    </div>
    <div>
      <a class="text-info" routerLink="/client">Cliente</a>
    </div>
  </div>
</div>

<div class="main-content">
  <!-- INPUT CLIENT -->
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h4 class="card-title" *ngIf="!isEdit">
                Nuevo Cliente
              </h4>
              <h4 class="card-title text-warning" *ngIf="isEdit">
                Modificar Cliente
              </h4>
            </div>
            <div class="col-1" *ngIf="isEdit">
              <a class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="cancelUpdate($event)"><i
                  class="fas fa-times"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="card-body">          
          <div class="row">
            <div class="col-md-2">
              <label>Bloqueado</label>
              <div class="form-group">
                <bSwitch [switch-on-color]="'success'"
                  [(ngModel)]="client.clientBlocked" switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                  switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                </bSwitch>
              </div>
            </div>
          </div>
          <!-- DATOS DE CLIENTE FACTURACIÓN -->
          <div class="row">
            <div class="col-12">
              <h6>Datos de Facturación</h6> <span>(Obligatorios)</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>*Nombre Facturación</label>
              <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="client.clientName" />
              </div>
            </div>
            <div class="col-md-3">
              <label>*NIT/DPI/Pasaporte</label>
              <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="client.clientTaxDocument" />
              </div>
            </div>
            <div class="col-md-3">
              <label>*Tipo Identificación</label>
              <!-- <select class="form-control form-control-sm" [ngModel]="client.clientType" (ngModelChange)="onChangeTypeClient($event)"> -->
              <select class="form-control form-control-sm" [ngModel]="client.clientType" (ngModelChange)="onChangeTypeClient($event)">
                  <option [value]="0">NIT</option>
                  <option [value]="1">CUI</option>
                  <option [value]="2">Pasaporte</option>
              </select>
            </div>           
            <div class="col-md-3">
              <label>*Correo</label>
              <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="client.clientEmail" />
              </div>
            </div>
            <div class="col-md-3">
              <label>*Teléfono</label>
              <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="client.clientPhone" />
              </div>
            </div>
            <div class="col-md-3">
              <label>*Dirección</label>
              <div class="form-group">
                <!-- <input type="text" class="form-control" [(ngModel)]="client.clientAddress" /> -->
                <textarea class="form-control" name="" id="" cols="10" rows="2" [(ngModel)]="client.clientAddress" placeholder="Ingrese dirección aqui..."></textarea>
              </div>
            </div>                      
          </div>
          <!-- FIN DATOS DE CLIENTE FACTURACIÓN -->

          <!-- DATOS DE CLIENTE FRECUENTE -->
          <hr>
          <div class="row">
            <div class="col-12">
              <h6>Datos de Cliente Frecuente</h6> <span>(opcional)</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <label>Nombre Cliente Frecuente</label>
              <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="client.clientNameFrequent" />
              </div>
            </div>
            <div class="col-md-3">
              <label>Teléfono</label>
              <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="client.clientPhone2" />
              </div>
            </div>
            <div class="col-md-3">
              <label>Dirección</label>
              <div class="form-group">
                <!-- <input type="text" class="form-control" [(ngModel)]="client.clientAddress" /> -->
                <textarea class="form-control" name="" id="" cols="10" rows="2" [(ngModel)]="client.clientAddressFrequent" placeholder="Ingrese dirección aqui..."></textarea>
              </div>
            </div>            
          </div>
          <!-- FIN DATOS DE CLIENTE FRECUENTE -->

          <hr>
          <div class="row">
            <div class="col-12">
              <h6>Configuración de Cliente</h6>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <label>*Cuenta con Crédito</label>
              <div class="form-group mt-2">
                <bSwitch [switch-on-color]="'success'" (changeState)="creditFunction()"
                  [(ngModel)]="client.clientCredit" switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                  switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                </bSwitch>
              </div>
            </div>
            <div class="col-md-2">
              <label>Días de Crédito</label>
              <div class="form-group">
                <input type="number" class="form-control" [(ngModel)]="client.clientCreditTime" [disabled]="noCredit" />
              </div>
            </div>
            <div class="col-md-2">
              <label>Monto</label>
              <div class="form-group">
                <input type="number" class="form-control" [(ngModel)]="client.clientCreditAmount"
                  [disabled]="noCredit" />
              </div>
            </div>
            <div class="col-md-2">
              <label>Interés</label>
              <div class="form-group">
                <input type="number" class="form-control" [(ngModel)]="client.clientInterest" [disabled]="noCredit" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>*Categoría</label>
                <angular2-multiselect [data]="categoryList" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                  (onSelect)="onItemSelect($event)">
                </angular2-multiselect>
              </div>
            </div>
            <div class="col-md-2">
              <label>Descuento otorgado</label>
              <div class="form-group">
                <input type="number" class="form-control" [(ngModel)]="client.clientDiscount" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mt-4">
                <button class="btn btn-info btn-block" (click)="save()" *ngIf="!isEdit" [disabled]="isDisabled">
                  Guardar
                </button>
                <button class="btn btn-primary btn-block" (click)="update()" *ngIf="isEdit" [disabled]="isDisabled">
                  Actualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END INPUT CLIENT -->

  <!-- TABLE -->
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-8">
              <h4 class="card-title">Listado de Clientes</h4>
              <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
              </h6>
            </div>
            <div class="col-md-4">
              <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                [disabled]="isDisabled">
                Descargar Excel
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="datatable_length">
                  <label>
                    Show
                    <select name="datatable_length" aria-controls="datatable" class="form-control form-control-sm"
                      (change)="entriesChange($event)">
                      <option value="10" [selected]="entries == 10">10</option>
                      <option value="25" [selected]="entries == 25">25</option>
                      <option value="50" [selected]="entries == 50">50</option>
                      <option value="-1" [selected]="entries == -1">All</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div id="datatable_filter" class="dataTables_filter">
                  <label>
                    <input type="search" class="form-control form-control-sm" placeholder="Search records"
                      aria-controls="datatable" (keyup)="filterTable($event)" />
                  </label>
                </div>
              </div>
            </div>
            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
              [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries : undefined" [rows]="temp"
              (activate)="onActivate($event)" [scrollbarH]="true">
              <ngx-datatable-column name="Código" prop="clientBusinessCode"></ngx-datatable-column>
              <ngx-datatable-column name="Nit/CUI/Pass" prop="nit"></ngx-datatable-column>
              <ngx-datatable-column name="Nombre F."  prop="clientName"></ngx-datatable-column>
              <ngx-datatable-column name="Nombre Cliente" prop="clientNameFrequent"></ngx-datatable-column>
              <ngx-datatable-column name="Telefono"></ngx-datatable-column>
              <ngx-datatable-column name="Telefono2" prop="clientPhone2"></ngx-datatable-column>
              <ngx-datatable-column name="Correo"></ngx-datatable-column>
              <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn">
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <a class="btn btn-round btn-warning btn-icon btn-sm" (click)="billing($event)"
                    ngbTooltip="Facturar" placement="left"><i class="fas fa-sticky-note"></i>
                  </a>
                  <a href="#" class="btn btn-round btn-info btn-icon btn-sm like" (click)="viewFunction($event)"
                    ngbTooltip="Ver" placement="left"><i class="fas fa-eye"></i>
                  </a>
                  <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit" (click)="editFunction($event)"
                    ngbTooltip="Editar" placement="left"><i class="fas fa-pencil-alt"></i>
                  </a>
                  <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event)"
                    ngbTooltip="Eliminar" placement="left"><i class="fas fa-times"></i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END TABLE -->
</div>