<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
  <div class="row link-header ml-5">
    <div>
      <a class="a-home" routerLink="/home">Home/</a>
    </div>
    <div>
      <a class="text-info" routerLink="/payment">Reporte ventas y compras</a>
    </div>
  </div>
</div>

<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="row">

        <!-- PARAMS -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-8">
                  <label>Tipo de Registro:</label>
                  <select class="form-control" [(ngModel)]="option">
                    <option [value]=1>Ventas</option>
                    <option [value]=2>Compras</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <br>
                  <div class="form-group">
                    <button class="btn btn-info btn-sale" style="width: 100%;" [disabled]="isDisabled"
                      (click)="getAll()">Aceptar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- VIEW REGISTER INVOICE-->
        <div class="col-md-12" *ngIf="isViewPayRegister">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-11 font-weight-bold">
                  Registros de Pago
                </div>
                <div class="col-1 text-right">
                  <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp" (click)="viewSales($event)"
                    *ngIf="isInvoicePurchase"><i class="fas fa-times"></i>
                  </a>
                  <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp" (click)="viewPurchase($event)"
                    *ngIf="!isInvoicePurchase"><i class="fas fa-times"></i>
                  </a>
                </div>
                <div class="col-sm-11">
                  <div class="row payRegisterList" *ngFor="let e of payRegister">
                    <div class="col-md-3 itemBlock" *ngIf="isInvoicePurchase">
                      <label>Factura</label>
                      <p>{{ e.factura }}</p>
                    </div>
                    <div class="col-md-3 itemBlock" *ngIf="!isInvoicePurchase">
                      <label>Compra</label>
                      <p>{{ e.compra }}</p>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>Fecha</label>
                      <p>{{ e.payRegisterDate | date:'dd-MM-yyyy'}}</p>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>Monto</label>
                      <p>Q {{ e.payRegisterAmount | number:'1.2-2' }} </p>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>Método de pago</label>
                      <p>{{ e.paymentMethodName }}</p>
                    </div>
                    <div class="col-md-3 itemBlock">
                      <label>No. Documento</label>
                      <p>{{ e.payRegisterDocument }}</p>
                    </div>
                    <div class="col-md-9 itemBlock">
                      <label>Descripción</label>
                      <p>{{ e.payRegisterDescription }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- TABLE INVOICE -->
        <div class="col-md-12" *ngIf="isViewSales">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-8">
                  <h4 class="card-title">Listado de Ventas</h4>
                  <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                  </h6>
                </div>
                <div class="col-md-4">
                  <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcelSale()"
                    [disabled]="isDisabled">
                    Descargar Excel
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="dataTables_wrapper">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="datatable_length">
                      <label>
                        Show
                        <select name="datatable_length" aria-controls="datatable" class="form-control form-control-sm"
                          (change)="entriesChange($event)">
                          <option value="10" [selected]="entries==10">10</option>
                          <option value="25" [selected]="entries==25">25</option>
                          <option value="50" [selected]="entries==50">50</option>
                          <option value="-1" [selected]="entries==-1">All</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="datatable_filter" class="dataTables_filter">
                      <label>
                        <input type="search" class="form-control form-control-sm" placeholder="Search records"
                          aria-controls="datatable" (keyup)="filterTable($event)" />
                      </label>
                    </div>
                  </div>
                </div>
                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                  [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                  (activate)="onActivate($event)" [rows]="temp">
                  <ngx-datatable-column name="Correlativo"></ngx-datatable-column>
                  <ngx-datatable-column name="Letra"></ngx-datatable-column>
                  <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                  <ngx-datatable-column name="Factura"></ngx-datatable-column>
                  <ngx-datatable-column name="Total"></ngx-datatable-column>
                  <ngx-datatable-column name="Pagado"></ngx-datatable-column>
                  <ngx-datatable-column name="Saldo"></ngx-datatable-column>
                  <ngx-datatable-column name="Cuotas"></ngx-datatable-column>
                  <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                      let-selectFn="selectFn">
                      Acciones
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-isSelected="isSelected"
                      let-onCheckboxChangeFn="onCheckboxChangeFn">
                      <a href="#" class="btn btn-round btn-info btn-icon btn-sm remove"
                        (click)="getPayRegister($event, 1)" ngbTooltip="Ver Registros" placement="left" 
                        [disabled]="cancelledInvoice == 1">
                        <i class="fas fa-eye"></i>
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
        <!-- TABLE INVOICE -->

        <!-- TABLE PURCHASE -->
        <div class="col-md-12" *ngIf="isViewPurchase">
          <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-8">
                      <h4 class="card-title">Listado de Compras</h4>
                      <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                      </h6>
                    </div>
                    <div class="col-md-4">
                      <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcelBuy()"
                        [disabled]="isDisabled">
                        Descargar Excel
                      </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
              <div class="dataTables_wrapper">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="datatable_length">
                      <label>
                        Show
                        <select name="datatable_length" aria-controls="datatable" class="form-control form-control-sm"
                          (change)="entriesChange($event)">
                          <option value="10" [selected]="entries==10">10</option>
                          <option value="25" [selected]="entries==25">25</option>
                          <option value="50" [selected]="entries==50">50</option>
                          <option value="-1" [selected]="entries==-1">All</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="datatable_filter" class="dataTables_filter">
                      <label>
                        <input type="search" class="form-control form-control-sm" placeholder="Search records"
                          aria-controls="datatable" (keyup)="filterTable($event)" />
                      </label>
                    </div>
                  </div>
                </div>
                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                  [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                  (activate)="onActivate($event)" [rows]="temp">
                  <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                  <ngx-datatable-column name="Compra"></ngx-datatable-column>
                  <ngx-datatable-column name="Total"></ngx-datatable-column>
                  <ngx-datatable-column name="Pagado"></ngx-datatable-column>
                  <ngx-datatable-column name="Saldo"></ngx-datatable-column>
                  <ngx-datatable-column name="Cuotas"></ngx-datatable-column>
                  <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                      let-selectFn="selectFn">
                      Acciones
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-isSelected="isSelected"
                      let-onCheckboxChangeFn="onCheckboxChangeFn">
                      <a href="#" class="btn btn-round btn-info btn-icon btn-sm remove"
                        (click)="getPayRegister($event, 2)" ngbTooltip="Ver Registros" placement="left">
                        <i class="fas fa-eye"></i>
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
        <!-- TABLE PURCHASE -->

      </div>
    </div>
  </div>
</div>
