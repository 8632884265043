<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">    
    <div class="row link-header ml-5">        
        <div>
            <a class="a-home" routerLink="/home">Home / </a>
        </div>
        <div>
            <a class="text-info" routerLink="/dairy">Libro de Diario / </a>
        </div>
        <div>
            <a class="a-return" routerLink="/reprint">Reporte de Retenciones</a>
        </div>
    </div>
</div>
<br />
<div class="main-content">
    <!-- LISTAR RETENCINES -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">                        
                        <div class="col-sm-12">
                            <b style="font-size: 1rem;">{{storeName}}</b>
                        </div>
                        <div class="col-md-4">
                            <label>Del:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="begDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Para:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="endDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <br>
                            <div class="form-group">
                                <button class="btn btn-info btn-sale" style="width: 100%;" [disabled]="isDisabled"
                                    (click)="getAll()">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN LISTAR FACTURAS -->

    <!-- TABLA -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Retenciones</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- [colWidth]="'auto'" -->
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [scrollbarH]="true" [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Correlativo"></ngx-datatable-column>
                            <ngx-datatable-column name="Letra"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Serie"></ngx-datatable-column>
                            <ngx-datatable-column name="Numero"></ngx-datatable-column>
                            <ngx-datatable-column name="Total"></ngx-datatable-column>
                            <ngx-datatable-column name="Cuenta"></ngx-datatable-column>
                            <ngx-datatable-column name="Documento"></ngx-datatable-column>
                            <ngx-datatable-column name="Monto"></ngx-datatable-column>                   
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN TABLA -->
</div>