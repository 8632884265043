import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { PayRegister } from 'src/app/models/pay-register';
import { AccountingService } from 'src/app/services/accounting.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-payment-records',
  templateUrl: './payment-records.component.html',
  styleUrls: ['./payment-records.component.scss'],
  providers: [UtilsService, AccountingService]
})
export class PaymentRecordsComponent implements OnInit {
  // MODELS VARIABLE 
  public payRegister = {} as PayRegister;
  public paymentList: any[];
  public isViewSales: boolean;
  public isViewPurchase: boolean;
  public isViewBeginning: boolean;
  public isViewPayRegister: boolean;
  public isInvoicePurchase: boolean;
  public isDisabled: boolean;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public option: number;
  public settingsSale = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      factura: {
        title: 'Factura',
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      pagado: {
        title: 'Pagado',
        type: 'number'
      },
      saldo: {
        title: 'Saldo',
        type: 'number'
      },
      cuotas: {
        title: 'Cuotas',
        type: 'number'
      }
    }
  };
  public settingsBuy = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      compra: {
        title: 'Compra',
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      pagado: {
        title: 'Pagado',
        type: 'number'
      },
      saldo: {
        title: 'Saldo',
        type: 'number'
      },
      cuotas: {
        title: 'Cuotas',
        type: 'number'
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;

  constructor(
    private _utilsService: UtilsService,
    private _accoutingService: AccountingService,
  ) { }

  ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.option = 1;    
    this.isDisabled = false;
    this.isViewBeginning = true;
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.paymentList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL PAYMENT
  getAll() {
    this.loading = true;
    this.isDisabled = true;
    this.cleanModel();

    let companyOrStore;
    this.option == 1 ? companyOrStore = this.storeCode : companyOrStore = this.companyCode;
    return this._accoutingService.getAllPaymentByStore(this.option, companyOrStore).then(
      async response => {
        if (response.result) {
          console.log(response.records);
          this.paymentList = [];  
          this.paymentList = response.records;
          await  this._utilsService.orderDate(this.paymentList, 'fecha', '/'); 
          this.temp = this.paymentList;
          this.option == 1 ? this.viewSales(event) : this.viewPurchase(event);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

   // GET REGISTER OF AN INVOICE
   getPayRegister($event, option) {
    $event.preventDefault();
    this.loading = true;

    if(option == 1){
      this.payRegister.payRegisterInvoiceCode = this.activeRow.payRegisterInvoiceCode;
      this.payRegister.payRegisterPurchaseCode = 0;
      this.isInvoicePurchase = true;
    }else{
      this.payRegister.payRegisterInvoiceCode = 0;
      this.payRegister.payRegisterPurchaseCode = this.activeRow.payRegisterPurchaseCode;
      this.isInvoicePurchase = false;
    }
    
    return this._accoutingService.getAllPayRegister(this.payRegister).then(
      response => {
        if (response.result) {
          this.payRegister = response.records[1];          
          this.viewPayRegister();
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //  VIEWS
  viewBeginning($event) {
    $event.preventDefault();
    this.isViewSales = false;
    this.isViewBeginning = true;
    this.isViewPurchase = false;        
    this.isViewPayRegister = false;
  }

  viewSales($event) {
    $event.preventDefault();
    this.cleanModel();

    this.isViewSales = true;
    this.isViewPurchase = false;
    this.isViewPayRegister = false;
  }

  viewPurchase($event) {
    $event.preventDefault();
    this.cleanModel();
    
    this.isViewSales = false;
    this.isViewPurchase = true;
    this.isViewPayRegister = false;
  }

  viewPayRegister(){
    this.isViewSales = false;     
    this.isViewPurchase = false;
    this.isViewBeginning = false;
    this.isViewPayRegister = true;
  }

  // CLEAR MODAL
  cleanModel() {
    // console.log('llego 2');
    this.payRegister = {} as PayRegister;
  }

   // DESCARGAR EXCEL
   async downloadToExcelSale() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settingsSale.columns).forEach((item) => {
        if (this.settingsSale.columns[item].type === "") {
          json[this.settingsSale.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settingsSale.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-ventas.xlsx');
  }

   async downloadToExcelBuy() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settingsBuy.columns).forEach((item) => {
        if (this.settingsBuy.columns[item].type === "") {
          json[this.settingsBuy.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settingsBuy.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-compras.xlsx');
  }
}
