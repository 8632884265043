import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import * as printJS from 'print-js';
import { Client } from 'src/app/models/client';
import { InvoiceHeader } from 'src/app/models/invoiceHeader';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { ClientService } from 'src/app/services/client.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Store } from 'src/app/models/store';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LetrasPipe } from 'src/app/pipe/letras/letras.pipe';
import { DatePipe, DecimalPipe } from '@angular/common';
import { PrintService } from 'src/app/services/print.service';
import { UtilsService } from 'src/app/services/utils.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-description-billing',
  templateUrl: './description-billing.component.html',
  styleUrls: ['./description-billing.component.scss'],
  providers: [UtilsService, PrintService, ClientService, InvoiceService, StoreService, CompanyService]
})
export class DescriptionBillingComponent implements OnInit {
  // Variables auxiliares
  pipeLetra = new LetrasPipe();
  pipeFecha = new DatePipe('en-US');
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  public nowDay: string;
  public userCode: number;
  public userName: string;
  public storeCode: number;
  public storeName: string;
  public companyCode: number;
  loading;

  public isBeginning: boolean;
  public isBilling: boolean;
  public isPrintT: boolean;
  public isPrintL: boolean;
  public isDisabled: boolean;
  public recurrentClient: boolean;
  public insertNewClient: boolean = false;

  // Variables de Linea de Compra
  public itemCode: string;
  public itemNumberCode: number;
  public moneda: string;
  public invoiceLine: any = new Array;
  public addAmount: boolean;
  // public dte: any[] = [];
  public dte: {
    serie,
    noFactura,
    autorizacion,
    certificador,
    nitCertificador,
    fechaCertificacion,
  };
  public fel: any;
  public felDetail: any;
  public companyLogoUrl: string;
  public taxToPay: number = 0
  public regimenMessage: string = '';

  //FACTURAR DESDE CLIENT
  public url: any;
  public code: number = 0;
  activatedRoute: ActivatedRoute | null | undefined;

  //FACTURA DESDE TRANSFERENCI
  public isParamsUrl: boolean = false;

  //Prueba de impresion
  public infile: any;
  public megaprint: any;

  public disabled = false;
  public disabledEXT = false;
  public facturando: boolean;

  public tipoCliente = 0;
  public tipoPago = 0;

  // DATA SELECT PAYMENT
  public idPayment: number;
  public itemNamePayment: string;
  public selectedItemsPayment = [];
  public PaymentList: any[] = new Array();
  public dropdownSettingsPayment: IDropdownSettings = {};
  closeDropdownSelectionPayment = false;
  
  public PaymentLine: any = new Array;
  public PaymentType = 0;
  public NamePaymentType = 0;
  public MontoPayment: number = 0.00;
  public MontoTotalPayment: number = 0.00;
  public Autorizacion: string = "0";
  public disabledPay = false;
  public isDisabledInvoice = false;
  public isMethodPay = false;

  constructor(
    private _utilsService: UtilsService,
    private router: Router,
    private _printService: PrintService,
    private _clientService: ClientService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
    private _storeService: StoreService,
    private _pipeNumero: DecimalPipe,
  ) {
  }

  async ngOnInit() {
    this.addAmount = true;
    this.isDisabled = false;
    this.insertNewClient = false;
    this.moneda = "Q";
    this.itemCode = "I";
    this.itemNumberCode = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceCurrency = 'GTQ'
    this.invoiceDetail.invoiceDetailType = 'S';
    this.invoiceDetail.invoiceDetailQuantity = 1;
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.paymentType = 0;
    this.companyLogoUrl = '../../../assets/img/Clever-azul-original.png';
    
    this.invoiceHeader.invoiceDate = this._utilsService.dateTime('yyy-MM-dd');
    this.nowDay = this.invoiceHeader.invoiceDate;

    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    const _user = JSON.parse(localStorage.getItem('user'));
    this.userName = _user.userName ? _user.userName.toString() : '';

    this.viewBeginning();
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
    await this.getPaymetList(this.storeCode);
    this.getSelectSettingPaymet(this.selectedItemsPayment);

    // console.log('Select -> ', this.selectedItems);
    console.log('Select Tipo pagos -> ', this.selectedItemsPayment);
    console.log('Tipo pagos -> ', this.PaymentList);

    await this.Pagado();

    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.disabledEXT = true;

    this.url = this.router.parseUrl(this.router.url);
    if (this.url.queryParams['nit'] != undefined) {

      if (localStorage.getItem('clientData') !== null) {
        console.log('si hay data de cliente');

        let _dataCli = JSON.parse(localStorage.getItem('clientData'));

        console.log('localstorage -> ', _dataCli.clientName);

        this.client.clientName = _dataCli.clientName;
        this.client.clientStatus = 1;
        this.client.clientDeleted = 0;
        this.client.clientPhone = _dataCli.clientPhone;
        this.client.clientEmail = _dataCli.clientEmail;
        this.client.clientAddress = _dataCli.clientAddress;
        this.client.clientCode =  _dataCli.clientCode;
        this.client.clientTaxDocument = _dataCli.clientTaxDocument;        
        this.tipoCliente = _dataCli.clientType;
        this.client.clientType = _dataCli.clientType;

        this.viewBilling();
        this.loading = false;
        this.isDisabled =false;

        console.log('Cliente -> ', this.client);
        console.log('Tipo Cliente -> ', this.tipoCliente);
  
        this.insertNewClient = false;
        this.recurrentClient = true;
      }

      // this.isParamsUrl = true;
      // this.code = this.url.queryParams['nit'];
      // await this.buscarNITLocal(event, this.code);

      // if (this.url.queryParams['transfer']) {
      //   let transfer = this.url.queryParams['transfer'];
      //   await this.cargarTrasnferencia(transfer);
      // }

    }

    this.tipoPago = this.invoiceHeader.paymentType;
    console.log(this.tipoPago);
  }

  async onItemSelectPaymet(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItemPayment = await this.PaymentList.find(x => x.id == item.id);
    console.log(_selectedItemPayment);

    this.tipoPago = item.payMethodBusinessCode;
    console.log('Tipo Pago -> ', this.tipoPago);

    this.PaymentType = this.tipoPago;
    this.NamePaymentType = item.payMethodName;

    // this.itemCode = item.idPayment;
    // this.invoiceDetail.invoiceDetailDescription = item.itemNamePayment;
    // this.stock = _selectedItem.inventoryQuantity;
    // this.Minstock = _selectedItem.inventoryMin;
  }

  getPaymetList(code) {
    return this._storeService.getPaymets(code).then(
      response => {
        if (response.result) {
          this.PaymentList = response.records;
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getSelectSettingPaymet(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItemsPayment = [selected] : '';

    this.dropdownSettingsPayment = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'payMethodBusinessCode',
      textField: 'payMethodName'
    };
  }

  async addPaymetDetail(){

    this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment + (this.MontoPayment))).toFixed(6));
    console.log(this.MontoTotalPayment);

    await this.PaymentLine.push({
      typePayment: this.PaymentType,
      NamePaymentType: this.NamePaymentType,
      MontoPayment: this.MontoPayment,
      Autorizacion: this.Autorizacion
    });

    this.MontoPayment = 0;
    this.Autorizacion = "0";
    this.selectedItemsPayment = [];

    this.Pagado();

    console.log(this.PaymentLine);
  }

  async deletePaymetDetail(item) {

    await this.PaymentLine.forEach(async (e, index) => {
      if (e.typePayment === item) {

        this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment - (e.MontoPayment))).toFixed(6));        
        this.PaymentLine.splice(index, 1);
      }
    })

    this.Pagado();
        
    console.log(this.PaymentLine);
  }

  async Pagado(){

    if(this.company.companyPaymentType == 1){
      this.isMethodPay = true;
      if((this.MontoTotalPayment == this.invoiceHeader.invoiceTotal) && this.MontoTotalPayment > 0){
        this.isDisabledInvoice = false;
      }
      else if(this.MontoTotalPayment > this.invoiceHeader.invoiceTotal){
        this.isDisabledInvoice = true;
        this._utilsService.showNotification(2,"Total pagado es mayor al Total de la factura")
      }
      else{
        this.isDisabledInvoice = true;
      }
    }
    else{
      this.isMethodPay = false;
      this.isDisabledInvoice = false;
    }
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
          this.company.companyTaxToPay == 12 ? this.taxToPay = 1.12 : this.taxToPay = 1.05;
          this.company.companyTaxToPay == 12 ? this.regimenMessage = "Sujeto a pagos trimestrales ISR" : this.regimenMessage = "*No genera derecho a crédito fiscal";
          this.company.companyLogo != 'undefined' ? this.companyLogoUrl = this.company.companyLogoUrl : '';
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      response => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  onChange(event) {
    this.invoiceHeader.invoiceCoin = event;

    if (this.invoiceHeader.invoiceCoin == 0) {
      this.moneda = "Q";
      this.invoiceHeader.invoiceCurrency = 'GTQ';
      this.invoiceHeader.invoiceExchangeRate = null;
    } else if (this.invoiceHeader.invoiceCoin == 1) {
      this.moneda = "$"
      this.invoiceHeader.invoiceCurrency = 'USD';
    }
  }

  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  onChangepaymentType(event) {
    this.invoiceHeader.paymentType = event;
    this.tipoPago = event;
    console.log(this.tipoPago);
  }

  ///////////////////////FUNCTION VALIDATE DATE///////////////////////
  validateDate($event) {
    $event.preventDefault();
    var valid = false;
    var now = this.nowDay.split('-');
    var date = this.invoiceHeader.invoiceDate.split('-');

    if (date[0] == now[0] || parseFloat(date[0]) == parseFloat(now[0]) - 1) {
      if (date[1] == now[1]) {
        if (parseFloat(date[2]) <= parseFloat(now[2]) + 5 && parseFloat(date[2]) >= parseFloat(now[2]) - 5) {
          valid = true;
        }
      }
      else if (parseFloat(date[1]) == parseFloat(now[1]) - 1 || parseFloat(date[1]) == 12) {
        if (parseFloat(date[2]) >= 26) {
          valid = true;
        }
      }
    }

    if (valid == false) {
      this._utilsService.showNotification(2, 'Fecha fuera de rango')
      this.invoiceHeader.invoiceDate = this.nowDay;
    };
  }

  ///////////////////////FUNCTION CHANGE INTERAL SERIAL///////////////////////
  onChangeInternSerial(event) {
    this.invoiceHeader.invoiceInternSerial = event;
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isBilling = false;
    this.isPrintT = false;
    this.isPrintL = false;
    this.client.clientType = 0;
    this.tipoCliente = 0;
  }

  viewBilling() {
    this.isBeginning = false;
    this.isBilling = true;
    this.isPrintT = false;
    this.isPrintL = false;
  }

  viewPrint() {
    this.isBeginning = false;
    this.isBilling = false;
    // this.company.companyInvoicePrint === 1 ? this.isPrintT = true : this.isPrintL = true;
    this.company.companyInvoicePrint === 1 ? this.isPrintT = true : this.printLetter();
  }

  viewCF() {
    this.isBeginning = true;
    this.isBilling = true;
    this.isPrintT = false;
    this.isPrintL = false;
    this.disabled = true;
  }

  ValidarCF() {
    /*J11 - Validar CF*/
    console.log(this.invoiceHeader.invoiceTotal);
    console.log(this.client.clientTaxDocument);

    if(this.invoiceHeader.invoiceTotal >= 2500){
      if(this.client.clientTaxDocument == "CF"){
        this._utilsService.showNotification(5, "No puede facturar como CF, el monto es mayor a 2500");
        this.viewCF();
      }
      else{
        this.disabled = false;
      }
    }
  }

  continue() {
    this.viewBeginning();
    this.cleanModel();
  }

  // PRINT
  printTest() {
    printJS({
      printable: "printSection",
      type: "html",
      style: "#printSection{width: 224px; text-align: center}",
    });
  }

  // CALCULAR IMPUESTO DE LINEA
  calculateTax() {
    let subTotalLine;

    subTotalLine = (this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice);

    if (this.invoiceDetail.invoiceDetailDiscount > 0) {
      if (this.invoiceDetail.invoiceDetailDiscount <= subTotalLine) {
        subTotalLine = subTotalLine - this.invoiceDetail.invoiceDetailDiscount;
      } else {
        this._utilsService.showNotification(2, "Cantidad No permitida");
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }
    }

    let tax;
    tax = (subTotalLine - (subTotalLine / this.taxToPay)).toFixed(6);
    this.invoiceDetail.invoiceDetailTax = parseFloat(tax);
  }

  // GUARDAR UNA LINEA DE COMPRA
  saveLineInvoice() {
    if (this.invoiceDetail.invoiceDetailDescription != "" && this.invoiceDetail.invoiceDetailQuantity > 0 && this.invoiceDetail.invoiceDetailUnitPrice > 0) {

      console.log(this.invoiceDetail.invoiceDetailDescription.length)
      if(this.invoiceDetail.invoiceDetailDescription.length >= 500){
        this._utilsService.showNotification(2, "Cantidad de caracteres supera los 500, no puede continuar.");
      }
      else{
        
        //Aumentar Codigo si no viene uno
        if (this.itemCode == "I") {
          this.itemNumberCode++;
          this.itemCode += this.itemNumberCode;
        }

        //Calcular subtotal de linea
        this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice;

        if (this.invoiceDetail.invoiceDetailDiscount != undefined && this.invoiceDetail.invoiceDetailDiscount != null && this.invoiceDetail.invoiceDetailDiscount > 0) {
          this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailDiscount;
          this.invoiceDetail.invoiceDetailTax = this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay);
        } else {
          this.invoiceDetail.invoiceDetailDiscount = 0;
        }

        //Actualizar
        this.invoiceLine.forEach((e) => {
          if (e.itemCode == this.itemCode) {

            this.invoiceHeader.invoiceTotal -= e.total;

            e.itemCode = this.itemCode;
            e.measure = "UNI";
            e.taxCode = 1;
            e.taxName = "IVA";
            e.isService = this.invoiceDetail.invoiceDetailType;
            e.quantity = parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(6));
            e.description = this.invoiceDetail.invoiceDetailDescription;
            e.unitPrice = parseFloat((this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
            e.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(6));
            e.discount = parseFloat((this.invoiceDetail.invoiceDetailDiscount).toFixed(6));
            e.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(6));
            e.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6));
            e.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6));

            // this.total = this.total + this.subTotal;
            this.addAmount = false;
          }
        })

        //Insertar
        if (this.addAmount == true) {
          this.invoiceLine.push({
            itemCode: this.itemCode,
            measure: "UNI",
            taxCode: 1,
            taxName: "IVA",
            isService: this.invoiceDetail.invoiceDetailType,
            quantity: parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(6)),
            description: this.invoiceDetail.invoiceDetailDescription,
            unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(6)),
            price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(6)),
            discount: parseFloat(this.invoiceDetail.invoiceDetailDiscount.toFixed(6)),
            taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(6)),
            taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6)),
            total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(6))
          });
        }
        // console.log(this.invoiceLine);
        // Sumar Total
        this.invoiceHeader.invoiceTotal += this.invoiceDetail.invoiceDetailSubtotal;
        this.invoiceHeader.invoiceTax += this.invoiceDetail.invoiceDetailTax;
        this.cleanLineInvoice();
        
      }      
    }

    this.ValidarCF();

    this.isDisabled = false;
  }

  // APLICAR DESCUENTO EN TIEMPO REAL
  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (parseFloat(descuento) <= temporal) {
          this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.total);
          e.total = temporal - parseFloat(descuento);
          e.taxableAmount = parseFloat((parseFloat(e.total) / this.taxToPay).toFixed(6));
          e.taxAmount = parseFloat(((parseFloat(e.total) / this.taxToPay) * (this.taxToPay - 1)).toFixed(6));
          this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal + parseFloat(e.total);
        } else {
          this._utilsService.showNotification(2, 'Cantidad No permitida');
        }
      }
    });
  }

  // CARGAR DATOS A EDITAR
  editLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        this.itemCode = e.itemCode;
        this.invoiceDetail.invoiceDetailType = e.isService;
        this.invoiceDetail.invoiceDetailDescription = e.description;
        this.invoiceDetail.invoiceDetailQuantity = e.quantity;
        this.invoiceDetail.invoiceDetailUnitPrice = e.unitPrice;
        this.invoiceDetail.invoiceDetailSubtotal = e.price;
        this.invoiceDetail.invoiceDetailDiscount = e.discount;
        this.invoiceDetail.invoiceDetailTax = e.taxAmount;
      }
    })
    this.isDisabled = false;
  }

  // ELIMINAR UNA LINEA
  deleteLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e, index) => {
      if (e.itemCode == item) {
        this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.price);
        this.invoiceLine.splice(index, 1);
      }
    })
    this.isDisabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.addAmount = true;

    this.itemCode = "I";
    this.invoiceDetail.invoiceDetailDescription = null;
    this.invoiceDetail.invoiceDetailDiscount = null;
    this.invoiceDetail.invoiceDetailQuantity = 1;
    this.invoiceDetail.invoiceDetailUnitPrice = null;
    this.invoiceDetail.invoiceDetailTax = null;
  }

  onChangeTypeClient(event) {    
    this.client.clientType = event;
    this.tipoCliente = event;
    console.log('Local TC: ', this.tipoCliente);

    if(event == 2){
      this.disabledEXT = false;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
    else{
      this.disabledEXT = true;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
  }

  ///////////////////////Funcion de Buscar por NIT///////////////////////
  buscarNITLocal($event, nit) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    if(this.client.clientType == 2){

      this.client.clientName = this.client.clientName;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientPhone = "00000000";
      this.client.clientEmail = "info@clever.com";
      this.client.clientAddress = "CIUDAD";

      this.insertNewClient = false;
      this.recurrentClient = false;
      this.viewBilling();
      this._utilsService.showNotification(4, "SE EMITIRA FACTURA CON PASAPORTE");
      this.loading = false;

    }
    else{
      if(nit == "CF"){
        this.client.clientType = 0;
        this.tipoCliente = 0;
      }
      // return this._clientService.getOneByNit(nit).then(
      return this._clientService.getOneByCompanyNit(this.companyCode, nit).then(
        response => {
          // console.log(response.records)
          if (response.records.length > 0) {
            this.client = response.records[0];
            this.recurrentClient = true;
            this.insertNewClient = false;
            this.viewBilling();
            this._utilsService.showNotification(1, response.message);
          } else {
            return this._clientService.searchNitSAT(nit, this.client.clientType).then(
              async response => {
                if (response.tipo_respuesta == 0) {
                  //this.client.clientName = response.nombre[0];
                  //console.log(response.nombre[0]);
                  this.client.clientName =  await this.replaceCharter(response.nombre[0]);
                  this.client.clientStatus = 1;
                  this.client.clientDeleted = 0;
                  this.client.clientPhone = "00000000";
                  this.client.clientEmail = "info@clever.com";
                  this.client.clientAddress = "CIUDAD";

                  this.insertNewClient = true;
                  this.recurrentClient = false;
                  this.viewBilling();
                  this._utilsService.showNotification(4, "Cliente Nuevo");
                } else {
                  this._utilsService.showNotification(3, response.listado_errores[0].error[0].desc_error[0]);
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;

        this.ValidarCF();
      });
    }    
  }

  async replaceCharter(name: string){
    name = await name.replace(/&/g, 'Y');
    name = await name.replace(/'/g, '');
    name = await name.replace(/"/g, '');

    //console.log(name);
    return name;
  }

  async insertInvoice() {
    

    //*
    this.loading = true;
    this.isDisabled = true;
    console.log(this.client);
    console.log('-> ', this.tipoCliente);

    //Crear Cliente en la DB
    if (this.insertNewClient) {
      this.client.clientCredit = 0;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientBlocked = 0;
      this.client.clientInterest = 0;
      this.client.clientDiscount = 0;
      this.client.clientCategory = 2;
      this.client.clientCreditTime = 0;
      this.client.clientCreditAmount = 0;
      this.client.clientCompanyCode = this.companyCode;
      this.client.clientType = this.tipoCliente;
      this.client.clientNameFrequent = '-';

      if (this.client.clientTaxDocument && this.client.clientName) {
        const response = await this._clientService.insert(this.client);
        this.client.clientCode = response.records[0].insertId;
      };
    }

    // Si es factura de pequeño contribuyente
    let transmitterType = "FACT";
    let transmitterMembership = "GEN";
    if (this.company.companyTaxToPay == 5) {
      transmitterType = "FPEQ";
      transmitterMembership = "PEQ";
    }

    this.invoiceHeader.invoiceTax = 0;
    this.invoiceLine.forEach(e => {
      e.itemCode = 0;
      this.invoiceHeader.invoiceTax += e.taxAmount;
    });

    if(this.company.companyPaymentType == 0){
      await this.PaymentLine.push({
        typePayment: 0,
        NamePaymentType: 'Efectivo',
        MontoPayment: parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4))
      });
    }

    const hour = await this._utilsService.dateTime('HH:mm:ss');

    const json = {
      "store": {
        "storeCode": this.storeCode
      },
      "user": {
        "userCode": this.userCode
      },
      "transmitter": {
        "transmitterMembership": transmitterMembership,
        "transmitterType": transmitterType,
        "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
        "transmitterEmail": this.company.companyEmail,
        "transmitterTaxDocument": this.company.companyTaxDocument,
        "transmitterComercialName": this.store.storeName, //NOMBRE DE ESTABLECIMIENTO. EJEMPLO: IMA
        "transmitterName": this.company.companyName, //EMISOR DUEÑO DEL NIT (RAZON SOCIAL) EJEMPLO: RENATTO TRINIDAD 
        "transmitterAddress": this.store.storeAddress,
        "transmitterZipCode": this.store.storeZipCode,
        "transmitterMunicipality": this.store.storeMunicipality,
        "transmitterDepartment": this.store.storeDepartment,
        "transmitterCountry": 'GT',
        "transmitterPrefix": this.company.companyPrefix,
        "transmitterUser": this.company.companyPrefix,
        "transmitterKey": this.company.companyKey,
        "transmitterToken": this.company.companyToken,
        "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
        "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
      },
      "receiver": {
        "receiverAddress": this.client.clientAddress,
        "receiverCode": this.client.clientCode,
        "receiverDeleted": 0,
        "receiverEmail": this.client.clientEmail,
        "receiverName": this.client.clientName,
        "receiverStatus": 1,
        "receiverTaxDocument": this.client.clientTaxDocument,
        "receiverTypeTaxDocument": this.tipoCliente,
        "receiverZipCode": 0,
        "receiverCountry": "GT"
      },
      "invoice": {
        "invoiceCode": 1,
        "invoiceCurrency": this.invoiceHeader.invoiceCurrency,        
        "invoiceChangeRate": this.invoiceHeader.invoiceExchangeRate == undefined ? 0 : this.invoiceHeader.invoiceExchangeRate,
        "invoiceTaxShortName": "IVA",
        "invoiceDate": this.invoiceHeader.invoiceDate + ' ' + hour,
        // "1invoiceDate": `${this.invoiceHeader.invoiceDate} ${this.hour}`,
        "invoiceInternSerial": this.invoiceHeader.invoiceInternSerial,
        "invoiceTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(6)),
        "invoiceTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(6)),
        "invoiceDuesQuantity": 0,
        "invoicepaymentType": this.tipoPago,
      },
      "detail": this.invoiceLine,
      "paymentType": this.PaymentLine,
      "phrase": this.company.phrase,
      "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint"
    };

    const logo = await this._companyService.getOne(this.companyCode);
    logo.records[0].companyLogoUrl != 'undefined' && (this.companyLogoUrl = logo.records[0].companyLogoUrl);

    // console.log(json);
    // this.loading = false;
    // this.isDisabled = false;
    //* Insertar Venta
    if (this.company.companyCertifier == 0) { //INFILE
      console.log(json);
      this._invoiceService.insertBilling(json).then(
        async (response) => {
          if (response.result) {
            // console.log(response);
            if (this.company.companyPDFType == 1) {
              this.fel = response.infileBody;
              this.felDetail = response.infileBody.detail;
              this.dte = {
                serie: response.records.infileServiceResponse.registerResponse.serie,
                noFactura: response.records.infileServiceResponse.registerResponse.numero,
                autorizacion: response.invoiceAuthorization,
                certificador: "INFILE, S.A.",
                nitCertificador: 12521337,
                fechaCertificacion: response.records.infileServiceResponse.registerResponse.fecha
              }
              // this.regimenMessage
              if(this.company.companyInvoicePrint === 1){
                this.viewPrint();
              }
              else{
                await this._printService.printLetter(this.company, this.store, this.userName, this.dte, this.fel, this.felDetail);
              }
              this.cleanModel();              
            } else {
              const uuid = response.invoiceAuthorization;
              window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid);
              // window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');
              this.viewBeginning();
              this.cleanModel();
            }
            this._utilsService.showNotification(1, response.message);
          } else {
            console.log(response);
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    }
    else if (this.company.companyCertifier == 1) { //Megaprint
      this._invoiceService.insertBilling(json).then(
        (response) => {
          if (response.result) {
            // console.log(response);
            if (this.company.companyInvoiceFuel == 1 || this.company.companyPDFType == 1) {
              this.fel = response.megaprintBody;
              this.felDetail = response.megaprintBody.detail;
              const dte = response.records.megaprintServiceResponse.jsonXml['dte:GTDocumento']['dte:SAT'][0]['dte:DTE'][0]['dte:Certificacion'][0];
              this.dte = {
                serie: dte['dte:NumeroAutorizacion'][0]['Serie'][0],
                noFactura: dte['dte:NumeroAutorizacion'][0]['Numero'][0],
                autorizacion: dte['dte:NumeroAutorizacion'][0]['_'],
                certificador: dte['dte:NombreCertificador'][0],
                nitCertificador: dte['dte:NITCertificador'][0],
                fechaCertificacion: dte['dte:FechaHoraCertificacion'][0]
              }
              this.viewPrint();
            } else {
              var pdfResult = response.invoicePdf;
              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")
              this.viewBeginning();
              this.cleanModel();
            }
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    }

    this.insertNewClient = false;
    //*/
  }

  async printLetter() {
    // const pdfWindow = window.open("", 'Factura', '_blank');
    const pdfWindow = window.open();
    const css = `<style>   
        td { font-size: 12px; padding: 2px;}     
        .brDescr {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF; border-bottom: 1px solid  #2CA8FF;}
        .brCompl {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF;}
      </style>`;

    let html = `
    <head><title>Factura Clever "${this.company.companyName + ' - ' + this.storeName}"</title></head>
    <body style="width: 21cm; height: 27cm; margin: auto; font-family: sans-serif, system-ui, monospace;"> 
      <div style="border: 2px solid #2CA8FF; border-radius: 10px; width: auto;">
        <table style="width: 100%;">
              <tr>
                  <td rowspan="5" style="width: 19%;">
                      <img style="width: 110px; margin:10px auto; display:block;" src="${this.companyLogoUrl}">
                  </td>
                  <th style="width: 62%; font-size: 19px; text-align: cent  er;">
                    ${this.fel.transmitter.transmitterComercialName}
                  </th>
                  <td style="width: 19%; font-size: 10px;">
                      Vendedor: ${this.userName}
                  </td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                    ${this.fel.transmitter.transmitterName}                   
                  </td>
                  <td style="font-size: 10px;">Factura Electronica Fel</td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      NIT: ${this.fel.transmitter.transmitterTaxDocument}
                  </td>
                  <td>Serie: ${this.dte.serie}</td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      Tel: ${this.store.storePhone}<br>
                      ${this.fel.transmitter.transmitterAddress}. ${this.fel.transmitter.transmitterMunicipality}, ${this.fel.transmitter.transmitterDepartment}
                  </td>
                  <td>No. DTE: ${this.dte.noFactura}</td>
              </tr>
              <tr>
                  <th style="text-align: center;">FACTURA</th>
                  <td>Fecha: ${this.pipeFecha.transform(this.fel.invoice.invoiceDate, 'dd-MM-yyy')}</td>
              </tr>
        </table>
      </div>
      <br>
      <div style="border: 2px solid #2CA8FF; border-radius: 10px 10px 0px 0px; width: auto; min-height: 750px" >
        <table cellspacing="0" cellpadding="0" style="width: 100%;">
            <tr style="max-height: 15px;">
              <td colspan="4"> Nombre: ${this.fel.receiver.receiverName}</td>
              <td colspan="3"> Nit: ${this.fel.receiver.receiverTaxDocument}</td>
            </tr>
            <tr style="max-height: 15px;">
              <td colspan="7"> Dirección: ${this.fel.receiver.receiverAddress}</td>
            </tr>
            <tr style="font-size: 11px; text-align: center;">
              <th class="brDescr">CANTIDAD</th>
              <th class="brDescr">TIPO</th>
              <th class="brDescr" style="width: 320px;">DESCRIPCIÓN</th>
              <th class="brDescr">PRECIO UNI.</th>
              <th class="brDescr">IMPUESTO</th>
              <th class="brDescr">DESCUENTO</th>
              <th class="brDescr" style="width: 120px;">TOTAL</th>
            </tr>`;
    
    for (const e of this.felDetail) {
      html += `<tr">
        <td style="text-align: center; "> ${this._pipeNumero.transform(e.quantity, '1.2-2')}</td>
        <td style="text-align: center;"> ${e.isService}</td>
        <td>${e.description}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(e.unitPrice, '1.2-2')}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(e.taxAmount, '1.2-2')}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(e.discount, '1.2-2')}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(e.total, '1.2-2')}</td>
        </tr>`
    };

    html += `          
        </table>
      </div>
      <div style="border: 2px solid #2CA8FF; border-radius: 0px 0px 10px 10px; width: auto;">
        <table cellspacing="0" style="width: 100%;">
              <tr>
                <td colspan="7" style="text-align: center; font-size: 10px;">${this.regimenMessage}</td>
              </tr>
              <tr style="height: 30px;">
                <td class="brCompl" style="max-width: 22px; padding-left: 5px;">Total en Letras:</td>
                  <td class="brCompl" style="font-size: 10px max-width: 65px; text-align: left;">${this.pipeLetra.transform(this.fel.invoice.invoiceTotal)}</td>
                  <th style="font-size: 14px; max-width: 65px; text-align: right; border-top: 1px solid  #2CA8FF;">
                    TOTAL: Q ${this._pipeNumero.transform(this.fel.invoice.invoiceTotal, '1.2-2')}
                  </th>
              </tr>
          </table>
      </div> 
      <br>
      <table style="border: 2px solid #2CA8FF; border-radius: 10px; width: 100%;" padding: 10px;" >
          <tr><td>
            No. de Autorización: ${this.dte.autorizacion}<br>
            Fecha de Certificación: ${this.dte.fechaCertificacion}  
          </td></tr>
          <tr><td style="text-align: center">Certificador: ${this.dte.certificador}. Nit: ${this.dte.nitCertificador}</td></tr>
      </table>
    </body>`;

    await pdfWindow.document.write(css + html); //await setTimeout( () => {pdfWindow.window.open()}, 1100)
    this.continue();
  }

  // Limpiar Campos
  cleanModel() {
    this.itemCode = "I";
    this.itemNumberCode = 0;

    this.isDisabled = false;
    this.insertNewClient = false;
    this.recurrentClient = false;
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceLine = [];
    this.moneda = "Q";
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceCurrency = 'GTQ',
    this.invoiceDetail.invoiceDetailType = 'S';
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.invoiceDate = this.nowDay;
    this.invoiceHeader.paymentType = 0;
    this.tipoPago = 0;
    
    this.PaymentLine = [];
    this.MontoTotalPayment = 0;
    
    this.addAmount = true;
    if (this.isParamsUrl) {
      this.router.navigate([], { relativeTo: this.activatedRoute });
    }
  }
}
