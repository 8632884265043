import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { PurchaseService } from 'src/app/services/purchase.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PrintService } from 'src/app/services/print.service';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-purchase-view',
  templateUrl: './purchase-view.component.html',
  styleUrls: ['./purchase-view.component.scss'],
  providers: [PurchaseService, UtilsService, PrintService, CompanyService]
})
export class PurchaseViewComponent implements OnInit {
  // MODELS VARIABLE 
  public purchase: any[];
  public purchaseDetail: any[];
  public isViewBeginning: boolean;
  public isViewPurchase: boolean;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isEdit: boolean;
  public isDisabled: boolean;
  public settings = {
    columns: {
      ingresado: {
        title: 'Ingresado'
      },
      proveedor: {
        title: 'Proveedor'
      },
      monto: {
        title: 'Monto',
        type: 'number'
      },
      estado: {
        title: 'Estado'
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE //GET ALL //GET PENDING 
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public allList = new Array();
  company = {} as Company;

  constructor(
    private _utilsService: UtilsService,
    private _purchaseService: PurchaseService,
    private _printService: PrintService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.isDisabled = false;
    this.isEdit = false;

    // LOAD INFORMATION
    this.getAllPurchase(this.companyCode);
    await this.getCompany(this.companyCode);

    // VIEWS
    this.viewBeginning();
  }

   ///////////////////////COMPANY///////////////////////
   getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.allList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL PURCHASE
  getAllPurchase(companyCode) {
    this.loading = true;

    return this._purchaseService.getAllByCompany(companyCode).then(
      response => {
        if (response.result) {
          this.allList = response.records;
          this.temp = this.allList;
          console.log('temp',this.temp);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  checkFunction($event) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    // console.log(this.activeRow.purchaseCode);
    console.log('active',this.activeRow);
    this._purchaseService.getOne(this.activeRow.purchaseCode).then(
      async (response) => {
        if (response.result == true) {
          console.log(response);
          this.purchase = response.records[0];
          this.purchaseDetail = response.records[0].detail;
          this.documentUrl = response.records[0].purchaseFileUrl;
          this.viewPurchase();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  checkFunctionPDF($event) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    // console.log(this.activeRow.purchaseCode);
    console.log('active',this.activeRow);
    this._purchaseService.getOne(this.activeRow.purchaseCode).then(
      async (response) => {
        if (response.result == true) {
          console.log(response);          
          this.purchase = response.records[0];
          this.purchaseDetail = response.records[0].detail;
          this.documentUrl = response.records[0].purchaseFileUrl;
          
          this._printService.purchaseNailHistory(this.purchase, this.company);
          //this.viewPurchase();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  public documentUrl: string = '';
  viewDoc($event) {
    $event.preventDefault();
    window.open(this.documentUrl, 'popup', 'width=600,height=600');
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //  VIEWS
  viewBeginning() {
    this.isViewBeginning = true;
    this.isViewPurchase = false;
    this.cleanModel();
  }

  viewPurchase() {
    this.isViewBeginning = false;
    this.isViewPurchase = true;
  }

  cleanModel() {
    //this.purchase = {} as Purchase;
    this.purchase = [];
    this.purchaseDetail = [];
    this.documentUrl = '';
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-compras.xlsx');
  }
}
