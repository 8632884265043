import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  isLogged: boolean;

  constructor(
    private userService: UserService,
    private router: Router,) {

  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let result = true;
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user') || '{}');
      await this.userService.isUserLogged() ? true : this.takeOutApp();
    } else {
      state.url !== '/userLogin' ? this.sendLogin() : false;
    }
    return result;
  }

  takeOutApp() {
    this.userService.userLogout();
    this.router.navigate(['/userLogin']);
  }

  sendLogin() {
    this.router.navigate(['/userLogin']);
  }
}
