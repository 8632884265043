import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import swal from "sweetalert2";

import { Data } from 'src/app/models/data';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { AccessService } from '../../../services/access.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['../../../app.component.scss'],
  providers: [UtilsService, ProductService, AccessService]
})

export class ProductComponent implements OnInit {
  @ViewChild('formulario') formulario!: NgForm;

  // MODELS VARIABLE 
  public product = {} as Product;
  public data = {} as Data;
  public isEdit: boolean = false;
  public loading: boolean = false;
  public isDisabled: boolean = false;
  public userCode: number;
  public companyCode: number;

  // VARIABLE FOR DATA TABLE
  public temp = [];
  public activeRow: any;
  public entries: number = 10;
  public selected: any[] = [];
  public productList = new Array();
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;

  constructor(
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _productService: ProductService,
  ) { }

  ngOnInit() {
    // GET ID USER & ID COMPANY    
    const _user = JSON.parse(localStorage.getItem('user'));
    this.data.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;
    this.data.companyCode = _user.userCompany ? parseInt(_user.userCompany.toString()) : 0;

    console.log('company', _user.companyCode);
    this.companyCode = _user.companyCode;
    this.product.productCompany = _user.companyCode;

    // LOAD INFORMATION
    this.getAll();
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.productList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL COMPANY PLAN
  getAll() {
    this.loading = true;

    return this._productService.getAll().then(
      async response => {
        if (response.result) {
          this.productList = response.records;

          const _selectedCategory = await this.productList.filter(x => x.productCompany == this.companyCode);
          console.log('producto',this.productList);
          console.log('producto company',_selectedCategory);
          this.temp = _selectedCategory;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  validate(type: number, name: string): boolean {
    switch (type) {
      case 1: return this.formulario?.controls[name]?.errors
        && this.formulario?.controls[name]?.touched;
        break;
        case 2: return this.formulario?.controls[name]?.value < 0;
        break;     
    }
  }
  validate_number(type: number, name: string, name_two?: string): void {
    switch (type) {
      case 1: (this.product[name] < 0) && (this.product[name] = 0);
        break;
      case 2: (this.product[name] < 0 || this.product[name] > this.product[name_two]) && (this.product[name] = 0);;
        break;
    }
  }
  // INSERT
  save() {
    if (this.formulario.valid ) {
      this.isDisabled = true;
      this.loading = true;

      this.product.productStatus = 1;
      this.product.productDeleted = 0;
      this.product.productCompany = this.companyCode.toString();

      console.log('Guardar', this.product);
      this._productService.insert(this.product).then(
        async (response) => {
          if (response.result == true) {
            this.getAll();
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this.formulario.control.markAllAsTouched();
      this._utilsService.showNotification(2, 'Falto llenar campos');
    }
  }

  // UPDATE
  update() {
    if (this.formulario.valid) {
    this.loading = true;
    this.isDisabled = true;

    this._productService.update(this.product).then(
      async (response) => {
        if (response.result == true) {
          this.getAll();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  } else {
    this.formulario.control.markAllAsTouched();
    this._utilsService.showNotification(2, 'Falto llenar campos');
  }
}

  // DELETED
  async deleteFunction($event) {
    $event.preventDefault();

    this.product.productStatus = 0;
    this.product.productDeleted = 1;
    this.product.productCode = this.activeRow.productCode;
    this.product.productName = this.activeRow.productName;

    swal.fire({
      title: "Esta seguro",
      text: "El tipo de empresa se eliminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.product.productStatus = 1;
    this.product.productDeleted = 0;
    this.product.productCode = this.activeRow.productCode;
    this.product.productName = this.activeRow.productName;
  }

  ///////////////////////COMPLEMENT///////////////////////  
  // CLEAR MODAL
  cleanModel(cancel?: boolean) {
    //(!cancel) && this.getAll();
    this.isEdit = false;
    this.isDisabled = false;
    this.formulario.resetForm();
    this.product = {} as Product;
  }
}
