<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/billing">Transferencia</a>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4" style="text-align: end;">
                            <button class="btn btn-success btn-beg" (click)="save()" [disabled]="isDisabled">
                                ✔ Realizar Transferencia
                            </button>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <label>Sucursal</label>
                                <h6>{{storeName}}</h6>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label><b>Tipo Transferencia</b></label>
                            <angular2-multiselect [data]="transferTypeList" [(ngModel)]="selectedItemstransferType"
                                [settings]="dropdownSettingstransferType" (onSelect)="onItemSelectTransferType($event)" (onDeSelect)="onDeSelectTransferType()">
                            </angular2-multiselect>
                        </div>
                        <div class="col-md-2" *ngIf="RefNotaSalida">
                            <label><b>Nota de Salida Ref.</b></label>
                            <input type="number" class="form-control" [(ngModel)]='transferDetail.transferNotaSalida' />
                        </div>
                        <div class="col-md-4" *ngIf="RefNotaSalida">
                            <label><b>Sucursal de Envío</b></label>
                            <angular2-multiselect [data]="storeList" [(ngModel)]="selectedItemsStore"
                                [settings]="dropdownSettingsStores" (onSelect)="onItemSelectStore($event)" (onSelectAll)="onSelectAllStore()"
                                (onDeSelect)="onDeItemSelectStore($event)" (onDeSelectAll)="onDeSelectAllStore()">
                            </angular2-multiselect>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Bodega</label>
                                <div style="color: black !important">
                                    <angular2-multiselect [data]="warehouseList" [(ngModel)]="selectedItemsWarehouse"
                                        [settings]="dropdownSettingsWarehouse"
                                        (onSelect)="onItemSelectWarehouse($event)">
                                    </angular2-multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <textarea class="form-control" rows="12" placeholder="Comentario"
                                [(ngModel)]="transferHeader.transferDescription"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Motivo de Transferencia</label>
                                <select class="form-control form-control-sm" [ngModel]="transferHeader.transferRazon" (ngModelChange)="onChangeRazonTransfer($event)">
                                    <option value=1>Prestamo</option>
                                    <option value=2>Venta</option>
                                    <option value=3>Garantía</option>
                                    <option value=4>Otros</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4" *ngIf="otherRazon">
                            <textarea class="form-control" rows="4" placeholder="Motivo...." [(ngModel)]="transferHeader.transOtherRazonDesc"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-12 col-md-8">
                            <ng-multiselect-dropdown [placeholder]="'Seleccione...'" [settings]="dropdownSettings"
                                [data]="inventoryList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                                (onDeSelect)="onItemDeSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <input type="number" class="form-control"
                                    [(ngModel)]='transferDetail.transferInventoryDetailQuantity'
                                    placeholder="Cantidad" />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <button type="submit" class="btn btn-info btn-sale" (click)="saveLine()"
                                [disabled]="isDisabled" *ngIf="!serial">Agregar</button>
                            <button type="submit" class="btn btn-info btn-sale" (click)="saveLineSerial()"
                                [disabled]="isDisabled" *ngIf="serial">Agregar</button>
                        </div>
                        <div class="col-md-12" *ngIf="serial">
                            <ng-multiselect-dropdown [placeholder]="'Seleccione...'" [settings]="dropdownSettingsSerial"
                                [data]="serialList" [(ngModel)]="selectedItemsSerial"
                                (onSelect)="onItemSelectSerial($event)" (onDeSelect)="onItemDeSelectSerial($event)">
                            </ng-multiselect-dropdown>
                            <br>
                        </div>
                    </div>
                    <!-- DETAIL -->
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr style="font-weight: bold;">
                                        <td>No.</td>
                                        <td>Cantidad</td>
                                        <td>Descripción</td>
                                        <td class="text-center">P/U con IVA Q</td>
                                        <td class="text-center">Total Q</td>
                                        <td class="text-center">Opción</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of transferLine, let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td class="text-center">{{e.transferInventoryDetailQuantity | number:'1.2-2'}}
                                        </td>
                                        <td>{{e.description}}</td>
                                        <td class="text-right">{{e.unitPrice | number:'1.2-2'}}</td>
                                        <td class="text-right">{{e.subtotal | number:'1.2-2'}}</td>
                                        <td class="text-center">
                                            <button class="btn btn-danger btn-sm"
                                                (click)="deleteLine(e.transferInventoryDetailInventoryCode)"
                                                [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                                                <i class="now-ui-icons ui-1_simple-remove"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12"
                            style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                            Total <small style="font-weight: bold; font-size: 20px;">Q</small>
                            {{total | number:'1.2-2'}}
                        </div>
                    </div>
                </div>
                <!-- END DETAIL -->
            </div>
        </div>
    </div>
</div>
