<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/billing">Transferencia</a>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="row">
        <div class="col-sm-12 text-right">
            <button class="btn btn-success btn-sale" routerLink="/transfer-report"
            [disabled]="isDisabled">Reporte de Transferencias</button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-4">
            <div class="card text-center">
                <div class="card-body">
                    <p class="card-text">Pendiente por Bodega</p>
                    <angular2-multiselect [data]="warehouseList" [(ngModel)]="selectedItemsWarehouse"
                    [settings]="dropdownSettingsWarehouse" (onSelect)="onItemSelectWarehouse($event)">
                    </angular2-multiselect>                        
                    <br>
                    <button class="btn btn-info  btn-pnd" style="width: 100%;" (click)="getAllByWarehouse()" [disabled]="isDisabled">Aceptar</button>
                </div>                
            </div>
        </div>
        <div class="col-4">
            <div class="card text-center">
                <div class="card-body">
                    <p class="card-text">Todas las pendiente</p>                           
                    <br>
                    <button class="btn btn-info btn-pnd btn-mag" (click)="getAllByCompany()" [disabled]="isDisabled">Aceptar</button>
                </div>                
            </div>
        </div>
        <div class="col-4">
            <div class="card text-center">
                <div class="card-body">
                    <p class="card-text">Procesadas entre Sucursal</p>                    
                    <br>
                    <button class="btn btn-info btn-pnd btn-mag btn-sale " (click)="getAllComeInStore()" [disabled]="isDisabled">Aceptar</button>
                </div>                
            </div>
        </div>
    </div>
    <!-- PARAMS -->
    <!-- <div class="row justify-content-center" *ngIf="isBeginning">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-7">
                            <label><b>Pendiente por Bodega</b></label>
                            <angular2-multiselect [data]="warehouseList" [(ngModel)]="selectedItemsWarehouse"
                                [settings]="dropdownSettingsWarehouse" (onSelect)="onItemSelectWarehouse($event)">
                            </angular2-multiselect>
                            <br>
                            <button class="btn btn-info  btn-pnd" style="width: 100%;" (click)="getAllByWarehouse()"
                                [disabled]="isDisabled">Aceptar</button>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-12">
                                    <label><b>Todas las pendiente</b></label>
                                    <br>
                                    <button class="btn btn-info btn-pnd" (click)="getAllByCompany()"
                                        [disabled]="isDisabled">Aceptar</button>
                                </div>
                                <div class="col-md-12">
                                    <label><b>Procesadas entre Sucursal</b></label>
                                    <br>
                                    <button class="btn btn-info btn-pnd btn-sale" (click)="getAllComeInStore()"
                                        [disabled]="isDisabled">Aceptar</button>
                                </div>
                            </div>
                        </div>                        
                    </div>                   
                </div>
            </div>
        </div>
    </div> -->

    <!-- TABLE -->
    <div class="row justify-content-center" *ngIf="isProcess">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de Transferencias pendientes de procesar</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                        records"
                    </h6>
                </div>
                <div class="col-md-4">
                    <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                        [disabled]="isDisabled">
                        Descargar Excel
                    </button>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Sucursal"></ngx-datatable-column>
                            <ngx-datatable-column name="Bodega"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Serie"></ngx-datatable-column>
                            <ngx-datatable-column name="Usuario"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewFunction($event)" ngbTooltip="Ver"><i class="fas fa-eye"></i>
                                    </a>
                                    <!-- Modal -->
                                    <ng-template #content let-modal>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-10 text-center">
                                                    <h6>Trasladar a Sucursal:</h6>
                                                </div>
                                                <div class="col-2 text-left">
                                                    <a class="btn btn-round btn-warning btn-icon btn-sm remove btn-erp"
                                                        (click)="modal.dismiss('Cross click')"><i
                                                            class="fas fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <angular2-multiselect [data]="storeList"
                                                            [(ngModel)]="selectedItemsStore"
                                                            [settings]="dropdownSettingsStore"
                                                            (onSelect)="onItemSelectStore($event)">
                                                        </angular2-multiselect>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center">
                                                    <button type="button" class="btn btn-success"
                                                        (click)="modal.close('Save click')">Aceptar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <a class="btn btn-round btn-success btn-icon btn-sm"
                                        ngbTooltip="Transferir a Sucursal" (click)="open(content)"><i
                                            class="fas fa-random"></i>
                                    </a>
                                    <!-- Fin Modal -->
                                    <!-- <a class="btn btn-round btn-gray btn-icon btn-sm"
                                        ngbTooltip="No es posible Facturar" *ngIf="row.clientTaxDocument == null"><i
                                            class="fas fa-sticky-note"></i>
                                    </a>
                                    <a class="btn btn-round btn-warning btn-icon btn-sm" (click)="alertBilling($event)"
                                        ngbTooltip="Facturar" *ngIf="row.clientTaxDocument != null"><i
                                            class="fas fa-sticky-note"></i>
                                    </a> -->
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="alertCancel($event)" ngbTooltip="Cancelar" placement="left"><i
                                            class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->

    <!-- TABLE PROCESS -->
    <div class="row justify-content-center" *ngIf="isFinish">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de Transferencias finalizadas</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                        records"</h6>
                </div>
                <div class="col-md-4">
                    <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                        [disabled]="isDisabled">
                        Descargar Excel
                    </button>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Salio"></ngx-datatable-column>
                            <ngx-datatable-column name="Entro"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Serie"></ngx-datatable-column>
                            <ngx-datatable-column name="Usuario"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewFunction($event)" ngbTooltip="Ver"><i class="fas fa-eye"></i>
                                    </a>   
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>