import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// import { NgbCalendar, NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { User } from 'src/app/models/user';
import { Client } from 'src/app/models/client';
import { InvoiceHeader } from 'src/app/models/invoiceHeader';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { ClientService } from 'src/app/services/client.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Store } from 'src/app/models/store';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { StoreService } from 'src/app/services/store.service';

// Interface para información de exportación RT
interface ExportInfo {
  consigneeCode;
  consigneeName;
  consigneeAddress;
  incotermCode;
  buyerCode;
  buyerName;
  buyerAddress;
  otherReferences;
  exporterCode;
  exporterName;
}
@Component({
  selector: 'app-description-export-invoice',
  templateUrl: './description-export-invoice.component.html',
  styleUrls: ['./description-export-invoice.component.scss'],
  providers: [ClientService, InvoiceService, StoreService, CompanyService],
})
export class DescriptionExportInvoiceComponent implements OnInit {
  // Variables auxiliares
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  exportInfo = {} as ExportInfo;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  public nowDay: string;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  loading;

  public isBeginning: boolean;
  public isBilling: boolean;
  public isDisabled: boolean;
  public recurrentClient: boolean;
  public insertNewClient: boolean = false;

  // Variables de Linea de Compra
  public itemCode: string;
  public itemNumberCode: number;
  public moneda: string;
  public invoiceLine: any = new Array();
  public addAmount: boolean;

  public disabled = false;
  public disabledEXT = false;
  public facturando: boolean;

  constructor(
    private toastr: ToastrService,
    private _clientService: ClientService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
    private _storeService: StoreService
  ) {}

  async ngOnInit() {
    this.addAmount = true;
    this.isDisabled = false;
    this.insertNewClient = false;
    this.moneda = 'Q';
    this.itemCode = 'I';
    this.itemNumberCode = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceDetail.invoiceDetailQuantity = 1;

    // DATE
    let day: any;
    let mount: any;
    let date: Date = new Date();

    date.getDate() < 9 ? (day = '0' + date.getDate()) : (day = date.getDate());
    date.getMonth() < 9 ? (mount = '0' + (date.getMonth() + 1)) : (mount = date.getMonth() + 1);
    let year = date.getFullYear();
    this.invoiceHeader.invoiceDate = year + '-' + mount + '-' + day; 
    this.nowDay = this.invoiceHeader.invoiceDate;

    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    this.isBilling = true;
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
    this.buscarNITLocal('CF');

    this.client.clientType = 0;
    this.disabledEXT = true;
  }

  public taxToPay: number = 0;
  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      (response) => {
        if (response.result) {
          this.company = response.records[0];
          this.company.companyTaxToPay == 12 ? this.taxToPay = 1.12 : this.taxToPay = 1.05;
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      (response) => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  onChange(event) {
    this.invoiceHeader.invoiceCoin = event;

    if (this.invoiceHeader.invoiceCoin == 0) {
      this.moneda = 'Q';
      this.invoiceHeader.invoiceExchangeRate = null;
    } else if (this.invoiceHeader.invoiceCoin == 1) {
      this.moneda = '$';
    }
  }

  ///////////////////////FUNCTION VALIDATE DATE///////////////////////
  validateDate($event) {
    $event.preventDefault();

    var nowDate = this.nowDay.split('-');
    var date = this.invoiceHeader.invoiceDate.split('-');

    if (
      date[0] == nowDate[0] &&
      date[1] == nowDate[1] &&
      parseFloat(date[2]) <= parseFloat(nowDate[2]) + 5 &&
      parseFloat(date[2]) >= parseFloat(nowDate[2]) - 5
    ) {
    } else {
      this.invoiceHeader.invoiceDate = this.nowDay;
      this.showNotification(2, 'Fecha fuera de rango');
    }
  }

  ///////////////////////FUNCTION CHANGE INTERAL SERIAL///////////////////////
  onChangeInternSerial(event) {
    this.invoiceHeader.invoiceInternSerial = event;
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isBilling = false;
    this.client.clientType = 0;
  }

  viewBilling() {
    this.isBeginning = false;
    this.isBilling = true;
  }

  // CALCULAR IMPUESTO DE LINEA
  calculateTax() {
    let subTotalLine;

    subTotalLine = this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice;

    if (this.invoiceDetail.invoiceDetailDiscount > 0) {
      if (this.invoiceDetail.invoiceDetailDiscount <= subTotalLine) {
        subTotalLine = subTotalLine - this.invoiceDetail.invoiceDetailDiscount;
      } else {
        this.showNotification(2, 'Cantidad No permitida');
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }
    }

    let tax = (subTotalLine - subTotalLine /this.taxToPay).toFixed(2);
    this.invoiceDetail.invoiceDetailTax = parseFloat(tax);
  }

  viewCF() {
    this.isBeginning = true;
    this.isBilling = true;
    this.disabled = true;
  }

  ValidarCF() {
    /*J11 - Validar CF*/
    console.log(this.invoiceHeader.invoiceTotal);
    console.log(this.client.clientTaxDocument);

    if(this.invoiceHeader.invoiceTotal >= 2500){
      if(this.client.clientTaxDocument == "CF"){
        this.showNotification(5, "No puede facturar como CF, el monto es mayor a 2500");
        this.viewCF();
      }
      else{
        this.disabled = false;
      }
    }
  }

  // GUARDAR UNA LINEA DE COMPRA
  saveLineInvoice() {
    if (
      this.invoiceDetail.invoiceDetailDescription != '' &&
      this.invoiceDetail.invoiceDetailQuantity > 0 &&
      this.invoiceDetail.invoiceDetailUnitPrice > 0
    ) {
      //Aumentar Codigo si no viene uno
      if (this.itemCode == 'I') {
        this.itemNumberCode++;
        this.itemCode += this.itemNumberCode;
      }

      //Calcular subtotal de linea
      this.invoiceDetail.invoiceDetailSubtotal =
        this.invoiceDetail.invoiceDetailQuantity *
        this.invoiceDetail.invoiceDetailUnitPrice;

      if (
        this.invoiceDetail.invoiceDetailDiscount != undefined &&
        this.invoiceDetail.invoiceDetailDiscount != null &&
        this.invoiceDetail.invoiceDetailDiscount > 0
      ) {
        this.invoiceDetail.invoiceDetailSubtotal =
          this.invoiceDetail.invoiceDetailSubtotal -
          this.invoiceDetail.invoiceDetailDiscount;
        this.invoiceDetail.invoiceDetailTax =
          this.invoiceDetail.invoiceDetailSubtotal -
          this.invoiceDetail.invoiceDetailSubtotal /this.taxToPay;
      } else {
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }

      //Actualizar
      this.invoiceLine.forEach((value) => {
        if (value.itemCode == this.itemCode) {
          this.invoiceHeader.invoiceTotal -= value.total;

          value.itemCode = this.itemCode;
          value.measure = 'UNI';
          value.taxCode = 1;
          value.taxName = 'IVA';
          value.isService = this.invoiceDetail.invoiceDetailType;
          value.quantity = parseFloat(
            this.invoiceDetail.invoiceDetailQuantity.toFixed(2)
          );
          value.description = this.invoiceDetail.invoiceDetailDescription;
          value.unitPrice = parseFloat(
            this.invoiceDetail.invoiceDetailUnitPrice.toFixed(2)
          );
          value.price = parseFloat(
            this.invoiceDetail.invoiceDetailSubtotal.toFixed(2)
          );
          value.discount = parseFloat(
            this.invoiceDetail.invoiceDetailDiscount.toFixed(2)
          );
          value.taxAmount = parseFloat(
            this.invoiceDetail.invoiceDetailTax.toFixed(2)
          );
          value.taxableAmount = parseFloat(
            (
              this.invoiceDetail.invoiceDetailSubtotal -
              this.invoiceDetail.invoiceDetailTax
            ).toFixed(2)
          );
          value.total = parseFloat(
            this.invoiceDetail.invoiceDetailSubtotal.toFixed(2)
          );

          // this.total = this.total + this.subTotal;
          this.addAmount = false;
        }
      });

      //Insertar
      if (this.addAmount == true) {
        this.invoiceLine.push({
          itemCode: this.itemCode,
          measure: 'UNI',
          taxCode: 1,
          taxName: 'IVA',
          isService: this.invoiceDetail.invoiceDetailType,
          quantity: parseFloat(
            this.invoiceDetail.invoiceDetailQuantity.toFixed(2)
          ),
          description: this.invoiceDetail.invoiceDetailDescription,
          unitPrice: parseFloat(
            this.invoiceDetail.invoiceDetailUnitPrice.toFixed(2)
          ),
          price: parseFloat(
            this.invoiceDetail.invoiceDetailSubtotal.toFixed(2)
          ),
          discount: parseFloat(
            this.invoiceDetail.invoiceDetailDiscount.toFixed(2)
          ),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(2)),
          taxableAmount: parseFloat(
            (
              this.invoiceDetail.invoiceDetailSubtotal -
              this.invoiceDetail.invoiceDetailTax
            ).toFixed(2)
          ),
          total: parseFloat(
            this.invoiceDetail.invoiceDetailSubtotal.toFixed(2)
          ),
        });
      }
      // console.log(this.invoiceLine);
      // Sumar Total
      this.invoiceHeader.invoiceTotal +=
        this.invoiceDetail.invoiceDetailSubtotal;
      this.invoiceHeader.invoiceTax += this.invoiceDetail.invoiceDetailTax;
      this.cleanLineInvoice();
    }

    this.ValidarCF();

    this.isDisabled = false;
  }

  // APLICAR DESCUENTO EN TIEMPO REAL
  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (parseFloat(descuento) <= temporal) {
          this.invoiceHeader.invoiceTotal =
            this.invoiceHeader.invoiceTotal - parseFloat(e.total);

          e.price = temporal - parseFloat(descuento);
          e.total = temporal - parseFloat(descuento);
          e.taxAmount = parseFloat(
            (parseFloat(e.price) - parseFloat(e.price) /this.taxToPay).toFixed(2)
          );
          e.taxableAmount = parseFloat(
            (parseFloat(e.price) - parseFloat(e.taxAmount)).toFixed(2)
          );

          this.invoiceHeader.invoiceTotal =
            this.invoiceHeader.invoiceTotal + parseFloat(e.total);
        } else {
          this.showNotification(2, 'Cantidad No permitida');
        }
      }
    });
  }

  // CARGAR DATOS A EDITAR
  editLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        this.itemCode = e.itemCode;
        this.invoiceDetail.invoiceDetailType = e.isService;
        this.invoiceDetail.invoiceDetailDescription = e.description;
        this.invoiceDetail.invoiceDetailQuantity = e.quantity;
        this.invoiceDetail.invoiceDetailUnitPrice = e.unitPrice;
        this.invoiceDetail.invoiceDetailSubtotal = e.price;
        this.invoiceDetail.invoiceDetailDiscount = e.discount;
        this.invoiceDetail.invoiceDetailTax = e.taxAmount;
      }
    });
    this.isDisabled = false;
  }

  // ELIMINAR UNA LINEA
  deleteLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e, index) => {
      if (e.itemCode == item) {
        this.invoiceHeader.invoiceTotal =
          this.invoiceHeader.invoiceTotal - parseFloat(e.price);
        this.invoiceLine.splice(index, 1);
      }
    });
    this.isDisabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.addAmount = true;

    this.itemCode = 'I';
    this.invoiceDetail.invoiceDetailDescription = null;
    this.invoiceDetail.invoiceDetailDiscount = null;
    this.invoiceDetail.invoiceDetailQuantity = null;
    this.invoiceDetail.invoiceDetailUnitPrice = null;
    this.invoiceDetail.invoiceDetailTax = null;
  }

  onChangeTypeClient(event) {    
    this.client.clientType = event;

    if(event == 2){
      this.disabledEXT = false;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
    else{
      this.disabledEXT = true;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
  }

  ///////////////////////Funcion de Buscar por NIT///////////////////////
  buscarNITLocal(nit) {
    //  $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    return this._clientService
      .getOneByNit(nit)
      .then(
        // return this._clientService.getOneByCompanyNit(this.companyCode, nit).then(
        (response) => {
          if (response.records.length > 0) {
            this.client = response.records[0];

            this.recurrentClient = true;
            this.insertNewClient = false;
            this.viewBilling();
            this.showNotification(1, response.message);
          } else {
            return this._clientService.searchNitSAT(nit,0).then(
              (response) => {
                if (response.tipo_respuesta == 0) {
                  this.client.clientName = response.nombre[0];
                  this.client.clientStatus = 1;
                  this.client.clientDeleted = 0;
                  this.client.clientPhone = '00000000';
                  this.client.clientEmail = 'info@clever.com';
                  this.client.clientAddress = 'CIUDAD';

                  this.insertNewClient = true;
                  this.recurrentClient = false;
                  this.viewBilling();
                  this.showNotification(4, 'Cliente Nuevo');
                } else {
                  this.showNotification(
                    3,
                    response.listado_errores[0].error[0].desc_error[0]
                  );
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
  }

  async insertInvoice() {
    this.loading = true;
    this.isDisabled = true;

    //TEMPORAL DOCUMENTO QUEMADO
    // setTimeout(() => {
    //   window.open('https://ima-design-images.s3.us-east-1.amazonaws.com/DOCUMENTOS_TRIBUTARIOS/EXPORTACION.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECAaCXVzLWVhc3QtMiJGMEQCIAqxH7GAv%2Bfze2AYTreP6kuP4ZFiWN7bvE%2FUDA7zUgtwAiAN9VJF1LxV7QbGAPSQpHlw1WC8ht9F2myH9Ph5pngBIyqWAwja%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAEaDDA2MDc2MzQ5MjE2NyIMRDaOoYFad4%2BwO3FRKuoCDkL%2Fh4Am6%2BwzP%2F46V8bnOwXOhOa19gfdmmhW%2B2BAFHMKdsrccl48k0tzi3Pdm6GupnMNv29Uu63lTOtHvwbUaduxlrf8tQZKXzJlssuSumlxdp6c6p%2Bc8PZj4GXTrZSz2s90xKxpCaosVmQBwztzElFNmc5hH9AZADHs2dvOLx%2FP9%2BIyq5iQlKSzj8PSRnLZPftCU2mW%2Fm3UstD3NnqVvESdIeinKOih1czOGETxpof%2FwTAMntWVnMt6QFQij3DgnYDJscjaC2zHSqbmwmvQEGCIQUNHZaObIE%2FUUo3zSLA2M34OoU%2Fh%2BrGS2GWp9BJveuO97jJhFlgGOmZ%2BH3iS28H%2FTt32tUCa4dy6DOETOQM5Tl7gsczP52HRrxSYROrMGdy1Hpk2d%2Bie3wZQTJpx%2FGLQ4sSvfGy%2B%2BeeyHe7BUNqX3hliMdm2lO5HMB3ZeW5kkVnYLBp6jYvY1mjWpwsXLJ4apRKhKBFmOS4wuKLfjAY6tAIudfrMP9GNhRk1LLgr1ceXXE0xCWQtkXCL5Tq4ffGqAUFu76N8feutiJZIpCsfvKfhsMPuLyPZVnhncooFR1eYCZnRFi4Vus1Gavyxhke6cdioeE96UT14Juu8U7f4CJ9cgXGO4k3OohvykFMqP1iy8MGqLmBq89182t8pfMaLBBWbKT0fn4Vyt24tcG%2F%2BYr0Q6AV5o5kXnGXFns%2BuqtHXUCUGwUxmWdPwCuD%2FAZMx71USR6fp2pDr7G2tTLQYaXNhY6pVohIYLjK6lAtPm3YsVoY0BUHGVKNyPrJGDAcnPH2dyu3zn7eLVDUVsy8gtbfxQyobB4NZTVE2hMFqfNMqKnT%2BT2ySIQZUapHosN8AF%2FrK9D02NxpQeknSDK4byNbXg5%2BTjzMnDeLfXXrRxmHVeXhwwg%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211119T170333Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAQ4JOJJ5DVE6EG6W6%2F20211119%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=60ee109021bfd0ab665fc1320594133bfd5cffdb1001980a446640d1bb03d0ba', 'popup', 'width=600');
    //   this.loading = false;
    //   this.cleanModel();
    //   this.buscarNITLocal('CF');
    // }, 5000);

    // Pequeño Contribuyente
    let transmitterType = "FEXP"; //FEXP?
    let transmitterMembership = "GEN";
    this.company.companyTaxToPay === 5 ? transmitterMembership = "PEQ" : '';


    this.invoiceHeader.invoiceTax = 
      (this.invoiceHeader.invoiceTotal /this.taxToPay) * (this.taxToPay - 1);

    this.invoiceLine.forEach((e) => {
      e.itemCode = 0;
    });

    const json = {
      store: {
        storeCode: this.storeCode,
      },
      user: {
        userCode: this.userCode,
      },
      transmitter: {
        transmitterMembership: transmitterMembership,
        transmitterType: transmitterType,
        transmitterEstablishmentCode: this.store.storeEstablishmentCode,
        transmitterEmail: this.company.companyEmail,
        transmitterTaxDocument: this.company.companyTaxDocument,
        transmitterComercialName: this.store.storeName,
        transmitterName: this.company.companyName,
        transmitterAddress: this.store.storeAddress,
        transmitterZipCode: this.store.storeZipCode,
        transmitterMunicipality: this.store.storeMunicipality,
        transmitterDepartment: this.store.storeDepartment,
        transmitterCountry: 'GT',
        transmitterPrefix: this.company.companyPrefix,
        transmitterUser: this.company.companyPrefix,
        transmitterKey: this.company.companyKey,
        transmitterToken: this.company.companyToken,
        transmitterPhraseCode: parseFloat(this.company.companyPhraseCode),
        transmitterPhraseType: parseFloat(this.company.companyPhraseType),
      },
      receiver: {
        receiverAddress: this.client.clientAddress,
        receiverCode: this.client.clientCode,
        receiverDeleted: 0,
        receiverEmail: this.client.clientEmail,
        receiverName: this.client.clientName,
        receiverStatus: 1,
        receiverTaxDocument: this.client.clientTaxDocument,
        receiverTypeTaxDocument: this.client.clientType,
        receiverZipCode: 0,
        receiverCountry: 'GT',
      },
      invoice: {
        invoiceCode: 1,
        invoiceCurrency: 'GTQ',
        invoiceTaxShortName: 'IVA',
        invoiceDate: this.invoiceHeader.invoiceDate, //"2021-11-01",
        invoiceInternSerial: this.invoiceHeader.invoiceInternSerial,
        invoiceTaxValue: parseFloat(this.invoiceHeader.invoiceTax.toFixed(2)),
        invoiceTotal: parseFloat(this.invoiceHeader.invoiceTotal.toFixed(2)),
      },
      detail: this.invoiceLine,
      provider: this.company.companyCertifier == 0 ? 'infile' : 'megaprint',
    };

    // console.log(json);
    // Insertar Venta
    if (this.company.companyCertifier == 0) {
      this._invoiceService.insertBilling(json).then(
        async (response) => {
          if (response.result) {
            console.log('infile', response);
            let uuid = response.invoiceAuthorization;
            window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');

            this.cleanModel();
            this.viewBeginning();
            this.showNotification(1, response.message);
          } else {
            this.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    }
    else if (this.company.companyCertifier == 1) {
      this._invoiceService.insertBilling(json).then(
        async (response) => {
          if (response.result) {
            var pdfResult = response.invoicePdf;

            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")

            this.cleanModel();
            this.viewBeginning();
            this.showNotification(1, response.message);
          } else {
            this.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    }

    this.insertNewClient = false;
  }

  // Limpiar Campos
  cleanModel() {
    this.itemCode = 'I';
    this.itemNumberCode = 0;

    this.isDisabled = false;
    this.insertNewClient = false;
    this.recurrentClient = false;
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceLine = [];
    this.moneda = 'Q';
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.invoiceDate = this.nowDay;

    this.addAmount = true;
  }

  //Mostrar Notificacion
  showNotification(type, message) {
    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' +
            message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-success alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 4:
        this.toastr.info(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Info</b> - ' +
            message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-info alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' +
            message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-warning alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' +
            message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-danger alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
    }
  }
}
