<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/payment">Cuentas por Pagar</a>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <!-- EDIT PURCHASE -->
                <div class="col-md-12" *ngIf="isViewPurchaseDueQ">
                    <div class="card">
                        <div class="card-body">                            
                            <div class="row">
                                <div class="col-11 font-weight-bold">
                                    Dividir en Pagos
                                </div>
                                <div class="col-1 text-right">
                                    <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                        (click)="viewBeginning($event)"><i class="fas fa-times"></i>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <label>Compra No.</label>
                                    <p>{{ payRegister.payRegisterPurchaseCode}} </p>
                                </div>
                                <div class="col-md-3">
                                    <label>Monto</label>
                                    <p>Q {{ payRegister.payRegisterAmount | number:'1.2-2' }} </p>
                                </div>
                                <div class="col-md-3">
                                    <label>No. Pagos {{purchase.purchaseDuesQuantity}}</label>
                                    <input type="number" class="form-control form-control-sm"
                                        [(ngModel)]="purchase.purchaseDuesQuantity" [disabled]="disabledDuesQ"/>
                                </div>
                                <div class="col-md-2 text-center">
                                    <label>&nbsp;</label>
                                    <button type="submit" class="btn btn-info btn-sm" (click)="updatePurchase()"
                                        style="padding-left: 2.5rem; padding-right: 2.5rem;">
                                        DIVIDIR EN PAGO ✔ </button>
                                </div>
                                <div class="col-md-12" *ngIf="!disabledDuesQ">
                                    <label>Comentario</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="purchase.purchaseComment" />
                                </div>  
                            </div>                            
                        </div>
                    </div>
                </div>

                <!-- NEW REGISTER -->
                <div class="col-md-12" *ngIf="isViewNewPayRegister">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-11 font-weight-bold">
                                    Registrar Pago
                                    <span *ngIf="cash"> de Contado</span>
                                </div>
                                <div class="col-1 text-right">
                                    <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                        (click)="viewBeginning($event)"><i class="fas fa-times"></i>
                                    </a>
                                </div>
                                <div class="col-md-3">
                                    <label>Compra No.</label>
                                    <p>{{ payRegister.payRegisterPurchaseCode }} </p>
                                </div>
                                <div class="col-md-3">
                                    <label>Monto</label>
                                    <input type="number" class="form-control form-control-sm"
                                        [(ngModel)]="payRegister.payRegisterAmount" />
                                </div>
                                <div class="col-md-3">
                                    <label>Método de pago</label>
                                    <select class="form-control form-control-sm"
                                        [(ngModel)]="payRegister.payRegisterMethodCode">
                                        <option *ngFor="let i of paymentMethodList"
                                            [selected]="i.paymentMethodCode == 2" [value]=i.paymentMethodCode>
                                            {{i.paymentMethodName}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label>No. Documento</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="payRegister.payRegisterDocument" />
                                </div>
                                <div class="col-md-10">
                                    <label>Descripción</label>
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="payRegister.payRegisterDescription" />
                                </div>
                                <div class="col-md-2 text-left">
                                    <button type="submit" class="btn btn-info btn-sm" (click)="saveRegister()"
                                        style="padding-left: 2.5rem; padding-right: 2.5rem;">
                                        REGISTRAR PAGO ✔ </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- VIEW REGISTER -->
                <div class="col-md-12" *ngIf="isViewPayRegister">
                    <div class="card">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-11 font-weight-bold">
                                    Registros de Pago
                                </div>
                                <div class="col-1 text-right">
                                    <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                        (click)="viewBeginning($event)"><i class="fas fa-times"></i>
                                    </a>
                                </div>
                                <div class="col-4 text-right">
                                    <a class="btn btn-warning btn-block btn-gen" role="button" (click)="printHistory($event,invoiceCodeThis)"><i class="now-ui-icons files_paper"></i> Imprimir</a>
                                </div>
                                <div class="col-sm-11">
                                    <div class="row payRegisterList" *ngFor="let e of payRegister, let i=index">
                                        <div class="col-md-3 itemBlock">
                                            <b class="text-info">Pago {{i+1}} de {{payRegister.length}}</b>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <label>Compra</label>
                                            <p>{{ e.payRegisterPurchaseCode }}</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <label>Fecha</label>
                                            <p>{{ e.payRegisterDate | date:'dd-MM-yyyy'}}</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <label>Monto</label>
                                            <p>Q {{ e.payRegisterAmount | number:'1.2-2' }} </p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <label>Método de pago</label>
                                            <p>{{ e.paymentMethodName }}</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <label>No. Documento</label>
                                            <p>{{ e.payRegisterDocument }}</p>
                                        </div>
                                        <div class="col-md-9 itemBlock">
                                            <label>Descripción</label>
                                            <p>{{ e.payRegisterDescription }}</p>
                                        </div>
                                    </div>
                                    <b class="text-info">Total Pagos Q.{{totalPayment}} </b><br>
                                    <b class="text-info">Saldo Q.{{saldox}} </b><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- TABLE -->
                <div class="col-md-12" *ngIf="isViewBeginning">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-8">
                                  <h4 class="card-title">Listado de cuentas por pagar</h4>
                                  <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                                  </h6>
                                </div>
                                <div class="col-md-4">
                                  <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                    [disabled]="isDisabled">
                                    Descargar Excel
                                  </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="dataTables_wrapper">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="datatable_length">
                                            <label>
                                                Show
                                                <select name="datatable_length" aria-controls="datatable"
                                                    class="form-control form-control-sm"
                                                    (change)="entriesChange($event)">
                                                    <option value="10" [selected]="entries==10">10</option>
                                                    <option value="25" [selected]="entries==25">25</option>
                                                    <option value="50" [selected]="entries==50">50</option>
                                                    <option value="-1" [selected]="entries==-1">All</option>
                                                </select>
                                                entries
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="datatable_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm"
                                                    placeholder="Search records" aria-controls="datatable"
                                                    (keyup)="filterTable($event)" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'"
                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                    [limit]="entries != -1 ? entries:undefined" (activate)="onActivate($event)"
                                    [rows]="temp">
                                    <ngx-datatable-column name="Fecha" prop="purchaseCreatedDate"></ngx-datatable-column>
                                    <ngx-datatable-column name="No." prop="purchaseCode"></ngx-datatable-column>
                                    <ngx-datatable-column name="Factura No." prop="purchaseInvoice"></ngx-datatable-column>
                                    <ngx-datatable-column name="Factura Serie" prop="purchaseInvoiceSeries"></ngx-datatable-column>
                                    <ngx-datatable-column name="Estado" prop="estado"></ngx-datatable-column>
                                    <ngx-datatable-column name="Total"></ngx-datatable-column>
                                    <ngx-datatable-column name="Pagado"></ngx-datatable-column>
                                    <ngx-datatable-column name="Saldo"></ngx-datatable-column>
                                    <ngx-datatable-column name="Cuotas"></ngx-datatable-column>
                                    <ngx-datatable-column name="Pagadas"></ngx-datatable-column>
                                    <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                        [resizeable]="false">
                                        <ng-template ngx-datatable-header-template let-value="value"
                                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                            Acciones
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                            <a href="#" class="btn btn-round btn-warning btn-icon btn-sm like"
                                                (click)="loadData($event, 1)" ngbTooltip="Fraccionar en Pagos" [disabled]="row.cancelledpurchase == 1" *ngIf="row.cuotas == 0 || row.cuotas == null">
                                                <i class="fas fa-cubes"></i>
                                            </a>
                                            <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                                (click)="loadData($event, 2)" ngbTooltip="Registrar Pago" *ngIf="row.saldo != 0"><i class="now-ui-icons files_paper"></i>
                                            </a>                                           
                                            <a href="#" class="btn btn-round btn-info btn-icon btn-sm remove"
                                                (click)="getPayRegister($event)" ngbTooltip="Ver Registros"
                                                placement="left" [disabled]="cancelledpurchase == 1">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>