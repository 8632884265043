<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home&nbsp;/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/balance-sheet">&nbsp;Balance</a>
        </div>
    </div>
</div>
<div class="main-content">
    <!-- PARAMS -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Cierre contable con fecha:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="date" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <br>
                            <div class="form-group">
                                <button class="btn btn-info btn-sale" style="width: 100%;"
                                    (click)="getBalanceSheet()">Aceptar</button>
                            </div>
                        </div>
                        <div class="col-md-4 border-left" style="border-left: 1px solid #ccc;">
                            <br>
                            <div class="form-group">
                                <button class="btn btn-info btn-pnd" (click)="getpendingBS()">Pendientes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- NEW REGISTER -->
    <div class="row" *ngIf="isViewNewRegister">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-11 font-weight-bold">
                            Nuevo Registro
                        </div>
                        <div class="col-1 text-right">
                            <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                (click)="viewBeginning($event)"><i class="fas fa-times"></i>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <label>Fecha</label>
                            <input type="date" class="form-control form-control-sm"
                                [(ngModel)]="balanceSheetRegister.balanceSheetRegisterDate" />
                        </div>
                        <div class="col-md-3">
                            <label>Método de pago</label>
                            <select class="form-control form-control-sm"
                                [(ngModel)]="balanceSheetRegister.balanceSheetRegisterType">
                                <option *ngFor="let i of paymentMethodList" [value]=i.paymentMethodCode>
                                    {{i.paymentMethodName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Monto en Sistema</label>
                            <input type="number" class="form-control form-control-sm"
                                [(ngModel)]="balanceSheetRegister.balanceSheetRegisterTotal" />
                        </div>
                        <div class="col-md-3">
                            <label>Monto a ingresar</label>
                            <input type="number" class="form-control form-control-sm"
                                [(ngModel)]="balanceSheetRegister.balanceSheetRegisterAmount" />
                        </div>
                        <div class="col-md-10">
                            <label>Descripción</label>
                            <input type="text" class="form-control form-control-sm"
                                [(ngModel)]="balanceSheetRegister.balanceSheetRegisterDescription" />
                        </div>
                        <div class="col-md-2 text-left">
                            <button type="submit" class="btn btn-info btn-sm" (click)="saveRegister()"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;">
                                ✔ </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- UPDATE REGISTER -->
    <div class="row" *ngIf="isViewUpdateRegister">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-11 font-weight-bold">
                            Actualizar Registro
                        </div>
                        <div class="col-1 text-right">
                            <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                (click)="viewBeginning($event)"><i class="fas fa-times"></i>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <label>Fecha Origen</label>
                            <p>{{ balanceSheetRegister.balanceSheetRegisterDate }}</p>
                        </div>
                        <div class="col-md-3">
                            <label>Método</label>
                            <p>{{balanceSheetRegister.balanceSheetRegisterTypeShow}}</p>
                        </div>
                        <div class="col-md-3">
                            <label>Monto en Sistema</label>
                            <p>Q {{ balanceSheetRegister.balanceSheetRegisterTotal }}</p>
                        </div>
                        <div class="col-md-3">
                            <label>Diferencia</label>
                            <p>Q {{ balanceSheetRegister.balanceSheetRegisterDiffAmount }}</p>
                        </div>
                        <div class="col-md-3">
                            <label>Monto</label>
                            <input type="number" class="form-control form-control-sm"
                                [(ngModel)]="balanceSheetRegister.balanceSheetRegisterAmount" />
                        </div>
                        <div class="col-md-7">
                            <label>Descripción</label>
                            <input type="text" class="form-control form-control-sm"
                                [(ngModel)]="balanceSheetRegister.balanceSheetRegisterDiffComment" />
                        </div>
                        <div class="col-md-2 text-left">
                            <button type="submit" class="btn btn-info btn-sm" (click)="updateRegister()"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;">
                                ✔ </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TALBE REGISTER-->
    <div class="row" *ngIf="isViewRegister">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <!-- DAY -->
                            <div class="col-sm-3">
                                <h5 class="card-title">En sistema</h5>
                                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'"
                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [rows]="tempBSPa">
                                    <ngx-datatable-column name="Metodo"></ngx-datatable-column>
                                    <ngx-datatable-column name="Total"></ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                            <!-- REGISTER -->
                            <div class="col-sm-9">
                                <h5 class="card-title">
                                    <div class="row">
                                        <div class="col-4">Ingresados</div>
                                        <div class="col-4">
                                            <button class="btn btn-success btn-nr btn-sale" (click)="downloadToExcelDate()"
                                              [disabled]="isDisabled">
                                              Descargar Excel
                                            </button>
                                        </div>
                                        <div class="col-3 text-right">
                                            <button class="btn-nr" type="submit" (click)="loadData($event, 1)">Nuevo
                                                Registro</button>
                                        </div>
                                    </div>
                                </h5>
                                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'"
                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                    [limit]="entries != -1 ? entries : undefined" [rows]="temp"
                                    (activate)="onActivate($event)">
                                    <ngx-datatable-column name="Cuenta"></ngx-datatable-column>
                                    <ngx-datatable-column name="Monto"></ngx-datatable-column>
                                    <ngx-datatable-column name="Diferencia"></ngx-datatable-column>
                                    <ngx-datatable-column name="Descripcion"></ngx-datatable-column>
                                    <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                        [resizeable]="false">
                                        <ng-template ngx-datatable-header-template let-value="value"
                                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                            Acciones
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">                                            
                                            <a class="font-weight-bold text-info" *ngIf="row.diferencia != 0"
                                                (click)="loadData($event, 2)">Actualizar</a>
                                            <a class="font-weight-bold text-gray" *ngIf="row.diferencia == 0">
                                                Actualizar</a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TALBE PENDING -->
    <div class="row" *ngIf="isViewPending">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-8">
                            <h4 class="card-title">Listado de cuentas por cobrar</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                                records"</h6>
                        </div>
                        <div class="col-4 text-right">
                            <button class="btn-nr" type="submit" (click)="loadData($event, 1)">Nuevo
                                Registro</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcelDif()"
                                  [disabled]="isDisabled">
                                  Descargar Excel
                                </button>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            (activate)="onActivate($event)" [rows]="temp">
                            <ngx-datatable-column name="Cuenta"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Total"></ngx-datatable-column>
                            <ngx-datatable-column name="Monto"></ngx-datatable-column>
                            <ngx-datatable-column name="Diferencia"></ngx-datatable-column>
                            <ngx-datatable-column name="Descripcion"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                                    let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a class="font-weight-bold text-info" (click)="loadData($event, 2)">Actualizar</a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>