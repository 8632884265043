import { Component, OnInit } from '@angular/core';
import { AccountingService } from 'src/app/services/accounting.service';
import { UtilsService } from 'src/app/services/utils.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-retentions-report',
  templateUrl: './retentions-report.component.html',
  styleUrls: ['./retentions-report.component.scss'],
  providers: [AccountingService, UtilsService]
})
export class RetentionsReportComponent implements OnInit {
  loading;
  public isBeginning: boolean;
  public isTable: boolean;
  public isDisabled: boolean;
  public begDate: string;
  public endDate: string;
  public storeCode: number;
  public storeName: string;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      serie: {
        title: 'Serie Factura Fel'
      },
      numero: {
        title: 'No. Factura Fel'
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      cuenta: {
        title: 'Cuenta',
      },
      documento: {
        title: 'Documento',
      },
      monto: {
        title: 'Monto retencion',
        type: 'number'
      },
    }
  };

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp = new Array();
  public retentionsList: any[] = new Array();

  constructor(
    private _utilsService: UtilsService,
    private _accoutingService: AccountingService
  ) { }

  ngOnInit() {
    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;

    this.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');

    this.viewBeginning();
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isTable = false;
  }

  viewTable() {
    this.isBeginning = true;
    this.isTable = true;
  }


  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.retentionsList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  getAll() {
    if (this.begDate && this.endDate) {
      this.isDisabled = true;
      this.loading = true;
      
      this._accoutingService.getAllRetention(this.begDate, this.endDate, this.storeCode).then(
        async (response) => {
          if (response.result == true) {
            this.retentionsList = response.records;
            this.temp = this.retentionsList;
            this.isDisabled = false;
            this._utilsService.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this._utilsService.showNotification(2, "Ingresar fecha");
    }
  }

  ///////////////////////DESCARGAR EXCEL///////////////////////
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-retenciones.xlsx');
  }
}
