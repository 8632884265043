<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/profile">Perfil</a>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h5 class="title">Editar perfil</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Usuario</label>
                                <input type="text" class="form-control" [(ngModel)]="user.userEmail" disabled/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Nombre</label>
                                <input type="text" class="form-control" [(ngModel)]="user.userName" disabled />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 pr-1">
                            <label>Contraseña actual</label>
                            <div class="input-group no-border form-control-lg">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <a (click)="changeText()">
                                            <i class="now-ui-icons gestures_tap-01"></i>
                                        </a>
                                    </div>
                                </div>                           
                                <input type="password" id="passPrevius" class="form-control form-control-White"
                                [(ngModel)]="password.previousPassword"/>       
                            </div>
                        </div>
                        <div class="col-md-6 pl-1">                          
                            <label>Contraseña nueva</label>
                            <div class="input-group no-border form-control-lg">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <a (click)="changeTextTwo()">
                                            <i class="now-ui-icons gestures_tap-01"></i>
                                        </a>
                                    </div>
                                </div>                           
                                <input type="password" id="passProposed"
                                class="form-control form-control-White" [(ngModel)]="password.proposedPassword"
                                placement="bottom" ngbTooltip="Política de contraseña: 8 caracteres mínimo, 1 Letra mayúscula al menos, 1 letra minúscula al menos, 1 caracter especial al menos, 1 número al menos"/>       
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-info" (click)="changePassword();" [disabled]="isDisabled">Modificar</button>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- 
<div class="main-content">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h5 class="title">Editar perfil</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Usuario</label>
                                <input type="text" class="form-control" [(ngModel)]="user.userEmail" disabled/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Nombre</label>
                                <input type="text" class="form-control" [(ngModel)]="user.userName" disabled />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 pr-1">
                            <div class="form-group">
                                <label>Contraseña actual</label>
                                <input type="password" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-6 pl-1">
                            <div class="form-group">
                                <label>Contraseña nueva</label>
                                <input type="password" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-info" (click)="changePassword();" [disabled]="isDisabled">Modificar</button>
                </div>
            </div>
        </div>
    </div>
</div> -->