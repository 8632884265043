<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="a-return" routerLink="/transfer-process">Procesar Transferencia/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/transfer-report">Reportes Generales</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- LISTAR FACTURAS -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label><b>Tipo Transferencia</b></label>
                            <angular2-multiselect [data]="transferTypeList" [(ngModel)]="selectedItemstransferType"
                                [settings]="dropdownSettingstransferType" (onSelect)="onItemSelectTransferType($event)" (onDeSelect)="onDeSelectTransferType()">
                            </angular2-multiselect>
                        </div>
                        <div class="col-md-6">
                            <label><b>Bodega/Cliente</b></label>
                            <angular2-multiselect [data]="warehouseList" [(ngModel)]="selectedItemsWarehouse"
                                [settings]="dropdownSettingsWarehouse" (onSelect)="onItemSelectWarehouse($event)" (onDeSelect)="onDeSelectWarehouse()">
                            </angular2-multiselect>
                        </div>
                        <div class="col-md-6">
                            <label><b>Estado Transferencia</b></label>
                            <angular2-multiselect [data]="transferStatusList" [(ngModel)]="selectedItemstransferStatus"
                                [settings]="dropdownSettingstransferStatus" (onSelect)="onItemSelectTransferStatus($event)" (onDeSelect)="onDeSelectTransferStatus()">
                            </angular2-multiselect>                            
                        </div>
                        <div class="col-md-6">
                            <label><b>Serie/Articulo</b></label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="product" autocomplete="off"/>
                            </div>                           
                        </div>
                        <div class="col-md-6">
                            <label>Del:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="begDate" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>Para:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="endDate" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <br>
                            <button class="btn btn-info  btn-pnd" style="width: 100%;" (click)="getReport()" [disabled]="isDisabled">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN LISTAR FACTURAS -->

    <!-- TABLE GET PENDIG -->
    <div class="row justify-content-center" *ngIf="isReport">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Trasnferencias</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)" [scrollbarH]="true">
                            <ngx-datatable-column name="# Traslado" prop="transferBusinessCode"></ngx-datatable-column>
                            <ngx-datatable-column name="Movimiento" prop="tipoTexto"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha" prop="transferDate"></ngx-datatable-column>
                            <ngx-datatable-column name="Sucursal"></ngx-datatable-column>
                            <ngx-datatable-column name="Bodega"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado" prop="estadoTexto"></ngx-datatable-column>
                            <ngx-datatable-column name="Cantidad" prop="transferInventoryDetailQuantity"></ngx-datatable-column>
                            <ngx-datatable-column name="Producto" prop="transferInventoryDetailDescription"></ngx-datatable-column>
                            <ngx-datatable-column name="Total" prop="Total"></ngx-datatable-column>
                            <ngx-datatable-column name="Usuario" prop="usuario"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE PENDING-->

     <!-- TABLE PROCESS -->
     <div class="row justify-content-center" *ngIf="isStoreReport">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de Transferencias finalizadas</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                        records"</h6>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Salio"></ngx-datatable-column>
                            <ngx-datatable-column name="Entro"></ngx-datatable-column>
                            <ngx-datatable-column name="Fecha"></ngx-datatable-column>
                            <ngx-datatable-column name="Recibo"></ngx-datatable-column>
                            <ngx-datatable-column name="Descripcion"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>