import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Product } from '../models/product';
import { environment } from 'src/environments/environment';


@Injectable()
export class ProductService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL PRODUCT
    getAll(): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-product/', { headers: headers }).toPromise();
    } 

    // INSERT PRODUCT
    insert(product: Product): Promise<any> {
        const params = JSON.stringify(product);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-product', params, { headers: headers }).toPromise();
    }   

    // UPDATE PRODUCT
    update(product: Product): Promise<any> {
        const params = JSON.stringify(product);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-product', params, { headers: headers }).toPromise();
    }  

    // update2(product: Product): Observable<any> {
    //     const params = JSON.stringify(product);
    //     const headers = new HttpHeaders().set('Content-Type', 'application/json');
    //     return this._http.put(this.url + 'imerbackend-product', params, { headers: headers });
    // }
}