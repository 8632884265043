import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { Provider } from 'src/app/models/provider';
import { ProviderService } from 'src/app/services/provider.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
  providers: [UtilsService, ProviderService]
})
export class ProviderComponent implements OnInit {
  // MODELS VARIABLE
  public provider = {} as Provider;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isEdit: boolean;
  public isDisabled: boolean;
  public noCredit: boolean;
  public settings = {
    columns: {
      providerBusinessCode: {
        title: 'Código'
      },
      providerName: {
        title: 'Nombre'
      },
      providerTaxDocument: {
        title: 'Nit'
      },
      providerPhone: {
        title: 'Teléfono'
      },
      providerAddress: {
        title: 'Dirección'
      },
      providerContactName: {
        title: 'Contacto'
      },
      providerContactPhone: {
        title: 'Teléfono'
      },
      providerContactEmail: {
        title: 'Correo'
      },
      providerCategoryShow: {
        title: 'Categoría'
      },
      providerCreditTime: {
        title: 'Días de Crédito'
      },
      providerCreditAmount: {
        title: 'Monto de Crédito'
      },
      providerDescription: {
        title: 'Descripción'
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public providerList: Provider[] = new Array();

  // VARIABLE FOR SELECT 
  // public providerCategoryList: any[] = new Array();
  public categoryList: any[] = new Array();
  public selectedItems = [];
  public dropdownSettings = {};

  constructor(
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _providerService: ProviderService
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    // SET VARIABLE
    this.provider.providerDiscount = 0;

    // LOAD VARIABLE
    this.isEdit = false;
    this.noCredit = true;
    this.isDisabled = false;

    // LOAD INFORMATION
    this.getAllByCompany(this.companyCode);

    // SELECT PROVIDER CATEGORY
    this.getAllCategory();
    this.getSelectSetting(this.selectedItems);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.providerList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////
  getSelectSetting(selected) {
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true
    };
  };

  getAllCategory() {
    return this._providerService.getAllProviderCategory().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.categoryList.push({
              id: e.providerCategoryCode,
              itemName: e.providerCategoryName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelect(item: any) {
    this.provider.providerCategory = item.id;
  }

  ///////////////////////CRUD///////////////////////
  // GET ALL PROVIDER
  getAllByCompany(companyCode) {
    this.loading = true;

    return this._providerService.getAllByCompany(companyCode).then(
      response => {
        if (response.result) {
          this.providerList = response.records;
          this.temp = this.providerList;
          
          response.records.forEach(e => {
            console.log(e.providerCategory)

            const resultado = this.categoryList.find(c => c.id == e.providerCategory)
            console.log(resultado)
          })
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE PROVIDER
  viewFunction($event) {
    $event.preventDefault();

    swal.fire({
      title: "Proveedor:",
      html:
        '<div class="text-left">' +
        '<b>Código: </b>' + this.activeRow.providerBusinessCode + '<br>' +
        '<b>Nombre: </b>' + this.activeRow.providerName + '<br>' +
        '<b>Nit: </b>' + this.activeRow.providerTaxDocument + '<br>' +
        '<b>Teléfono: </b>' + this.activeRow.providerPhone + '<br>' +
        '<b>Dirección: </b>' + this.activeRow.providerAddress + '<hr>' +
        '<b>Contacto: </b>' + this.activeRow.providerContactName + '<br>' +
        '<b>Teléfono Cont.: </b>' + this.activeRow.providerContactPhone + '<br>' +
        '<b>Correo: </b>' + this.activeRow.providerContactEmail + '<hr>' +
        '<b>Categoría: </b>' + this.activeRow.providerCategoryShow + '<br>' +
        '<b>Días de Crédito: </b>' + this.activeRow.providerCreditTime + '<br>' +
        '<b>Monto de Crédito: </b>' + this.activeRow.providerCreditAmount + '<br>' +
        '<b>Descripción: </b>' + this.activeRow.providerDescription + '<br></div>',
      // public providerCredit: number | boolean, //Trabaja con crédito
      // public providerBlocked: number | boolean, //Bloqueo
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    })
  }

  // INSERT
  save() {
    this.isDisabled = true;
    this.loading = true;

    this.provider.providerCompanyCode = this.companyCode;
    this.provider.providerStatus = 1;
    this.provider.providerDeleted = 0;

    this.provider.providerCredit ? this.provider.providerCredit = 1 : this.provider.providerCredit = 0;
    this.provider.providerBlocked ? this.provider.providerBlocked = 1 : this.provider.providerBlocked = 0;

    // console.log(this.provider);
    this._providerService.insert(this.provider).then(
      async (response) => {
        if (response.result == true) {
          this.getAllByCompany(this.companyCode);
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = true;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // UPDATE
  update() {
    this.isDisabled = true;

    this.provider.providerCredit ? this.provider.providerCredit = 1 : this.provider.providerCredit = 0;
    this.provider.providerBlocked ? this.provider.providerBlocked = 1 : this.provider.providerBlocked = 0;

    // console.log(this.provider);
    this._providerService.update(this.provider).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          this.getAllByCompany(this.companyCode);
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.provider.providerCode = this.activeRow.providerCode;
    this.provider.providerName = this.activeRow.providerName;
    this.provider.providerStatus = 0;
    this.provider.providerDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "El proveedor se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    // console.log(this.provider);
    this.provider.providerCode = this.activeRow.providerCode;
    this.provider.providerBussinnesCode = this.activeRow.providerBussinnesCode;
    this.provider.providerName = this.activeRow.providerName;
    this.provider.providerTaxDocument = this.activeRow.providerTaxDocument;
    this.provider.providerPhone = this.activeRow.providerPhone;
    this.provider.providerAddress = this.activeRow.providerAddress;
    this.provider.providerDescription = this.activeRow.providerDescription;
    this.provider.providerCategory = this.activeRow.providerCategory;
    this.provider.providerCreditTime = this.activeRow.providerCreditTime;
    this.provider.providerCreditAmount = this.activeRow.providerCreditAmount;
    this.provider.providerContactName = this.activeRow.providerContactName;
    this.provider.providerContactPhone = this.activeRow.providerContactPhone;
    this.provider.providerContactEmail = this.activeRow.providerContactEmail;
    this.provider.providerBlocked = this.activeRow.providerBlocked;

    const _selectedItem = this.categoryList.find(x => x.id == this.activeRow.providerCategory);
    this.getSelectSetting(_selectedItem);

    this.activeRow.providerCredit == 1 ? this.provider.providerCredit = true : this.provider.providerCredit = false;
    this.activeRow.providerBlocked == 1 ? this.provider.providerBlocked = true : this.provider.providerBlocked = false;
  }

  ///////////////////////COMPLEMENT///////////////////////
  creditFunction() {
    this.provider.providerCredit == true ? this.noCredit = false : this.noCredit = true;
    this.provider.providerCreditTime = null
    this.provider.providerCreditAmount = null;
  }

  //CANCEL EDIT
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.isDisabled = false;
    this.provider = {} as Provider;
    this.selectedItems = null;

    // this.selectedItems = [{ 'id': 1, 'itemName': 'Tipo A' }]
    // this.provider.providerCategory = 1;
    this.provider.providerDiscount = 0;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-proveedor.xlsx');
  }
}
