import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reprint',
  templateUrl: './reprint.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class ReprintComponent implements OnInit {

  ngOnInit(): void {
  }
}
