import { Component, OnInit } from '@angular/core';
import { Data, Router } from '@angular/router';

import { UtilsService } from 'src/app/services/utils.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PrintService } from 'src/app/services/print.service';
import { CompanyService } from 'src/app/services/company.service';

interface Filter {
  begDate: string,
  endDate: string,
  store: number //storeCode
}

@Component({
  selector: 'app-notes',
  templateUrl: './reprint-notes.component.html',
  styleUrls: ['../../../../app.component.scss'],
  providers: [UtilsService, PrintService, InvoiceService, CompanyService]
})

export class ReprintNotesComponent implements OnInit {
  public data: Data = {} as Data;
  public filter: Filter = {} as Filter;

  public certifier: number;
  public filer_note: string = '';
  public loading: boolean = false;
  public disabled: boolean = false;
  public reprint_note: string = '';

  // variables from data table
  public activeRow: any;
  public rows = new Array();
  public entries: number = 10;
  public noteList: any[] = new Array();

  constructor(
    public router: Router,
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService
  ) { }

  async ngOnInit() {
    const _data = JSON.parse(localStorage.getItem('data'));
    this.data.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.data.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.data.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    const urlTree = this.router.parseUrl(this.router.url);
    const location = urlTree.root.children.primary.segments[0].path;
    if (location === 'reprint-credit-note') {
      this.filer_note = 'NCRE';
      this.reprint_note = 'Nota de Crédito';
    } else {
      this.filer_note = 'NDEB';
      this.reprint_note = 'Nota de Debito';
    }
    const company = await this._companyService.getOne(this.data.companyCode);
    this.certifier = company.records[0].companyCertifier;

    this.filter.store = this.data.storeCode;
    this.filter.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.filter.endDate = this._utilsService.dateTime('yyy-MM-dd');
  }

  ///////////////////////FUNCIONES LIBRARY///////////////////////

  // DATA TABLE
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.rows = this.noteList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  // DOWNLOAD TABLE TO EXCEL
  // Exportar a excel
  // 1. Eliminar: Toda las propiedades que no se desee exportar.
  // 2. Convertir: Las propiedades a numericas. 
  // EJEMPLO:
  // for (const e of this.rows){
  //     delete e.eliminarElemento;
  //     e.convertirElemento = parseFloat(e.convertirElemento);
  //   }
  // this.excelServie.downloadToExcel(this.rows, 'Nombre del libro', 'nombre del reporte');
  downloadToExcel() {
  }

  getAll(note) {
    if (this.filter.begDate != '' && this.filter.endDate != '') {
      this.loading = true;
      this.disabled = true;

      if (this.filer_note == 'NCRE') {
        this._invoiceService.getAllCreditNote(this.filter).then(
          (response) => {
            if (response.result == true) {
              this.noteList = response.records;
              this.rows = this.noteList;
              this._utilsService.showNotification(1, response.message);
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            console.log(error);
            this._utilsService.showNotification(2, error);
          }
        ).finally(() => {
          this.loading = false;
          this.disabled = false;
        });
      } else {
        this._invoiceService.getAllDebitNote(this.filter).then(
          (response) => {
            if (response.result == true) {
              this.noteList = response.records;
              this.rows = this.noteList;
              this._utilsService.showNotification(1, response.message);
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            console.log(error);
            this._utilsService.showNotification(2, error);
          }
        ).finally(() => {
          this.loading = false;
          this.disabled = false;
        });
      }

    } else {
      this.disabled = false;
      this._utilsService.showNotification(2, "Seleccionar una fecha");
    }
  }

  async reprint($event, note) {
    $event.preventDefault();
    this.loading = true;
    this.disabled = true;

    //INFILE = 0; MEGAPRINT = 1.
    if (this.certifier === 0) {
      await this._printService.printInfile(note);
      this.loading = false;
      this.disabled = false;
    }
    else if (this.certifier === 1) {

      this.filer_note === 'NCRE' ? note = this.activeRow.creditNoteCode : note = this.activeRow.debitNoteCode;

      this._invoiceService.getOneMegaprint(note).then(
        async (response) => {
          if (response.result == true) {
            await this._printService.printMegaprint(response.records.pdfBase64);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this._utilsService.showNotification(2, error);
        }
      ).finally(() => {
        this.loading = false;
        this.disabled = false;
      });
    }
  }

  ///////////////////////DESCARGAR XML FIRMADO///////////////////////
  downloadFunction($event: Event, noteAuthorization: string, noteCode: string) {
    $event.preventDefault();
    this.disabled = true;
    this.loading = true;

    //records: queryResult[0][1][0]['invoiceExchangeRegisterResponse']

    // if (this.company.companyCertifier == 1) { }
    this._invoiceService.xmlCreditNote(noteCode).then(
      async (response) => {
        if (response.result == true) {
          // const string = response.records[0].invoiceExchangeRegisterResponse;
          console.log(response)
          const xmlData = response.records;
          const xmlStart = xmlData.indexOf("<?xml");
          const xmlEnd = xmlData.indexOf("</dte:GTDocumento>") + 18;
          const xmlSubstring = xmlData.substring(xmlStart, xmlEnd);
          console.log(xmlSubstring);

          const dataXML = [xmlSubstring];
          const data = new Blob(dataXML, { type: 'text/xml' });

          const save = document.createElement('a');
          save.download = noteAuthorization;
          save.target = '_blank';
          save.href = URL.createObjectURL(data);

          var eventoClic = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });
          save.dispatchEvent(eventoClic);
          URL.revokeObjectURL(save.href);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.disabled = false;
      this.loading = false;
    });


  }
}
