<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/user">Usuarios multi empresa</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- FORMULARIO -->
    <div class="row">
        <div class="col-md-12">
            <div class="card ">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="card-title">Nuevo usuario multi empresa</h4>
                            <label>*Nota: Como primer paso debes volver el usuario como multi empresa de tu empresa.</label>
                        </div>
                    </div>
                </div>

                <div class="card-body ">
                    <div>
                        <div class="row">
                            <div class="col-sm-6 col-md-4">
                                <label>*Empresa</label>
                                <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItemsCompany"
                                    [settings]="dropdownSettingsCompany" (onSelect)="onItemSelectCompany($event)"  (onDeSelect)="onItemDeSelectCompany($event)">
                                </angular2-multiselect>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label>*Usuario</label>
                                <angular2-multiselect [data]="userList" [(ngModel)]="selectedItemsUser"
                                    [settings]="dropdownSettingsUser" (onSelect)="onItemSelectUser($event)"  (onDeSelect)="onItemDeSelectUser($event)">
                                </angular2-multiselect>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="statistics">
                                    <button class="btn btn-primary btn-block btn-lg mt-10 btn-info" (click)="validation()"
                                        [disabled]="isDisabled">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN FORMULARIO -->

    <!-- TABLA -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Usuarios Multisucursal</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Empresa"></ngx-datatable-column>
                            <ngx-datatable-column name="Usuario"></ngx-datatable-column>
                            <ngx-datatable-column name="Rol"></ngx-datatable-column>
                            <ngx-datatable-column name="Telefono"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="deleteFunction($event)" ngbTooltip="Eliminar Usuario"><i
                                            class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN TABLA -->
</div>