<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/store-type">Encargado & Sucursal</a>
        </div>
    </div>
</div>

<div class="main-content">

    <!-- INPUT'S -->
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <!-- INPUT MANAGER -->
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header ">
                            <div class="row">
                                <div class="col-10">
                                    <b class="card-title" *ngIf="!isEdit">Nuevo Encargado</b>
                                    <b class="card-title text-warning" *ngIf="isEdit">Modificar Encargado</b>
                                </div>
                                <div class="col-2 text-right" *ngIf="isEdit">
                                    <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                        (click)="cancelUpdate($event)"><i class="fas fa-times"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>*Empresas</label>
                                        <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItemsCompany"
                                            [settings]="dropdownSettingsCompany" (onSelect)="onItemSelectCompany($event)">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>*Nombre</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="manager.managerName" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>*Correo</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="manager.managerEmail" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>*Teléfono</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="manager.managerPhone" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>&nbsp;</label>
                                    <button class="btn btn-info btn-block btn-erp" (click)="save()" *ngIf="!isEdit"
                                        [disabled]="isDisabled">Guardar</button>
                                    <button class="btn btn-primary btn-block btn-erp" (click)="update()" *ngIf="isEdit"
                                        [disabled]="isDisabled">Actualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END INPUT MANAGER -->

                <!-- TABLE MANAGER -->
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Listado de Encargados</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                                records"</h6>
                        </div>
                        <div class="card-body">
                            <div class="dataTables_wrapper">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="datatable_length">
                                            <label>
                                                Show
                                                <select name="datatable_length" aria-controls="datatable"
                                                    class="form-control form-control-sm"
                                                    (change)="entriesChange($event)">
                                                    <option value="10" [selected]="entries==10">10</option>
                                                    <option value="25" [selected]="entries==25">25</option>
                                                    <option value="50" [selected]="entries==50">50</option>
                                                    <option value="-1" [selected]="entries==-1">All</option>
                                                </select>
                                                entries
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="datatable_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm"
                                                    placeholder="Search records" aria-controls="datatable"
                                                    (keyup)="filterTable($event)" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'"
                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                    [limit]="entries != -1 ? entries:undefined" [rows]="temp"
                                    (activate)="onActivate($event)">
                                    <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                                    <ngx-datatable-column name="Telefono"></ngx-datatable-column>
                                    <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                        [resizeable]="false">
                                        <ng-template ngx-datatable-header-template let-value="value"
                                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                            Acciones
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-value="value"
                                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                            <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                                (click)="viewFunction($event)" ngbTooltip="Ver"><i
                                                    class="fas fa-eye"></i>
                                            </a>
                                            <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                                (click)="editFunction($event)" ngbTooltip="Editar"><i
                                                    class="fas fa-pencil-alt"></i>
                                            </a>
                                            <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                                (click)="deleteFunction($event)" ngbTooltip="Eliminar"><i
                                                    class="fas fa-times"></i>
                                            </a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TABLE MANAGER -->
            </div>
        </div>

        <div class="col-md-6">
            <div class="row">
                <!-- INPUT MANAGER - STORE -->
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header ">
                            <div class="row">
                                <div class="col-sm-8">
                                    <b class="card-title" *ngIf="!isEditStoreManager">Crear Relacion Encargado -
                                        Sucursal</b>
                                    <b class="card-title text-warning" *ngIf="isEditStoreManager">Modificar Encargado -
                                        Sucursal</b>
                                </div>
                                <div class="col-sm-4 text-right" *ngIf="isEditStoreManager">
                                    <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                        (click)="cancelUpdateStoreManager($event)"><i class="fas fa-times"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>*Encargado</label>
                                        <angular2-multiselect [data]="managerListSelect"
                                            [(ngModel)]="selectedItemsManager" [settings]="dropdownSettings"
                                            (onSelect)="onItemSelectManager($event)">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>*Sucursal</label>
                                        <angular2-multiselect [data]="storeList" [(ngModel)]="selectedItemsStore"
                                            [settings]="dropdownSettingsStore" (onSelect)="onItemSelectStore($event)">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <button class="btn btn-info btn-block btn-erp" (click)="saveStoreManager()"
                                        *ngIf="!isEditStoreManager" [disabled]="isDisabled">Guardar</button>
                                    <button class="btn btn-primary btn-block btn-erp" (click)="updateStoreManager()"
                                        *ngIf="isEditStoreManager" [disabled]="isDisabled">Actualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END INPUT MANAGER STORE -->

                <!-- TABLE MANAGER STORE -->
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-8">
                                    <h4 class="card-title">Listado de Encargado - Sucursal</h4>
                                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                                        records"
                                    </h6>
                                </div>
                                <div class="col-md-4">
                                    <button class="btn btn-success btn-sale" style="width: 100%;"
                                        (click)="downloadToExcel()" [disabled]="isDisabled">
                                        Descargar Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="dataTables_wrapper">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="datatable_length">
                                            <label>
                                                Show
                                                <select name="datatable_length" aria-controls="datatable"
                                                    class="form-control form-control-sm"
                                                    (change)="entriesChange($event)">
                                                    <option value="10" [selected]="entries==10">10</option>
                                                    <option value="25" [selected]="entries==25">25</option>
                                                    <option value="50" [selected]="entries==50">50</option>
                                                    <option value="-1" [selected]="entries==-1">All</option>
                                                </select>
                                                entries
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="datatable_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm"
                                                    placeholder="Search records" aria-controls="datatable"
                                                    (keyup)="filterTable($event)" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'"
                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                    [limit]="entries != -1 ? entries:undefined" [rows]="tempStoreManager"
                                    (activate)="onActivate($event)">
                                    <ngx-datatable-column name="Sucursal"></ngx-datatable-column>
                                    <ngx-datatable-column name="Encargado"></ngx-datatable-column>
                                    <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                        [resizeable]="false">
                                        <ng-template ngx-datatable-header-template let-value="value"
                                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                            Acciones
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-value="value"
                                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                            <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                                (click)="editFunctionStoreManager($event)" ngbTooltip="Editar"><i
                                                    class="fas fa-pencil-alt"></i>
                                            </a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END TABLE MANAGER STORE -->
            </div>
        </div>
    </div>