import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/models/store';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { InvoiceHeader } from 'src/app/models/invoiceHeader';
import { PrintService } from 'src/app/services/print.service';
import { UtilsService } from 'src/app/services/utils.service';
import { StoreService } from 'src/app/services/store.service';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

interface Job {
  jobDay,
  jobLastDay,
  jobDeleted,
  jonGenerated
}

@Component({
  selector: 'app-invoice-recurrent-insert',
  templateUrl: './invoice-recurrent-insert.component.html',
  styleUrls: ['./invoice-recurrent-insert.component.scss'],
  providers: [ClientService, CompanyService, InvoiceService, StoreService, UtilsService, PrintService]
})
export class InvoiceRecurrentInsertComponent implements OnInit {
  // Variables auxiliares
  job = {} as Job;
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  public nowDay: string;
  public userCode: number;
  public userName: string;
  public storeCode: number;
  public storeName: string;
  public companyCode: number;
  loading;

  public isBilling: boolean;
  public isDisabled: boolean;
  public isBeginning: boolean;
  public generate: boolean = false;

  // Variables de Linea de Compra
  public itemCode: string;
  public itemNumberCode: number;
  public moneda: string;
  public invoiceLine: any = new Array;
  public addAmount: boolean;

  public dte: {
    serie,
    noFactura,
    autorizacion,
    certificador,
    nitCertificador,
    fechaCertificacion,
  };
  public fel: any;
  public felDetail: any;
  public companyLogoUrl: string;
  public taxToPay: number = 0
  public regimenMessage: string = '';

  public disabled = false;
  public disabledEXT = false;
  public facturando: boolean;

  public tipoCliente = 0;
  public tipoPago = 0;
  public insertNewClient: boolean = false; //crear cliente si no existe

  // DATA SELECT PAYMENT
  public idPayment: number;
  public itemNamePayment: string;
  public selectedItemsPayment = [];
  public PaymentList: any[] = new Array();
  public dropdownSettingsPayment: IDropdownSettings = {};
  closeDropdownSelectionPayment = false;
  
  public PaymentLine: any = new Array;
  public PaymentType = 0;
  public NamePaymentType = 0;
  public MontoPayment: number = 0.00;
  public MontoTotalPayment: number = 0.00;
  public disabledPay = false;
  public isDisabledInvoice = false;
  public isMethodPay = false;

  constructor(
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _storeService: StoreService,
    private _clientService: ClientService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.job.jobDay = 1;
    this.job.jobLastDay = 0;
    this.addAmount = true;
    this.isDisabled = false;
    this.moneda = "Q";
    this.itemCode = "I";
    this.itemNumberCode = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceDetail.invoiceDetailType = 'S';
    this.invoiceDetail.invoiceDetailQuantity = 1;
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.paymentType = 0;
    this.tipoPago = 0;
    this.companyLogoUrl = '../../../assets/img/Clever-azul-original.png';
    this.insertNewClient = false;

    // DATE
    this.nowDay = this._utilsService.dateTime('yyy-MM-dd');
    this.invoiceHeader.invoiceDate = this.nowDay;

    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    const _user = JSON.parse(localStorage.getItem('user'));
    this.userName = _user.userName ? _user.userName.toString() : '';

    this.viewBeginning();
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
    await this.getPaymetList(this.storeCode);
    this.getSelectSettingPaymet(this.selectedItemsPayment);

    // console.log('Select -> ', this.selectedItems);
    console.log('Select Tipo pagos -> ', this.selectedItemsPayment);
    console.log('Tipo pagos -> ', this.PaymentList);

    await this.Pagado();

    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.disabledEXT = true;

    this.tipoCliente = 0;
    this.client.clientType = 0;
    this.disabledEXT = true;
  }

  async onItemSelectPaymet(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItemPayment = await this.PaymentList.find(x => x.id == item.id);
    console.log(_selectedItemPayment);

    this.tipoPago = item.payMethodBusinessCode;
    console.log('Tipo Pago -> ', this.tipoPago);

    this.PaymentType = this.tipoPago;
    this.NamePaymentType = item.payMethodName;

    // this.itemCode = item.idPayment;
    // this.invoiceDetail.invoiceDetailDescription = item.itemNamePayment;
    // this.stock = _selectedItem.inventoryQuantity;
    // this.Minstock = _selectedItem.inventoryMin;
  }

  getPaymetList(code) {
    return this._storeService.getPaymets(code).then(
      response => {
        if (response.result) {
          this.PaymentList = response.records;
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getSelectSettingPaymet(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItemsPayment = [selected] : '';

    this.dropdownSettingsPayment = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'payMethodBusinessCode',
      textField: 'payMethodName'
    };
  }

  async addPaymetDetail(){

    this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment + (this.MontoPayment))).toFixed(6));
    console.log(this.MontoTotalPayment);

    await this.PaymentLine.push({
      typePayment: this.PaymentType,
      NamePaymentType: this.NamePaymentType,
      MontoPayment: this.MontoPayment
    });

    this.MontoPayment = 0;
    this.selectedItemsPayment = [];

    this.Pagado();

    console.log(this.PaymentLine);
  }

  async deletePaymetDetail(item) {

    await this.PaymentLine.forEach(async (e, index) => {
      if (e.typePayment === item) {

        this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment - (e.MontoPayment))).toFixed(6));        
        this.PaymentLine.splice(index, 1);
      }
    })

    this.Pagado();
        
    console.log(this.PaymentLine);
  }

  async Pagado(){

    if(this.company.companyPaymentType == 1){
      this.isMethodPay = true;
      if((this.MontoTotalPayment == this.invoiceHeader.invoiceTotal) && this.MontoTotalPayment > 0){
        this.isDisabledInvoice = false;
      }
      else if(this.MontoTotalPayment > this.invoiceHeader.invoiceTotal){
        this.isDisabledInvoice = true;
        this._utilsService.showNotification(2,"Total pagado es mayor al Total de la factura")
      }
      else{
        this.isDisabledInvoice = true;
      }
    }
    else{
      this.isMethodPay = false;
      this.isDisabledInvoice = false;
    }
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBilling = false;
    this.isBeginning = true;
    this.client.clientType = 0;
  }

  viewBilling() {
    this.isBilling = true;
    this.isBeginning = false;
  }

  onChangepaymentType(event) {
    this.invoiceHeader.paymentType = event;
    this.tipoPago = event;
    console.log(this.tipoPago);
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
          this.company.companyTaxToPay == 12 ? this.taxToPay = 1.12 : this.taxToPay = 1.05;
          this.company.companyTaxToPay == 12 ? this.regimenMessage = "Sujeto a pagos trimestrales ISR" : this.regimenMessage = "*No genera derecho a crédito fiscal";
          this.company.companyLogo != 'undefined' ? this.companyLogoUrl = this.company.companyLogoUrl : '';
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      response => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  viewCF() {
    this.isBeginning = true;
    this.isBilling = true;
    this.disabled = true;
  }

  ValidarCF() {
    /*J11 - Validar CF*/
    console.log(this.invoiceHeader.invoiceTotal);
    console.log(this.client.clientTaxDocument);

    if(this.invoiceHeader.invoiceTotal >= 2500){
      if(this.client.clientTaxDocument == "CF"){
        this._utilsService.showNotification(5, "No puede facturar como CF, el monto es mayor a 2500");
        this.viewCF();
      }
      else{
        this.disabled = false;
      }
    }
  }

  onChangeTypeClient(event) {    
    this.client.clientType = event;
    this.tipoCliente = event;
    console.log(this.tipoCliente);

    if(event == 2){
      this.disabledEXT = false;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
    else{
      this.disabledEXT = true;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
  }

  ///////////////////////BUSCAR NIT///////////////////////
  buscarNITLocal($event, nit) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    if(this.client.clientType == 2){

      this.client.clientName = this.client.clientName;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientPhone = "00000000";
      this.client.clientEmail = "info@clever.com";
      this.client.clientAddress = "CIUDAD";

      this.insertNewClient = false;
      // this.recurrentClient = false;
      this.viewBilling();
      this._utilsService.showNotification(4, "SE EMITIRA FACTURA CON PASAPORTE");
      this.loading = false;
      this.tipoCliente = 2;

    }
    else{
      if(nit == "CF"){
        this.client.clientType = 0;
        this.tipoCliente = 0;
      }
      // return this._clientService.getOneByNit(nit).then(
      return this._clientService.getOneByCompanyNit(this.companyCode, nit).then(
        response => {
          // console.log(response.records)
          if (response.records.length > 0) {
            this.client = response.records[0];
            // this.recurrentClient = true;
            this.insertNewClient = false;
            this.viewBilling();
            this._utilsService.showNotification(1, response.message);
          } else {
            return this._clientService.searchNitSAT(nit, this.client.clientType).then(
              async response => {
                if (response.tipo_respuesta == 0) {
                  //this.client.clientName = response.nombre[0];
                  //console.log(response.nombre[0]);
                  this.client.clientName =  response.nombre[0];
                  this.client.clientStatus = 1;
                  this.client.clientDeleted = 0;
                  this.client.clientPhone = "00000000";
                  this.client.clientEmail = "info@clever.com";
                  this.client.clientAddress = "CIUDAD";

                  this.insertNewClient = true;
                  // this.recurrentClient = false;
                  this.viewBilling();
                  this._utilsService.showNotification(4, "Cliente Nuevo");
                } else {
                  this._utilsService.showNotification(3, response.listado_errores[0].error[0].desc_error[0]);
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;

        this.ValidarCF();
      });
    }    
  }

  searchNIT($event, nit) {
    $event.preventDefault();
    this.isDisabled = true;
    this.loading = true;

    return this._clientService.getOneByCompanyNit(this.companyCode, nit).then(
      response => {
        if (response.records.length > 0) {
          this._utilsService.showNotification(1, response.message);
          this.client = response.records[0];
          this.viewBilling();
        } else {
          this._utilsService.showNotification(2, 'Cliente no encontra');
        }
      },
      error => {
        console.log(error);
        this._utilsService.showNotification(3, error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  ///////////////////////RECURRENCIA///////////////////////
  validateJobDay(jobDay: number) {
    if (1 <= jobDay && jobDay < 29 || jobDay == null) {   
    }else{
      this.job.jobDay = 1;
      this._utilsService.showNotification(2, 'Fecha no permitida');
    }
  }

  switchLastDay($event) {
    if ($event.currentValue == true) {
      this.job.jobDay = 1;
      this.job.jobLastDay = 1;
    } else {
      this.job.jobLastDay = 0;
    }
  }

  switchInvoice($event) {
    $event.currentValue == false && (this.invoiceHeader.invoiceDate = this.nowDay);
  }

  ///////////////////////DETALLE DE FACTURA///////////////////////
  // VALIDATE DATE
  validateDate($event) {
    $event.preventDefault();
    var valid = false;
    var now = this.nowDay.split('-');
    var date = this.invoiceHeader.invoiceDate.split('-');

    if (date[0] == now[0] || parseFloat(date[0]) == parseFloat(now[0]) - 1) {
      if (date[1] == now[1]) {
        if (parseFloat(date[2]) <= parseFloat(now[2]) + 5 && parseFloat(date[2]) >= parseFloat(now[2]) - 5) {
          valid = true;
        }
      }
      else if (parseFloat(date[1]) == parseFloat(now[1]) - 1 || parseFloat(date[1]) == 12) {
        if (parseFloat(date[2]) >= 26) {
          valid = true;
        }
      }
    }

    if (valid == false) {
      this._utilsService.showNotification(2, 'Fecha fuera de rango')
      this.invoiceHeader.invoiceDate = this.nowDay;
    };
  }
  
  // CALCULAR IMPUESTO DE LINEA
  calculateTax() {
    let subTotalLine;

    subTotalLine = (this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice);

    if (this.invoiceDetail.invoiceDetailDiscount > 0) {
      if (this.invoiceDetail.invoiceDetailDiscount <= subTotalLine) {
        subTotalLine = subTotalLine - this.invoiceDetail.invoiceDetailDiscount;
      } else {
        this._utilsService.showNotification(2, "Cantidad No permitida");
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }
    }

    let tax;
    tax = (subTotalLine - (subTotalLine / this.taxToPay)).toFixed(6);
    this.invoiceDetail.invoiceDetailTax = parseFloat(tax);
  }

  // GUARDAR UNA LINEA DE COMPRA
  saveLineInvoice() {
    if (this.invoiceDetail.invoiceDetailDescription != "" && this.invoiceDetail.invoiceDetailQuantity > 0 && this.invoiceDetail.invoiceDetailUnitPrice > 0) {
      //Aumentar Codigo si no viene uno
      if (this.itemCode == "I") {
        this.itemNumberCode++;
        this.itemCode += this.itemNumberCode;
      }

      //Calcular subtotal de linea
      this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice;

      if (this.invoiceDetail.invoiceDetailDiscount != undefined && this.invoiceDetail.invoiceDetailDiscount != null && this.invoiceDetail.invoiceDetailDiscount > 0) {
        this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailDiscount;
        this.invoiceDetail.invoiceDetailTax = this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay);
      } else {
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }

      //Actualizar
      this.invoiceLine.forEach((x) => {
        if (x.itemCode == this.itemCode) {

          this.invoiceHeader.invoiceTotal -= x.total;

          x.itemCode = this.itemCode;
          x.measure = "UNI";
          x.taxCode = 1;
          x.taxName = "IVA";
          x.isService = this.invoiceDetail.invoiceDetailType;
          x.quantity = parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(6));
          x.description = this.invoiceDetail.invoiceDetailDescription;
          x.unitPrice = parseFloat((this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
          x.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(6));
          x.discount = parseFloat((this.invoiceDetail.invoiceDetailDiscount).toFixed(6));
          x.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(6));
          x.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6));
          x.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6));

          // this.total = this.total + this.subTotal;
          this.addAmount = false;
        }
      })

      //Insertar
      if (this.addAmount == true) {
        this.invoiceLine.push({
          itemCode: this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: this.invoiceDetail.invoiceDetailType,
          quantity: parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(6)),
          description: this.invoiceDetail.invoiceDetailDescription,
          unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(6)),
          price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(6)),
          discount: parseFloat(this.invoiceDetail.invoiceDetailDiscount.toFixed(6)),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(6)),
          taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6)),
          total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(6))
        });
      }
      // console.log(this.invoiceLine);
      // Sumar Total
      this.invoiceHeader.invoiceTotal += this.invoiceDetail.invoiceDetailSubtotal;
      this.invoiceHeader.invoiceTax += this.invoiceDetail.invoiceDetailTax;
      this.cleanLineInvoice();
    }

    console.log('Line->',this.invoiceLine);

    this.ValidarCF();

    this.isDisabled = false;
  }

  // APLICAR DESCUENTO EN TIEMPO REAL
  applyDiscount($event, item, descuento) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (parseFloat(descuento) <= temporal) {
          this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.total);
          e.total = temporal - parseFloat(descuento);
          e.taxableAmount = parseFloat((parseFloat(e.total) / this.taxToPay).toFixed(6));
          e.taxAmount = parseFloat(((parseFloat(e.total) / this.taxToPay) * (this.taxToPay - 1)).toFixed(6));
          this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal + parseFloat(e.total);
        } else {
          this._utilsService.showNotification(2, 'Cantidad No permitida');
        }
      }
    });
  }

  // CARGAR DATOS A EDITAR
  editLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        this.itemCode = e.itemCode;
        this.invoiceDetail.invoiceDetailType = e.isService;
        this.invoiceDetail.invoiceDetailDescription = e.description;
        this.invoiceDetail.invoiceDetailQuantity = e.quantity;
        this.invoiceDetail.invoiceDetailUnitPrice = e.unitPrice;
        this.invoiceDetail.invoiceDetailSubtotal = e.price;
        this.invoiceDetail.invoiceDetailDiscount = e.discount;
        this.invoiceDetail.invoiceDetailTax = e.taxAmount;
      }
    })
    this.isDisabled = false;
  }

  // ELIMINAR UNA LINEA
  deleteLine(item) {
    this.isDisabled = true;

    this.invoiceLine.forEach((e, index) => {
      if (e.itemCode == item) {
        this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.price);
        this.invoiceLine.splice(index, 1);
      }
    })
    this.isDisabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.addAmount = true;

    this.itemCode = "I";
    this.invoiceDetail.invoiceDetailDescription = null;
    this.invoiceDetail.invoiceDetailDiscount = null;
    this.invoiceDetail.invoiceDetailQuantity = null;
    this.invoiceDetail.invoiceDetailUnitPrice = null;
    this.invoiceDetail.invoiceDetailTax = null;
  }

  async save() {

    //Crear Cliente en la DB
    if (this.insertNewClient) {
      // Resolver problema con DATA BASE
      let nombre;
      nombre = await this.client.clientName.replace(/&/g, '&amp;');
      nombre = await this.client.clientName.replace(/'/g, '&apos;');
      nombre = await this.client.clientName.replace(/"/g, '&quot;');

      this.client.clientName = nombre;
      this.client.clientCredit = 0;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientBlocked = 0;
      this.client.clientInterest = 0;
      this.client.clientDiscount = 0;
      this.client.clientCategory = 2;
      this.client.clientCreditTime = 0;
      this.client.clientCreditAmount = 0;
      this.client.clientCompanyCode = this.companyCode;
      this.client.clientType = this.tipoCliente;
      this.client.clientNameFrequent = '-';

      if (this.client.clientTaxDocument && this.client.clientName) {
        const response = await this._clientService.insert(this.client);
        this.client.clientCode = response.records[0].insertId;
      };
    }

    if (this.invoiceLine.length > 0 && this.job.jobDay >= 1) {
      this.loading = true;
      this.isDisabled = true;

      // Si es factura de pequeño contribuyente
      let transmitterType = "FACT";
      let transmitterMembership = "GEN";
      if (this.company.companyTaxToPay == 5) {
        transmitterType = "FPEQ";
        transmitterMembership = "PEQ";
      }

      this.invoiceHeader.invoiceTax = 0;
      this.invoiceLine.forEach(e => {
        e.itemCode = 0;
        this.invoiceHeader.invoiceTax += e.taxAmount;
      });

      if(this.company.companyPaymentType == 0){
        await this.PaymentLine.push({
          typePayment: 0,
          NamePaymentType: 'Efectivo',
          MontoPayment: parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4))
        });
      }

      const hour = await this._utilsService.dateTime('HH:mm:ss');

      const json = {
        "job": this.job,
        "store": {
          "storeCode": this.storeCode
        },
        "user": {
          "userCode": this.userCode
        },
        "transmitter": {
          "transmitterMembership": transmitterMembership,
          "transmitterType": transmitterType,
          "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
          "transmitterEmail": this.company.companyEmail,
          "transmitterTaxDocument": this.company.companyTaxDocument,
          "transmitterComercialName": this.store.storeName,
          "transmitterName": this.company.companyName,
          "transmitterAddress": this.store.storeAddress,
          "transmitterZipCode": this.store.storeZipCode,
          "transmitterMunicipality": this.store.storeMunicipality,
          "transmitterDepartment": this.store.storeDepartment,
          "transmitterCountry": 'GT',
          "transmitterPrefix": this.company.companyPrefix,
          "transmitterUser": this.company.companyPrefix,
          "transmitterKey": this.company.companyKey,
          "transmitterToken": this.company.companyToken,
          "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
          "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
        },
        "receiver": {
          "receiverAddress": this.client.clientAddress,
          "receiverCode": this.client.clientCode,
          "receiverDeleted": 0,
          "receiverEmail": this.client.clientEmail,
          "receiverName": this.client.clientName,
          "receiverStatus": 1,
          "receiverTaxDocument": this.client.clientTaxDocument,
          "receiverTypeTaxDocument": this.tipoCliente,
          "receiverZipCode": 0,
          "receiverCountry": "GT"
        },
        "invoice": {
          "invoiceCode": 1,
          "invoiceCurrency": "GTQ",
          "invoiceTaxShortName": "IVA",
          "invoiceDate": this.invoiceHeader.invoiceDate + ' ' + hour,
          "invoiceInternSerial": this.invoiceHeader.invoiceInternSerial,
          "invoiceTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(6)),
          "invoiceTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(6)),
          "invoicepaymentType": this.tipoPago,
          "invoiceChangeRate": this.invoiceHeader.invoiceExchangeRate == undefined ? 0 : this.invoiceHeader.invoiceExchangeRate,
          "invoiceDuesQuantity": 0,
        },
        "phrase": this.company.phrase,
        "detail": this.invoiceLine,
        "paymentType": this.PaymentLine,
        "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint"
      };

      console.log(this.tipoCliente);
      console.log(json)

      if (this.generate == true) {
        json.job.jonGenerated = 1;
        this.job.jonGenerated = 1;
        await this.insertInvoice(json);                
      } else {
        json.job.jonGenerated = 0;
        this.job.jonGenerated = 0;
        await this.insertRecurrent(json);
      }
    } else {
      this._utilsService.showNotification(2, 'Campos incompletos, por favor revisa.')
    }
  }

  insertRecurrent(json) {
    // Insertar recurrencia
    this._invoiceService.insertRecurrentBilling(json).then(
      async (response) => {
        if (response.result) {
          this.viewBeginning();
          await this.cleanModel()
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
    }).finally(() => {
        this.loading = false;
        this.isDisabled = false;
    });
  }

  async insertInvoice(invoice) {
    // INFILE
    if (this.company.companyCertifier == 0) {
      await this._invoiceService.insertBilling(invoice).then(
        async (response) => {
          if (response.result) {
            await this.insertRecurrent(invoice);
            console.log('respuesta factura' + response.result);
            const uuid = response.invoiceAuthorization;
            window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid);            
          } else {
            this.loading = false;
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.loading = false;
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
        });
    }
    // MEGAPRINT
    else if (this.company.companyCertifier == 1) {
      await this._invoiceService.insertBilling(invoice).then(
        async (response) => {
          if (response.result) {
            await this.insertRecurrent(invoice);
            const pdfResult = response.invoicePdf;
            const pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")
          } else {
            this.loading = false;
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          console.log(error);
          this.loading = false;
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
        });
    }
  }

  // Limpiar Campos
  cleanModel() {
    this.job = {} as Job;
    this.job.jobDay = 1;
    this.job.jobLastDay = 0;

    this.moneda = "Q";
    this.itemCode = "I";
    this.itemNumberCode = 0;

    this.invoiceLine = [];
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceDetail.invoiceDetailType = 'S';
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.invoiceDate = this.nowDay;
    this.invoiceHeader.paymentType = 0;
    this.tipoPago = 0;

    this.addAmount = true;
    this.generate = false;
    this.isDisabled = false;
    this.viewBeginning();
  }
}
