import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class WarehouseService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL WAREHOUSE
    getAll(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-warehouse', { headers: headers }).toPromise();
    }

    // GET ALL WAREHOUSE
    getAllByCompany(companyCode): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-warehouse/' + companyCode, { headers: headers }).toPromise();
    }

    // GET ALL WAREHOUSE Status
    getAllByCompanyStatus(companyCode): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-warehouseStatus/' + companyCode, { headers: headers }).toPromise();
    }

    // INSERT WAREHOUSE
    insert(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-warehouse', params, { headers: headers }).toPromise();
    }

    // UPDATE WAREHOUSE
    update(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-warehouse', params, { headers: headers }).toPromise();
    }

    // INSERT WAREHOUSE
    insertTransfer(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-transfer', params, { headers: headers }).toPromise();
    }

    // GET ALL TRANSFER BY WAREHOSUE
    getTransferByWarehouse(code, method): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-transfer/header/warehouse/' + code + '/' + method, { headers: headers }).toPromise();
    }

    // GET ALL TRANSFER BY COMPANY
    getTransferByCompany(code, method): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-transfer/header/company/' + code + '/' + method, { headers: headers }).toPromise();
    }

    // GET ONE TRANSFER
    getOneTransfer(code): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-transfer/inventory/' + code, { headers: headers }).toPromise();
    }

    // GET ONE SERIAL LINE BY TRANSFER CODE & INVENTORY CODE 
    getOneSerial(transfer, inventory): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-transfer/serial/' + transfer + '/' + inventory, { headers: headers }).toPromise();
    }

    // INSERT DESTINATION
    insertDestination(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-transfer/destination', params, { headers: headers }).toPromise();
    }

    reportTransfer(wharehouseCode, companyCode): Promise<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //return this._http.get(this.url + 'imerbackend-transfer/detail/' + code, {headers: headers}).toPromise();
        return this._http.get(this.url + 'imerbackend-transfer/detail/' + wharehouseCode + '/' + companyCode, {headers: headers}).toPromise();
    }

    reportAllTransfer(company): Promise<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-transfer/detail/-1/' + company, {headers: headers}).toPromise();
    }

    // reportsTransfer(companyCode, type, wharehouseCode, status, serial, begDate, endDate): Promise<any>{
    //     let headers = new HttpHeaders().set('Content-Type', 'application/json');
    //     //return this._http.get(this.url + 'imerpbellum-transfer/detail/' + code, {headers: headers}).toPromise();
    //     return this._http.get(this.url + 'imerbackend-transfer/report/' + companyCode + '/' + type + '/' + wharehouseCode + '/' + status + '/' + serial + '/' + begDate + '/' + endDate, {headers: headers}).toPromise();
    // }

    reportsTransfer(json): Promise<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let params = JSON.stringify(json);
        //return this._http.get(this.url + 'imerpbellum-transfer/detail/' + code, {headers: headers}).toPromise();
        return this._http.post(this.url + 'imerbackend-transfer/report', params, {headers: headers}).toPromise();
    }
}