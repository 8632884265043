import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from "sweetalert2";
import { Role } from 'src/app/models/role';
import { Access } from 'src/app/models/access';
import { AccessType } from 'src/app/models/accessType';
import { RoleAccess } from 'src/app/models/roleAccess';
import { RoleService } from 'src/app/services/role.service';
import { AccessService } from 'src/app/services/access.service';
import { CompanyService } from 'src/app/services/company.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss'],
  providers: [RoleService, AccessService, CompanyService, UtilsService]
})

export class AccessComponent implements OnInit {
  // MODELS VARIABLE 
  public role = {} as Role;
  public access = {} as Access;
  public accessType = {} as AccessType;
  public roleAccess = {} as RoleAccess;
  public isViewBeginning: boolean;
  public isViewAccess: boolean;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isEditRole: boolean;
  public isEditAccess: boolean;
  public isEditRoleAccess: boolean;
  public isDisabled: boolean;
  loading;

  // VARIABLE FOR INSERT/UPDATE ROLE - ACCESS
  public roleCodeTemp: number = null;
  public success: boolean = false;
  public message: string = '';

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public tempRole = [];
  public tempAccess = [];
  public activeRow: any;
  public roleList = new Array();
  public accessList = new Array();

  // VARIABLE FOR SELECT 
  public companyList: any[] = new Array();
  public accessTypeList: any[] = new Array();
  public selectedItemsCompany = [];
  public selectedItemsAccessType = [];
  public dropdownSettingsCompany = {};
  public dropdownSettingsAccessType = {};

  // SELECT todos los accesos para asignar a un rol
  public arrayAccess = new Array;
  public accessSelectList: any[] = new Array();
  public selectedItemsAccess = []; //: []; //any[] = new Array();
  public dropdownSettingsAccess = {};

  public selectedItemsAccessName = [];
  public dropdownSettingsAccessName = {};
  public sidebarAccess = [
    //EMPRESA
    { 'id': 'company', 'itemName': 'Crear Empresa' },
    { 'id': 'store', 'itemName': 'Sucursal' },
    { 'id': 'manager', 'itemName': 'Encargado' },
    { 'id': 'owner', 'itemName': 'Propietario' },

    //USUARIOS
    { 'id': 'user', 'itemName': 'Usuario' },
    { 'id': 'multi-company-user', 'itemName': 'Usuario Multi Empresa' },
    { 'id': 'permission', 'itemName': 'Rol y Acceso' },
    { 'id': 'access', 'itemName': 'Rol y Acceso' },

    //ADMINISTRACION
    { 'id': 'provider', 'itemName': 'Proveedor' },
    { 'id': 'client', 'itemName': 'Cliente' },
    { 'id': 'inventory', 'itemName': 'Inventario' },
    { 'id': 'warehouse', 'itemName': 'Bodega' },
    { 'id': 'transfer', 'itemName': 'Transferencia' },
    { 'id': 'transfer-process', 'itemName': 'Procesar Transferencia' },

    //COMPRAS
    { 'id': 'purchase-insert', 'itemName': 'Ingresar Orden' },
    { 'id': 'purchase-pending', 'itemName': 'Autorizar / Cancelar Orden' },
    { 'id': 'purchase-process', 'itemName': 'Procesar Compra' },
    { 'id': 'purchase-view', 'itemName': 'Ver Compras' },

    //FACTURACION
    { 'id': 'billing', 'itemName': 'Facturar' },
    { 'id': 'inventory-billing-fuel', 'itemName': 'Facturar Combustible' },
    { 'id': 'invoice-recurrent', 'itemName': 'Factura Recurrente' },
    { 'id': 'exchange-note', 'itemName': 'Factura Cambiaria' },
    { 'id': 'export-invoice', 'itemName': 'Factura Exportacion' },
    { 'id': 'report-store', 'itemName': 'Reporte de Venta Sucursal' },
    { 'id': 'report-company', 'itemName': 'Reporte de Venta Empresa' },
    { 'id': 'cancellation', 'itemName': 'Anular' },
    { 'id': 'cancellation', 'itemName': 'Re-imprimir' },
    { 'id': 'credit-note', 'itemName': 'Nota de Credito' },
    { 'id': 'debit-note', 'itemName': 'Nota de Debito' },

    //CONTABILIDAD
    { 'id': 'payment-invoice', 'itemName': 'Cuentas por Cobrar' },
    { 'id': 'payment-purchase', 'itemName': 'Cuentas por Pagar' },
    { 'id': 'payment-records', 'itemName': 'Registros Ventas / Compras' },
    { 'id': 'balance-sheet', 'itemName': 'Balance' },
    { 'id': 'dairy', 'itemName': 'Libro Diario' },

    //MANTENIMIENTO
    { 'id': 'client-category', 'itemName': 'Catalogo de Clientes' },
    { 'id': 'provider-category', 'itemName': 'Catalogo de Proveedores' },
    { 'id': 'company-plan', 'itemName': 'Planes' },
    { 'id': 'product', 'itemName': 'Producto' },
    { 'id': 'company-type', 'itemName': 'Tipo de Empresa' },
    { 'id': 'phrase-stage', 'itemName': 'Frase / Escenario' }
  ];

  constructor(
    private _utilsService: UtilsService,
    private cdref: ChangeDetectorRef,
    private _roleService: RoleService,
    private _accessService: AccessService,
    private _companyService: CompanyService
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    // LOAD VARIABLE
    this.isEditRole = false;
    this.isEditAccess = false;
    this.isEditRoleAccess = false;
    this.isDisabled = false;

    // LOAD INFORMATION
    this.getAllRole();
    this.getAllAccess();

    // SELECT COMPANY & TYPE
    this.getCompanyList();
    this.getSelectSettingCompany(this.selectedItemsCompany);
    this.getTypeAcesssList();
    this.getSelectSettingAccessType(this.selectedItemsAccessType);

    this.getAccessList();
    this.getSelectSettingAccess(this.selectedItemsAccess);

    this.getSelectSettingAccessName(this.selectedItemsAccessName);

    // LOAD VIEWS
    this.viewBeginning();
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.tempAccess = this.accessList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSettingCompany(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsCompany = [selected] : '';


    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true
    };
  };

  getSelectSettingAccessType(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsAccessType = [selected] : '';

    this.dropdownSettingsAccessType = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };

  getSelectSettingAccess(selected) {
    // this.selectedItemsAccess = selected;
    Object.keys(selected).length > 0 ? this.selectedItemsAccessType = [selected] : '';

    this.dropdownSettingsAccess = {
      singleSelection: false,
      text: "Seleccione...",
      selectAllText: 'Seleccionar todos',
      unSelectAllText: 'Deseleccionar todos',
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };

  getSelectSettingAccessName(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsAccessName = [selected] : '';

    this.dropdownSettingsAccessName = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true,
    };
  };


  getCompanyList() {
    return this._companyService.getAll().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.companyList.push({
              id: e.companyCode,
              itemName: e.companyBusinessName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getTypeAcesssList() {
    return this._accessService.getAllTypeAccess().then(
      response => {
        if (response.result == true) {
          response.records[0].forEach(e => {
            this.accessTypeList.push({
              id: e.accessTypeCode,
              itemName: e.accessTypeName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getAccessList() {
    this.loading = true;

    return this._accessService.getAllAccess().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.accessSelectList.push({
              id: e.accessCode,
              itemName: e.accessName + ' - ' + e.tipo
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  onItemSelectCompany(item: any) {
    this.role.roleCompanyCode = item.id;
  }

  onItemSelectAccessType(item: any) {
    this.access.accessType = item.id;
  }

  // ACCESS
  onItemSelectAccess(item: any) {
    this.arrayAccess.push({
      RoleAccessAccessCode: item.id
    });
  }

  onItemSelectAccessName(item: any) {
    this.access.accessName = item.itemName
  }

  OnItemDeSelectAccess(item: any) {
    this.arrayAccess.forEach((e, index) => {
      if (e.RoleAccessAccessCode == item.id) this.arrayAccess.splice(index, 1);
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL ROLE
  getAllRole() {
    this.loading = true;

    return this._roleService.getAll().then(
      response => {
        if (response.result) {
          this.roleList = response.records;
          this.tempRole = this.roleList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ALL ACCESS
  getAllAccess() {
    this.loading = true;

    return this._accessService.getAllAccess().then(
      response => {
        if (response.result) {
          this.accessList = response.records;
          this.tempAccess = this.accessList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE ROLE - ACCESS
  accessFunction($event) {
    $event.preventDefault();
    this.role.roleName = this.activeRow.roleName;
    this.roleAccess.roleAccessRoleCode = this.activeRow.roleCode;
    this.roleCodeTemp = this.activeRow.roleCode;

    this.getOneRoleAccess(this.activeRow.roleCode);
    this.viewAccess();
  }

  getOneRoleAccess(role) {
    this.loading = true;
    this.arrayAccess = new Array;

    return this._roleService.getOneRoleAccess(role).then(
      response => {
        if (response.result) {
          let count = response.records.length;
          let _selectedItemAccess = new Array;

          if (count > 0) {
            response.records.forEach(e => {
              let items = this.accessSelectList.find(x => x.id == e.RoleAccessAccessCode);

              _selectedItemAccess.push({
                id: items.id,
                itemName: items.itemName
              });
              this.arrayAccess.push({
                RoleAccessAccessCode: items.id
              });
            });
            this.getSelectSettingAccess(_selectedItemAccess);
          }
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  saveRoleAccess() {
    this.isDisabled = true;
    this.success = false;
    this.loading = true;

    // UPDATE ROLE => DELETED ACCESS
    this.roleAccess.roleAccessRoleCode = this.roleCodeTemp;
    this._roleService.updateRoleAccess(this.roleAccess).then(
      async (response) => {

        // INSERT ACCESS ROLE
        this.roleAccess = {} as RoleAccess;
        this.arrayAccess.forEach(e => {
          this.roleAccess.roleAccessRoleCode = this.roleCodeTemp;
          this.roleAccess.roleAccessAccessCode = e.RoleAccessAccessCode;
          this._roleService.insertRoleAccess(this.roleAccess).then(
            async (response) => {
              if (response.result == true) {
                this.success = true;
                this.message = response.message;
              } else {
                this.success = false;
                this.message = response.errorMessage;
                console.log(response.errorMessage);
              }
            },
            error => {
              this.success = false;
              this.message = error;
              console.log(error);
            }
          );
          this.roleAccess = {} as RoleAccess;
        });
      },
      error => {
        console.log("Update", error);
      }
    );

    if (this.success = true) {
      this.isDisabled = false;
      this.loading = false;
      console.log(this.message);
      this._utilsService.showNotification(1, this.message);
      this.cleanModelRoleAccess();
    } else if (this.success = false) {
      this.isDisabled = false;
      this.loading = false;
      this._utilsService.showNotification(3, this.message);
      console.log(this.message);
    }
  }

  // INSERT ROLE
  saveRole() {
    this.isDisabled = true;
    this.loading = true;

    this.role.roleStatus = 1;
    this.role.roleDeleted = 0;

    console.log(this.role);
    this._roleService.insert(this.role).then(
      async (response) => {
        if (response.result == true) {
          this.cleanModelRole();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // INSERT ACCESS
  saveAccess() {
    this.isDisabled = true;

    this.access.accessStatus = 1;
    this.access.accessDeleted = 0;

    // console.log(this.access);

    this._accessService.insert(this.access).then(
      async (response) => {
        if (response.result == true) {
          this.cleanModelAccess();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // DELETED ROLE
  deleteFunctionRole($event) {
    $event.preventDefault();

    this.role.roleCompanyCode = this.activeRow.roleCompanyCode;
    this.role.roleCode = this.activeRow.roleCode;
    this.role.roleName = this.activeRow.roleName;
    this.role.roleStatus = 0;
    this.role.roleDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "El rol se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.updateRole();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // DELETED ACCESS
  deleteFunctionAccess($event) {
    $event.preventDefault();

    this.access.accessCode = this.activeRow.accessCode;
    this.access.accessName = this.activeRow.accessName;
    this.access.accessType = this.activeRow.accessType;
    this.access.accessStatus = 0;
    this.access.accessDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "El rol se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.updateAccess();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // UPDATE ROLE
  updateRole() {
    this.isDisabled = true;
    this.loading = true;

    this._roleService.update(this.role).then(
      async (response) => {
        if (response.result == true) {
          this.isEditRole = false;
          this.cleanModelRole();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // UPDATE ACCESS
  updateAccess() {
    this.isDisabled = true;
    this.loading = true;

    this._accessService.update(this.access).then(
      async (response) => {
        if (response.result == true) {
          this.isEditAccess = false;
          this.cleanModelAccess();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // LOAD DATA TO EDIT ROLE
  editFunctionRole($event) {
    $event.preventDefault();
    this.isEditRole = true;

    this.role.roleCode = this.activeRow.roleCode;
    this.role.roleName = this.activeRow.roleName;
    this.role.roleStatus = this.activeRow.roleStatus;
    this.role.roleDeleted = this.activeRow.roleDeleted;
    this.role.roleCompanyCode = this.activeRow.roleCompanyCode;

    const _selectedItemCompany = this.companyList.find(x => x.id == this.activeRow.roleCompanyCode);
    this.getSelectSettingCompany(_selectedItemCompany);
  }

  // LOAD DATA TO EDIT ACCESS
  editFunctionAccess($event) {
    $event.preventDefault();
    this.isEditAccess = true;

    this.access.accessCode = this.activeRow.accessCode;
    this.access.accessName = this.activeRow.accessName;
    this.access.accessRoute = this.activeRow.accessRoute;
    this.access.accessStatus = this.activeRow.accessStatus;
    this.access.accessDeleted = this.activeRow.accessDeleted;
    this.access.accessType = this.activeRow.accessType;

    const _selectedItemAccessType = this.accessTypeList.find(x => x.id == this.activeRow.accessType);
    this.getSelectSettingAccessType(_selectedItemAccessType);

    const _selectedItemAccessName = this.sidebarAccess.find(x => x.itemName == this.activeRow.accessName);
    this.getSelectSettingAccessName(_selectedItemAccessName);
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //  VIEWS
  viewBeginning() {
    this.isViewBeginning = true;
    this.isViewAccess = false;
  }

  viewAccess() {
    this.isViewBeginning = false;
    this.isViewAccess = true;
  }

  //CANCEL EDIT ROLE
  cancelUpdateRole($event) {
    this.cleanModelRole();
    this.isEditRole = false;
  }

  //CANCEL EDIT ACCESS
  cancelUpdateAccess($event) {
    this.cleanModelAccess();
    this.isEditAccess = false;
  }

  //CANCEL EDIT ROLE ACCESS
  cancelUpdateRoleAccess($event) {
    this.cleanModelRoleAccess();
    this.isEditAccess = false;
    this.viewBeginning();
  }

  // CLEAR MODAL ROLE
  cleanModelRole() {
    this.getAllRole();
    this.isDisabled = false;
    this.role = {} as Role;
    this.selectedItemsCompany = null;
  }

  // CLEAR MODAL ACCESS
  cleanModelAccess() {
    this.getAllAccess();
    this.isDisabled = false;
    this.access = {} as Access;
    this.selectedItemsAccessName = null;
    this.selectedItemsAccessType = null;
  }

  cleanModelRoleAccess() {
    this.getAllAccess();
    this.getAllRole();
    this.isDisabled = false;
    this.roleAccess = {} as RoleAccess;
    this.role = {} as Role;
    this.access = {} as Access;
    this.selectedItemsAccess = null;

    this.viewBeginning();
  }
}
