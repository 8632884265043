<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/purchase">Ingresar Orden</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- INPUT PURCHASE -->
    <div class="row">
        <div class="col-md-12">
            <div class="card ">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-11">
                            <h4 class="card-title">
                                Ingresar orden de compra
                            </h4>
                        </div>
                    </div>
                </div>

                <div class="card-body ">
                    <div>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Importado</label>
                                        <div class="form-group">
                                            <bSwitch [switch-on-color]="'success'" (changeState)="onChange($event)"
                                                [(ngModel)]="purchase.purchaseImport"
                                                switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                                switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                            </bSwitch>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <label>Proveedor</label>
                                        <angular2-multiselect [data]="providerList" [(ngModel)]="selectedItemsProvider"
                                            [settings]="dropdownSettingsProvider"
                                            (onSelect)="onItemSelectProvider($event)">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="row">
                                    <div class="col-md-7">
                                        <label>Descuento</label>
                                        <div class="form-group">
                                            <input type="number" class="form-control"
                                                (blur)="ApplyDiscount($event, purchase.purchaseDiscount)"
                                                [(ngModel)]="purchase.purchaseDiscount" autocomplete="off"/>
                                                <!-- [disabled]="isImport" -->
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <label></label>
                                        <div class="form-group">
                                            <button class="btn btn-success btn-sale" (click)="savePurchase()"
                                                [disabled]="isDisabled">
                                                ✔ Ingresar Orden
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <!-- NEW ITEM -->
                        <div class="row">
                            <div class="col-md-5">
                                <div class="row">
                                    <!-- NEW ITEM -->
                                    <div class="col-md-12 pa-star">
                                        <label>Descripción</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control pa"
                                                [(ngModel)]="purchaseDetail.purchaseDetailDescription"
                                                placeholder="Descripción" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4 pa-mar">
                                        <label>Cantidad</label>
                                        <div class="form-group">
                                            <input type="number" class="form-control pa"
                                                [(ngModel)]="purchaseDetail.purchaseDetailRequestQuantity"
                                                placeholder="Cantidad" (blur)="calculateTax()" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 pa-mar">
                                        <label>Precio</label>
                                        <div class="form-group">
                                            <input type="number" class="form-control pa"
                                                [(ngModel)]="purchaseDetail.purchaseDetailUnitPrice"
                                                placeholder="Precio" (blur)="calculateTax()" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 pa-mar">
                                        <label>Impuesto</label>
                                        <div class="form-group">
                                            <input type="number" class="form-control pa"
                                                [(ngModel)]=" purchaseDetail.purchaseDetailTax" placeholder="Impuesto"
                                                disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="row">
                                    <div class="col-md-12 pa-end">
                                        <label>&nbsp;</label>
                                        <button type="submit" class="btn-min btn btn-info btn-sale" (click)="saveLine()"
                                            style="padding: 0px 0px 0px 0px!important;">
                                            ✔ </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr style="font-weight: bold;">
                                                <td>No.</td>
                                                <td>Cantidad</td>
                                                <td>Descripción</td>
                                                <td class="text-center">P/U con IVA {{moneda}}</td>
                                                <td class="text-center">Total {{moneda}}</td>
                                                <td class="text-center">Impuesto {{moneda}}</td>
                                                <td class="text-center">Opcion</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let detail of purchaseLine, let i=index">
                                                <td class="text-center">{{i+1}}</td>
                                                <td class="text-center">{{detail.detailRequestQuantity}}</td>
                                                <td> {{detail.detailDescription}}</td>
                                                <td class="text-right">{{detail.detailUnitPrice | number:'1.2-2'}}</td>
                                                <td class="text-right">{{detail.detailPrice | number:'1.2-2'}}</td>
                                                <td class="text-right" style="color: #b7b7b7;">{{detail.detailTax |
                                                    number:'1.2-2'}}
                                                </td>
                                                <td class="text-center">
                                                    <button class="btn btn-warning btn-sm"
                                                        (click)="editLine(detail.detailCode)" [disabled]="isDisabled"
                                                        ngbTooltip="Editar" placement="left">
                                                        <i class="now-ui-icons arrows-1_share-66"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button class="btn btn-danger btn-sm"
                                                        (click)="deleteLine(detail.detailCode)" [disabled]="isDisabled"
                                                        ngbTooltip="Eliminar" placement="left">
                                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4" style="text-align: right;">
                                <span style="font-weight: bold; font-size: 15px; color: darkgray;">
                                    Total sin descuento <small
                                        style="font-weight: bold; font-size: 10px;">{{moneda}}</small>
                                    {{totalSD | number:'1.2-2'}}
                                </span>
                            </div>
                            <div class="col-md-4" style="text-align: right; color: darkgray;">
                                <span style="font-weight: bold; font-size: 15px;">
                                    Impuesto <small style="font-weight: bold; font-size: 10px;">{{moneda}}</small>
                                    {{totalTax | number:'1.2-2'}}
                                </span>
                            </div>
                            <div class="col-md-4" style="text-align: right;">
                                <span style="font-weight: bold; font-size: 25px;">
                                    Total <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                                    {{total | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <div style="text-align: end;">
        <h3>
          Numero: {{numero | letras}} <br>
        </h3> 
     </div> -->
</div>