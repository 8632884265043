<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/company">Empresas</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- INPUT COMPANY -->
    <div class="row">
        <div class="col-md-12">
            <div class="card ">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-11">
                            <b class="card-title" *ngIf="!isEdit">Nueva Empresa</b>
                            <b class="card-title text-warning" *ngIf="isEdit">Modificar Empresa</b>
                        </div>
                        <div class="col-1" *ngIf="isEdit">
                            <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp"
                                (click)="cancelUpdate($event)"><i class="fas fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <ngb-tabset type="pills" class="nav nav-pills nav-pills-info justify-content-center tab-space">
                        <ngb-tab title="Datos">
                            <ng-template ngbTabContent>
                                <div class="row">
                                    <div class="col-md-6 col-lg-4">
                                        <label>*Razón Social</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="company.companyName" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <label>*Nombre Comercial</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyBusinessName" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <label>*Dirección</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyAddress" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>*Tipo de Empresas</label>
                                            <angular2-multiselect [data]="companyTypeList"
                                                [(ngModel)]="selectedItemsType" [settings]="dropdownSettings"
                                                (onSelect)="onItemSelect($event)">
                                            </angular2-multiselect>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>*Nit</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyTaxDocument" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Teléfono</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyPhone" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Correo</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyEmail" />
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Facturación">
                            <!-- Certificador -->
                            <ng-template ngbTabContent>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>*Certificador</label>
                                            <select class="form-control" [(ngModel)]="company.companyCertifier">
                                                <option value="0">Infile</option>
                                                <option value="1">Megaprint</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>*Llave</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="company.companyKey" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Token</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyToken" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>*Prefijo</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyPrefix" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>*Escenario </label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyPhraseCode" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>*Tipo Frase</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companyPhraseType" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>*Impuesto</label>
                                            <select class="form-control" [(ngModel)]="company.companyTaxToPay">
                                                <option value=5>5%</option>
                                                <option value=12>12%</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>*Plantilla</label>
                                            <select class="form-control" [(ngModel)]="company.companyPDFType">
                                                <option value=0>Certificador</option>
                                                <option value=1 selected>Clever</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>*Tipo de Factura</label>
                                            <select class="form-control" [(ngModel)]="company.companyInvoicePrint">
                                                <option value=0>Carta</option>
                                                <option value=1 selected>Ticket</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>*Facturar sin existencia</label>
                                        <div class="form-group">
                                            <!-- [switch-disabled]="company.companyInvoiceType == 0 || company.companyInvoiceType == 3" -->
                                            <bSwitch [switch-on-color]="'success'"
                                                [(ngModel)]="company.companyExistenceFlag"
                                                switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                                switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                            </bSwitch>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Vende combustible</label>
                                        <div class="form-group">
                                            <!-- [switch-disabled]="company.companyInvoiceType == 0" -->
                                            <bSwitch [switch-on-color]="'success'"                                                
                                                [(ngModel)]="company.companyInvoiceFuel"
                                                switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                                switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                            </bSwitch>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Opción">
                            <ng-template ngbTabContent>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label>*Plan</label>
                                            <angular2-multiselect [data]="companyPlanList"
                                                [(ngModel)]="selectedItemsPlan" [settings]="dropdownSettings"
                                                (onSelect)="onItemSelectPlan($event)">
                                            </angular2-multiselect>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Descuento en plan</label>
                                        <div class="form-group">
                                            <input type="number" class="form-control"
                                                [(ngModel)]="company.companyDiscount" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Página web</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" [(ngModel)]="company.companyUrl" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Sub dominio</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="company.companySubdomain" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Compra Centralizada</label>
                                        <div class="form-group">
                                            <bSwitch [switch-on-color]="'success'"
                                                [(ngModel)]="company.companyCentralized"
                                                switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                                switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                            </bSwitch>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Cambiar contraseña primer ingreso</label>
                                        <div class="form-group">
                                            <bSwitch [switch-on-color]="'success'"
                                                [(ngModel)]="company.companySignInPasswordChange"
                                                switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                                switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                            </bSwitch>
                                        </div>
                                    </div>                                    
                                    <div class="col-md-3">
                                        <label>No. intentos de ingreso</label>
                                        <div class="form-group">
                                            <input type="number" class="form-control"
                                                [(ngModel)]="company.companySignInAttempt" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Inventario Unificado</label>
                                        <div class="form-group">
                                            <bSwitch [switch-on-color]="'success'"
                                                [(ngModel)]="company.companyInventoryUnique"
                                                switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                                switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                            </bSwitch>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Sucursal Inventario</label>
                                            <angular2-multiselect [data]="storeList" [(ngModel)]="selectedItemsStore"
                                                [settings]="dropdownSettingsStore" (onSelect)="onItemSelectStore($event)">
                                            </angular2-multiselect>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="img-container" style="width: 90%">
                                                    <img src={{companyLogoUrl}}>
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="form-group mt-4">
                                                    <span class="btn btn-block btn-round btn-file"
                                                        [ngClass]="{'btn-gray' : isObjectEmpty(state), 'btn-success' : !isObjectEmpty(state)}">
                                                        <span class="fileinput-new">JPG/PNG</span>
                                                        <input type="file" (change)="addFile($event)" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mt-4">
                                            <button class="btn btn-info btn-block" (click)="save()" *ngIf="!isEdit"
                                                [disabled]="isDisabled">Guardar</button>
                                            <button class="btn btn-primary btn-block" (click)="update()" *ngIf="isEdit"
                                                [disabled]="isDisabled">Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
    <!-- END INPUT COMPANY -->
    <!-- TABLE -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Empresas</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                            <ngx-datatable-column name="Telefono"></ngx-datatable-column>
                            <ngx-datatable-column name="Correo"></ngx-datatable-column>
                            <ngx-datatable-column name="Direccion"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewFunction($event)" ngbTooltip="Ver"><i class="fas fa-eye"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="editFunction($event)" ngbTooltip="Editar"><i
                                            class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="deleteFunction($event)" ngbTooltip="Eliminar"><i
                                            class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>