import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-recurrent',
  templateUrl: './invoice-recurrent.component.html',
  styleUrls: ['./invoice-recurrent.component.scss']
})
export class InvoiceRecurrentComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit() {
  }
}
